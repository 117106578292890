import React, { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRegDoc, useRegFlusso } from "../../../hooks/useAnalytics";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { spidStatusSelectors } from "../../../store/spidStatus/spidStatus.selectors";
import { utagLink, utagView } from "../../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../../store/wizard/wizard.selectors";
import {
  checkingForm,
  contactsContainsError,
} from "../../../utility/checkingForm/checkingForm.container";
import formIsValid from "../../../utility/formIsValid/formIsValid.container";
import { moveCursorAtTheEnd } from "../../../utility/input/input";
import { isValidEmailFormat } from "../../../utility/regexCheck/regexCheck";
import MessageError from "../../elementForm/messageError/messageError.component";
import { useSummaryFormErrorsTracking } from "../../summary/summary.hooks";
import { useContactsBackendValidation } from "../contacts.hooks";
import EmailPredictions from "./partials/EmailPredictions.component"; // Import the new component

export default function EmailContact() {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const reg_doc = useRegDoc();

  const [selectedEmailPrediction, setSelectedEmailPrediction] = useState("");

  const [canShowEmailError, setCanShowEmailError] = useState(false);
  let [mailPrediction, setMailPrediction] = useState(false);
  const [emailPredictions] = useState([
    "@gmail.com",
    "@hotmail.it",
    "@libero.it",
    "@icloud.com",
  ]);
  let [previousValue, setPreviousValue] = useState({
    email: formData.email,
  });

  const emailInputRef = useRef();

  let predictionButtons = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  useSummaryFormErrorsTracking("email", canShowEmailError);

  const { validateEmailAPI } = useContactsBackendValidation();

  const reg_flusso = useRegFlusso();

  const handleKeyDownEmail = (event) => {
    switch (event.key) {
      case " ":
        event.preventDefault();
        break;
      case "Enter":
        emailInputRef.current.blur();
        break;
      default:
        break;
    }
  };

  const handleChangeEmail = async (value) => {
    dispatch(addFormStatus({ email: "" }));

    setMailPrediction(!isValidEmailFormat(value));

    const limitedValue = value.substring(0, 50);

    if (/^[a-zA-Zàáèéìíòóùú@0-9./'_\-!#$%&'*+-/=?^_`{|}~.@]*$/.test(value)) {
      isValidEmail(limitedValue);

      dispatch(disabledBtn(contactsContainsError(formData, formStatus)));

      if (
        limitedValue.substring(
          limitedValue.indexOf("@"),
          limitedValue.length
        ) !== emailPredictions[selectedEmailPrediction]
      ) {
        setSelectedEmailPrediction("");
      }
    }

    dispatch(addFormStatus({ isBounceClickEmail: false }));

    inizializePrediction();
  };

  const isValidEmail = (value) => {
    setCanShowEmailError(false);

    dispatch(addFormData({ email: value }));

    const error = formIsValid("email", value);
    dispatch(addFormStatus(error));
  };

  const handleBlurEmail = (value) => {
    if (value.length === 0) {
      setMailPrediction(false);

      setCanShowEmailError(true);
    } else {
      const error = formIsValid("email", value);
      dispatch(addFormStatus(error));
    }

    if (previousValue.email !== value && component.name === "summary") {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "email",
        })
      );
    }
    setPreviousValue({ ...previousValue, email: value });
  };

  const checkValidation = () => {
    dispatch(disabledBtn(checkingForm("contacts")));
  };

  const handleEmailBlur = async (value, relatedTarget) => {
    let isPredictionButton = false;
    for (let i = 0; i < predictionButtons.length && !isPredictionButton; i++) {
      if (relatedTarget === predictionButtons[i].current) {
        isPredictionButton = true;
      }
    }
    if (!isPredictionButton) {
      setCanShowEmailError(true);
    }
    setMailPrediction(false);
    await handleBlurEmail(value);
  };

  const validatorEmail = async () => {
    if (!formStatus.email) {
      await validateEmailAPI(formData.email);
    }
    checkValidation();
  };

  const setEmailPrediction = (key, value) => {
    dispatch(addFormStatus({ email: "" }));
    if (selectedEmailPrediction.toString() === key) {
      isValidEmail(formData.email.substring(0, formData.email.indexOf("@")));
      checkValidation();
      setSelectedEmailPrediction("");
    } else {
      let emailInput = formData.email;
      if (emailInput.indexOf("@") !== -1) {
        emailInput = emailInput.substring(0, emailInput.indexOf("@"));
      }
      emailInput = emailInput + value;
      isValidEmail(emailInput);
      let error = formIsValid("email", emailInput);
      dispatch(addFormStatus(error));
      if (error && error.email === "") {
        validatorEmail();
      }
      setSelectedEmailPrediction(key);
      if (error && error.email === "") validateEmailAPI(emailInput);
    }

    setCanShowEmailError(true);

    dispatch(addFormStatus({ isBounceClickEmail: true }));

    dispatch(utagLink({ reg_event: "suggerimento-dominio" }));

    setMailPrediction(false);
  };

  const inizializePrediction = () => {
    let indexOfSelectedMail = emailPredictions.indexOf(
      formData.email.substring(
        formData.email.indexOf("@"),
        formData.email.length
      )
    );
    if (indexOfSelectedMail > -1) {
      setSelectedEmailPrediction(indexOfSelectedMail.toString());
      setMailPrediction(false);
    }
  };

  useEffect(() => {
    if (formData.email !== "") {
      setCanShowEmailError(true);
    }
     checkValidation();

    inizializePrediction();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (emailInputRef.current && window.screen.width >= 899) {
      emailInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (component.name !== "contacts") return;

    if (flusso === "C1" || flusso === "OCR") {
      dispatch(
        utagView({
          reg_step: "contatti",
          conversion_pagename: "contatti",
          reg_flusso,
          ...(reg_flusso === "ocr" && { country: "italia", reg_doc }),
        })
      );
    }
  }, [component.name, dispatch, flusso, reg_doc, reg_flusso]);

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        {(spidStatus.showEmail || flusso !== "SPID") && (
          <>
            <div
              id="it-ar-contacts-tab-email"
              className={
                "form-group mb-0 " +
                (component.name === "summary" ? "accordion-form mt-md-0 " : "")
              }
            >
              <input
                ref={emailInputRef}
                autoComplete="email"
                className={
                  "form-control text-lowercase " +
                  (formStatus.email.length > 0 && canShowEmailError
                    ? formStatus.email === "err.required" &&
                      component.name === "contacts"
                      ? " is-present"
                      : " is-invalid"
                    : "")
                }
                type="email"
                name="email"
                required
                maxLength="50"
                spellCheck="false"
                autoCorrect="off"
                id="email"
                value={formData.email}
                onFocus={(e) => moveCursorAtTheEnd(e.target)}
                onKeyDown={handleKeyDownEmail}
                onChange={(e) => handleChangeEmail(e.target.value)}
                onBlur={(e) => handleEmailBlur(e.target.value, e.relatedTarget)}
                style={{
                  paddingRight: "10px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              />
              <label
                className="form-control-label"
                id="it-ar-contacts-lblEmail"
                htmlFor="email"
              >
                <Translate id="lbl.email" />
              </label>
              {canShowEmailError ? (
                <MessageError
                  id="it-ar-contacts-msgEmail"
                  currentValue={formData.email}
                  currentName={"email"}
                />
              ) : (
                ""
              )}
            </div>
            {mailPrediction ? (
              <EmailPredictions
                emailPredictions={emailPredictions}
                selectedEmailPrediction={selectedEmailPrediction}
                setEmailPrediction={setEmailPrediction}
                predictionButtons={predictionButtons}
              />
            ) : (
              ""
            )}
          </>
        )}
      </form>
    </div>
  );
}
