import { connect } from "react-redux";
import MessageAlert from "./messageAlert.component";
import * as formStatusAction from "../../../store/formStatus/formStatus.action";
import * as formDataAction from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";

const mapStateToProps = (state, ownProps) => {
  return {
    formStatus: formStatusSelectors.getFormStatus(state),
    formData: formDataSelectors.getFormData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
  };
};

const MessageAlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageAlert);
export default MessageAlertContainer;
