import "@jumio/websdk";
import { Template } from "@jumio/websdk-react";
import "@jumio/websdk/assets/style.css";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setFlusso } from "../../store/wizard/wizard.action";
import { OcrPageBackBtn } from "./ocrPageBackBtn/ocrPageBackBtn.component";
import { selectJumioSdkToken } from "../../store/ocr/ocr.selectors";

export default function OcrPage() {
  const history = useHistory();

  const dispatch = useDispatch();

  const jumioSdkRef = useRef();

  const { regType } = useParams();

  const sdkToken = useSelector(selectJumioSdkToken);

  useEffect(() => {
    const flusso = regType === "pdv" ? "OCRPDV" : "OCR";
    dispatch(setFlusso(flusso));
  }, [dispatch, regType]);

  useEffect(() => {
    const jumioSdk = jumioSdkRef.current;

    if (!sdkToken || !jumioSdk) return;

    const onWorkflowSuccess = ({ detail }) => {
      history.push(
        `/ocr-callback/${regType}/${detail.accountId}/${detail.workflowExecutionId}`,
      );
    };

    const onWorkflowError = () => {
      history.push("/errore-ocr");
    };

    jumioSdk.addEventListener("workflow:success", onWorkflowSuccess);
    jumioSdk.addEventListener("workflow:failed", onWorkflowError);

    return () => {
      jumioSdk.removeEventListener("workflow:success", onWorkflowSuccess);
      jumioSdk.removeEventListener("workflow:failed", onWorkflowError);
    };
  }, [history, regType, sdkToken]);

  return (
    <div className="ocr-page">
      <OcrPageBackBtn regType={regType} />

      <main>
        <Template id="start_title">
          <h1>Fotografa documento</h1>
        </Template>

        <Template id="start_explanation">
          <p>Prepara il documento e fai click sul pulsante "Inizio".</p>
        </Template>

        <Template id="start_checklist">
          <x-ds-shield-list>
            <x-ds-shield-list-item slot="item">
              Usa un documento in corso di validità
            </x-ds-shield-list-item>
            <x-ds-shield-list-item slot="item">
              Trova una superficie ben illuminata
            </x-ds-shield-list-item>
          </x-ds-shield-list>
        </Template>

        <jumio-sdk dc="eu" token={sdkToken} locale="it" ref={jumioSdkRef}>
          <x-ds-color-scheme-config slot="theme" data-color="light" />
        </jumio-sdk>
      </main>
    </div>
  );
}
