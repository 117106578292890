import { KEY_RE_CAPTCHA } from "../config";

export async function getRecaptchaToken(action) {
  try {
    return await window.grecaptcha.enterprise.execute(KEY_RE_CAPTCHA, {
      action,
    });
  } catch {
    console.error("Could not get recaptcha token");
    return "";
  }
}

export function isRecaptchaError(error) {
  const errorObj = error.response?.data?.error;
  const errorInfos = errorObj?.errorInfos;

  if (!errorInfos) return false;

  return (
    error.response.status === 422 &&
    errorObj.type === "VALIDATION" &&
    errorObj.message?.includes("qr failure") &&
    Array.isArray(errorInfos.void) &&
    errorInfos.void.some((err) => err === "err.void")
  );
}
