import React from "react";
import { Translate } from "react-localize-redux";
import PopupModal from "../../popupModal/popupModal";
import PrivacyInfoModalBody from "./partials/privacyInfoModalBody.component";

export default function PrivacyInfoModal({
  isMobile,
  onClose,
  setPdfActive,
  type,
}) {
  return (
    <div className="privacy-info-modal">
      <PopupModal>
        <PopupModal.Header closeMe={onClose}>
          <Translate id="text.informationPrivacy" />
        </PopupModal.Header>
        <PopupModal.Body hideScrollbar>
          <PrivacyInfoModalBody
            onClose={onClose}
            isMobile={isMobile}
            setPdfActive={setPdfActive}
            type={type}
          />
        </PopupModal.Body>
        <PopupModal.Footer>
          <button onClick={onClose}>
            <Translate id="lbl.button.ok" />
          </button>
        </PopupModal.Footer>
      </PopupModal>
    </div>
  );
}
