import { v4 as uuidv4 } from "uuid";
import { instancePost } from "../axios.config";
import axios from "axios";
import { PORT, REACT_APP_URL } from "../config";

export const postOcrData = async (
  docType,
  fiscalCode,
  front,
  back,
  channel,
) => {
  const CancelToken = axios.CancelToken; //cancel previous calls
  const source = CancelToken.source();

  let url = `${REACT_APP_URL}${PORT}/ocr-ms/ms/docsUpload`; //TODO cambiare url /ms/ocr/docsUpload

  var formData = new FormData();

  if (back && back !== "") {
    /* controlla docType !== 14 in if non serve*/
    var extBack = back.name.substr(back.name.lastIndexOf(".") + 1);
    var filenameBack = back.name.substr(0, back.name.lastIndexOf(".") + 1);
    var newBack = new File([back], filenameBack + extBack.toLowerCase(), {
      type: back.type,
    });
    formData.append("retro", newBack);
  }
  var extFront = front.name.substr(front.name.lastIndexOf(".") + 1);
  var filenamefront = front.name.substr(0, front.name.lastIndexOf(".") + 1);
  var newfront = new File([front], filenamefront + extFront.toLowerCase(), {
    type: front.type,
  });
  formData.append("docType", docType);
  formData.append("fiscalCode", fiscalCode);
  formData.append("front", newfront);
  formData.append("requestId", uuidv4());
  formData.append("channel", channel);
  var option = { timeout: 1000 * 50 };

  const res = await instancePost.post(url, formData, option, {
    cancelToken: source.token,
  });

  if (source) {
    source.cancel("Operation canceled by the user.");
  }
  return res.data;
};

export const uploadImages = async (
  docType,
  fiscalCode,
  front,
  back,
  email,
  channel,
  token,
) => {
  const CancelToken = axios.CancelToken; //cancel previous calls
  const source = CancelToken.source();

  let url = `${REACT_APP_URL}${PORT}/ocr-ms/ms/auth/docsUpload`; //TODO cambiare url /ms/ocr/docsUpload

  const formData = new FormData();

  if (back && back !== "") {
    /* controlla docType !== 14 in if non serve*/
    const extBack = back.name.substr(back.name.lastIndexOf(".") + 1);
    const filenameBack = back.name.substr(0, back.name.lastIndexOf(".") + 1);
    const newBack = new File([back], filenameBack + extBack.toLowerCase(), {
      type: back.type,
    });
    formData.append("retro", newBack);
  }
  const extFront = front.name.substr(front.name.lastIndexOf(".") + 1);
  const filenamefront = front.name.substr(0, front.name.lastIndexOf(".") + 1);
  const newfront = new File([front], filenamefront + extFront.toLowerCase(), {
    type: front.type,
  });

  formData.append("docType", docType.toString());
  formData.append("fiscalCode", fiscalCode);
  formData.append("front", newfront);
  formData.append("email", email);
  formData.append("requestId", uuidv4());
  formData.append("channel", channel);
  const option = {
    headers: {
      authorization: `Bearer\u0020${token}`,
    },
    timeout: 1000 * 50,
  };
  const res = await instancePost.post(url, formData, option, {
    cancelToken: source.token,
  });

  if (source) {
    source.cancel("Operation canceled by the user.");
  }
  return res.data;
};
