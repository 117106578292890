import { isEqual } from "lodash";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../../store/formStatus/formStatus.selectors";
import {
  domicileContainsErrors,
  residenceContainsErrors,
} from "../../../../utility/checkingForm/checkingForm.container";
import Domicile from "../../../domicile/domicile.component";
import PopupModal from "../../../popupModal/popupModal";
import Residence from "../../../residence/residence.component";
import { extractAddressDataFromFormData } from "../../summary.helpers";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupEditFooter from "../../summaryPopupFooter/summaryPopupEditFooter.component";
import { addressLinkTranslateId } from "../summaryAddress.config";

export default function SummaryAddressPopupEditContent({
  onClose,
  onCancelEdit,
  previousData,
}) {
  const handleCloseIcon = () => {
    onCancelEdit();
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleCloseIcon}>
        <Translate id="lbl.residenceDomicile" />
      </PopupModal.Header>

      <PopupModal.Body>
        <SummaryAddressPopupEditBody />
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryAddressPopupEditFooter
          onConfirm={onClose}
          onCancel={onCancelEdit}
          previousData={previousData}
        />
      </PopupModal.Footer>
    </>
  );
}

function SummaryAddressPopupEditBody() {
  const { sameResidenceAndDomicile } = useSelector(
    formDataSelectors.getFormData,
  );

  return (
    <div className="popup-body popup-address-body">
      <Residence />

      {!sameResidenceAndDomicile && <Domicile />}
    </div>
  );
}

function SummaryAddressPopupEditFooter({ onCancel, onConfirm, previousData }) {
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const { trackCancelEditData, trackConfirmEditData } =
    useSummaryAnalyticsTrackLinks();

  const dirty = isAddressFormDirty(formData, previousData);

  const confirmDisabled =
    !dirty ||
    residenceContainsErrors(formData, formStatus) ||
    domicileContainsErrors(formData, formStatus);

  const handleCancel = () => {
    trackCancelEditData(addressLinkTranslateId);
    onCancel();
  };

  const handleConfirm = () => {
    trackConfirmEditData(addressLinkTranslateId);
    onConfirm();
  };

  return (
    <SummaryPopupEditFooter
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmDisabled={confirmDisabled}
    />
  );
}

function isAddressFormDirty(formData, previousData) {
  const current = extractAddressDataFromFormData(formData);
  const previous = extractAddressDataFromFormData(previousData);
  return !isEqual(current, previous);
}
