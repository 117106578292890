import { useEffect, useRef } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { disableBeforeUnloadPopup } from "../../../hooks/useBeforeUnloadPopup";
import { followUpSelectors } from "../../../store/followUp/followUp.selectors";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
  wizardSelectorsSignUpStatus,
} from "../../../store/wizard/wizard.selectors";
import { sendResumeRegistrationDataEmail } from "../../../utility/followUp/followUp";
import { isMobile } from "../../../utility/mobileDetection";

export default function CloseButton() {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const signupCompleted = useSelector(
    wizardSelectorsSignUpStatus.getSignUpCompleted,
  );
  const visitedFollowUpLink = useSelector(
    followUpSelectors.getVisitedFollowUpLink,
  );

  const btnRef = useRef(null);

  // Medallia is a third party library that replaces the close button with a custom one
  // This code is needed to handle the case where the user clicks on the Medallia close button
  // and we need to remove the beforeunload event listener
  useEffect(() => {
    const handleMedalliaCloseBtn = () => {
      disableBeforeUnloadPopup();
    };

    const getMedalliaCloseBtn = () =>
      document.querySelector("button#headerBtn.bb_button_show");

    const observer = new MutationObserver((mutations) => {
      const mutation = mutations.find(
        (m) => m.type === "attributes" && m.attributeName === "class",
      );

      const hasMedalliaSubstitutedCloseBtn =
        mutation?.target.classList.contains("bb_button_hide");

      if (hasMedalliaSubstitutedCloseBtn) {
        getMedalliaCloseBtn()?.addEventListener(
          "click",
          handleMedalliaCloseBtn,
        );
      }
    });

    observer.observe(btnRef.current, { attributes: true });

    return () => {
      observer.disconnect();
      getMedalliaCloseBtn()?.removeEventListener(
        "click",
        handleMedalliaCloseBtn,
      );
    };
  }, []);

  const closeCallbackUrl = () => {
    dispatch(utagLink({ reg_event: "chiudi" }));

    // only handle mobile case, desktop is handled by browser event beforeunload
    if (isMobile()) {
      sendResumeRegistrationDataEmail(
        signupCompleted,
        formData,
        visitedFollowUpLink,
        component.componentName,
        flusso,
      );
    }

    disableBeforeUnloadPopup();
    window.location.href = formData.cancelcallbackurl;
  };

  return (
    <button
      ref={btnRef}
      type="button"
      className="btn btn-discard px-0 float-right"
      id="headerBtn"
      onClick={closeCallbackUrl}
    >
      <Translate id="lbl.buttonDiscard" />
    </button>
  );
}
