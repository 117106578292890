import { Translate } from "react-localize-redux";

export default function OcrSummaryTextBlock() {
  return (
    <div className="ocr-summary-text-block">
      <h1 className="title-form">
        <Translate id="text.registrationOCR.verifyAndCompleteData" />
      </h1>

      <p className="label-form">
        <Translate id="text.registrationOCR.verifyAndCompleteDataDescription" />
      </p>
    </div>
  );
}
