import { instanceGet } from "../axios.config";
import { REACT_APP_URL } from "../config";

export const getFlows = async (flow, channel) => {
  const res = await instanceGet.get(
    `${REACT_APP_URL}/config-reg-ms/ms/routedflows${flow ? `/${flow}` : ""}`,
    { params: { channel: channel } },
  );
  return res.data;
};

export const getDefaultFlowName = async () => {
  const url = `${REACT_APP_URL}/config-reg-ms/ms/routedflowsConfig/flowDefault`;
  const res = await instanceGet.get(url);
  return res.data;
};

export const getDefaultClassicFlowName = async () => {
  const url = `${REACT_APP_URL}/config-reg-ms/ms/routedflowsConfig/flowClassicDefault`;
  const res = await instanceGet.get(url);
  return res.data;
};
