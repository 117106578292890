export default function DocumentSelectionHeadForm() {
  return (
    <div className="document-selection-head-form header-form">
      <h2 className="document-selection-head-form__title sub-title-form">
        Seleziona il documento da fotografare
      </h2>

      <p className="document-selection-head-form__description label-form">
        Seleziona il documento dall'elenco qui sotto, sono accettati solo
        documenti <strong>rilasciati in Italia.</strong>
      </p>
    </div>
  );
}
