import moment from "moment";

const DATE_FORMAT = "DD/MM/YYYY";

export function convertToFormData(value) {
  return moment(value, DATE_FORMAT)
    .add(1, "hour")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}

export function convertFromFormData(value) {
  const newValue = moment(value).format(DATE_FORMAT);
  return value && newValue !== "Invalid date" ? newValue : "";
}

export function addSlashesToDateInputValue(oldValue, typedValue) {
  const newValue =
    oldValue.length < typedValue.length &&
    (typedValue.length === 2 || typedValue.length === 5)
      ? `${typedValue}/`
      : typedValue;
  return newValue;
}

export function allowOnlyNumbers(oldValue, currentValue) {
  const lastChar = currentValue.slice(-1);

  const isDeleting = currentValue.length < oldValue.length;
  const isAddingSlash =
    (currentValue.length === 3 || currentValue.length === 6) &&
    lastChar === "/" &&
    oldValue.length < currentValue.length;

  const removeTrailingChar = (str) =>
    str.slice(0, -1) + str.slice(-1).replace(/[^0-9]/g, "");

  return !isDeleting && !isAddingSlash
    ? removeTrailingChar(currentValue)
    : currentValue;
}
