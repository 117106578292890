export default function TownLiContent({ town, province, typedTown }) {
  const townAndProvince = `${town} (${province})`;
  const afterHighlight = townAndProvince.slice(typedTown.length);

  return (
    <>
      <span className="highlighted-match">{typedTown.toUpperCase()}</span>
      {afterHighlight ?? ""}
    </>
  );
}
