import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { utagLink } from "../../store/utagStore/utagStore.action";
import ClassicRegistrationCard from "../registrationTypeSelection/classicRegistrationCard/classicRegistrationCard.component";
import RegistrationTypeSelection from "../registrationTypeSelection/registrationTypeSelection.component";
import RegistrationTypeSelectionLinkPdv from "../registrationTypeSelection/registrationTypeSelectionLinkPdv/registrationTypeSelectionLinkPdv.component";
import { useHistory } from "react-router-dom";

export default function RegistrationTypeSelectionWithOcrPage() {
  return (
    <RegistrationTypeSelection
      classicRegistrationCard={<ClassicRegistrationCardOcr />}
      bottomElement={<RegistrationTypeSelectionLinkPdv />}
    />
  );
}

function ClassicRegistrationCardOcr() {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleClick = () => {
    dispatch(
      utagLink({
        reg_event: "registrati",
        reg_campo: "registrazione|pagina_di_disambiguazione|registrati",
      }),
    );

    history.push("/seleziona-ocr");
  };

  return (
    <ClassicRegistrationCard
      title={<Translate id="text.quickRegistration" />}
      description={
        <Translate id="text.registrationOCR.registrationInstructions" />
      }
      badge={
        <div className="card-label">
          <Translate id="text.news" />
        </div>
      }
      onClick={handleClick}
    />
  );
}
