import { useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { LOGIN_REDIRECT } from "../../config";
import { useBeforeUnloadPopup } from "../../hooks/useBeforeUnloadPopup";
import {
  useAcuityAdsTracking,
  useOneTagCompletedSignupLoadScript,
  useSignUpTracking,
} from "../../hooks/useOneTagScript";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import Background from "../commons/background/background.component";
import {
  useAnalyticsUtagView,
  useAuthAction,
  useCustomRedirect,
  useDocValidationStatusCheck,
} from "./thankYouPage.hooks";
import ThankYouPageBonusBanner from "./thankYouPageBonusBanner/thankYouPageBonusBanner.component";
import ThankYouPageCompletionCtaBlocks from "./thankYouPageCompletionBlocks/thankYouPageCompletionBlocks.component";
import { ThankYouPageHeader } from "./thankYouPageHeader/thankYouPageHeader.component";
import ThankYouPageProgressWidget from "./thankYouPageProgressWidget/thankYouPageProgressWidget.component";
import TYPContactCheckModal from "./typContactCheckModal/typContactCheckModal.component";
import TypLoyaltyCard from "./typLoyaltyCard/typLoyaltyCard.component";

export default function ThankYouPage() {
  const formData = useSelector(formDataSelectors.getFormData);
  const emailCertified = useSelector(spidStatusSelectors.isEmailCertified);

  const { authenticate } = useAuthAction();
  const { customRedirect } = useCustomRedirect();

  const [isEmailVerified, setIsEmailVerified] = useState(
    emailCertified ?? false,
  );
  const [showTYPContactCheckModal, setShowTYPContactCheckModal] =
    useState(false);

  const docValidationStatus = useDocValidationStatusCheck();

  const completionPercentage = getCompletionPercentage({
    phoneNumberCertified: formData.phoneNumberCertified,
    emailCertified: isEmailVerified,
    idDocumentCertifiedOrInProgress:
      docValidationStatus === "IN_PROGRESS" ||
      docValidationStatus === "VALIDATED",
  });

  useOneTagCompletedSignupLoadScript(formData.contoId);
  useSignUpTracking(formData.contoId);
  useAcuityAdsTracking(formData.contoId);

  useBeforeUnloadPopup();

  useAnalyticsUtagView(completionPercentage);

  return (
    <div className="typ-spid">
      <Background />

      <ThankYouPageHeader />

      <div className="typ-spid-container">
        <div className="typ-spid-headline">
          <h1 className="typ-spid-title">
            <Translate id="text.typ.spid.title" />
          </h1>

          <div className="typ-spid-subtitle">
            <Translate id="text.typ.spid.subtitle" />
          </div>
        </div>

        <div className="typ-spid-progress">
          <div className="typ-spid-progress-box">
            <ThankYouPageProgressWidget
              completionPercentage={completionPercentage}
            />

            <div className="typ-spid-progress-bar-text">
              {completionPercentage !== 100 ? (
                <Translate id="text.typ.spid.completeProfile" />
              ) : (
                <Translate id="text.typ.spid.profileIsCompleted" />
              )}
            </div>
          </div>

          <ThankYouPageCompletionCtaBlocks
            emailVerified={isEmailVerified}
            onClickVerifyEmail={() => setShowTYPContactCheckModal(true)}
            completionPercentage={completionPercentage}
          />
        </div>

        {docValidationStatus === "TO_BE_UPLOADED" && (
          <div className="typ-spid-note">
            <Translate id="text.spid.document.info" />
          </div>
        )}

        <TypLoyaltyCard completionPercentage={completionPercentage} />

        {showTYPContactCheckModal && (
          <TYPContactCheckModal
            isEmailVerified={isEmailVerified}
            onClose={() => setShowTYPContactCheckModal(false)}
            setIsEmailVerified={setIsEmailVerified}
          />
        )}

        <ThankYouPageBonusBanner
          onClickRecharge={async () => {
            await authenticate();
            customRedirect(`${LOGIN_REDIRECT}/area-riservata/ricarica`);
          }}
        />
      </div>
    </div>
  );
}

function getCompletionPercentage({
  phoneNumberCertified,
  emailCertified,
  idDocumentCertifiedOrInProgress,
}) {
  let percentage = 40;
  if (phoneNumberCertified) percentage += 20;
  if (emailCertified) percentage += 20;
  if (idDocumentCertifiedOrInProgress) percentage += 20;
  return percentage;
}
