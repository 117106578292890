import {
  CLEAN_SPID_STATUS,
  SET_EMAIL_CERTIFIED,
  SPID_FISCALCODE_FLOW_STATUS,
  SPID_STATUS,
} from "./spidStatus.action";

const initialState = {
  spidStatus: {
    spidCallbackError: "",
    fiscalCodeBelfiore: false,
    fromSpidFirstLandingContacts: false,
    fromSpidFirstLandingResidence: false,
    fromSpidFirstLandingDocument: false,
    fromSpidFirstLandingFiscalCode: false,
    showEmail: false,
    showPhone: false,
    notItalianPrefix: false,
  },
  spidFiscalCodeFlowStatus: {
    error: "",
    showGenerationStep: false,
  },
  emailCertified: false,
};

export const spidStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPID_STATUS:
      const newSpidStatus = { ...state.spidStatus, ...action.spidStatus };
      return { ...state, spidStatus: newSpidStatus };
    case SPID_FISCALCODE_FLOW_STATUS:
      const newFiscalCodeFlowStatus = {
        ...state.spidFiscalCodeFlowStatus,
        ...action.spidFiscalCodeFlowStatus,
      };
      return { ...state, spidFiscalCodeFlowStatus: newFiscalCodeFlowStatus };
    case SET_EMAIL_CERTIFIED:
      return { ...state, emailCertified: action.payload };
    case CLEAN_SPID_STATUS:
      return initialState;
    default:
      return state;
  }
};
