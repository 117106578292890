import { DISABLE_SPID, ENABLE_SPID } from "./services.action";

/**
 * Initial state for the services reducer.
 *
 * @typedef {Object} InitialState
 * @property {?boolean} spidStatus - The SPID status.
 */

/** @type {InitialState} */
const initialState = {
  spidStatus: null,
};

export const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_SPID:
      return { ...state, spidStatus: true };
    case DISABLE_SPID:
      return { ...state, spidStatus: false };
    default:
      return state;
  }
};
