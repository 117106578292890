import { useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { setBonus } from "../../../../store/wizard/wizard.action";
import { wizardSelectorsBonus } from "../../../../store/wizard/wizard.selectors";
import { welcomeBonusContainsError } from "../../../../utility/checkingForm/checkingForm.container";
import PromoCode from "../../../elementForm/promoCode/promoCode.component";
import PopupModal from "../../../popupModal/popupModal";
import BonusDetailsModal from "../../../welcomeBonus/bonusDetailsModal/bonusDetailsModal.component";
import WelcomeBonusPromoCodeBlock from "../../../welcomeBonus/welcomeBonusPromoCodeBlock/welcomeBonusPromoCodeBlock.component";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupEditFooter from "../../summaryPopupFooter/summaryPopupEditFooter.component";
import { bonusLinkTranslateId } from "../summaryWelcomeBonus.config";
import SummaryWelcomeBonusListNoPromo from "./summaryWelcomeBonusListNoPromo.component";

export default function SummaryWelcomeBonusPopup({
  show,
  onClose,
  onRestoreState,
}) {
  const [popupMode, setPopupMode] = useState("default"); // modes: default, promo, detail

  const handleCancelEdit = () => {
    onRestoreState();
    onClose();
  };

  const handleCancelPromoCode = () => {
    onRestoreState();
    setPopupMode("default");
  };

  return (
    <>
      <BonusPopup
        show={show && popupMode === "default"}
        onClose={onClose}
        onClickInfoLink={() => setPopupMode("detail")}
        onClickChangeButton={() => setPopupMode("promo")}
        onCancelEdit={handleCancelEdit}
      />

      {show && popupMode === "promo" && (
        <PromoCode
          onClose={() => setPopupMode("default")}
          onCancel={handleCancelPromoCode}
        />
      )}

      <BonusDetailsModal
        show={show && popupMode === "detail"}
        onClose={() => setPopupMode("default")}
      />
    </>
  );
}

function BonusPopup({
  show,
  onClose,
  onClickInfoLink,
  onClickChangeButton,
  onCancelEdit,
}) {
  const { trackClosePopup } = useSummaryAnalyticsTrackLinks();

  const handleCloseIcon = () => {
    trackClosePopup(bonusLinkTranslateId);
    onCancelEdit();
  };

  return (
    <PopupModal show={show}>
      <PopupModal.Header closeMe={handleCloseIcon}>
        <Translate id="text.typ.spid.welcomeBonus" />
      </PopupModal.Header>

      <PopupModal.Body>
        <SummaryRechargePopupEditBody
          onClickChangeButton={onClickChangeButton}
          onClickInfoLink={onClickInfoLink}
        />
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryRechargePopupEditFooter
          onConfirm={onClose}
          onCancel={onCancelEdit}
        />
      </PopupModal.Footer>
    </PopupModal>
  );
}

function SummaryRechargePopupEditBody({
  onClickChangeButton,
  onClickInfoLink,
}) {
  const { promoCode } = useSelector(formDataSelectors.getFormData);
  const bonus = useSelector(wizardSelectorsBonus.getBonus);

  return (
    <div className="popup-body popup-welcome-body welcomeBonus">
      {promoCode != null && !!bonus ? (
        <WelcomeBonusPromoCodeBlock
          onClickChangeButton={onClickChangeButton}
          onClickInfoLink={onClickInfoLink}
        />
      ) : (
        <SummaryWelcomeBonusListNoPromo
          onClickChangeButton={onClickChangeButton}
          onClickInfoLink={onClickInfoLink}
        />
      )}
    </div>
  );
}

function SummaryRechargePopupEditFooter({ onCancel, onConfirm }) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const bonus = useSelector(wizardSelectorsBonus.getBonus);

  const { trackConfirmEditData, trackCancelEditData } =
    useSummaryAnalyticsTrackLinks();

  const confirmDisabled = welcomeBonusContainsError(formData);

  const handleConfirm = () => {
    trackConfirmEditData(bonusLinkTranslateId);

    if (bonus?.bonusCode !== formData.promoCode) {
      dispatch(setBonus(null));
    }
    onConfirm();
  };

  const handleCancel = () => {
    trackCancelEditData(bonusLinkTranslateId);
    onCancel();
  };

  return (
    <SummaryPopupEditFooter
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmDisabled={confirmDisabled}
    />
  );
}
