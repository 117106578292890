import { useEffect, useRef } from "react";
import { Translate } from "react-localize-redux";
import { useRegBonus, useRegFlusso } from "../../hooks/useAnalytics";
import CheckSex from "../elementForm/checkSex/checkSex.container";
import BirthDateInputField from "../inputFields/birthDateInputField/birthDateInputField.component";
import PersonalData from "../personalData/personalData.component";

export default function Sex({
  formStatus,
  component,
  spidFiscalCodeFlowStatus,
  utagView,
  hideUtag,
}) {
  const singleInputDateRef = useRef(null);

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();

  useEffect(() => {
    if (singleInputDateRef.current && window.screen.width >= 899) {
      singleInputDateRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (hideUtag) return;
    if (
      component.name !== "summary" &&
      !spidFiscalCodeFlowStatus.showGenerationStep
    ) {
      utagView({
        reg_step: "calcolo-cf-1",
        ...(reg_bonus ? { reg_bonus } : {}),
        reg_flusso,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="container step-container sex pb"
      id="it-ar-securityquestion-container"
    >
      <div className={"row"}>
        <div
          className={
            "col-12" +
            (component.name !== "summary" && component.name !== "newSummary"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : component.name === "newSummary"
                ? " p-0"
                : "")
          }
        >
          <div className="header-form" id="it-ar-securityquestion-header-form">
            <h2 className="sub-title-form" id="it-ar-sex-sub-title">
              <Translate id="text.whatsybdate_A" />
            </h2>
            <p
              className="label-form margin-bot-standard"
              id="it-ar-sex-useForFiscalCode"
            ></p>
            <p className="label-form" id="it-ar-sex-ourSecret">
              <Translate id="text.ourSecret" />
            </p>
          </div>

          {spidFiscalCodeFlowStatus.showGenerationStep && <PersonalData />}

          <div id="it-ar-securityquestion-row" className="row mt-0 mt-md-0">
            <div
              id="it-ar-securityquestion-col"
              className={`col-12 ${
                component.name === "summary" ? "col-md-6 md-0 mt-4" : ""
              }`}
            >
              <div
                id="it-ar-securityquestion-is-invalid"
                className={
                  formStatus.birthDate.length > 0 ? "  is-invalid" : ""
                }
              >
                <BirthDateInputField />
              </div>
            </div>
            <div
              id="it-ar-securityquestion-col2"
              className={
                component.name === "summary"
                  ? "col-12 col-md-6  md-3 md-3"
                  : component.name === "newSummary"
                    ? "col-12"
                    : "col-12 col-md-8"
              }
            >
              <CheckSex />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
