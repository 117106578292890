import { useDispatch, useSelector } from "react-redux";
import { useFlow } from "../../hooks/useFlow";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { openRechargeLimitApprovalPopupInSummary } from "../../store/rechargeLimit/rechargeLimit.action";
import { rechargeLimitSelectors } from "../../store/rechargeLimit/rechargeLimit.selectors";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";
import { useSignUp } from "./signButton.hooks";
import SignButtonUI from "./signButtonUI.component";

export default function SignButton({
  className = "",
  onClick,
  disabled,
  signUpTranslateId = "lbl.signIn",
}) {
  const dispatch = useDispatch();

  const { goToPreviousStep } = useFlow();

  const { isLoading, signUp } = useSignUp();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const approvalAmount = useSelector(
    rechargeLimitSelectors.getRechargeLimitApprovalAmount,
  );
  const hasShownApprovalPopup = useSelector(
    rechargeLimitSelectors.getHasShownApprovalPopup,
  );

  const approvalRechargeLimit = approvalAmount / 100;

  const showApprovalRechargeLimitPopup = () => {
    if (hasShownApprovalPopup) {
      return false;
    }

    const rechargeLimit = parseInt(formData.rechargeLimit?.replace(".", ""));
    return !isNaN(rechargeLimit) && rechargeLimit > approvalRechargeLimit;
  };

  const handleSignupClick = () => {
    onClick?.();

    if (showApprovalRechargeLimitPopup()) {
      dispatch(openRechargeLimitApprovalPopupInSummary());
    } else {
      signUp();
    }
  };

  const isButtonDisabled = () => {
    if (flusso === "SPID") {
      const termAndConditionsStepValid =
        formData.gameContract &&
        formData.termsAndConditions &&
        formData.privacyPolicy &&
        formData.personalDataTreatmentConsent != null &&
        formData.personalDataProfilingTreatmentConsent != null;

      return isLoading || !termAndConditionsStepValid;
    }

    return (
      isLoading ||
      !(
        formStatus.fiscalCode === "" &&
        formData.releaseDate.length &&
        formStatus.releaseDate === "" &&
        formData.expirationDate.length &&
        formStatus.expirationDate === "" &&
        formData.birthDate.length &&
        formStatus.birthDate === "" &&
        formData.gameContract &&
        formData.termsAndConditions &&
        formData.privacyPolicy &&
        formData.rechargeLimit !== ""
      ) ||
      !(
        (formData.bonusCode !== "" || formData.promoCode !== "") &&
        formStatus.bonusCode === ""
      )
    );
  };

  return (
    <SignButtonUI
      className={className}
      disabled={disabled != null ? disabled : isButtonDisabled()}
      onClickSubmit={handleSignupClick}
      onClickBack={goToPreviousStep}
      signUpTranslateId={signUpTranslateId}
    />
  );
}
