import React from "react";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store.config";
import * as ReactDOM from "react-dom";
import { LocalizeProvider } from "react-localize-redux";
import { initApp } from "./utility/initialize";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

initApp();

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider store={store}>
      <App />
    </LocalizeProvider>
  </Provider>,
  document.getElementById("root"),
);
