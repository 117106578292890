import { Translate } from "react-localize-redux";
import Phone from "../../../../images/svg/icon/phone.svg";
import PreFooterContact from "../preFooterContact/preFooterContact.component";

export default function PreFooterPhone({ utagLink }) {
  const onClickPhoneLink = () => {
    utagLink({ reg_contatto: "contatto", reg_event: "telefono" });
  };

  return (
    <PreFooterContact
      title={<Translate id="text.callUs" />}
      iconSrc={Phone}
      linkComponent={
        <a
          href="tel:800999445"
          target="_blank"
          rel="noopener noreferrer"
          className="preFooter-caption-action"
          onClick={onClickPhoneLink}
        >
          800.999.445
        </a>
      }
    />
  );
}
