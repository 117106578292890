import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  useFlow,
  useRegistrationSelectionNavigation,
} from "../../../hooks/useFlow";
import ArrowLeft from "../../../images/svg/icon/white-chevron-left.svg";
import { addFormData } from "../../../store/formData/formData.action";
import { servicesSelectors } from "../../../store/services/services.selectors";

export default function ActiveAccountBtnBack() {
  const dispatch = useDispatch();

  const spidEnabled = useSelector(servicesSelectors.getSpidStatus);

  const { goToPreviousStep } = useFlow();

  const { goToRegistrationSelection } = useRegistrationSelectionNavigation();

  const jumpBack = () => {
    dispatch(
      addFormData({
        birthTown: "",
        birthCityFiscalCode: "",
        birthState: "",
        birthDate: "",
        email: "",
        fiscalCode: "",
        gender: "",
        name: "",
        phoneNumber: "",
        surname: "",
        username: "",
        birthProvince: "",
        birthTownPdv: "",
        birthProvincePdv: "",
        birthCityFiscalCodePdv: "",
        birthStatePdv: "",
        birthDatePdv: "",
        emailPdv: "",
        birthId: "",
        fiscalCodePdv: "",
        genderPdv: "",
        namePdv: "",
        phoneNumberPdv: "",
        surnamePdv: "",
        usernamePdv: "",
        fromPdv: false,
        pdvCode: {
          accountCode: "",
          oldPin: "",
        },
      }),
    );

    if (spidEnabled) {
      goToRegistrationSelection();
    } else {
      goToPreviousStep();
    }
  };

  return (
    <button
      id="btn_ricevuta_registrati"
      className="btn btn-link pl-0 text-left styleBack"
      onClick={jumpBack}
    >
      <i className="icon-btn-left">
        <img src={ArrowLeft} width="24" alt="arrow-left" />
      </i>

      <span>
        <u>
          <Translate id="lbl.backPdv" />
        </u>
      </span>
    </button>
  );
}
