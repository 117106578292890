import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../services/validatorController";
import { enablePhoneErrorView } from "../../store/contacts/contacts.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { incrementPhoneNumberAlreadyPresentCounter } from "../../store/vivochaProactiveChat/vivochaProactiveChat.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import formIsValid from "../../utility/formIsValid/formIsValid.container";
import { getRecaptchaToken } from "../../utility/recaptcha";
import { isVivochaError } from "../../utility/vivochaProactiveChat/vivochaProactiveChat";

export function useContactsBackendValidation() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { emailPdv, channel, ipAddress } = useSelector(
    formDataSelectors.getFormData,
  );

  const validateEmailAPI = async (email) => {
    const emailTemplateErrStatus = formIsValid("email", email).email;

    if (email === emailPdv) {
      dispatch(addFormStatus({ email: "" }));
      dispatch(disabledBtn(checkingForm("contacts")));
      return true;
    }

    if (
      (emailPdv === "" || email !== emailPdv) &&
      emailTemplateErrStatus === ""
    ) {
      const recaptchaToken = await getRecaptchaToken("VERIFY_EMAIL");

      try {
        await validateEmail(email, channel, recaptchaToken);

        dispatch(addFormStatus({ email: "" }));

        dispatch(disabledBtn(checkingForm("contacts")));

        return true;
      } catch (err) {
        dispatch(enablePhoneErrorView());

        if (err.response && err.response.status === 422) {
          let nameError = Object.keys(err.response.data.error.errorInfos)[0];
          dispatch(
            addFormStatus({
              email: err.response.data.error.errorInfos[nameError][0],
            }),
          );
        } else {
          history.push("/errore-registrazione");
        }

        dispatch(disabledBtn(checkingForm("contacts")));

        return false;
      }
    }

    dispatch(disabledBtn(checkingForm("contacts")));
    return false;
  };

  const validatePhoneAPI = async (phone) => {
    try {
      const recaptchaToken = await getRecaptchaToken("VERIFY_PHONE");

      await validatePhoneNumber(phone, channel, ipAddress, recaptchaToken);

      dispatch(disabledBtn(checkingForm("contacts")));

      return true;
    } catch (err) {
      dispatch(enablePhoneErrorView());

      if (err.response?.status === 422) {
        const nameError = Object.keys(err.response.data.error.errorInfos)[0];
        const phoneNumberError =
          err.response.data.error.errorInfos[nameError][0];
        dispatch(addFormStatus({ phoneNumber: phoneNumberError }));

        if (isVivochaError(phoneNumberError)) {
          dispatch(incrementPhoneNumberAlreadyPresentCounter());
        }
      } else {
        dispatch(addFormStatus({ phoneNumber: "err.void" }));
      }

      dispatch(disabledBtn(checkingForm("contacts")));

      return false;
    }
  };

  return { validateEmailAPI, validatePhoneAPI };
}
