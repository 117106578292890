import { Translate } from "react-localize-redux";
import { ReactComponent as Id } from "../../../images/svg/summary/id.svg";
import Identification from "../../identification/identification.container";
import OcrForm from "../../ocrForm/ocrForm.container";
import OcrSummaryInfoBlock from "../ocrSummaryInfoBlock/ocrSummaryInfoBlock.component";
import OcrSummarySection from "../ocrSummarySection/ocrSummarySection.component";

export default function OcrSummaryDocument() {
  return (
    <OcrSummarySection
      className="ocr-summary-document"
      infoBlock={
        <OcrSummaryInfoBlock
          title={<Translate id="lbl.document" />}
          icon={<Id />}
        />
      }
    >
      <Identification />
      <OcrForm />
    </OcrSummarySection>
  );
}
