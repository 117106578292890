import { v4 as uuidv4 } from "uuid";
import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL, THX_URL_REG, URL_API_BONUS } from "../config";

export const checkBonusByCode = async (promoCode, channel, codiceRice) => {
  const url = `${REACT_APP_URL}${PORT}/bonus-ms/ms/bonus/v1/checkBonusByCode`;
  const data = {
    params: {
      requestId: uuidv4(),
      promoCode,
      channel,
      codiceRice,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const getBonus = async (channel, fromPdv) => {
  const res = await instanceGet.get(URL_API_BONUS, {
    params: {
      siteId: channel,
      isPdv: fromPdv ? "true" : "false",
      isOnline: fromPdv ? "false" : "true",
    },
  });

  return res.data;
};

export const getBonusDetails = async (slug) => {
  const url = `${THX_URL_REG}/bin/welcome-card-detail.${slug}.json`;
  const res = await instanceGet(url);

  return res.data;
};
