import { useDispatch, useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { Translate } from "react-localize-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { disabledBtn } from "../../../store/wizard/wizard.action";

export default function WelcomeBonusManualInsertBtn({ onClick }) {
  const dispatch = useDispatch();

  const { promoCode } = useSelector(formDataSelectors.getFormData);

  const className = `clicca-qui-per-inser ${
    promoCode !== "" || document.getElementsByClassName("newbonus")[0]
      ? "d-none"
      : "d-block"
  }`;

  const handleClick = () => {
    dispatch(
      addFormData({
        promoCode: "",
        customizesBonus: false,
        bonusCode: "",
      }),
    );

    dispatch(disabledBtn(true));

    onClick();
  };

  return (
    <button
      id="btn_inserisci_bonus"
      className={className}
      onClick={handleClick}
    >
      <Translate id="text.bonusClickHereToInsertCode" />
    </button>
  );
}
