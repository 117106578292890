import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { useFlow } from "../../../../../../hooks/useFlow";
import clearIcon from "../../../../../../images/svg/popupModal/bonusPopupClear.svg";
import bonusPopupLogo from "../../../../../../images/svg/popupModal/bonusPopupLogo.svg";
import verifiedLogo from "../../../../../../images/svg/popupModal/verifiedLogo.svg";
import { formDataSelectors } from "../../../../../../store/formData/formData.selectors";
import { checkingForm } from "../../../../../../utility/checkingForm/checkingForm.container";
import { moveCursorAtTheEnd } from "../../../../../../utility/input/input";
import PromoCodePopupNote from "../promoCodePopupNote/promoCodePopupNote.component";

export default function PromoCodePopupBody({
  bonusDesc,
  bonusValidityErr,
  checkBonusCodeFunction,
  clearBonusInput,
  inputRef,
  inputValueIsChangedFromLastCheck,
  promoCode,
  setInputValueIsChangedFromLastCheck,
  setStatusForm,
  validationFunction,
}) {
  const { goToNextStep } = useFlow();

  const { isMobile } = useSelector(formDataSelectors.getFormData);

  const isValid = (value) => {
    validationFunction(value);
    if (!inputValueIsChangedFromLastCheck) {
      setInputValueIsChangedFromLastCheck(true);
    }
    setStatusForm(checkingForm("welcomeBonus"));
  };

  const onFocus = (e) => {
    moveCursorAtTheEnd(e.target);
    if (isMobile) {
      setTimeout(() => {
        let element = document.getElementById("promo-overlay");
        if (element) element.scrollTop = element.scrollHeight;
      }, 500);
    }
  };

  const onKeyPressed = (key, e) => {
    if (key === "Enter") {
      e.preventDefault();
      if (!checkingForm("welcomeBonus")) {
        goToNextStep();
      } else {
        checkBonusCodeFunction();
      }
    }
  };

  const showNote = !(
    bonusDesc !== "" &&
    !bonusValidityErr &&
    !inputValueIsChangedFromLastCheck
  );

  return (
    <div className="promo-popup">
      <div
        className={`promo-popup-body-title ${
          bonusDesc !== "" &&
          !bonusValidityErr &&
          !inputValueIsChangedFromLastCheck
            ? "promo-popup-body-title-success"
            : ""
        }`}
      >
        <div
          className={`promo-popup-body-title-logo-box ${
            bonusDesc !== "" &&
            !bonusValidityErr &&
            !inputValueIsChangedFromLastCheck
              ? "promo-popup-body-title-logo-box-success"
              : ""
          }`}
        >
          <img
            className="promo-popup-body-title-logo"
            src={
              bonusDesc !== "" &&
              !bonusValidityErr &&
              !inputValueIsChangedFromLastCheck
                ? verifiedLogo
                : bonusPopupLogo
            }
            alt="bonus-logo"
          />
        </div>
        <h2
          className={`promo-popup-body-title-text ${
            !(
              bonusDesc !== "" &&
              !bonusValidityErr &&
              !inputValueIsChangedFromLastCheck
            )
              ? "not-valid"
              : ""
          }`}
        >
          {bonusDesc !== "" &&
          !bonusValidityErr &&
          !inputValueIsChangedFromLastCheck ? (
            <Translate id="lbl.promocode.welcome.verified" />
          ) : (
            <Translate id="lbl.promocode.welcome.title" />
          )}
        </h2>
      </div>
      <div
        className={`promo-popup-body-bonus ${
          bonusDesc !== "" &&
          !bonusValidityErr &&
          !inputValueIsChangedFromLastCheck
            ? "bonus-is-valid"
            : ""
        }`}
      >
        <div className="form-group">
          <Translate>
            {({ translate }) => (
              <input
                type="text"
                className={
                  "form-control  inserisci-codice" +
                  (promoCode.length > 0 && bonusValidityErr
                    ? " is-invalid"
                    : promoCode.length > 0
                      ? " promo-input-valid"
                      : "")
                }
                ref={inputRef}
                placeholder={
                  promoCode.length === 0
                    ? translate("text.insertBonusCode")
                    : ""
                }
                value={promoCode.toUpperCase()}
                id="it-ar-promoCode"
                name="promoCode"
                onKeyDown={(e) => onKeyPressed(e.key, e)}
                onChange={(e) => isValid(e.target.value)}
                onFocus={(e) => onFocus(e)}
              />
            )}
          </Translate>
          <label
            className="form-control-label label-left"
            htmlFor="it-ar-promoCode"
          >
            {promoCode.length > 0 ? <Translate id="lbl.bonusCode" /> : null}
          </label>
          {promoCode.length > 0 ? (
            <button
              className={"input-clear"}
              onClick={(e) => clearBonusInput(e, isValid)}
            >
              <img src={clearIcon} alt="clear" />
            </button>
          ) : null}
          <div
            className={"promo-input-text "}
            id="it-ar-promoCod-validBonusCode"
          >
            {bonusDesc !== "" &&
              !bonusValidityErr &&
              !inputValueIsChangedFromLastCheck && (
                <p className={"valid-bonus-code-text"}>{bonusDesc}</p>
              )}
            {bonusValidityErr && (
              <p className="error-msg">
                <Translate id="err.bonusCode" />
              </p>
            )}
          </div>
        </div>
      </div>

      {showNote && <PromoCodePopupNote />}
    </div>
  );
}
