import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../../images/svg/wizardBtn/arrowLeft.svg";

export function OcrPageBackBtn({ regType }) {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/seleziona-documento/${regType}`);
  };

  return (
    <button className="back-to-docs-btn" onClick={handleClick}>
      <ArrowLeft />
      <span>Cambia documento</span>
    </button>
  );
}
