export const defaultCardTitleError = "err.e80XX_B";
export const genericError = "genericError";

export const errorCardButtonLabels = {
  pdv: "lbl.errorcard.button.pdv",
  login: "lbl.errorcard.button.login",
  exit: "lbl.errorcard.button.exit",
  close: "lbl.errorcard.button.close",
  retry: "lbl.errorcard.button.retry",
  leave: "lbl.leaving",
};

export const errorCardsPageText = {
  "err.fiscalcode.alreadypresent": {
    cardTitle: "text.cflreadypresent.card.title",
    cardText: "text.cfalreadypresent.card.body",
    pdvTitle: "text.cfalreadypresentpdv.card.title",
    pdvText: "text.cfalreadypresentpdv.card.body",
    utagErrorMessage: "hai già un conto aperto",
    buttonText: errorCardButtonLabels.login,
    variant: "login",
  },
  "err.fiscalcode.alreadypresentPdv": {
    cardTitle: "text.cflreadypresent.card.titlePdv",
    cardText: "text.cfalreadypresent.card.bodyPdv",
    buttonText: errorCardButtonLabels.leave,
  },
  "err.bill.activation.already.submitted": {
    cardTitle: "text.cfalreadypresentpdv.card.title",
    cardText: "text.cfalreadypresentpdv.card.body",
    utagErrorMessage: "attivazione conto già inviata",
    buttonText: errorCardButtonLabels.pdv,
    variant: "info",
  },
  "err.fiscalcode.activationalreadystarted": {
    cardTitle: "text.cfalreadypresentpdv.card.title",
    cardText: "text.cfalreadypresentpdv.card.body",
    utagErrorMessage: "attivazione conto già inviata",
    buttonText: errorCardButtonLabels.pdv,
    variant: "info",
  },
  "err.fiscalcode.activationalreadystartedPdv": {
    cardTitle: "err.fiscalcode.activationalreadystartedPdv.title",
    cardText: "err.fiscalcode.activationalreadystartedPdv.body",
    buttonText: errorCardButtonLabels.leave,
  },
  "err.fiscalcode.userselfexcluted": {
    cardTitle: "text.cfuserselfexcluted.card.title",
    cardText: "text.cfuserselfexcluted.card.body",
    utagErrorMessage: "autoesclusione trasversale",
  },
  "err.fiscalcode.recentlyclosedaccount": {
    cardTitle: "text.cfrecentlyclosedaccount.card.title",
    cardText: "text.cfrecentlyclosedaccount.card.body",
    utagErrorMessage:
      "codice fiscale associato ad un conto gioco chiuso recentemente",
  },
  "err.fiscalcode.multipleclosedaccounts": {
    cardTitle: "text.cfmultipleclosedaccounts.card.title",
    cardText: "text.cfmultipleclosedaccounts.card.body",
    utagErrorMessage:
      "codice fiscale associato a più di un conto gioco chiuso in 12 mesi",
  },
  "err.fiscalcode.noemployersallowed": {
    cardTitle: "text.noemployersallowed.card.title",
    cardText: "text.noemployersallowed.card.body",
    utagErrorMessage: "codice fiscale presente in blacklist (dipendenti)",
  },
  "err.date.notadult": {
    cardTitle: "text.cfnotadult.card.title",
    cardText: "text.cfnotadult.card.body",
    utagErrorMessage: "codice fiscale associato a minorenne",
  },
  "err.fiscalcode.userunderage": {
    cardTitle: "text.cfnotadult.card.title",
    cardText: "text.cfnotadult.card.body",
    utagErrorMessage: "codice fiscale associato a minorenne",
  },
  "err.date.lessThen": {
    cardTitle: "text.cflessThen.card.title",
    cardText: "text.cflessThen.card.body",
    utagErrorMessage: "codice fiscale associato a ultracentenario",
  },
  "err.fiscalcode.userover100yearsold": {
    cardTitle: "text.cflessThen.card.title",
    cardText: "text.cflessThen.card.body",
    utagErrorMessage: "codice fiscale associato a ultracentenario",
  },
  "err.e3510": {
    cardTitle: "text.e3510.card.title",
    utagErrorMessage:
      "non è possibile registrarsi dalla nazione in cui ti trovi",
  },
  "err.e3512": {},
  "err.e8002": {
    cardTitle: "err.header.title.e8002",
    cardText: "err.e8002_B",
  },
  "err.e8003": {
    cardText: "err.e8003_B",
  },
  "err.e8004": {
    cardTitle: "err.header.title.e8004",
  },
  "err.e8007": {
    cardTitle: "err.header.title.e8007",
    cardText: "err.e8007_B",
  },
  "err.e8008": {
    cardTitle: "err.header.title.e8008",
  },
  "err.e8014": {
    cardTitle: "err.header.title.e8014",
    cardText: "err.e8014",
    buttonText: "lbl.errorcard.button.close",
  },
  "err.spid.agid": {
    cardTitle: "err.header.title.agid",
    cardText: "",
    utagErrorMessage: "le credenziali non sono compatibili con il servizio",
  },
  "err.spid.auth": {
    cardTitle: "text.somethingWentWrong",
    cardText: "text.genericSpid.card.body",
    buttonText: errorCardButtonLabels.retry,
  },
  genericError: {
    cardTitle: "text.somethingWentWrong",
    cardText: "text.genericSpid.card.body",
    buttonText: errorCardButtonLabels.retry,
  },
};

export const spidRetryRegistrationCases = ["err.spid.agid", "err.spid.auth"];
