import { useHistory } from 'react-router-dom';
import { useStore } from "react-redux";
import { Translate } from 'react-localize-redux';
import ArrowRight from '../../../images/svg/icon/white-chevron-right.svg';
import { useActiveAccountHelper } from '../activeAccount.hooks';
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { useDispatch, useSelector } from "react-redux";
import { utagLink } from "../../../store/utagStore/utagStore.action";

export default function ActiveAccountBtnSubmit() {
  const { validateAndGoToNextStep, isFormValid } = useActiveAccountHelper();
  const history = useHistory();
  const { getState } = useStore();
  const formData = useSelector(formDataSelectors.getFormData);
  const dispatch = useDispatch();

  const handleClick = async () => {
    const formStatus = formStatusSelectors.getFormStatus(getState());

    trackAnalytics();

    if (formStatus.accountPin === 'err.pdv.ticket') {
      history.push('/registrazione-scaduta');
    } else {
      await validateAndGoToNextStep();
    }
  };

  const trackAnalytics = () => {
    dispatch(
      utagLink({
        reg_event: formData.chargingPin ? "pin_ricarica_si" : "pin_ricarica_no",
        cta_label: "Continua",
        conversion_pagename: "step1:pdv:attiva il tuo conto",
      })
    );
  };

  const disabled = !isFormValid();

  return (
    <button
      type="submit"
      disabled={disabled}
      className="btn btn_primary btn-icon-right"
      onClick={handleClick}
    >
      <span>
        <Translate id="lbl.continuous" />
      </span>
      <i className="icon-btn-right">
        <img src={ArrowRight} width="24" alt="" />
      </i>
    </button>
  );
}
