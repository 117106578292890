export const ENABLE_SPID = "ENABLE_SPID";
export const DISABLE_SPID = "DISABLE_SPID";

export function enableSpid() {
  return { type: ENABLE_SPID };
}

export function disableSpid() {
  return { type: DISABLE_SPID };
}
