import { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { getQuestionListAPI } from "../../store/securityList/securityList.action";
import { questionListSelectors } from "../../store/securityList/securityList.selectors";
import { servicesSelectors } from "../../store/services/services.selectors";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagView } from "../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { getRegFlusso } from "../../utility/analytics";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import NameInputField from "../inputFields/nameInputField/nameInputField.component";
import SurnameInputField from "../inputFields/surnameInputField/surnameInputField.component";
import LoyaltyBanner from "../loyaltyBanner/loyaltyBanner.component";
import PrivacyDisclaimer from "../privacyDisclaimer/privacyDisclaimer.component";
import PersonalDataHeaderForm from "./personalDataHeaderForm/personalDataHeaderForm.component";

export default function PersonalData({ hideUtag }) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const spidStatus = useSelector(servicesSelectors.getSpidStatus);
  const spidFiscalCodeFlowStatus = useSelector(
    spidStatusSelectors.getSpidFiscalCodeFlowStatus,
  );
  const questionList = useSelector(questionListSelectors.getQuestionList);

  const [canShowNameError, setCanShowNameError] = useState(false);
  const [canShowSurnameError, setCanShowSurnameError] = useState(false);

  const nameInput = useRef();
  const surnameInput = useRef();

  const history = useHistory();

  const showLoyaltyBanner =
    component.name !== "summary" &&
    formData.fromPdv &&
    !formData.loyalty.gameCodeExist;

  const handleKeyDownName = (event) => {
    if (event.key === "Enter") {
      surnameInput.current.focus();
    }
  };

  useEffect(() => {
    if (nameInput.current && window.screen.width >= 899) {
      nameInput.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleButtonActivationOnMount = () => {
      const hasError = checkingForm("personaData");
      dispatch(disabledBtn(hasError));
    };

    handleButtonActivationOnMount();
  }, [dispatch]);

  useEffect(() => {
    if (questionList.fetched === false) {
      dispatch(getQuestionListAPI(formData.channel, history));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hideUtag) return;

    if (
      component.name !== "summary" &&
      !spidFiscalCodeFlowStatus.showGenerationStep
    ) {
      dispatch(
        utagView({
          reg_step: "dati-personali",
          reg_bonus: formData.promoCode || formData.bonusCode,
          reg_flusso: getRegFlusso(flusso, formData.fromPdv),
          conversion_pagename: "dati-personali",
          ...(showLoyaltyBanner ? { loyalty_banner: "yes" } : {}),
        }),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className="container step-container"
        id="it-ar-personalData-container"
      >
        <div className="row">
          <div
            className={
              "col-12" +
              (component.name !== "summary"
                ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
                : "") +
              (spidFiscalCodeFlowStatus.showGenerationStep
                ? " generate-fc-step"
                : "")
            }
          >
            {!spidFiscalCodeFlowStatus.showGenerationStep && (
              <PersonalDataHeaderForm />
            )}

            <div className="personDat">
              <NameInputField
                ref={nameInput}
                canShowNameError={canShowNameError}
                setCanShowNameError={setCanShowNameError}
                onKeyDown={handleKeyDownName}
              />

              <SurnameInputField
                ref={surnameInput}
                canShowSurnameError={canShowSurnameError}
                setCanShowSurnameError={setCanShowSurnameError}
              />
            </div>

            {!spidStatus && component.name !== "summary" && (
              <PrivacyDisclaimer
                pdfHeaderTitle={<Translate id="text.informationPrivacy" />}
              />
            )}
          </div>
        </div>
      </div>

      {showLoyaltyBanner && <LoyaltyBanner />}
    </>
  );
}
