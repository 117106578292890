import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const formattedDate = (date) => {
  return date ? moment(date).format("DD/MM/YYYY") : "";
};

export function prepareSignUpParams(
  formData,
  fromSpid,
  emailCertified,
  recaptchaToken,
  jumioAccountId,
) {
  const signUpParams = {
    ipAddress: formData.ipAddress,
    phoneNumberCertified: formData.phoneNumberCertified,
    recaptchaToken,
    accessInfo: {
      password: formData.password,
      username: formData.username,
      ...getContactInfo(formData),
    },
    channel: formData.channel,
    fiscalCode: formData.fiscalCode,
    gender: formData.gender,
    name: formData.name,
    promoCode: formData.promoCode || formData.bonusCode,
    rechargeLimit: formData.rechargeLimit.split(".").join(""),
    requestId: uuidv4(),
    sameResidenceAndDomicile: formData.sameResidenceAndDomicile,
    siteId: formData.channel,
    surname: formData.surname,

    termsAndConditions: {
      gameContract: formData.gameContract,
      loyaltyLightConsent: formData.loyaltyLightConsent,
      personalDataProfilingTreatmentConsent:
        formData.personalDataProfilingTreatmentConsent,
      personalDataTreatmentConsentEmail: formData.treatmentConsents.email,
      personalDataTreatmentConsentSms: formData.treatmentConsents.sms,
      personalDataTreatmentConsentChiamate: formData.treatmentConsents.phone,
      privacyPolicy: formData.privacyPolicy,
      termsAndConditions: formData.termsAndConditions,
    },
    hasDocFile: formData.hasDocFile,
    fromPdv: formData.fromPdv,
    securityAnswer: "AUTOMATIC VALUE",
    securityQuestion: "Cognome di mia madre",
    nazioneResidenza: formData.state.description,
    zipCodeResidenza: formData.state.fiscalCode,
    nazioneDomicilio: formData.domicileState.description,
    zipCodeDomicilio: formData.domicileState.fiscalCode,
    fromSpid,
    emailCertified,
    lotteryReceipt: formData.loyalty.lotteryReceipt,
    spidProcess: formData.spidProcess,
    birthInfo: getBirthInfo(formData),
    ...(formData.fromPdv ? { pdvCode: formData.pdvCode } : {}),
    ...(formData.hasDocFile ? { uploadDocsId: formData.uploadDocsId } : {}),
    residenceInfos: getResidenceInfos(formData),
    ...(!formData.sameResidenceAndDomicile ? getDomicileInfos(formData) : {}),
    ...(formData.mpPartner !== "" ? { mpPartner: formData.mpPartner } : {}),
    document: getDocument(formData),
    ...(!!jumioAccountId ? { jumioAccountId } : {}),
  };

  return signUpParams;
}

export function prepareSignUpPdvParams(
  formData,
  recaptchaToken,
  jumioAccountId,
) {
  const signUpParams = {
    b2bAccessInfo: getContactInfo(formData),
    birthInfo: getBirthInfo(formData),
    channel: formData.channel,
    document: getDocument(formData),
    domicileInfos: getDomicileInfos(formData),
    emailCertified: false, // emailCertified is always false for pdv workflows
    fiscalCode: formData.fiscalCode,
    flagAffiliation: true, // flagAffiliation is always true for pdv workflows
    fromPdv: false, // fromPdv is always false for pdv workflows
    fromSpid: false, // fromSpid is always false for pdv workflows
    gender: formData.gender,
    hasDocFile: formData.hasDocFile,
    ipAddress: formData.ipAddress,
    ...(!!jumioAccountId ? { jumioAccountId } : {}),
    lotteryReceipt: formData.loyalty.lotteryReceipt,
    ...(formData.mpPartner !== "" ? { mpPartner: formData.mpPartner } : {}),
    name: formData.name,
    nazioneDomicilio: formData.domicileState.description,
    nazioneResidenza: formData.state.description,
    phoneNumberCertified: formData.phoneNumberCertified,
    promoCode: formData.promoCode || formData.bonusCode,
    recaptchaToken,
    requestId: uuidv4(),
    residenceInfos: getResidenceInfos(formData),
    sameResidenceAndDomicile: formData.sameResidenceAndDomicile,
    securityAnswer: "AUTOMATIC VALUE",
    securityQuestion: "Cognome di mia madre",
    siteId: formData.channel,
    spidProcess: false, // spidProcess is always false for pdv workflows
    surname: formData.surname,
    ...(formData.hasDocFile ? { uploadDocsId: formData.uploadDocsId } : {}),
    userAgent: formData.userAgent,
    zipCodeDomicilio: formData.domicileState.fiscalCode,
    zipCodeResidenza: formData.state.fiscalCode,
  };

  return signUpParams;
}

function getContactInfo(formData) {
  return {
    email: formData.email.toLowerCase(),
    jwt: formData.jwt,
    phoneNumber: formData.phoneNumber,
    phonePrefix: formData.phonePrefix.callingCodes
      ? "+" + formData.phonePrefix.callingCodes
      : "",
  };
}

function getBirthInfo(formData) {
  const isForeign = formData.birthState.fiscalCode !== "Z000";

  if (isForeign) {
    return {
      birthDate: formattedDate(formData.birthDate),
      cityFiscalCode: "ESTERO",
      countryFiscalCode: formData.birthState.fiscalCode,
      provinceCode: "EE",
      cityId: "EE",
    };
  }

  return {
    birthDate: formattedDate(formData.birthDate),
    cityFiscalCode: formData.birthCityFiscalCode,
    countryFiscalCode: formData.birthState.fiscalCode,
    provinceCode: formData.birthProvince,
    cityId: formData.birthId,
  };
}

function getResidenceInfos(formData) {
  const isForeign = formData.state.fiscalCode !== "Z000";

  if (isForeign) {
    return {
      address: "ESTERO",
      cap: 99999,
      cityFiscalCode: "ESTERO",
      countryFiscalCode: formData.state.fiscalCode || "",
      provinceCode: "EE",
    };
  }

  return {
    address: formData.address,
    cap: formData.cap,
    cityFiscalCode: formData.cityFiscalCode,
    countryFiscalCode: formData.state.fiscalCode,
    provinceCode: formData.province,
  };
}

function getDomicileInfos(formData) {
  const isForeign = formData.domicileState.fiscalCode !== "Z000";

  if (isForeign) {
    return {
      address: "ESTERO",
      cap: 99999,
      cityFiscalCode: "ESTERO",
      countryFiscalCode: formData.domicileState.fiscalCode,
      provinceCode: "EE",
    };
  }

  return {
    address: formData.domicileAddress,
    cap: formData.domicileCap,
    cityFiscalCode: formData.domicileCityFiscalCode,
    countryFiscalCode: formData.domicileState.fiscalCode,
    provinceCode: formData.domicileProvince,
  };
}

function getDocument(formData) {
  if (
    formData.releasedByEntity != null &&
    formData.releasedByEntity.toString() === "5"
  ) {
    return {
      cityFiscalCode: formData.documentTown,
      docReleaseLocation: formData.documentTown,
      expirationDate: formattedDate(formData.expirationDate),
      number: formData.number,
      provinceCode: formData.documentTown,
      releaseDate: formattedDate(formData.releaseDate),
      releasedByEntity: formData.releasedByEntity,
      type: formData.documentType,
      customCityDocument: true,
    };
  }
  return {
    cityFiscalCode: formData.documentCityFiscalCode,
    expirationDate: formattedDate(formData.expirationDate),
    number: formData.number,
    provinceCode: formData.documentProvinceCode,
    releaseDate: formattedDate(formData.releaseDate),
    releasedByEntity: formData.releasedByEntity,
    type: formData.documentType,
    customCityDocument: false,
    cityId: formData.documentId,
  };
}
