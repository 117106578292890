import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addFormData } from "../../../../store/formData/formData.action";
import PopupModal from "../../../popupModal/popupModal";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../../summary.hooks";
import { addressLinkTranslateId } from "../summaryAddress.config";
import SummaryAddressPopupDetailContent from "./summaryAddressPopupDetailContent.component";
import SummaryAddressPopupEditContent from "./summaryAddressPopupEditContent.component";

export default function SummaryAddressPopup({ show, onClose }) {
  const dispatch = useDispatch();

  const { getCurrentAddressFormData } = useCurrentData();

  const [editMode, setEditMode] = useState(false);

  const { trackEditData } = useSummaryAnalyticsTrackLinks();

  const addressFormDataBeforeEditRef = useRef(getCurrentAddressFormData());

  const handlePressEdit = () => {
    trackEditData(addressLinkTranslateId);
    addressFormDataBeforeEditRef.current = getCurrentAddressFormData();
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);

    // setTimeout as workaround to avoid jumps modal close animation due
    // to different height when sameResidenceAndDomicile is modified
    setTimeout(() => {
      if (!!addressFormDataBeforeEditRef.current) {
        dispatch(addFormData(addressFormDataBeforeEditRef.current));
      }
    }, 300);
  };

  const handleCloseEdit = () => {
    setEditMode(false);
    onClose();
  };

  return (
    <PopupModal show={show}>
      {editMode ? (
        <SummaryAddressPopupEditContent
          onClose={handleCloseEdit}
          onCancelEdit={handleCancelEdit}
          previousData={addressFormDataBeforeEditRef.current}
        />
      ) : (
        <SummaryAddressPopupDetailContent
          onClose={onClose}
          onPressEdit={handlePressEdit}
        />
      )}
    </PopupModal>
  );
}
