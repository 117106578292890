const getPdvKoCheckCrendentialsCounter = (state) => {
  return state.vivochaProactiveChatReducer.pdvKoCheckCrendentialsCounter;
};

const getPhoneNumberAlreadyPresent = (state) => {
  return state.vivochaProactiveChatReducer.phoneNumberAlreadyPresent;
};

export const vivochaProactiveChatSelectors = {
  getPdvKoCheckCrendentialsCounter,
  getPhoneNumberAlreadyPresent,
};
