import { createArrayComponents } from "../../utility/wizardConfig/wizardConfig";
import {
  contactsSpidErrors,
  documentSpidErrors,
  fiscalCodeSpidErrors,
  residenceSpidErrors,
} from "./constants";

const stepTypes = [
  {
    name: "fiscalCode",
    group: "A",
    isLast: false,
    displayStep: true,
    nextStep: false,
    path: "/codice-fiscale",
    componentName: "FiscalCode",
    dependencies: "/calcolo-codice-fiscale-step1",
    components: ["FiscalCode"],
  },
  {
    name: "birthplace",
    group: "A",
    isLast: null,
    displayStep: false,
    nextStep: false,
    path: "/calcolo-codice-fiscale-step1",
    componentName: "Sex",
    components: ["Sex", "Birthplace"],
  },
  {
    name: "fiscalCodeConferma",
    group: "A",
    isLast: null,
    displayStep: false,
    nextStep: false,
    path: "/codice-fiscale-conferma",
    componentName: "FiscalCode",
    dependencies: "/calcolo-codice-fiscale-step1",
    components: ["FiscalCode"],
  },
  {
    name: "ocrForm",
    group: "E",
    isLast: null,
    displayStep: false,
    nextStep: false,
    path: "/documento-inserimento-dati",
    componentName: "OcrForm",
    components: ["Identification", "OcrForm"],
  },
  {
    name: "residence",
    group: "B",
    displayStep: true,
    path: "/indirizzo-residenza",
    componentName: "Residence",
    components: ["Residence", "Domicile"],
  },
  {
    name: "contacts",
    group: "C",
    displayStep: true,
    path: "/contatti",
    componentName: "Contacts",
    components: ["Contacts"],
  },
];

const componentsToGenerateCheckNames = [
  "FiscalCode",
  "Sex",
  "Identification",
  "Residence",
  "Contacts",
];

const updateStep = (step, index, steps, commonAttrs) => {
  const newStep = {
    ...step,
    step: index + 1,
    component: componentsToGenerateCheckNames.includes(
      step.components && step.components[0],
    )
      ? createArrayComponents(step.components)
      : step.component,
    ...commonAttrs,
  };

  if (index > 0 && step.name !== "fiscalCodeConferma") {
    newStep.previousComponentName =
      steps[0].name === "fiscalCode" && index === 3
        ? steps[0].path
        : steps[index - 1].path;
  }

  if (index < steps.length - 1) {
    newStep.nextComponentName =
      step.name === "fiscalCode"
        ? steps[index + 3].path
        : steps[index + 1].path;
  }

  return newStep;
};

const generateSpidFlow = (errors, flowSteps, commonAttrs) => {
  const stepsNameArray = errors.reduce((acc, curr) => {
    if (fiscalCodeSpidErrors.includes(curr.code) && !acc.includes("fiscalCode"))
      acc.push("fiscalCode", "birthplace", "fiscalCodeConferma");
    else if (
      contactsSpidErrors.includes(curr.code) &&
      !acc.includes("contacts")
    )
      acc.push("contacts");
    else if (
      residenceSpidErrors.includes(curr.code) &&
      !acc.includes("residence")
    )
      acc.push("residence");
    else if (documentSpidErrors.includes(curr.code) && !acc.includes("ocrForm"))
      acc.push("ocrForm");
    return acc;
  }, []);

  const errorsSteps = stepTypes
    .filter((step) => stepsNameArray.includes(step.name))
    .concat(flowSteps);

  const newFlow = errorsSteps.map((step, index, steps) =>
    updateStep(step, index, steps, commonAttrs),
  );

  return newFlow;
};

export default generateSpidFlow;
