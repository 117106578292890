import { useState } from "react";
import WelcomeBonusCard from "../welcomeBonusCard/welcomeBonusCard.component";
import WelcomeBonusSeeOthers from "../welcomeBonusSeeOthers/welcomeBonusSeeOthers.component";

export default function WelcomeBonusOthers({
  onClickChangeButton,
  onClickInfoLink,
  otherBonusList,
}) {
  const [seeOtherBonuses, setSeeOtherBonuses] = useState(false);

  return (
    <>
      <WelcomeBonusSeeOthers
        open={seeOtherBonuses}
        onClick={() => setSeeOtherBonuses((visibleBonus) => !visibleBonus)}
      />

      {seeOtherBonuses && (
        <div className="bonus-card" id="it-ar-welcomeBonus-row">
          {otherBonusList.map((item, index) => (
            <WelcomeBonusCard
              key={index}
              bonusItem={item}
              onClickInfoLink={onClickInfoLink}
              onClickChangeButton={onClickChangeButton}
            />
          ))}
        </div>
      )}
    </>
  );
}
