import { connect } from "react-redux";

import { stepNext, setIsLoading } from "../../store/wizard/wizard.action";
import Wizard from "./wizard.component";
import {
  wizardSelectorsBtn,
  wizardSelectorsIsLoading,
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { utagView } from "../../store/utagStore/utagStore.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { getAllCitiesAPI } from "../../store/allCities/allCities.action";
import { countriesSelectors } from "../../store/countries/countries.selectors";
import { getCountriesAPI } from "../../store/countries/countries.action";
import { allCitiesSelectors } from "../../store/allCities/allCities.selectors";

const mapStateToProps = (state) => ({
  btnDisabled: wizardSelectorsBtn.getWizardBtn(state),
  isLoading: wizardSelectorsIsLoading.getWizardIsLoading(state),
  formData: formDataSelectors.getFormData(state),
  countries: countriesSelectors.getCountries(state),
  allCities: allCitiesSelectors.getAllCities(state),
  component: wizardSelectorsComponent.getComponent(state),
  flusso: wizardSelectorsFlusso.getFlusso(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    stepNext: (step) => dispatch(stepNext(step)),
    setIsLoading: (isLoading) => dispatch(setIsLoading(isLoading)),
    utagView: (state) => dispatch(utagView(state)),
    getCountriesAPI: (status, channel, history) =>
      dispatch(getCountriesAPI(status, channel, history)),
    getAllCitiesAPI: (channel, history) =>
      dispatch(getAllCitiesAPI(channel, history)),
  };
};

const WizardContainer = connect(mapStateToProps, mapDispatchToProps)(Wizard);
export default WizardContainer;
