import { FETCH_ALL_CITIES_START } from "./allCities.action";
import { RECEIVE_ALLCITIES_TRUE } from "./allCities.action";
import { FETCH_ALL_CITIES_ERROR } from "./allCities.action";
import { RECEIVE_ALLCITIES_FALSE } from "./allCities.action";

const initialState = {
  allCitiesTrue: [],
  allCitiesFalse: [],
  fetching: false,
  fetched: false,
  error: null,
};

export const allCitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_CITIES_START: {
      return { ...state, fetching: true };
    }
    case FETCH_ALL_CITIES_ERROR: {
      return { ...state, fetching: false, error: action.payload };
    }
    case RECEIVE_ALLCITIES_TRUE: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allCitiesTrue: action.payload,
      };
    }
    case RECEIVE_ALLCITIES_FALSE: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allCitiesFalse: action.payload,
      };
    }
    default:
      return state;
  }
};
