import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import BaseErrorMessage from "../../commons/baseErrorMessage/baseErrorMessage.component";
import { getRegFlusso } from "../../../utility/analytics";

export default function RechargeLimitMessageError({
  formStatusError,
  maxRechargeLimit,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (formStatusError.length > 0) {
      dispatch(
        utagLink({
          error_field: "rechargeLimit",
          error_message: formStatusError,
          reg_event: "form-error",
          reg_flusso: getRegFlusso(),
        }),
      );
    }
  }, [dispatch, formStatusError]);

  return (
    <BaseErrorMessage
      containerId="messaggio_errore_fail"
      containerClassName="mb-3 invalid-error"
      message={
        <Translate id={formStatusError} data={{ amount: maxRechargeLimit }} />
      }
    />
  );
}
