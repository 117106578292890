import { Translate } from "react-localize-redux";
import { DEFAULT_ENDCALLBACKURL } from "../../../config";
import close from "../../../images/svg/icon/close-upfile-white.svg";
import { richiestaAdvPlus } from "../../../services/questionarioPepController";

const QuestionarioPepModal = (props) => {
  let { incomplete, complete, optionSelected, formData, utagView, utagLink } =
    props;

  const closeModal = () => {
    utagView({ reg_step: "pep-scelta:conferma_no" });
    props.closeModal();
  };

  const trackConfirm = () => {
    if (incomplete) {
      utagView({ reg_step: "pep-questionario:questionario-incompleto" });
    }
    if (complete) {
      utagView({ reg_step: "pep-questionario:questionario-completo" });
    }
  };

  const richiestaAdv = () => {
    trackConfirm();
    richiestaAdvPlus(formData, optionSelected, true)
      .then((resp) => {
        window.location = formData.typUrl;
      })
      .catch((err) => {
        utagLink({
          error_field: "submit",
          error_message: "errore submit",
          reg_event: "form-error",
        });
      });
  };

  const goToUrl = () => {
    trackConfirm();
    richiestaAdvPlus(formData, optionSelected, false)
      .then((resp) => {
        window.location = formData.typUrl;
      })
      .catch((err) => {
        utagLink({
          error_field: "submit",
          error_message: "errore submit",
          reg_event: "form-error",
        });
      });
  };

  const goToHomePage = () => {
    trackConfirm();
    window.location = DEFAULT_ENDCALLBACKURL;
  };

  return (
    <div className="questionario-pep-modal">
      <div className="pep-modal">
        <div className="conferma-header">
          {complete ? (
            <h4>
              <Translate id="text.pepModalConfirmation" />
            </h4>
          ) : (
            <h4>
              {incomplete ? (
                <Translate id="text.pepModalIncomplete" />
              ) : (
                <Translate id="text.pepModalChoice" />
              )}
            </h4>
          )}
          <button onClick={() => closeModal()}>
            <img src={close} alt="close" />
          </button>
        </div>
        <div className="block">
          {complete ? (
            <>
              <p>
                <Translate id="text.pepModalConfirmAnswer" />
              </p>
              <button onClick={() => richiestaAdv()}>
                <Translate id="text.pepModalConfirm" />
              </button>
            </>
          ) : (
            <>
              <p>
                {incomplete ? (
                  <Translate id="text.pepModalFillSurvey" />
                ) : (
                  <Translate id="text.pepModalConfirmChoice" />
                )}
              </p>
              {incomplete ? (
                <>
                  <button onClick={() => closeModal()}>
                    <Translate id="text.pepModalBackToSurvey" />
                  </button>
                  <a onClick={() => goToHomePage()}>
                    <Translate id="text.pepModalBackToHome" />
                  </a>
                </>
              ) : (
                <>
                  <button onClick={() => goToUrl()}>
                    <Translate id="text.pepModalConfirm" />
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default QuestionarioPepModal;
