const flowC1 = {
  status: "SUCCESS",
  flow: {
    name: "C1",
    useOtp: false,
    steps: [
      {
        step: 0,
        name: "inizio",
        path: "/",
        componentName: "Inizio",
        nextComponentName: "/nome-cognome",
        components: ["Inizio"],
      },
      {
        step: 0,
        name: "activeAccount",
        path: "/registrazione-pdv",
        componentName: "ActiveAccount",
        nextComponentName: "/nome-cognome",
        previousComponentName: "/nome-cognome",
        components: ["ActiveAccount"],
      },
      {
        step: 1,
        name: "personalData",
        path: "/nome-cognome",
        componentName: "PersonalData",
        nextComponentName: "/codice-fiscale",
        components: ["PersonalData"],
      },
      {
        step: 2,
        name: "fiscalCode",
        group: "A",
        isLast: false,
        displayStep: true,
        path: "/codice-fiscale",
        componentName: "FiscalCode",
        nextComponentName: "/indirizzo-residenza",
        previousComponentName: "/nome-cognome",
        dependencies: "/calcolo-codice-fiscale-step1",
        components: ["FiscalCode"],
      },
      {
        step: 2,
        name: "fiscalCodeConferma",
        group: "A",
        displayStep: true,
        path: "/codice-fiscale-conferma",
        componentName: "FiscalCode",
        nextComponentName: "/indirizzo-residenza",
        previousComponentName: "/nome-cognome",
        dependencies: "/calcolo-codice-fiscale-step1",
        components: ["FiscalCode"],
      },
      {
        step: 3,
        name: "birthplace",
        group: "A",
        path: "/calcolo-codice-fiscale-step1",
        componentName: "Sex",
        nextComponentName: "/codice-fiscale-conferma",
        previousComponentName: "/codice-fiscale",
        components: ["Sex", "Birthplace"],
      },
      {
        step: 4,
        name: "residence",
        group: "B",
        displayStep: true,
        path: "/indirizzo-residenza",
        componentName: "Residence",
        nextComponentName: "/contatti",
        previousComponentName: "/codice-fiscale",
        components: ["Residence", "Domicile"],
      },
      {
        step: 5,
        name: "contacts",
        group: "C",
        displayStep: true,
        path: "/contatti",
        componentName: "Contacts",
        nextComponentName: "/dati-accesso",
        previousComponentName: "/indirizzo-residenza",
        components: ["Contacts"],
      },
      {
        step: 6,
        name: "userData",
        group: "D",
        path: "/dati-accesso",
        componentName: "UserData",
        nextComponentName: "/bonus",
        previousComponentName: "/contatti",
        components: ["UserData"],
      },
      {
        step: 8,
        name: "welcomeBonus",
        group: "Bonus",
        displayStep: true,
        path: "/bonus",
        componentName: "WelcomeBonus",
        nextComponentName: "/documento-inserimento-dati",
        previousComponentName: "/dati-accesso",
        components: ["Bonus"],
      },
      {
        step: 9,
        name: "ocrForm",
        group: "E",
        path: "/documento-inserimento-dati",
        componentName: "OcrForm",
        nextComponentName: "/limite-ricarica",
        previousComponentName: "/bonus",
        components: ["Identification", "OcrForm"],
      },
      {
        step: 10,
        name: "rechargeLimit",
        group: "G",
        displayStep: true,
        path: "/limite-ricarica",
        componentName: "RechargeLimit",
        nextComponentName: "/termini-condizioni",
        previousComponentName: "/bonus",
        components: ["RechargeLimit"],
      },
      {
        step: 11,
        name: "termsAndConditions",
        group: "H",
        displayStep: true,
        path: "/termini-condizioni",
        componentName: "TermsAndConditions",
        nextComponentName: "/riepilogo",
        previousComponentName: "/limite-ricarica",
        components: ["TermsAndConditions"],
      },
      {
        step: 12,
        name: "summary",
        group: "Summary",
        path: "/riepilogo",
        componentName: "Summary",
        previousComponentName: "/termini-condizioni",
        components: ["Summary"],
      },
    ],
  },
};

export const defaultFlow = flowC1;
