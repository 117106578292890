import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { utagLink, utagView } from "../../../store/utagStore/utagStore.action";
import PopupModal from "../../popupModal/popupModal";
import { selectBonusDetail } from "../../../store/bonusDetail/bonusDetail.selectors";

export default function BonusDetailsModal({ show, onClose }) {
  const dispatch = useDispatch();

  const bonusData = useSelector(selectBonusDetail);

  const handleClose = () => {
    dispatch(
      utagLink({
        event_type: "dettaglio_bonus_chiudi",
        cta_label: "chiudi",
        conversion_pagename: "scelta-bonus-dettaglio",
      }),
    );

    onClose();
  };

  useEffect(() => {
    if (show) {
      dispatch(
        utagView({ pagename: "scelta-bonus-dettaglio" }, { force: true }),
      );
    }
  }, [dispatch, show]);

  return (
    <PopupModal show={show} className="bonus-description-modal">
      <PopupModal.Header closeMe={handleClose}>
        <Translate id="text.typ.spid.welcomeBonus" />
      </PopupModal.Header>

      <div className="bonus-pointed-rules">
        <h5 className="modal-body-product-title">{bonusData?.productLabel}</h5>

        <p className="modal-body-heading">{bonusData?.detailTitle}</p>

        <div
          className="modal-body-what-bonus"
          dangerouslySetInnerHTML={{ __html: bonusData?.detailWhatBonus }}
        ></div>

        <p className="modal-body-title-how-it-works">
          {bonusData?.titleHowItWorks}
        </p>

        <div
          className="modal-body-how-it-works"
          dangerouslySetInnerHTML={{ __html: bonusData?.detailHowItWorks }}
        ></div>

        <div className="modal-body-list">
          <ul style={{ listStyle: "decimal", marginBottom: "24px" }}>
            {bonusData?.detailStepList?.slice(1)?.map((step, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: step }}></li>
            ))}
          </ul>
        </div>
      </div>

      <PopupModal.Footer>
        <button onClick={handleClose}>
          <Translate id="lbl.buttonDiscard" />
        </button>
      </PopupModal.Footer>
    </PopupModal>
  );
}
