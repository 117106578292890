import { instanceGet } from "../axios.config";
import { REACT_APP_URL } from "../config";
import { v4 as uuidv4 } from "uuid";

export async function getSelfLimitationRegistration(
  userAge,
  ipAddress,
  channel,
  infoChannel,
) {
  const url = `${REACT_APP_URL}/gioca-giusto-ms/ms/v1/self-limitation-registration`;
  const data = {
    params: {
      birthDate: userAge.getTime(),
      ipAddress,
      requestId: uuidv4(),
      infoChannel,
      channel,
    },
  };

  const res = await instanceGet.get(url, data);
  return res.data;
}
