import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRegBonus, useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { utagView } from "../../store/utagStore/utagStore.action";
import { wizardSelectorsComponent } from "../../store/wizard/wizard.selectors";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import DocumentViewer from "../documentViewer/documentViewer.component";
import SignButton from "../signButton/signButton.component";
import PrivacyInfoModal from "./privacyInfoModal/privacyInfoModal.component";
import { MODAL_STATUS } from "./privacyInfoModal/privacyInfoModal.helpers";

export default function TermsAndConditions(props) {
  const { formData, component, flusso } = props;

  const [statusForm, setStatusForm] = useState(true);
  const [pdfActive, setPdfActive] = useState({
    status: false,
    value: "",
    pdfTitle: "",
  });

  const [privacyModalStatus, setPrivacyModalStatus] = useState(
    MODAL_STATUS.OFF,
  );

  const [showConsents, setShowConsents] = useState(
    formData.personalDataTreatmentConsent === false,
  );

  const [showProfilingBox, setShowProfilingBox] = useState(
    formData.personalDataProfilingTreatmentConsent === false,
  );

  const [treatmentRadio, setTreatmentRadio] = useState({
    on:
      formData.personalDataTreatmentConsent !== null &&
      formData.personalDataTreatmentConsent,
    off:
      formData.personalDataTreatmentConsent !== null &&
      !formData.personalDataTreatmentConsent,
  });
  const [profilingRadio, setProfilingRadio] = useState({
    on:
      formData.personalDataProfilingTreatmentConsent !== null &&
      formData.personalDataProfilingTreatmentConsent,
    off:
      formData.personalDataProfilingTreatmentConsent !== null &&
      !formData.personalDataProfilingTreatmentConsent,
  });
  const [treatmentCheckboxes, setTreatmentCheckboxes] = useState({
    email: formData.treatmentConsents.email,
    sms: formData.treatmentConsents.sms,
    phone: formData.treatmentConsents.phone,
  });

  const controlCheckBox = (name, value) => {
    if (component.name === "summary") {
      props.utagLink({
        reg_event: "riepilogo_modifica",
        reg_campo: name,
      });
    }
    props.addFormData({ [name]: value });

    if (
      name === "gameContract" ||
      name === "termsAndConditions" ||
      name === "privacyPolicy"
    ) {
      setStatusForm(checkingForm("termsAndConditions"));
    }
  };

  const LinkPdf = (props) => (
    <Translate>
      {({ translate }) => (
        <>
          {!formData.isMobile ? (
            <a
              rel="noopener noreferrer"
              href={translate(props.link)}
              target="_blank"
              className="link-text link-bold"
            >
              {props.noLinkText && <Translate id={props.noLinkText} />}
              <Translate id={props.text} />
            </a>
          ) : (
            <span
              className={!props.noLinkText ? "mobile-link" : ""}
              onClick={() =>
                setPdfActive({
                  status: true,
                  value: translate(props.link),
                  pdfTitle: translate(props.title),
                })
              }
            >
              {props.noLinkText && <Translate id={props.noLinkText} />}
              <Translate id={props.text} />
            </span>
          )}
        </>
      )}
    </Translate>
  );

  useEffect(() => {
    setStatusForm(checkingForm("termsAndConditions"));
    props.disabledBtn(statusForm);
  }, [statusForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useAnalyticsUtagView(props.hideUtag);

  const onChangeTreatmentRadioButton = (radioValue) => {
    setTreatmentRadio({ on: radioValue, off: !radioValue });
    if (!radioValue) setShowConsents(!radioValue);
    setTreatmentCheckboxes({
      email: radioValue,
      sms: radioValue,
      phone: radioValue,
    });
    props.addFormData({
      personalDataTreatmentConsent: radioValue,
      treatmentConsents: {
        email: radioValue,
        sms: radioValue,
        phone: radioValue,
      },
    });
    setStatusForm(checkingForm("termsAndConditions"));
  };

  const onChangeProfilingRadioButton = (radioValue) => {
    setProfilingRadio({ on: radioValue, off: !radioValue });
    props.addFormData({ personalDataProfilingTreatmentConsent: radioValue });
    setStatusForm(checkingForm("termsAndConditions"));
  };

  const onChangeEmailCheckbox = () => {
    let personalDataTreatmentConsentNewValue;
    if (
      treatmentCheckboxes.email &&
      !treatmentCheckboxes.sms &&
      !treatmentCheckboxes.phone
    ) {
      personalDataTreatmentConsentNewValue = false;
      setTreatmentRadio({ on: false, off: true });
    } else {
      personalDataTreatmentConsentNewValue = true;
      setTreatmentRadio({ on: true, off: false });
    }
    setTreatmentCheckboxes({
      ...treatmentCheckboxes,
      email: !treatmentCheckboxes.email,
    });
    props.addFormData({
      personalDataTreatmentConsent: personalDataTreatmentConsentNewValue,
      treatmentConsents: {
        ...formData.treatmentConsents,
        email: !formData.treatmentConsents.email,
      },
    });
    props.utagLink({
      reg_event: "riepilogo_modifica",
      reg_campo: "personalDataTreatmentConsent_email",
    });
  };

  const onChangeSmsCheckbox = () => {
    let personalDataTreatmentConsentNewValue;
    if (
      !treatmentCheckboxes.email &&
      treatmentCheckboxes.sms &&
      !treatmentCheckboxes.phone
    ) {
      personalDataTreatmentConsentNewValue = false;
      setTreatmentRadio({ on: false, off: true });
    } else {
      personalDataTreatmentConsentNewValue = true;
      setTreatmentRadio({ on: true, off: false });
    }
    setTreatmentCheckboxes({
      ...treatmentCheckboxes,
      sms: !treatmentCheckboxes.sms,
    });
    props.addFormData({
      personalDataTreatmentConsent: personalDataTreatmentConsentNewValue,
      treatmentConsents: {
        ...formData.treatmentConsents,
        sms: !formData.treatmentConsents.sms,
      },
    });
    props.utagLink({
      reg_event: "riepilogo_modifica",
      reg_campo: "personalDataTreatmentConsent_sms",
    });
  };

  const onChangePhoneCheckbox = () => {
    let personalDataTreatmentConsentNewValue;
    if (
      !treatmentCheckboxes.email &&
      !treatmentCheckboxes.sms &&
      treatmentCheckboxes.phone
    ) {
      personalDataTreatmentConsentNewValue = false;
      setTreatmentRadio({ on: false, off: true });
    } else {
      personalDataTreatmentConsentNewValue = true;
      setTreatmentRadio({ on: true, off: false });
    }
    setTreatmentCheckboxes({
      ...treatmentCheckboxes,
      phone: !treatmentCheckboxes.phone,
    });
    props.addFormData({
      personalDataTreatmentConsent: personalDataTreatmentConsentNewValue,
      treatmentConsents: {
        ...formData.treatmentConsents,
        phone: !formData.treatmentConsents.phone,
      },
    });
    props.utagLink({
      reg_event: "riepilogo_modifica",
      reg_campo: "personalDataTreatmentConsent_chiamata",
    });
  };

  return (
    <>
      <div
        className="container step-container termsAndConditions"
        id="it-ar-termsandconditions-container"
      >
        <div className={"row"}>
          <div
            className={
              "col-12" +
              (component.name !== "summary"
                ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
                : "")
            }
          >
            <form>
              {component.isLast ? (
                ""
              ) : (
                <div
                  className="header-form"
                  id="it-ar-termsandconditions-header-form"
                >
                  <h2
                    className="sub-title-form"
                    id="it-ar-termsandconditions-sub-title-form"
                  >
                    <Translate
                      id={
                        flusso !== "SPID"
                          ? "text.termscondSubTitle"
                          : "text.termscondSubTitle.spid"
                      }
                    />
                  </h2>
                  <p
                    className="label-form margin-bot-standard"
                    id="it-ar-termsandconditions-label-form"
                  >
                    <Translate id="text.termscondPar" />
                  </p>
                </div>
              )}

              <div
                id="it-ar-termsandconditions-form-group-checkbox"
                className={
                  "form-group-checkbox ml-15 " +
                  (component.name === "summary"
                    ? " mt-0 mt-md-0"
                    : "mt-3 mt-md-3")
                }
              >
                <div
                  className="form-check"
                  id="it-ar-termsandconditions-form-check"
                >
                  <label
                    className="form-check-label"
                    htmlFor="termsAndConditionsCheck1"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={formData.gameContract}
                      onChange={() =>
                        controlCheckBox("gameContract", !formData.gameContract)
                      }
                      id="termsAndConditionsCheck1"
                    />
                    <span
                      className={
                        !formData.gameContract && component.name === "summary"
                          ? "checkmark invalid"
                          : "checkmark"
                      }
                      id="it-ar-termsandconditions-checkmark1"
                    />
                  </label>
                  <LinkPdf
                    link={"link.gameContract"}
                    text={"text.gameContract"}
                    title={"text.contractOfGame"}
                  />
                </div>
              </div>

              <div
                id="it-ar-termsandconditions-form-group-checkbox2"
                className="form-group-checkbox mt-4 ml-15"
              >
                <div
                  className="form-check"
                  id="it-ar-termsandconditions-form-check2"
                >
                  <label
                    className="form-check-label"
                    htmlFor="termsAndConditionsCheck2"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={formData.termsAndConditions}
                      onChange={() =>
                        controlCheckBox(
                          "termsAndConditions",
                          !formData.termsAndConditions,
                        )
                      }
                      id="termsAndConditionsCheck2"
                    />
                    <span
                      className={
                        !formData.termsAndConditions &&
                        component.name === "summary"
                          ? "checkmark invalid"
                          : "checkmark"
                      }
                      id="it-ar-termsandconditions-checkmark2"
                    />
                  </label>
                  <LinkPdf
                    link={"link.termandcondition"}
                    text={"text.termsandcondition"}
                    title={"text.termscondTitle"}
                  />
                </div>
              </div>

              <div
                className="form-group-checkbox mt-4 ml-15"
                id="it-ar-termsandconditions-form-group-checkbox3"
              >
                <div
                  className="form-check terms-box"
                  id="it-ar-termsandconditions-form-check3"
                >
                  <label
                    className="form-check-label"
                    htmlFor="termsAndConditionsCheck3"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={formData.privacyPolicy}
                      onChange={() =>
                        controlCheckBox(
                          "privacyPolicy",
                          !formData.privacyPolicy,
                        )
                      }
                      id="termsAndConditionsCheck3"
                    />
                    <span
                      className={
                        !formData.privacyPolicy && component.name === "summary"
                          ? "checkmark invalid"
                          : "checkmark"
                      }
                      id="it-ar-termsandconditions-checkmark3"
                    />
                  </label>
                  <div className="terms-new without-arrow information-text">
                    <LinkPdf
                      link={"link.informationPrivacy"}
                      text={"text.informationPrivacy_B"}
                      noLinkText={"text.informationPrivacy_B1"}
                      title={"text.informationPrivacy"}
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-group-checkbox mt-1 ml-15"
                id="it-ar-termsandconditions-form-group-checkbox4"
              >
                <div
                  className="form-check terms-box"
                  id="it-ar-termsandconditions-form-check4"
                >
                  <label
                    className="form-check-label color-subtext-checkbox"
                    htmlFor="termsAndConditionsCheck4"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={false}
                      value={formData.personalDataTreatmentConsent || ""}
                      onChange={() =>
                        controlCheckBox(
                          "personalDataTreatmentConsent",
                          !formData.personalDataTreatmentConsent,
                        )
                      }
                      id="termsAndConditionsCheck4"
                    />
                  </label>
                  <div className="terms-new">
                    <div className="terms-new-text">
                      <Translate
                        id="text.termsandconditionPersonalDataText_B"
                        data={{
                          privacyPolicy: (
                            <span
                              className="info-privacy-link"
                              onClick={() =>
                                setPrivacyModalStatus(MODAL_STATUS.TREATMENT)
                              }
                            >
                              <Translate id="text.informationPrivacy" />
                            </span>
                          ),
                        }}
                      />
                      <b> *</b>.
                    </div>
                    <div className="radio-container">
                      <div className="radio-box">
                        <input
                          id="personalDataTreatment-radio-yes"
                          className="terms-radio-button"
                          type="radio"
                          name="personalDataTreatment"
                          value="true"
                          checked={treatmentRadio.on}
                          onChange={() => onChangeTreatmentRadioButton(true)}
                        />
                        <label htmlFor="personalDataTreatment-radio-yes">
                          <Translate id="lbl.termsCondition.yes" />
                        </label>
                      </div>
                      <div className="radio-box">
                        <input
                          id="personalDataTreatment-radio-no"
                          className="terms-radio-button"
                          type="radio"
                          name="personalDataTreatment"
                          value="false"
                          checked={treatmentRadio.off}
                          onChange={() => onChangeTreatmentRadioButton(false)}
                        />
                        <label htmlFor="personalDataTreatment-radio-no">
                          <Translate id="lbl.termsCondition.no" />
                        </label>
                      </div>
                    </div>
                    {showConsents && (
                      <div className="tripartion-container">
                        <p className="tripartion-text tripartion-alert">
                          {treatmentRadio.on ? (
                            <Translate id="lbl.terms.tripartion.text.on" />
                          ) : (
                            <Translate id="lbl.terms.tripartion.text" />
                          )}
                        </p>
                        <div className="tripartion-action-area">
                          <div className="checkbox-container">
                            <input
                              id="email"
                              className="tripartion-checkbox"
                              type="checkbox"
                              onChange={() => onChangeEmailCheckbox()}
                              checked={treatmentCheckboxes.email}
                            />
                            <span
                              className="tripartion-chechmark"
                              onClick={() => onChangeEmailCheckbox()}
                            />
                            <label htmlFor="email">
                              <Translate id="lbl.email" />
                            </label>
                          </div>
                          <div className="checkbox-container">
                            <input
                              id="sms"
                              className="tripartion-checkbox"
                              type="checkbox"
                              onChange={() => onChangeSmsCheckbox()}
                              checked={treatmentCheckboxes.sms}
                            />
                            <span
                              className="tripartion-chechmark"
                              onClick={() => onChangeSmsCheckbox()}
                            />
                            <label htmlFor="sms">
                              <Translate id="lbl.terms.tripartion.sms" />
                            </label>
                          </div>
                          <div className="checkbox-container">
                            <input
                              id="phoneCall"
                              className="tripartion-checkbox"
                              type="checkbox"
                              onChange={() => onChangePhoneCheckbox()}
                              checked={treatmentCheckboxes.phone}
                            />
                            <span
                              className="tripartion-chechmark"
                              onClick={() => onChangePhoneCheckbox()}
                            />
                            <label htmlFor="phoneCall">
                              <Translate id="lbl.terms.tripartion.phone" />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="form-group-checkbox mt-1 ml-15"
                id="it-ar-termsandconditions-form-group-checkbox5"
              >
                <div
                  className="form-check terms-box"
                  id="it-ar-termsandconditions-form-check5"
                >
                  <label
                    className="form-check-label color-subtext-checkbox"
                    htmlFor="termsAndConditionsCheck5"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={false}
                      value={
                        formData.personalDataProfilingTreatmentConsent || ""
                      }
                      onChange={() =>
                        controlCheckBox(
                          "personalDataProfilingTreatmentConsent",
                          !formData.personalDataProfilingTreatmentConsent,
                        )
                      }
                      id="termsAndConditionsCheck5"
                    />
                  </label>
                  <div className="terms-new">
                    <div className="terms-new-text">
                      <Translate
                        id="text.termsandconditionPersonalProfilingText_B"
                        data={{
                          privacyPolicy: (
                            <span
                              className="info-privacy-link"
                              onClick={() =>
                                setPrivacyModalStatus(MODAL_STATUS.PROFILING)
                              }
                            >
                              <Translate id="text.informationPrivacy" />
                            </span>
                          ),
                        }}
                      />
                    </div>
                    <div className="radio-container">
                      <div className="radio-box">
                        <input
                          id="personalDataProfiling-radio-yes"
                          className="terms-radio-button"
                          type="radio"
                          name="personalDataProfiling"
                          value="true"
                          checked={profilingRadio.on}
                          onChange={() => {
                            onChangeProfilingRadioButton(true);
                            setShowProfilingBox(false);
                          }}
                        />
                        <label htmlFor="personalDataProfiling-radio-yes">
                          <Translate id="lbl.termsCondition.yes" />
                        </label>
                      </div>
                      <div className="radio-box">
                        <input
                          id="personalDataProfiling-radio-no"
                          className="terms-radio-button"
                          type="radio"
                          name="personalDataProfiling"
                          value="false"
                          checked={profilingRadio.off}
                          onChange={() => {
                            onChangeProfilingRadioButton(false);
                            setShowProfilingBox(true);
                          }}
                        />
                        <label htmlFor="personalDataProfiling-radio-no">
                          <Translate id="lbl.termsCondition.no" />
                        </label>
                      </div>
                    </div>
                    {showProfilingBox && (
                      <div className="tripartion-container">
                        <p className="tripartion-text tripartion-alert my-0">
                          <Translate id="lbl.terms.profiling.text" />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <DocumentViewer
              pdfHeaderTitle={pdfActive.pdfTitle}
              pdfActive={pdfActive}
              setPdfActive={setPdfActive}
            />
          </div>
        </div>

        {flusso === "SPID" || flusso === "OCR" ? (
          <div className="row flex-column terms-sign-button">
            <div className="spid-sign-button">
              <SignButton className="container step-container pt-5 pl-0 pr-0" />
            </div>

            {flusso === "SPID" && (
              <div className="re-captcha mt-3">
                <label>
                  This site is protected by reCAPTCHA and the Google&nbsp;
                  <a
                    href={
                      formData.channel === "62"
                        ? "https://policies.google.com/privacy"
                        : "https://policies.google.com/privacy?open=https%3A%2F%2Fpolicies.google.com%2Fprivacy"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                    className="green"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href={
                      formData.channel === "62"
                        ? "https://policies.google.com/terms"
                        : "https://policies.google.com/terms?open=https%3A%2F%2Fpolicies.google.com%2Fterms"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                    className="green"
                  >
                    Terms of Service
                  </a>
                  &nbsp;apply
                </label>
              </div>
            )}
          </div>
        ) : null}

        {privacyModalStatus && (
          <PrivacyInfoModal
            type={privacyModalStatus}
            isMobile={formData.isMobile}
            setPdfActive={setPdfActive}
            onClose={() => setPrivacyModalStatus(MODAL_STATUS.OFF)}
          />
        )}
      </div>
    </>
  );
}

function useAnalyticsUtagView(hideUtag) {
  const dispatch = useDispatch();

  const component = useSelector(wizardSelectorsComponent.getComponent);

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();
  const reg_doc = useRegDoc();

  useEffect(() => {
    if (hideUtag || component.name === "summary") return;

    dispatch(
      utagView({
        conversion_pagename: "termini-condizioni",
        reg_step: "termini-condizioni",
        ...(reg_bonus ? { reg_bonus } : {}),
        reg_flusso,
        country: "italia",
        reg_doc,
      }),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
