import { connect } from "react-redux";
import SecurityQuestion from "./securityQuestion.component";
import * as formDataAction from "../../store/formData/formData.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { getQuestionListAPI } from "../../store/securityList/securityList.action";
import { questionListSelectors } from "../../store/securityList/securityList.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { utagSelectors } from "../../store/utagStore/utagStore.selectors";

const mapStateToProps = (state, ownProps) => {
  return {
    formData: formDataSelectors.getFormData(state),
    questionList: questionListSelectors.getQuestionList(state),
    utag: utagSelectors.getUtag(state),
    component: wizardSelectorsComponent.getComponent(state),
    flusso: wizardSelectorsFlusso.getFlusso(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    disabledBtn: (status) => dispatch(disabledBtn(status)),
    getQuestionListAPI: (channel, history) =>
      dispatch(getQuestionListAPI(channel, history)),
    utagView: (data) => dispatch(utagView(data)),
    utagLink: (data) => dispatch(utagLink(data)),
  };
};

const SecurityQuestionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityQuestion);
export default SecurityQuestionContainer;
