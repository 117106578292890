import { useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as Home } from "../../../images/svg/summary/home.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { isForeign } from "../summary.helpers";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../summary.hooks";
import SummaryInfoBlock from "../summaryInfoBlock/summaryInfoBlock.component";
import SummaryAddressPopup from "./summaryAddressPopup/summaryAddressPopup.component";
import { addressLinkTranslateId } from "./summaryAddress.config";

export default function SummaryAddress() {
  const [showPopup, setShowPopup] = useState(false);

  const formData = useSelector(formDataSelectors.getFormData);

  const { trackOpenPopup } = useSummaryAnalyticsTrackLinks();

  const { getCurrentAddressFormData } = useCurrentData();

  const addressFormDataBeforeEditRef = useRef(getCurrentAddressFormData());

  const residence = getResidenceDataToBeShown(
    showPopup,
    addressFormDataBeforeEditRef.current,
    formData,
  );

  const domicile = getDomicileDataToBeShown(
    showPopup,
    addressFormDataBeforeEditRef.current,
    residence,
    formData,
  );

  const description = formData.sameResidenceAndDomicile
    ? residence
    : `${residence} - ${domicile}`;

  const handleOpenPopup = () => {
    trackOpenPopup(addressLinkTranslateId);
    addressFormDataBeforeEditRef.current = getCurrentAddressFormData();
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <SummaryInfoBlock
        icon={<Home />}
        title={<Translate id={addressLinkTranslateId} />}
        description={description}
        onClick={handleOpenPopup}
      />

      <SummaryAddressPopup show={showPopup} onClose={handleClosePopup} />
    </>
  );
}

function getResidenceDataToBeShown(
  showPopup,
  previousAddressFormData,
  formData,
) {
  if (showPopup) {
    return isForeign(previousAddressFormData.state)
      ? getResidence(
          previousAddressFormData.addressEstero,
          previousAddressFormData.capEstero,
          previousAddressFormData.cityEstero,
        )
      : getResidence(
          previousAddressFormData.address,
          previousAddressFormData.cap,
          previousAddressFormData.city,
          previousAddressFormData.province,
        );
  } else {
    return isForeign(formData.state)
      ? getResidence(
          formData.addressEstero,
          formData.capEstero,
          formData.cityEstero,
        )
      : getResidence(
          formData.address,
          formData.cap,
          formData.city,
          formData.province,
        );
  }
}

function getDomicileDataToBeShown(
  showPopup,
  previousAddressFormData,
  residence,
  formData,
) {
  if (showPopup) {
    return isForeign(previousAddressFormData.domicileState)
      ? getDomicile(
          previousAddressFormData.sameResidenceAndDomicile,
          residence,
          previousAddressFormData.addressEsteroDomi,
          previousAddressFormData.capEsteroDomi,
          previousAddressFormData.cityEsteroDomi,
        )
      : getDomicile(
          previousAddressFormData.sameResidenceAndDomicile,
          residence,
          previousAddressFormData.domicileAddress,
          previousAddressFormData.domicileCap,
          previousAddressFormData.domicileCity,
          previousAddressFormData.domicileProvince,
        );
  } else {
    return isForeign(formData.domicileState)
      ? getDomicile(
          formData.sameResidenceAndDomicile,
          residence,
          formData.addressEsteroDomi,
          formData.capEsteroDomi,
          formData.cityEsteroDomi,
        )
      : getDomicile(
          formData.sameResidenceAndDomicile,
          residence,
          formData.domicileAddress,
          formData.domicileCap,
          formData.domicileCity,
          formData.domicileProvince,
        );
  }
}

function getResidence(address, cap, city, province) {
  const partWithoutProvince = `${address} ${cap} ${city}`;
  const provincePart = ` (${province})`;
  return partWithoutProvince + (!!province ? provincePart : "");
}

function getDomicile(
  sameResidenceAndDomicile,
  residence,
  domicileAddress,
  domicileCap,
  domicileCity,
  domicileProvince,
) {
  const domicileProvinceStr = !!domicileProvince ? `(${domicileProvince})` : "";

  return sameResidenceAndDomicile
    ? residence
    : `${domicileAddress} ${domicileCap} ${domicileCity} ${domicileProvinceStr}`;
}
