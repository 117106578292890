import { v4 as uuidv4 } from "uuid";
import { instancePost } from "../axios.config";
import { LOGIN_APP_URL, PORT } from "../config";

export const autheLogin = async (formData, channel) => {
  // let url = `${REACT_APP_URL}${PORT}/authentication-ms/ms/auth/login`
  let url = `${LOGIN_APP_URL}${PORT}/authentication-ms/ms/auth/login`;

  let body = {
    recaptchaToken: window.grecaptcha.token,
    channel: channel,
    flagConto: 0,
    ipAddress: formData.ipAddress,
    password: formData.password,
    pvd: "BO9999",
    requestId: uuidv4(),
    sessionId: formData.sessionId,
    userAgent: formData.userAgent,
    username: formData.username,
  };

  const res = await instancePost.post(url, body);
  return res.data;
};
