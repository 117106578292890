import { KEY_RE_CAPTCHA, REDIRECT_APP_URL } from "../../config";

export function customRedirectRecaptchaToken(
  formData,
  addFormData,
  redirect,
  history,
) {
  window.grecaptcha.enterprise
    .execute(KEY_RE_CAPTCHA, { action: "LOGIN" })
    .then((token) => {
      window.grecaptcha.token = token;
      customRedirect(formData, addFormData, redirect, history);
    });
}

function customRedirect(formData, addFormData, redirect, history) {
  switch (formData.channel) {
    case "62":
      const urlDesktop = `${REDIRECT_APP_URL}/?auth-token=${formData.token}&endcallbackurl=${redirect}`;

      if (formData.flagAdvPlus) {
        addFormData({ typUrl: urlDesktop });
        history.push("/questionario");
      } else {
        window.location = urlDesktop;
      }

      break;
    default:
      const urlMobile = `http://appmobile.sisal.it/?auth-token=${formData.token}&jwtToken=${formData.tokenTouch}&flagConto=${formData.flagConto}`;

      if (formData.flagAdvPlus) {
        addFormData({ typUrl: urlMobile });
        history.push("/questionario");
      } else {
        window.location = urlMobile;
      }
  }
}
