import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFlow } from "../../../hooks/useFlow";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { wizardSelectorsFlow } from "../../../store/wizard/wizard.selectors";
import { getFlow } from "../../../utility/wizardConfig/wizardConfig";
import LinkPdv from "../../elementForm/linkPdv/linkPdv.component";

export default function RegistrationTypeSelectionLinkPdv() {
  const dispatch = useDispatch();

  const history = useHistory;
  const defaultClassicFlowName = useSelector(
    wizardSelectorsFlow.getWizardDefaultClassicFlowName,
  );

  const { goToSpecificStep } = useFlow();

  const handleClick = async () => {
    dispatch(
      utagLink({
        reg_event: "attiva-conto-pdv",
        conversion_pagename: "pagina_di_disambiguazione",
        reg_cta: "attivalo qui",
      }),
    );

    await getFlow(defaultClassicFlowName, null, history);
    goToSpecificStep("/registrazione-pdv");
  };

  return <LinkPdv onClick={handleClick} className="link-pdv-container" />;
}
