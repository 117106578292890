import { useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RechargeLimit } from "../../../images/svg/summary/recharge-limit.svg";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { closeRechargeLimitApprovalPopup } from "../../../store/rechargeLimit/rechargeLimit.action";
import { rechargeLimitSelectors } from "../../../store/rechargeLimit/rechargeLimit.selectors";
import RechargeLimitEvaluationModal from "../../rechargeLimit/rechargeLimitEvalutationModal/rechargeLimitEvaluationModal.component";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../summary.hooks";
import SummaryInfoBlock from "../summaryInfoBlock/summaryInfoBlock.component";
import SummaryRechargeLimitsPopup from "./summaryRechargeLimitsPopup.component";
import { rechargeLimitslinkTranslateId } from "./summaryRechargeLimits.config";

export default function SummaryRechargeLimits() {
  const [showPopup, setShowPopup] = useState(false);

  const { rechargeLimit } = useSelector(formDataSelectors.getFormData);

  const { trackOpenPopup } = useSummaryAnalyticsTrackLinks();

  const { getCurrentRechargeLimitsFormData } = useCurrentData();

  const rechargeLimitsFormDataBeforeEditRef = useRef(
    getCurrentRechargeLimitsFormData(),
  );

  const description = `${
    showPopup
      ? rechargeLimitsFormDataBeforeEditRef.current.rechargeLimit
      : rechargeLimit
  } €`;

  const handleOpenPopup = () => {
    trackOpenPopup(rechargeLimitslinkTranslateId);
    rechargeLimitsFormDataBeforeEditRef.current =
      getCurrentRechargeLimitsFormData();
    setShowPopup(true);
  };

  return (
    <>
      <SummaryInfoBlock
        icon={<RechargeLimit />}
        title={<Translate id={rechargeLimitslinkTranslateId} />}
        description={description}
        onClick={handleOpenPopup}
      />

      <SummaryRechargeLimitsPopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
      />

      <RechargeLimitEvaluationModalConnected />
    </>
  );
}

function RechargeLimitEvaluationModalConnected() {
  const dispatch = useDispatch();

  const showApprovalPopup = useSelector(
    rechargeLimitSelectors.getShowApprovalPopupInSummary,
  );

  const approvalAmount = useSelector(
    rechargeLimitSelectors.getRechargeLimitApprovalAmount,
  );

  const handleRechargeLimitApprovalConfirm = () => {
    dispatch(closeRechargeLimitApprovalPopup());
  };

  const handleRechargeLimitApprovalCancel = () => {
    const approvalRechargeLimit = approvalAmount / 100;
    const rechargeLimit = approvalRechargeLimit.toLocaleString("it-IT");
    dispatch(addFormData({ rechargeLimit }));

    dispatch(closeRechargeLimitApprovalPopup());
  };

  return (
    <RechargeLimitEvaluationModal
      show={showApprovalPopup}
      onConfirm={handleRechargeLimitApprovalConfirm}
      onCancel={handleRechargeLimitApprovalCancel}
    />
  );
}
