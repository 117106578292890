import {
  FABRICK_SPID_BUTTON_FE_API_KEY,
  FABRICK_SPID_ENVIRONMENT,
  FABRICK_SPID_ON_FAILURE_REDIRECT_URL,
  FABRICK_SPID_ON_SUCCESS_REDIRECT_URL,
} from "../../config";

export function getSpidButtonConfig() {
  // eslint-disable-next-line no-new-func
  const env = Function(`"use strict"; return ${FABRICK_SPID_ENVIRONMENT}`)();

  const spidButtonConfig = {
    size: "m",
    providerId: "sisal",
    attributeConsumingService: "REG",
    authContextClass: "SpidL2",
    env,
    onSuccessRedirectUrl: FABRICK_SPID_ON_SUCCESS_REDIRECT_URL,
    onFailureRedirectUrl: FABRICK_SPID_ON_FAILURE_REDIRECT_URL,
    apiKey: FABRICK_SPID_BUTTON_FE_API_KEY,
  };

  return spidButtonConfig;
}
