import {
  CLOSE_RECHARGE_LIMIT_APPROVAL_POPUP,
  FETCH_RECHARGE_LIMIT_ERROR,
  FETCH_RECHARGE_LIMIT_START,
  FETCH_RECHARGE_LIMIT_SUCCESS,
  OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_RECHARGE_LIMIT_STEP,
  OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_SUMMARY,
} from "./rechargeLimit.action";

const initialState = {
  showApprovalPopupInRechargeLimitStep: false,
  showApprovalPopupInSummary: false,
  approvalAmount: null,
  maxAmount: null,
  fetching: false,
  hasShownApprovalPopup: false,
};

export function rechargeLimitReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_RECHARGE_LIMIT_STEP:
      return {
        ...state,
        showApprovalPopupInRechargeLimitStep: true,
        hasShownApprovalPopup: true,
      };
    case OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_SUMMARY:
      return {
        ...state,
        showApprovalPopupInSummary: true,
        hasShownApprovalPopup: true,
      };
    case CLOSE_RECHARGE_LIMIT_APPROVAL_POPUP:
      return {
        ...state,
        showApprovalPopupInRechargeLimitStep: false,
        showApprovalPopupInSummary: false,
      };
    case FETCH_RECHARGE_LIMIT_START:
      return { ...state, fetching: true };
    case FETCH_RECHARGE_LIMIT_SUCCESS:
      return {
        ...state,
        approvalAmount: action.payload.approvalAmount,
        maxAmount: action.payload.maxAmount,
        fetching: false,
      };
    case FETCH_RECHARGE_LIMIT_ERROR:
      return { ...state, fetching: false };
    default:
      return state;
  }
}
