import { v4 as uuidv4 } from "uuid";
import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export async function generateFiscalCode(
  birthDate,
  cityCode,
  countryFiscalCode,
  gender,
  name,
  surname,
  channel,
) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/utils/generateFs`;
  const data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      birthDate: birthDate,
      cityBelfCode: cityCode || "ESTERO",
      countryFiscalCode: countryFiscalCode,
      gender: gender,
      name: name,
      surname: surname,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
}
