import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { wizardSelectorsComponent } from "../../../store/wizard/wizard.selectors";
import formIsValid from "../../../utility/formIsValid/formIsValid.container";

const noAutoValidationFields = ["password", "phoneNumber", "fiscalCode"];

export default function MessageError({
  currentValue,
  currentName,
  customAlert,
  translationData,
}) {
  const dispatch = useDispatch();

  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);

  const formStatusCurrentName = formStatus[currentName];

  useEffect(() => {
    if (noAutoValidationFields.includes(currentName)) return;

    const error = formIsValid(currentName, currentValue);
    dispatch(addFormStatus(error));
  }, [dispatch, currentValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      formStatusCurrentName.length > 0 &&
      formStatusCurrentName !== "err.required" &&
      formStatusCurrentName !== "err.password.noVisualError" &&
      formStatusCurrentName !== customAlert &&
      !formStatus.chargingPin
    ) {
      dispatch(
        utagLink({
          error_field: currentName,
          error_message: formStatusCurrentName,
          reg_event: "form-error",
        }),
      );
    }
  }, [formStatusCurrentName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        "invalid-feedback " +
        (formStatus[currentName].length > 0 ? "" : "d-none")
      }
    >
      {formStatus[currentName].length > 0 &&
        !formStatus[currentName].endsWith(".noVisualError") &&
        (formStatus[currentName] === "err.required" &&
        component.name !== "summary" ? (
          <div
            id={
              component.path === "/documento-scelta"
                ? "massagio_errore_alert-date"
                : "massagio_errore_alert"
            }
            className={
              component.path === "/documento-scelta"
                ? "invalid-alert-date"
                : "mb-3 invalid-alert"
            }
          >
            <Translate id={!!customAlert ? customAlert : "err.required"} />
          </div>
        ) : (
          <div
            id={
              component.path === "/documento-scelta"
                ? "messagio_errore_fail-date"
                : "messagio_errore_fail"
            }
            className={
              "mb-3 " +
              (component.path === "/documento-scelta"
                ? "invalid-error-date"
                : "invalid-error")
            }
          >
            <Translate id={formStatus[currentName]} data={translationData} />
          </div>
        ))}
    </div>
  );
}
