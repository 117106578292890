import { Translate } from "react-localize-redux";
import { usePdvFlowDetection } from "../../../../hooks/useFlow";

export default function CalculateFiscalCodeButton({ onClick }) {
  const isPdvFlow = usePdvFlowDetection();

  const translateId = isPdvFlow
    ? "text.calculateFiscalCode"
    : "text.notRememberFiscalCode";

  return (
    <button
      id="it-ar-fiscalCode-custom-p-link"
      className="calculate-fiscal-code-button"
      onClick={onClick}
    >
      <Translate id={translateId} />
    </button>
  );
}
