import React, { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRegDoc, useRegFlusso } from "../../../hooks/useAnalytics";
import { useFlow } from "../../../hooks/useFlow";
import {
  disablePhoneErrorView,
  enablePhoneErrorView,
} from "../../../store/contacts/contacts.action";
import { selectCanShowPhoneError } from "../../../store/contacts/contacts.selectors";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { addSpidStatus } from "../../../store/spidStatus/spidStatus.action";
import { spidStatusSelectors } from "../../../store/spidStatus/spidStatus.selectors";
import { utagLink, utagView } from "../../../store/utagStore/utagStore.action";
import {
  disabledBtn,
  setIsVisiblePhonePopup,
  setVerifyPhone,
} from "../../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
  wizardSelectorsIsVisiblePhonePopup,
  wizardSelectorsVerifyPhone,
} from "../../../store/wizard/wizard.selectors";
import {
  checkingForm,
  contactsContainsError,
} from "../../../utility/checkingForm/checkingForm.container";
import formIsValid from "../../../utility/formIsValid/formIsValid.container";
import { moveCursorAtTheEnd } from "../../../utility/input/input";
import MessageError from "../../elementForm/messageError/messageError.component";
import PhonePopup from "../../elementForm/phoneNumberPopup/phoneNumberPopup.container";
import Vector from "../../../images/svg/vector.svg";
import Prefix from "./partials/prefix.component";
import NoteContactPage from "./partials/note.component";

export default function PhoneContact() {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const isVisiblePhonePopup = useSelector(
    wizardSelectorsIsVisiblePhonePopup.getWizardIsVisiblePhonePopup
  );
  const isVerifiedPhone = useSelector(
    wizardSelectorsVerifyPhone.getWizardIsVerifyPhone
  );

  const reg_doc = useRegDoc();

  const { goToNextStep } = useFlow();

  let [previousValue, setPreviousValue] = useState({
    phoneNumber: formData.phoneNumber,
  });

  const canShowPhoneError = useSelector(selectCanShowPhoneError);

  const phoneNumberRef = useRef();

  const reg_flusso = useRegFlusso();

  const handleChangeTelephone = async (value) => {
    if (component.name === "summary") {
      return;
    }

    if (
      /^[0-9]*$/.test(value) &&
      ((formData.phonePrefix.callingCodes === "39" &&
        (value.length === 9 || value.length <= 10)) ||
        value.length === 7 ||
        value.length <= 15)
    ) {
      dispatch(setVerifyPhone(false));

      isValidPhone(value);

      const errorsInFormPresent = contactsContainsError(formData, formStatus);
      dispatch(disabledBtn(errorsInFormPresent));
    }
  };

  const handleKeyDownPhone = (event) => {
    if (event.key === "Enter") {
      phoneNumberRef.current.blur();
    }
  };

  const isValidPhone = (value) => {
    dispatch(disablePhoneErrorView());

    dispatch(addFormData({ phoneNumber: value }));
    const error = formIsValid("phoneNumber", value);
    dispatch(addFormStatus(error));
  };

  const handleBlurPhoneNumber = async (value) => {
    if (value.length === 0) {
      dispatch(addFormStatus({ phoneNumber: "err.required" }));

      dispatch(enablePhoneErrorView());
    } else {
      if (
        !formStatus.phoneNumber &&
        formData.phoneNumber !== formData.phoneNumberPdv
      ) {
        dispatch(setVerifyPhone(false));
        checkValidation();
      }

      validatorPhoneNumber();
    }

    if (
      previousValue["phoneNumber"] !== value &&
      component.name === "summary"
    ) {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "phoneNumber",
        })
      );
    }
    setPreviousValue({ ...previousValue, phoneNumber: value });
  };

  const handleKeyUpPhoneNumber = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.target.blur();
    }
  };

  const checkValidation = () => {
    dispatch(disabledBtn(checkingForm("contacts")));
  };

  const validatorPhoneNumber = () => {
    dispatch(enablePhoneErrorView());

    checkValidation();
  };

  const isPhoneNumberValid = () => {
    checkValidation();
    validatorPhoneNumber();
    setPreviousValue({ ...previousValue, phoneNumber: formData.phoneNumber });
  };

  useEffect(() => {
    if (formData.phoneNumber) {
      isPhoneNumberValid();
    }

    if (flusso === "SPID" && spidStatus.notItalianPrefix) {
      dispatch(
        utagView({
          reg_section: "registrazione",
          reg_step: "numero-di-telefono",
          reg_flusso,
        })
      );
    }

    return () => {
      if (flusso === "SPID" && spidStatus.fromSpidFirstLandingContacts) {
        dispatch(
          addSpidStatus({
            fromSpidFirstLandingContacts: false,
            notItalianPrefix: false,
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (component.name !== "contacts") return;

    if (flusso === "C1" || flusso === "OCR") {
      dispatch(
        utagView({
          reg_step: "contatti",
          conversion_pagename: "contatti",
          reg_flusso,
          ...(reg_flusso === "ocr" && { country: "italia", reg_doc }),
        })
      );
    }
  }, [component.name, dispatch, flusso, reg_doc, reg_flusso]);

  const closePopupHandler = () => {
    dispatch(setIsVisiblePhonePopup(false));
  };

  const activate = () => {
    setTimeout(() => {
      dispatch(setIsVisiblePhonePopup(false));
      goToNextStep();
    }, 1500);
    dispatch(setVerifyPhone(true));
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        {(spidStatus.showPhone || flusso !== "SPID") && (
          <>
            <div
              id="it-ar-contacts-form-group-nation"
              className={
                "form-group nation" +
                (component.name === "summary" ? " accordion-form md-5" : "")
              }
            >
              <div
                className="form-row align-items-start"
                id="it-ar-contacts-form-row"
              >
                <Prefix
                  formData={formData}
                  component={component}
                  handleChangeTelephone={handleChangeTelephone}
                  handleBlurPhoneNumber={handleBlurPhoneNumber}
                />
                <div
                  id="it-ar-contacts-col-tel"
                  className={
                    "col col-tel tel" +
                    (component.name === "summary" ? " mt-0 mt-md-2" : "")
                  }
                >
                  <input
                    ref={phoneNumberRef}
                    autoComplete="tel"
                    type="tel"
                    className={
                      "form-control " +
                      (formStatus.phoneNumber.length > 0 && canShowPhoneError
                        ? formStatus.phoneNumber === "err.required" &&
                          component.name === "contacts"
                          ? " is-present"
                          : " is-invalid"
                        : "") +
                      (component.name === "summary" ? " remove-border" : "")
                    }
                    name="phoneNumber"
                    id="it-ar-contacts-phoneNumber"
                    required
                    maxLength={
                      formData.phonePrefix.callingCodes === "39" ? "10" : "15"
                    }
                    readOnly={component.name === "summary" ? true : false}
                    value={formData.phoneNumber}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownPhone}
                    onChange={(e) => handleChangeTelephone(e.target.value)}
                    onBlur={(e) => handleBlurPhoneNumber(e.target.value)}
                    onKeyUp={(e) => handleKeyUpPhoneNumber(e)}
                  />

                  {isVerifiedPhone && component.useOtp && (
                    <div className="vector">
                      <img src={Vector} alt="" />
                    </div>
                  )}

                  <label
                    className="form-control-label"
                    id="it-ar-contacts-lblTelephone"
                    htmlFor="it-ar-contacts-phoneNumber"
                  >
                    <Translate id="lbl.telephone" />
                  </label>

                  {canShowPhoneError && (
                    <MessageError
                      id="it-ar-contacts-msgPhoneNumber"
                      currentValue={formData.phoneNumber}
                      currentName={"phoneNumber"}
                    />
                  )}

                  {isVerifiedPhone && component.useOtp ? (
                    <div className="success">
                      <Translate id="lbl.numVerificato" />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}

        {component.useOtp &&
        component.name === "contacts" &&
        (spidStatus.showPhone || flusso !== "SPID") ? (
          <NoteContactPage />
        ) : null}
      </form>

      {isVisiblePhonePopup && component.useOtp && (
        <PhonePopup
          closeMe={closePopupHandler}
          number={formData.phoneNumber}
          phonePrefix={formData.phonePrefix.callingCodes}
          requestId={formData.requestId}
          activate={activate}
        />
      )}
    </div>
  );
}
