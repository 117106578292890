import React from "react";
import { useSelector } from "react-redux";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { vivochaProactiveChatSelectors } from "../../store/vivochaProactiveChat/vivochaProactiveChat.selectors";
import {
  getCurrentVivochaErrors,
  vivochaErrorCodes,
} from "../../utility/vivochaProactiveChat/vivochaProactiveChat";

function repeatedOccurrenceFilterFn(vivochaErrorCode, counter) {
  return (value) => {
    if (value === vivochaErrorCode && counter <= 1) {
      return false;
    }
    return true;
  };
}

function VivochaProactiveChatDataErrors() {
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const flusso = useSelector((state) => state.wizard.flusso);

  const pdvKoCheckCredentialsCounter = useSelector(
    vivochaProactiveChatSelectors.getPdvKoCheckCrendentialsCounter,
  );
  const phoneNumberAlreadyPresent = useSelector(
    vivochaProactiveChatSelectors.getPhoneNumberAlreadyPresent,
  );

  const currentErrorCodes =
    flusso === "SPID" || flusso === "SPID_E"
      ? []
      : getCurrentVivochaErrors(formStatus)
          .filter(
            repeatedOccurrenceFilterFn(
              vivochaErrorCodes.PDV_KO_CHECK_CRENDENTIALS,
              pdvKoCheckCredentialsCounter,
            ),
          )
          .filter(
            repeatedOccurrenceFilterFn(
              vivochaErrorCodes.PHONE_NUMBER_ALREADY_PRESENT,
              phoneNumberAlreadyPresent,
            ),
          );

  const currentErrorCodesString = JSON.stringify(currentErrorCodes);

  return (
    <div
      id="vivocha-proactive-chat-error-codes"
      data-vivocha-proactive-chat-error-codes={currentErrorCodesString}
      style={{ display: "none" }}
    ></div>
  );
}

export default VivochaProactiveChatDataErrors;
