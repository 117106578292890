import { Translate } from "react-localize-redux";
import PopupModal from "../popupModal/popupModal";

export default function LeaveConfirmPopup({
  onClickLeave,
  onClickContinue,
  onClose,
  text,
}) {
  return (
    <div className="spid-exit-popup">
      <PopupModal>
        <PopupModal.Header closeMe={onClose}>
          <Translate id="spid.leaving.title" />
        </PopupModal.Header>

        <PopupModal.Body>
          <div className="spid-exit-popup-text mb-3">
            <Translate id="spid.leaving.question" />
          </div>

          <div className="spid-exit-popup-text">{text}</div>
        </PopupModal.Body>

        <PopupModal.Footer>
          <button className="outline-button" onClick={onClickLeave}>
            <Translate id="lbl.leaving" />
          </button>

          <button onClick={onClickContinue}>
            <Translate id="lbl.resume" />
          </button>
        </PopupModal.Footer>
      </PopupModal>
    </div>
  );
}
