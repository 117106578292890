import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { utagView } from "../../store/utagStore/utagStore.action";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";

export function useAnalyticsUtagView() {
  const dispatch = useDispatch();

  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const reg_doc = useRegDoc();

  const reg_flusso = useRegFlusso();

  useEffect(() => {
    if (!reg_doc) return;

    const reg_step =
      flusso === "OCRPDV"
        ? "registrazione:pdv ocr:step5:verifica dati"
        : "ocr:step5:verifica dati";

    dispatch(
      utagView(
        {
          reg_step,
          conversion_pagename: reg_step,
          reg_doc,
          country: "italia",
          reg_flusso,
        },
        { delay: 700 },
      ),
    );
  }, [dispatch, flusso, reg_doc, reg_flusso]);
}
