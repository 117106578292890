import React, { useEffect, useState } from "react";
import { postGenerateOtp } from "../../../../services/otpController";
import PopupModal from "../../../popupModal/popupModal";
import { Translate } from "react-localize-redux";
import SubNote from "../../../commons/subNote/subNote.component";
import BaseErrorMessage from "../../../commons/baseErrorMessage/baseErrorMessage.component";
import {
  getClassFromError,
  validateContact,
} from "./typcModalEmailStep.helpers";
import { useHistory } from "react-router-dom";

export default function TYPCModalEmailStep({
  email,
  formData,
  nextStep,
  setEmail,
  setIsLoading,
  setIsOtpNotValid,
  setIsOtpSended,
  setOtpBlockStatus,
  utagView,
}) {
  const history = useHistory();
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState("");
  const [activeOtp, setActiveOtp] = useState(false);

  const onChange = (newValue) => {
    if (!/[^0-9a-zA-Z-_.@]/.test(newValue)) {
      setEmail(newValue);
      setError("");
    }
  };

  const onBlur = () => {
    const error = validateContact(email);
    setError(error);
    setFocus(false);
  };

  const onClick = () => {
    setIsLoading(true);
    postGenerateOtp(formData, {
      email,
    })
      .then((res) => {
        setIsLoading(false);
        setOtpBlockStatus({
          blocked: false,
          invalided: false,
        });
        setIsOtpNotValid(false);
        setIsOtpSended(false);
        if (res.counterRefresh > 2) {
          setOtpBlockStatus({
            blocked: true,
            invalided: false,
          });
        }
        setActiveOtp(true);
        nextStep();
      })
      .catch((err) => {
        setIsLoading(false);
        const errInfo = err.response.data.error?.errorInfos?.generic[0];

        if (err.response.status === 422 && errInfo !== "err.void") {
          setIsOtpNotValid(false);
          setIsOtpSended(false);

          switch (errInfo) {
            case "err.otp.block":
              setOtpBlockStatus({
                blocked: true,
                invalided: true,
              });
              break;
            case "err.out.limit":
              setIsOtpNotValid(true);
              break;
            case "err.otp.alreadySent":
              setIsOtpSended(true);
              break;
            default:
              break;
          }
          setActiveOtp(true);
          nextStep();
        } else {
          history.push("/errore-registrazione");
        }
      });
  };

  useEffect(() => {
    utagView({
      pagename: `popup:verifica-mail":conferma-aggiorna-mail`,
      page_type: "popup-servizio",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeOtp) {
      utagView({
        pagename: `popup:verifica-contatti:mail:otp_mail`,
        page_type: "popup-servizio",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOtp]);

  return (
    <div id="typ-ccm-email-step">
      <PopupModal.Body>
        <h5 className="modal-body-title">
          <Translate id="text.emailTYP.title" />
        </h5>
        <p className="modal-body-text">
          <Translate id="text.emailTYP.body" />
        </p>
        <div className="form-group">
          <input
            className={`form-control ${getClassFromError(error, focus)}`}
            type="text"
            required
            value={email}
            onFocus={() => setFocus(true)}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
            id="email-typ-input"
          />
          <label className="form-control-label" htmlFor="email-typ-input">
            <Translate id="lbl.email" />
          </label>
          {error && !focus && (
            <div className={`feedback-container ${getClassFromError(error)}`}>
              <BaseErrorMessage message={<Translate id={error} />} />
            </div>
          )}
        </div>
        <SubNote text={"text.emailTYP.subnote"} />
      </PopupModal.Body>
      <PopupModal.Footer>
        <button onClick={onClick}>
          <Translate id="lbl.emailTYP.button" />
        </button>
      </PopupModal.Footer>
    </div>
  );
}
