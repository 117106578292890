import { v4 as uuidv4 } from "uuid";
import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export const getQuestionList = async (channel) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/utils/questions`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      activeQuestions: true,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};
