import { useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { useRegFlusso } from "../../hooks/useAnalytics";
import redHalfCircle from "../../images/svg/red-half-circle.svg";
import { utagLink } from "../../store/utagStore/utagStore.action";
import LoyaltyPopup from "../loyaltyPopup/loyaltyPopup.component";

export default function LoyaltyBanner() {
  const dispatch = useDispatch();

  const reg_flusso = useRegFlusso();

  const [showLoyaltyModal, setShowLoyaltyModal] = useState(false);

  const handleClickInsert = () => {
    dispatch(
      utagLink({
        reg_event: "loyalty-banner-click",
        reg_cta: "inserisci",
        conversion_pagename: "dati-personali",
        reg_flusso,
      }),
    );

    setShowLoyaltyModal(true);
  };

  return (
    <div id="loyalty-green-banner">
      <div className="loyalty-banner">
        <div className="red-half-circle">
          <img alt="red-half-circle" src={redHalfCircle} />
        </div>

        <div className="description-banner">
          <h6>
            <Translate id="text.doYouHaveCodeSuperEnalotto" />
          </h6>

          <p>
            <Translate id="text.insert.gameCodeForSisalPoints" />
          </p>
        </div>

        <button className="btn-banner" onClick={handleClickInsert}>
          <Translate id="lbl.insert" />
        </button>
      </div>

      {showLoyaltyModal && (
        <LoyaltyPopup closeLoyaltyModal={() => setShowLoyaltyModal(false)} />
      )}
    </div>
  );
}
