import React, { useEffect, useState } from "react";
import logo from "../../images/svg/logo-sisal.svg";
import { Translate } from "react-localize-redux";
import QuestionarioPepModal from "../elementForm/questionarioPepModal/questionarioPepModal.component";
import PreFooter from "../elementForm/preFooter/preFooter.component";
import ArrowRight from "../../images/svg/icon/white-chevron-right.svg";
import ArrowLeft from "../../images/svg/icon/white-chevron-left.svg";
import {
  getDisponibilita,
  getOrigineFondi,
  getProfessioni,
} from "../../services/questionarioPepController";
import store from "../../store.config";
import MoreInfoModal from "../elementForm/moreInfoModal/moreInfoModal";

const QuestionarioPep = (props) => {
  let [confermaQuestion, setConfermaQuestion] = useState(false);
  let [openFormDisplay, setOpenFormDisplay] = useState(false);
  let [incomplete, setIncomplete] = useState(false);
  let [complete, setComplete] = useState(false);
  let [activeMedio, setActiveMedio] = useState(false);
  let [activeFonte, setActiveFonte] = useState(false);
  let [activeProfessione, setActiveProfessione] = useState(false);
  let [disponibilita, setDisponibilita] = useState();
  let [origineFondi, setOrigineFondi] = useState();
  let [professione, setProfessione] = useState();
  let [optionSelected, setOptionSelected] = useState({
    origineFondi: "",
    professione: "",
    disponibilita: "",
  });
  let [moreInfoModal, setMoreInfoModal] = useState(false);

  const formData = store.getState().formDataReducer.formData;

  const openModal = (incomplete, complete) => {
    setIncomplete(incomplete);
    setConfermaQuestion(true);
    setComplete(complete);
  };

  const closeModal = () => {
    setConfermaQuestion(false);
  };

  const openForm = () => {
    setOpenFormDisplay(!openFormDisplay);
  };

  const toogleListClose = (name) => {
    name(false);
  };

  const toogleListOpen = (name, boolean) => {
    name(!boolean);
  };

  const addSelectedOption = (name, value) => {
    setOptionSelected(Object.assign(optionSelected, { [name]: value }));
  };

  const openMoreInfoModal = () => {
    setMoreInfoModal(true);
  };

  const closeInfoModal = () => {
    setMoreInfoModal(false);
  };

  useEffect(() => {
    // Disponibilita
    getDisponibilita(formData.channel)
      .then((resp) => {
        if (resp && resp.disponibilita && resp.disponibilita.length > 0) {
          resp.disponibilita.sort((a, b) => (a.id > b.id ? 1 : -1));
          setDisponibilita(resp.disponibilita);
        }
      })
      .catch((err) => {
        props.utagLink({
          error_field: "disponibilita",
          error_message: "errore caricamento disponibilita",
          reg_event: "form-error",
        });
      });

    // OrigineFondi
    getOrigineFondi(formData.channel)
      .then((resp) => {
        if (resp && resp.origineFondi && resp.origineFondi.length > 0) {
          resp.origineFondi.sort((a, b) => (a.id > b.id ? 1 : -1));
          setOrigineFondi(resp.origineFondi);
        }
      })
      .catch((err) => {
        props.utagLink({
          error_field: "origine fondi",
          error_message: "errore caricamento origine fondi",
          reg_event: "form-error",
        });
      });

    // Professioni
    getProfessioni(formData.channel)
      .then((resp) => {
        if (resp && resp.professioni && resp.professioni.length > 0) {
          resp.professioni.sort((a, b) => (a.id > b.id ? 1 : -1));
          setProfessione(resp.professioni);
        }
      })
      .catch((err) => {
        props.utagLink({
          error_field: "professioni",
          error_message: "errore caricamento professioni",
          reg_event: "form-error",
        });
      });
  }, []);

  useEffect(() => {
    if (props.hideUtag) return;
    openFormDisplay
      ? props.utagView({ reg_step: "pep-questionario" })
      : props.utagView({ reg_step: "pep-scelta" });
  }, [openFormDisplay]);

  return (
    <div className="questionario-pep">
      <header className="sisal-header">
        <div className="container-fluid">
          <a title="Sisal" onClick={() => openModal(true, false)}>
            <img className="logoThank logo" alt="logo" src={logo} />
          </a>
          <button
            type="button"
            className="btn btn-discard px-0 float-right"
            onClick={() => openModal(true, false)}
          >
            <Translate id="lbl.buttonDiscard" />
          </button>
        </div>
      </header>

      <section
        className={
          "pep-first-step " + (!openFormDisplay ? "d-block" : "d-none")
        }
      >
        <h4>
          <Translate id="text.pepHeadline" />
        </h4>
        <p>
          <Translate id="text.pepDescription" />
        </p>
        <div className="pep-question">
          <h3>
            <Translate id="text.pepIsExposed" />
          </h3>
          <p>
            <Translate id="text.pepExposedDesc" />
            &nbsp;
            <u onClick={() => openMoreInfoModal()}>
              <Translate id="text.pepMoreInfo" />
            </u>
          </p>
          <div className="pep-question-buttons">
            <button onClick={() => openForm()}>
              <Translate id="text.pepYes" />
            </button>
            <button onClick={() => openModal(false, false)}>
              <Translate id="text.pepNo" />
            </button>
          </div>
        </div>
      </section>

      <section
        className={
          "pep-second-step " + (openFormDisplay ? "d-block" : "d-none")
        }
      >
        <h4>
          <Translate id="text.pepHeadline" />
        </h4>
        <p>
          <Translate id="text.pepDescription" />
        </p>
        <>
          <h5>
            <Translate id="text.pepIncomeQuestion" />
          </h5>
          <div className={"form-group"}>
            <label className="form-control-label isSpecial">
              <Translate id="lbl.pepIncome" />
            </label>
            <div
              className={"select-option"}
              onMouseDown={() => toogleListOpen(setActiveMedio, activeMedio)}
              tabIndex={0}
              onBlur={() => toogleListClose(setActiveMedio)}
            >
              <label>
                {optionSelected.disponibilita ? (
                  disponibilita[optionSelected.disponibilita - 1].descrizione
                ) : (
                  <Translate id="lbl.pepSelect" />
                )}
              </label>
            </div>
            <div className={"list-style " + (activeMedio ? "" : "d-none")}>
              <ul
                className={
                  "list-group opened-list " + (activeMedio ? "" : "d-none")
                }
              >
                {disponibilita && disponibilita.length > 0 && (
                  <>
                    {disponibilita.map((option, index) => (
                      <li
                        className={
                          "list-group-item col-12 " +
                          (optionSelected.disponibilita &&
                          disponibilita[optionSelected.disponibilita - 1]
                            .descrizione === option.descrizione
                            ? "active"
                            : "")
                        }
                        onMouseDown={(e) =>
                          addSelectedOption("disponibilita", option.id)
                        }
                        onClick={(e) =>
                          addSelectedOption("disponibilita", option.id)
                        }
                        key={index}
                        id={option.id}
                      >
                        {option.descrizione}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </>
        <>
          <h5>
            <Translate id="text.pepSourceQuestion" />
          </h5>
          <div className={"form-group"}>
            <label className="form-control-label isSpecial">
              <Translate id="lbl.pepSource" />
            </label>
            <div
              className={"select-option"}
              onMouseDown={() => toogleListOpen(setActiveFonte, activeFonte)}
              tabIndex={0}
              onBlur={() => toogleListClose(setActiveFonte)}
            >
              <label>
                {optionSelected.origineFondi ? (
                  origineFondi[optionSelected.origineFondi - 1].descrizione
                ) : (
                  <Translate id="lbl.pepSelect" />
                )}
              </label>
            </div>
            <div className={"list-style " + (activeFonte ? "" : "d-none")}>
              <ul
                className={
                  "list-group opened-list " + (activeFonte ? "" : "d-none")
                }
              >
                {origineFondi && origineFondi.length > 0 && (
                  <>
                    {origineFondi.map((option, index) => (
                      <li
                        className={
                          "list-group-item col-12 " +
                          (optionSelected.origineFondi &&
                          origineFondi[optionSelected.origineFondi - 1]
                            .descrizione === option.descrizione
                            ? "active"
                            : "")
                        }
                        onMouseDown={(e) =>
                          addSelectedOption("origineFondi", option.id)
                        }
                        onClick={(e) =>
                          addSelectedOption("origineFondi", option.id)
                        }
                        key={index}
                        id={option.id}
                      >
                        {option.descrizione}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </>
        <>
          <h5>
            <Translate id="text.pepJobQuestion" />
          </h5>
          <div className={"form-group"}>
            <label className="form-control-label isSpecial">
              <Translate id="lbl.pepJob" />
            </label>
            <div
              className={"select-option"}
              onMouseDown={() =>
                toogleListOpen(setActiveProfessione, activeProfessione)
              }
              tabIndex={0}
              onBlur={() => toogleListClose(setActiveProfessione)}
            >
              <label>
                {optionSelected.professione ? (
                  professione[optionSelected.professione - 1].descrizione
                ) : (
                  <Translate id="lbl.pepSelect" />
                )}
              </label>
            </div>
            <div
              className={"list-style " + (activeProfessione ? "" : "d-none")}
            >
              <ul
                className={
                  "list-group opened-list " +
                  (activeProfessione ? "" : "d-none")
                }
              >
                {professione && professione.length > 0 && (
                  <>
                    {professione.map((option, index) => (
                      <li
                        className={
                          "list-group-item col-12 " +
                          (optionSelected.professione &&
                          professione[optionSelected.professione - 1]
                            .descrizione === option.descrizione
                            ? "active"
                            : "")
                        }
                        onMouseDown={(e) =>
                          addSelectedOption("professione", option.id)
                        }
                        onClick={(e) =>
                          addSelectedOption("professione", option.id)
                        }
                        key={index}
                        id={option.id}
                      >
                        {option.descrizione}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </>

        <div className="questionario-buttons">
          <button className="indietro" onClick={() => openForm()}>
            <img src={ArrowLeft} width="24" alt="" />
            <span>
              <Translate id="text.pepBack" />
            </span>
          </button>
          <button
            className="continua"
            disabled={
              optionSelected.disponibilita === "" ||
              optionSelected.origineFondi === "" ||
              optionSelected.professione === ""
            }
            onClick={() => openModal(false, true)}
          >
            <span>
              <Translate id="text.pepContinue" />
            </span>
            <img src={ArrowRight} width="24" alt="" />
          </button>
        </div>
      </section>

      <section className="questionario-footer text-center">
        <PreFooter />
      </section>

      {confermaQuestion ? (
        <QuestionarioPepModal
          utagLink={props.utagLink}
          utagView={props.utagView}
          closeModal={closeModal}
          incomplete={incomplete}
          complete={complete}
          formData={formData}
          optionSelected={optionSelected}
        />
      ) : null}

      {moreInfoModal ? <MoreInfoModal closeModal={closeInfoModal} /> : null}
    </div>
  );
};
export default QuestionarioPep;
