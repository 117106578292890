import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { rechargeLimitSelectors } from "../../../store/rechargeLimit/rechargeLimit.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { getRegFlusso } from "../../../utility/analytics";
import PopupModal from "../../popupModal/popupModal";

export default function RechargeLimitEvaluationModal({
  show,
  onConfirm,
  onCancel,
}) {
  const dispatch = useDispatch();

  const { email } = useSelector(formDataSelectors.getFormData);

  const rechargeLimitApprovalAmount = useSelector(
    rechargeLimitSelectors.getRechargeLimitApprovalAmount,
  );
  const { rechargeLimit } = useSelector(formDataSelectors.getFormData);

  const approvalRechargeLimit = rechargeLimitApprovalAmount / 100;

  const alertMessage = `Hai chiesto di impostare un limite superiore a ${approvalRechargeLimit}€. Il limite desiderato deve essere approvato da Sisal. Riceverai nei prossimi giorni una comunicazione via mail a ${email} con le istruzioni su come completare la tua richiesta.`;

  const handleConfirm = () => {
    dispatch(
      utagLink({
        event_type: "cta_alert_popup",
        alert_title: "limite massimo di ricarica",
        alert_message: alertMessage,
        reg_limite_ricarica: rechargeLimit.replace(".", ""),
        reg_flusso: getRegFlusso(),
        link_name: "registrazione:limite_massimo:conferma",
      }),
    );

    onConfirm();
  };

  useEffect(() => {
    if (show) {
      dispatch(
        utagLink({
          event_type: "alert_popup",
          alert_title: "limite massimo di ricarica",
          alert_message: alertMessage,
          reg_limite_ricarica: rechargeLimit.replace(".", ""),
          reg_flusso: getRegFlusso(),
          link_name: "registrazione:limite_massimo",
        }),
      );
    }
  }, [alertMessage, dispatch, rechargeLimit, show]);

  return (
    <PopupModal show={show} className="recharge-limit-evaluation-modal">
      <PopupModal.Header closeMe={onCancel}>
        <Translate id="text.maxRechargeLimit" />
      </PopupModal.Header>

      <PopupModal.Body hideScrollbar>
        <h3 className="title">
          <Translate id="text.desiredRechargeLimitEvaluating" />
        </h3>

        <p className="text">
          <Translate
            id="text.desiredRechargeLimitDescription"
            data={{ email, amount: approvalRechargeLimit }}
          />
        </p>

        <div className="max-limit-block">
          <Translate id="text.maxRechargeLimit" />
          <span className="amount">{rechargeLimit}€</span>
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <button onClick={handleConfirm}>
          <Translate id="text.confirmRechargeLimitRequest" />
        </button>
      </PopupModal.Footer>
    </PopupModal>
  );
}
