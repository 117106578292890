export const STEP_BACK = "STEP_BACK";
export const STEP_NEXT = "STEP_NEXT";
export const DISABLED_BTN = "DISABLED_BTN";
export const IS_LOADING = "IS_LOADING";
export const SET_COMPONENT = "SET_COMPONENT";
export const SET_VISIBLEPHONEPOPUP = "SET_VISIBLEPHONEPOPUP";
export const SET_ISPHONEPOPUPOTPSENDED = "SET_ISPHONEPOPUPOTPSENDED";
export const SET_ISOTPNOTVALID = "SET_ISOTPNOTVALID";
export const SET_OTPBLOCKSTATUS = "SET_OTPBLOCKSTATUS";
export const SET_SMSENABLED = "SET_SMSENABLED";
export const SET_VERIFIEDPHONE = "SET_VERIFIEDPHONE";
export const SET_BONUS = "SET_BONUS";
export const FLOW = "FLOW";
export const FLUSSO = "FLUSSO";
export const MARK_SIGNUP_COMPLETED = "MARK_SIGNUP_COMPLETED";
export const SET_DEFAULT_FLOW_NAME = "SET_DEFAULT_FLOW_NAME";
export const SET_DEFAULT_CLASSIC_FLOW_NAME = "SET_DEFAULT_CLASSIC_FLOW_NAME";

export const stepBack = (state) => {
  return {
    type: STEP_BACK,
    step: state,
  };
};

export const stepNext = (state) => {
  return {
    type: STEP_NEXT,
    step: state,
  };
};

export const disabledBtn = (state) => {
  return {
    type: DISABLED_BTN,
    disabled: state,
  };
};

export const setIsLoading = (state) => {
  return {
    type: IS_LOADING,
    isLoading: state,
  };
};

export const setComponent = (state) => {
  return {
    type: SET_COMPONENT,
    setComponent: state,
  };
};

export const setSmsEnabled = (state) => {
  return {
    type: SET_SMSENABLED,
    setSmsEnabled: state,
  };
};

export const setIsVisiblePhonePopup = (state) => {
  return {
    type: SET_VISIBLEPHONEPOPUP,
    setIsVisiblePhonePopup: state,
  };
};

export const setIsPhonePopupOtpSended = (state) => ({
  type: SET_ISPHONEPOPUPOTPSENDED,
  isPhonePopupOtpSended: state,
});

export const setIsOptNotValid = (state) => ({
  type: SET_ISOTPNOTVALID,
  isOtpNotValid: state,
});

export const setOtpBlockStatus = (state) => ({
  type: SET_OTPBLOCKSTATUS,
  otpBlockStatus: state,
});

export const setVerifyPhone = (state) => {
  return {
    type: SET_VERIFIEDPHONE,
    setVerifyPhone: state,
  };
};

export const setBonus = (state) => {
  return {
    type: SET_BONUS,
    setBonus: state,
  };
};

export const setFlow = (state) => {
  return {
    type: FLOW,
    flow: state,
  };
};

export const setFlusso = (state) => {
  return {
    type: FLUSSO,
    flusso: state,
  };
};

export const markSignUpCompleted = () => {
  return { type: MARK_SIGNUP_COMPLETED };
};

export const setDefaultFlowName = (defaultFlowName) => {
  return { type: SET_DEFAULT_FLOW_NAME, defaultFlowName };
};

export const setDefaultClassicFlowName = (defaultClassicFlowName) => {
  return { type: SET_DEFAULT_CLASSIC_FLOW_NAME, defaultClassicFlowName };
};
