import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useOneTagStartLoadScript } from "../../hooks/useOneTagScript";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { wizardSelectorsIsLoading } from "../../store/wizard/wizard.selectors";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";
import Header from "../header/header.component";
import RegisterWithSpidCard from "./registerWithSpidCard/registerWithSpidCard.component";

export default function RegistrationTypeSelection({
  classicRegistrationCard,
  bottomElement,
}) {
  const dispatch = useDispatch();

  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);
  const isLoading = useSelector(wizardSelectorsIsLoading.getWizardIsLoading);

  useEffect(() => {
    dispatch(
      utagView({
        reg_section: "registrazione",
        reg_step: "pagina_di_disambiguazione",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (spidStatus.spidCallbackError === "err.spid.auth") {
      dispatch(
        utagLink({
          error_message: "errore autenticazione con terza parte",
          reg_event: "form-error",
        }),
      );
    }
  }, [dispatch, spidStatus.spidCallbackError]);

  useOneTagStartLoadScript();

  return (
    <>
      {isLoading && <FullPageLoader />}

      <Header />

      <div className="container" id="it-spid-form-group">
        <div className="spid-container">
          <div className="header-form">
            <h5 className="title-form">
              <Translate id="text.whatsyname_A" />
            </h5>

            <p className="subtitle-form">
              <Translate id="text.subtitleInizio" />
            </p>
          </div>

          <div className="card-spid-wrapper-container">
            {classicRegistrationCard}

            <RegisterWithSpidCard />
          </div>

          {bottomElement}
        </div>
      </div>
    </>
  );
}
