import { useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";

export default function SexSelectInputField({ onSelect }) {
  const { gender } = useSelector(formDataSelectors.getFormData);

  const [isSelectVisible, setIsSelectVisibile] = useState(false);

  const handleFocus = () => {
    setIsSelectVisibile(true);
  };

  const handleSelect = () => {
    setIsSelectVisibile(false);
    onSelect?.();
  };

  return (
    <Translate>
      {({ translate }) => (
        <div className="form-group sex-select-container">
          <input
            id="sex-select-input"
            className="form-control sex-select-input"
            type="text"
            autoComplete="off"
            onFocus={handleFocus}
            required
            value={
              gender === "F" ? translate("lbl.female2") : translate("lbl.male2")
            }
            readOnly
          />

          <label
            className="form-control-label sex-select-label"
            htmlFor="sex-select-input"
          >
            <Translate id="lbl.sex" />
          </label>

          <ul
            className={`list-group opened-list ${
              isSelectVisible ? "visible" : "not-visible"
            }`}
          >
            <MaleItem onClick={handleSelect} />

            <FemaleItem onClick={handleSelect} />
          </ul>
        </div>
      )}
    </Translate>
  );
}

function Item({ translateId, onClick }) {
  return (
    <li className="list-group-item" onClick={onClick}>
      <span className="highlighted-match">
        <Translate id={translateId} />
      </span>
    </li>
  );
}

function MaleItem({ onClick }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(addFormData({ gender: "M" }));
    onClick();
  };

  return <Item translateId="lbl.male2" onClick={handleClick} />;
}

function FemaleItem({ onClick }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(addFormData({ gender: "F" }));
    onClick();
  };

  return <Item translateId="lbl.female2" onClick={handleClick} />;
}
