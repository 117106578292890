export default function PreFooterContact({ iconSrc, title, linkComponent }) {
  return (
    <div className="popup-col">
      <div className="icon-circle">
        <img alt="" src={iconSrc} />
      </div>
      <div className="preFooter-caption">
        <div className="preFooter-caption-title">{title}</div>
        {linkComponent}
      </div>
    </div>
  );
}
