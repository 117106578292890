import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import SexSelectInputField from "../../inputFields/sexSelectInputField/sexSelectInputField.component";
import {
  FISCAL_CODE_GEN_DEBOUNCE_TIME,
  useFiscalCodeGeneration,
} from "../unmatchingPersonalDataPage.hooks";

export default function UnmatchingPersonalDataSex() {
  const dispatch = useDispatch();

  const generateFiscalCode = useFiscalCodeGeneration();

  const recalcFiscalCode = async () => {
    const fiscalCode = await generateFiscalCode();
    if (fiscalCode) {
      dispatch(addFormData({ fiscalCode }));
    }
  };

  const handleSelect = debounce(
    recalcFiscalCode,
    FISCAL_CODE_GEN_DEBOUNCE_TIME,
  );

  return <SexSelectInputField onSelect={handleSelect} />;
}
