import { isEqual } from "lodash";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../../store/formStatus/formStatus.selectors";
import { userDataContainsError } from "../../../../utility/checkingForm/checkingForm.container";
import PopupModal from "../../../popupModal/popupModal";
import UserData from "../../../userData/userData.component";
import { extractAccessDataFromFormData } from "../../summary.helpers";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupEditFooter from "../../summaryPopupFooter/summaryPopupEditFooter.component";
import { accessDataLinkTranslateId } from "../summaryAccessData.config";
import { selectUsernameHasBeenValidated } from "../../../../store/validateUsername/validateUsername.selectors";

export default function SummaryAccessDataPopupEditContent({
  onClose,
  onCancelEdit,
  previousData,
}) {
  const handleCloseIcon = () => {
    onCancelEdit();
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleCloseIcon}>
        <Translate id="lbl.accessData" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body popup-access-data-body">
          <UserData />
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryAccessDataPopupEditFooter
          onConfirm={onClose}
          onCancel={onCancelEdit}
          previousData={previousData}
        />
      </PopupModal.Footer>
    </>
  );
}

function SummaryAccessDataPopupEditFooter({
  onCancel,
  onConfirm,
  previousData,
}) {
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const usernameHasBeenValidated = useSelector(selectUsernameHasBeenValidated);

  const { trackCancelEditData, trackConfirmEditData } =
    useSummaryAnalyticsTrackLinks();

  const dirty = isAccessDataFormDirty(formData, previousData);

  const confirmDisabled =
    !dirty ||
    !usernameHasBeenValidated ||
    userDataContainsError(formData, formStatus);

  const handleConfirm = () => {
    trackConfirmEditData(accessDataLinkTranslateId);
    onConfirm();
  };

  const handleCancel = () => {
    trackCancelEditData(accessDataLinkTranslateId);
    onCancel();
  };

  return (
    <SummaryPopupEditFooter
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmDisabled={confirmDisabled}
    />
  );
}

function isAccessDataFormDirty(formData, previousData) {
  const current = extractAccessDataFromFormData(formData);
  const previous = extractAccessDataFromFormData(previousData);
  return !isEqual(current, previous);
}
