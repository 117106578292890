import moment from "moment";

export function getBirthCityObj(userTips, allCities) {
  return userTips.countryFiscalCode === "Z000"
    ? getBirthCityObjItaly(userTips.cityId, allCities)
    : {
        birthCityFiscalCode: "",
        birthProvince: "",
        birthTown: "",
      };
}

function getBirthCityObjItaly(cityId, allCities) {
  const birthCity = allCities.allCitiesFalse.find((city) => city.id === cityId);

  if (birthCity == null) {
    throw new Error(`Could not find birth city using id ${cityId}`);
  }

  return {
    birthCityFiscalCode: birthCity.fiscalCode,
    birthProvince: birthCity.province,
    birthTown: birthCity.description,
    birthId: birthCity.id,
  };
}

export function formatBirthDate(userTipsBirthDate) {
  return moment(userTipsBirthDate, "DD/MM/YYYY").format(
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  );
}
