import { getCountry } from "../../services/geoController";
export const FETCH_COUNTRIES_START = "FETCH_COUNTRIES_START";
export const RECEIVE_COUNTRIES_TRUE = "RECEIVE_COUNTRIES_TRUE";
export const FETCH_COUNTRIES_ERROR = "FETCH_COUNTRIES_ERROR";
export const RECEIVE_COUNTRIES_FALSE = "RECEIVE_COUNTRIES_FALSE";

export const getCountriesAPI = (currentState, channel, history) => {
  return (dispatch) => {
    dispatch(fetchCountriesStart());
    getCountry(currentState, channel)
      .then((response) => {
        if (currentState) dispatch(receiveCountries_true(response.countries));
        else dispatch(receiveCountries_false(response.countries));
      })
      .catch((err) => {
        if (err.response && err.response.error === 422)
          dispatch(fetchCountriesError(err.response));
        else {
          history.push("/errore-registrazione");
        }
      });
  };
};

export const fetchCountriesStart = () => {
  return {
    type: FETCH_COUNTRIES_START,
  };
};

export const receiveCountries_true = (state) => {
  return {
    type: RECEIVE_COUNTRIES_TRUE,
    payload: state,
  };
};

export const receiveCountries_false = (state) => {
  return {
    type: RECEIVE_COUNTRIES_FALSE,
    payload: state,
  };
};

export const fetchCountriesError = (state) => {
  return {
    type: FETCH_COUNTRIES_ERROR,
    formData: state,
  };
};
