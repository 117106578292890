import { Translate } from "react-localize-redux";
import Book from "../../../../images/svg/icon/book.svg";
import PreFooterChat from "../preFooterChat/preFooterChat.container";
import PreFooterContact from "../preFooterContact/preFooterContact.component";
import PreFooterPhone from "../preFooterPhone/preFooterPhone.container";

export default function PreFooterDesktop() {
  return (
    <div id="preFooter" className="preFooter preFooter-desktop-container">
      <div className="preFooter-desktop-text">
        <Translate id="text.needHelp" />
      </div>

      <div className="preFooter-desktop-body">
        <PreFooterChat />

        <PreFooterPhone />

        <PreFooterContact
          title={<Translate id="text.seeOur" />}
          iconSrc={Book}
          linkComponent={
            <a
              href="https://www.sisal.it/faq/registrazione"
              target="_blank"
              rel="noopener noreferrer"
              className="preFooter-caption-action"
            >
              <Translate id="text.FAQ" />
            </a>
          }
        />
      </div>
    </div>
  );
}
