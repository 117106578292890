import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFlow } from "../../hooks/useFlow";
import { checkBonusByCode } from "../../services/bonusController";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { disabledBtn, setBonus } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsBonus,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import PromoCode from "../elementForm/promoCode/promoCode.component";
import BonusDetailsModal from "./bonusDetailsModal/bonusDetailsModal.component";
import {
  useAnalyticsUtagView,
  useWelcomeBonusFetch,
} from "./welcomeBonus.hooks";
import WelcomeBonusCardList from "./welcomeBonusCardList/welcomeBonusCardList.component";
import WelcomeBonusHeadForm from "./welcomeBonusHeadForm/welcomeBonusHeadForm.component";
import WelcomeBonusPromoCodeBlock from "./welcomeBonusPromoCodeBlock/welcomeBonusPromoCodeBlock.component";

export default function WelcomeBonusWizard() {
  const dispatch = useDispatch();

  const history = useHistory();

  const fetchWelcomeBonus = useWelcomeBonusFetch();

  const { goToNextStep } = useFlow();

  const formData = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const bonus = useSelector(wizardSelectorsBonus.getBonus);

  const hasAlreadyDownloadedBonuses = !!formData.bonusListAll?.length;

  const [showPromoPopup, setShowPromoPopup] = useState(false);
  const [bonusHasLoaded, setBonusHasLoaded] = useState(
    hasAlreadyDownloadedBonuses,
  );
  const [showBonusDetailsModal, setShowBonusDetailsModal] = useState(false);

  const onKeyPressed = (key) => {
    if (key === "Enter") {
      if (formData.bonusCode.length > 0) goToNextStep();
    }
  };

  const bonusSelect = () => {
    if (formData.findBonus) {
      dispatch(addFormData({ findBonus: false }));
    }

    if (formData.promoCode !== "" && formData.bonusCode === "") {
      if (formData.bonusList && formData.bonusList.length > 0) {
        const item = formData.bonusList.find(
          (bonus) => bonus.bonusCode === formData.promoCode,
        );

        if (item) {
          dispatch(setBonus(item));

          dispatch(
            addFormData({
              promoCode: formData.promoCode,
              customizesBonus: true,
              bonusCode: "",
              findBonus: false,
            }),
          );
        } else {
          addBlankBonus();
        }
      } else {
        addBlankBonus();
      }
    }
  };

  const addBlankBonus = () => {
    dispatch(
      setBonus({
        bonusCode: formData.promoCode,
        title: formData.promoCode,
        bonusTitleLightBox: formData.promoCode,
        description:
          "Il codice promozioni inserito è corretto, completa la registrazione per riscattare il tuo bonus",
        style: "bonus",
      }),
    );

    dispatch(
      addFormData({
        promoCode: formData.promoCode,
        customizesBonus: true,
        bonusCode: "",
        findBonus: false,
      }),
    );
  };

  const checkBonusByCodeAPI = async () => {
    try {
      const data = await checkBonusByCode(
        formData.promoCodeUrl,
        formData.channel,
        formData.codiceRice,
      );

      if (data.isValid) {
        dispatch(
          addFormData({
            promoCode: formData.promoCodeUrl.toUpperCase().trim(),
            customizesBonus: true,
            bonusCode: "",
          }),
        );

        dispatch(disabledBtn(false));
      }
    } catch {
      history.push("/errore-registrazione");
    }
  };

  useAnalyticsUtagView();

  useEffect(() => {
    if (formData.promoCodeUrl !== "") {
      checkBonusByCodeAPI().then(() => {
        dispatch(addFormData({ promoCodeUrl: "" }));
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(disabledBtn(checkingForm("welcomeBonus")));
  }, [dispatch]);

  useEffect(() => {
    const checkPromoCallback = () => {
      if (formData.promoCodeUrl) {
        checkBonusByCodeAPI();
      }
    };

    if (hasAlreadyDownloadedBonuses) {
      checkPromoCallback();
      return;
    }

    fetchWelcomeBonus()
      .finally(() => setBonusHasLoaded(true))
      .then(checkPromoCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => bonusSelect(), [formData.promoCode]);

  return (
    <div
      className="container step-container welcomeBonus"
      id="it-ar-welcomeBonus-form-group"
      tabIndex={0}
      onKeyDown={(e) => onKeyPressed(e.key)}
    >
      <div className="row">
        <div className="col-12 col-lg-6 offset-0 offset-lg-3 px-sm-0">
          <WelcomeBonusHeadForm
            promoCode={formData.promoCode}
            flusso={flusso}
          />

          {formData.promoCode != null && !!bonus ? (
            <div>
              <WelcomeBonusPromoCodeBlock
                onClickChangeButton={() => setShowPromoPopup(true)}
                onClickInfoLink={() => setShowBonusDetailsModal(true)}
              />
            </div>
          ) : bonusHasLoaded ? (
            <WelcomeBonusCardList
              bonusList={formData.bonusList}
              onClickInfoLink={() => setShowBonusDetailsModal(true)}
              onClickChangeButton={() => setShowPromoPopup(true)}
            />
          ) : (
            <span>
              <Translate id="text.bonusLoading" />
            </span>
          )}

          {showPromoPopup && (
            <PromoCode onClose={() => setShowPromoPopup(false)} />
          )}

          <BonusDetailsModal
            show={showBonusDetailsModal}
            onClose={() => setShowBonusDetailsModal(false)}
          />
        </div>
      </div>
    </div>
  );
}
