import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { utagLink } from "../../../store/utagStore/utagStore.action";

export default function RegisterWithSpidCard() {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleSpidRegClick = () => {
    dispatch(
      utagLink({
        reg_cta: "registrati con spid",
        reg_event: "registrazione|pagina_di_disambiguazione|registrati_SPID",
      }),
    );

    history.push("/registrazione-spid-cf");
  };

  return (
    <div
      onClick={handleSpidRegClick}
      className="card-spid-wrapper card-spid-wrapper--register-with-spid"
    >
      <div className="spid-wrapper-title">
        <Translate id="lbl.spid.registrationWithSpid" />
      </div>

      <div className="spid-wrapper-info">
        <Translate id="text.spid.spidRegistrationInstructions" />
      </div>

      <div className="spid-wrapper-button">
        <button className="btn registration-button registration-button--secondary">
          <Translate id="lbl.signIn" />
        </button>
      </div>
    </div>
  );
}
