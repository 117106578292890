import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addFormData } from "../../../../store/formData/formData.action";
import { addFormStatus } from "../../../../store/formStatus/formStatus.action";
import PopupModal from "../../../popupModal/popupModal";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../../summary.hooks";
import { accessDataLinkTranslateId } from "../summaryAccessData.config";
import SummaryAccessDataPopupDetailContent from "./summaryAccessDataPopupDetailContent.component";
import SummaryAccessDataPopupEditContent from "./summaryAccessDataPopupEditContent.component";

export default function SummaryAccessDataPopup({ show, onClose }) {
  const dispatch = useDispatch();

  const { getCurrentAccessDataFormData } = useCurrentData();

  const accessDataFormDataBeforeEditRef = useRef(
    getCurrentAccessDataFormData(),
  );

  const [editMode, setEditMode] = useState(false);

  const { trackEditData } = useSummaryAnalyticsTrackLinks();

  const handlePressEdit = () => {
    trackEditData(accessDataLinkTranslateId);
    accessDataFormDataBeforeEditRef.current = getCurrentAccessDataFormData();
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);

    dispatch(addFormStatus({ username: "", password: "" }));

    if (!!accessDataFormDataBeforeEditRef.current) {
      dispatch(addFormData(accessDataFormDataBeforeEditRef.current));
    }
  };

  const handleCloseEdit = () => {
    setEditMode(false);
    onClose();
  };

  return (
    <PopupModal show={show}>
      {editMode ? (
        <SummaryAccessDataPopupEditContent
          onClose={handleCloseEdit}
          onCancelEdit={handleCancelEdit}
          previousData={accessDataFormDataBeforeEditRef.current}
        />
      ) : (
        <SummaryAccessDataPopupDetailContent
          onClose={onClose}
          onPressEdit={handlePressEdit}
        />
      )}
    </PopupModal>
  );
}
