export async function compileOtpFromSms(
  setOTPCode,
  abortController = new AbortController(),
) {
  const OTP_READ_TIMEOUT = 60 * 1000;

  setTimeout(() => {
    abortController.abort();
  }, OTP_READ_TIMEOUT);

  try {
    const otp = await navigator.credentials.get({
      otp: { transport: ["sms"] },
      signal: abortController.signal,
    });

    if (otp?.code != null) {
      setOTPCode(otp.code);
    }
  } catch (e) {
    console.error(e);
    abortController.abort();
  }
}
