import { connect } from "react-redux";
import Jumio from "./jumio.component";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { utagSelectors } from "../../store/utagStore/utagStore.selectors";
import * as formDataAction from "../../store/formData/formData.action";

const mapStateToProps = (state) => {
  return {
    formData: formDataSelectors.getFormData(state),
    utag: utagSelectors.getUtag(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    utagView: (data) => dispatch(utagView(data)),
    utagLink: (data) => dispatch(utagLink(data)),
  };
};

const JumioContainer = connect(mapStateToProps, mapDispatchToProps)(Jumio);
export default JumioContainer;
