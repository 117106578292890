import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import logo18 from "../../images/svg/18logo.svg";
import logoSpidAgId from "../../images/svg/spid-agid-logo.svg";
import { wizardSelectorsIsLoading } from "../../store/wizard/wizard.selectors";
import Background from "../commons/background/background.component";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";
import Header from "../header/header.component";
import SpidButton from "../spidButton/spidButton.component";

export default function SpidRegistration() {
  const isLoading = useSelector(wizardSelectorsIsLoading.getWizardIsLoading);

  return (
    <>
      {isLoading && <FullPageLoader />}

      <Background />

      <Header />

      <div className="container" id="it-spid-registration-form-group">
        <div className="spid-registration-container">
          <h5 className="spid-registration-title">
            <Translate id="lbl.spid.registrationWithSpid" />
          </h5>

          <div className="card-spid-registration-wrapper">
            <div className="spid-registration-wrapper-title">
              <Translate id="text.spid.chooseIdP" />
            </div>

            <div className="mx-0 spid-registration-text">
              <Translate id="text.spid.serviceDescription" />
            </div>

            <div className="spid-registration-info spid-registration-info-plus">
              <a
                href="https://www.spid.gov.it/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>
                  <Translate id="text.spid.moreInfo" />
                </u>
              </a>
            </div>

            <div className="spid-registration-info">
              <a
                href="https://www.spid.gov.it/cos-e-spid/come-attivare-spid/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>
                  <Translate id="text.spid.dontHaveSpid" />
                </u>
              </a>
            </div>

            <div className="spid-registration-button">
              <SpidButton />
            </div>

            <div className="spid-registration-logo">
              <img src={logoSpidAgId} alt="logoSpidAgId" />
            </div>
          </div>
        </div>
      </div>

      <div className="spid-registration-footer">
        <span>
          <img src={logo18} alt="18logo" />
          <Translate id="text.gameIsProhibitedToMinors" />
        </span>
      </div>
    </>
  );
}
