import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { ReactComponent as CheckOff } from "../../../images/svg/icon/check_off.svg";
import { ReactComponent as CheckOn } from "../../../images/svg/icon/check_on.svg";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../../store/wizard/wizard.action";
import { checkingForm } from "../../../utility/checkingForm/checkingForm.container";
import { bonusTitle } from "../welcomeBonus.helpers";
import { useSelectedBonusDetection } from "../welcomeBonus.hooks";

export default function WelcomeBonusSelectedCard({
  bonusItem,
  onClickChangeButton,
}) {
  const dispatch = useDispatch();

  const { bonusCode, promoCode } = useSelector(formDataSelectors.getFormData);

  const detectSelectedBonus = useSelectedBonusDetection();

  const isAtLeastTabletBreakpoint = useMediaQuery("TabletMedium");

  const selected = detectSelectedBonus(bonusItem);

  const selectBonus = () => {
    if (bonusItem.bonusCode === bonusCode.toUpperCase()) {
      return;
    }

    dispatch(
      utagLink({
        reg_bonus: bonusItem.bonusCode,
        reg_event: "bonus-cambio",
      }),
    );

    if (bonusItem.bonusCode.toUpperCase() !== promoCode.toUpperCase()) {
      dispatch(
        addFormData({
          promoCode: "",
          customizesBonus: false,
          bonusCode: bonusItem.bonusCode?.toUpperCase() ?? "",
        }),
      );

      dispatch(disabledBtn(checkingForm("welcomeBonus")));
    }
  };

  const handleClickChangeButton = () => {
    dispatch(disabledBtn(true));
    onClickChangeButton();
  };

  const handleClickCard = () => {
    if (!isAtLeastTabletBreakpoint) {
      selectBonus(bonusItem);
    }
  };

  const handleClickCheck = () => {
    if (isAtLeastTabletBreakpoint) {
      selectBonus(bonusItem);
    }
  };

  const idSuffix = !!bonusItem.bonusClass ? `-${bonusItem.bonusClass}` : "";

  return (
    <div className="row" id="it-ar-welcomeBonus-row" onClick={handleClickCard}>
      <div className="col-12 maskContainer">
        <div
          className={`maskNew ${bonusItem.style.toLowerCase()} ${
            selected ? " active " : ""
          }`}
          id={`it-ar-welcomeBonus${idSuffix}`}
        >
          <div className="newbonus">
            <i
              className="check-mask"
              id="it-ar-welcomeBonus-check-mask"
              onClick={handleClickCheck}
            >
              {selected ? <CheckOn /> : <CheckOff />}
            </i>

            <h5
              id="it-ar-welcomeBonus-bonusTitleLightBox"
              className="text-capitalize"
            >
              {bonusTitle(bonusItem.style)}
            </h5>

            <h3 id="it-ar-welcomeBonus-bonusAmount">{bonusItem.title}</h3>

            <p
              id="it-ar-welcomeBonus-bonusDescription"
              className={
                "cont-description mt-md-0b " +
                (!!bonusItem.bonusMoreInfoLink ? "" : " desk-without-link")
              }
            >
              {bonusItem.description}
            </p>

            <button
              className="element-button-secondary-activ"
              onClick={handleClickChangeButton}
            >
              <Translate id="btn.bonus.changeCode" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
