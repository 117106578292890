/**
 * @param {string} flusso
 * @param {boolean} fromPdv
 */
export function getRegFlusso(flusso, fromPdv) {
  if (fromPdv) return "pdv";

  switch (flusso) {
    case "SPID":
      return "spid";
    case "OCR":
      return "ocr";
    case "PDV":
      return "pdv rivenditori";
    case "OCRPDV":
      return "ocr rivenditori";
    default:
      return "standard";
  }
}
