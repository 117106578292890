import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCountriesAPI } from "../../../store/countries/countries.action";
import { countriesSelectors } from "../../../store/countries/countries.selectors";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { SignCheckSelectors } from "../../../store/signCheck/signCheck.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { disabledBtn, setComponent } from "../../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlow,
} from "../../../store/wizard/wizard.selectors";
import { checkingForm } from "../../../utility/checkingForm/checkingForm.container";
import filter from "../../../utility/filter";
import FullPageLoader from "../fullPageLoader/fullPageLoader.component";
import MessageError from "../messageError/messageError.component";
import { useFlow } from "../../../hooks/useFlow";

export default function Nation({
  paramNation,
  nameForm,
  existentState,
  onOpenList,
  onCloseList,
}) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const countries = useSelector(countriesSelectors.getCountries);
  const signStatus = useSelector(SignCheckSelectors.getSignStatus);
  const flow = useSelector(wizardSelectorsFlow.getWizardFlow);
  const component = useSelector(wizardSelectorsComponent.getComponent);

  const { goToNextStep } = useFlow();

  const [nation, setNation] = useState(formData[paramNation]);
  const [listaCountries, setListaCountries] = useState([]);
  const [myRefNation] = useState(React.createRef(true));
  const [statusForm, setStatusForm] = useState(true);
  const [flag, setFlag] = useState({ nation: false });
  const [abilityCheck, setAbilityCheck] = useState(false);
  const [listArrowPosition, setListArrowPosition] = useState(-1);
  const [isListCountriesVisible, setIsListCountriesVisible] = useState(false);
  const [inputValue, setInputValue] = useState(
    formData[paramNation].description,
  );
  const [selectedNationFlagLoading, setSelectedNationFlagLoading] =
    useState(false);

  const history = useHistory();

  const actionUtagLink = () => {
    if (component.name === "summary") {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: paramNation,
        }),
      );
    }
  };

  const openDropdown = () => {
    setIsListCountriesVisible(true);
  };

  const closeDropdown = () => {
    setIsListCountriesVisible(false);
  };

  const selectFromList = (selectObj, actionUtag) => {
    if (actionUtag) {
      actionUtagLink();
    }

    if (formData[paramNation] !== selectObj) {
      setSelectedNationFlagLoading(true);
    }

    // If estero reset status
    if (selectObj.fiscalCode !== "Z000")
      dispatch(addFormStatus({ cityEsteroBirth: "", birthTown: "" }));

    dispatch(addFormData({ [paramNation]: selectObj }));
    setInputValue(selectObj.description);
    setNation(selectObj);
    myRefNation.current.value = selectObj.description;
    setStatusForm(checkingForm(nameForm));
    setFlag({ ...flag, nation: false });
    setAbilityCheck(true);
  };

  const validateNation = async (value) => {
    setAbilityCheck(true);
    setFlag({ ...flag, nation: true });
    const index = countries.countriesFalse
      .map((e) => {
        return e.description;
      })
      .indexOf(value.toUpperCase());
    if (index >= 0) {
      selectFromList(countries.countriesFalse[index], true);
      setListaCountries([countries.countriesFalse[index]]);
    } else {
      setInputValue(formData[paramNation].description);
      setStatusForm(checkingForm(nameForm));
    }

    if (
      component.name !== "residence" &&
      component.name !== "domicile" &&
      component.name !== "birthplace"
    )
      dispatch(disabledBtn(checkingForm(nameForm)));
    setListArrowPosition(-1);
  };

  const setItaly = () => {
    if (Object.entries(nation).length === 0 && nation.constructor === Object) {
      const index = countries.countriesFalse
        .map((e) => {
          return e.fiscalCode;
        })
        .indexOf("Z000");
      if (index > -1) selectFromList(countries.countriesFalse[index]);
      setListaCountries([countries.countriesFalse[index]]);
    }
  };

  const onChange = (value) => {
    const filterData = filter(paramNation, value, "regex");

    if (filterData.boolean) {
      setFlag({ ...flag, nation: false });
      setAbilityCheck(true);
      setInputValue(value);
      setListaCountries(
        countries.countriesFalse.filter((item) => {
          return item.description.startsWith(value.toUpperCase()) === true;
        }),
      );
    }
  };

  const onFocus = () => {
    if (inputValue === formData[paramNation].description) {
      setInputValue("");
      setListaCountries(countries.countriesFalse);
    }
    dispatch(addFormStatus({ [paramNation + "IsChanging"]: true }));
    openDropdown();
  };

  const onBlur = (value) => {
    closeDropdown();
    validateNation(value);
    dispatch(addFormStatus({ [paramNation + "IsChanging"]: false }));
  };

  const onKeyPressed = async (event) => {
    switch (event.key) {
      case "Enter":
        if (listArrowPosition !== -1) {
          selectFromList(listaCountries[listArrowPosition], false);
          setListaCountries([]);
          setListArrowPosition(-1);
        } else {
          if (component.name !== "summary") {
            if (!checkingForm(nameForm)) {
              if (component.name === "residence") {
                if (formData.sameResidenceAndDomicile) {
                  goToNextStep();
                } else {
                  if (component.dependencies) {
                    const val = flow.find(
                      (v) => v.path === component.dependencies,
                    );
                    if (val) {
                      dispatch(setComponent(val));
                    }
                    history.push(component.dependencies);
                  }
                }
              } else {
                goToNextStep();
              }
            }
          }
        }
        break;
      case "ArrowDown":
        event.preventDefault();
        if (listaCountries.length > 0) {
          if (listArrowPosition === -1) {
            setListArrowPosition(0);
          } else {
            if (listArrowPosition < listaCountries.length - 1) {
              setListArrowPosition(listArrowPosition + 1);
            } else {
              break;
            }
          }
        }
        selectFromList(listaCountries[listArrowPosition + 1], false);
        document
          .getElementById("scrollList")
          .scrollTo({ top: (listArrowPosition + 1) * 30, behavior: "smooth" });
        break;
      case "ArrowUp":
        event.preventDefault();
        if (event.target.value !== "") {
          if (listaCountries.length > 0) {
            if (listArrowPosition === -1 || listArrowPosition === 0) {
              break;
            } else {
              setListArrowPosition(listArrowPosition - 1);
            }
          }
          selectFromList(listaCountries[listArrowPosition - 1], false);
          document.getElementById("scrollList").scrollTo({
            top: (listArrowPosition - 1) * 30,
            behavior: "smooth",
          });
          break;
        } else {
          break;
        }
      default:
        setListArrowPosition(-1);
        return event.key;
    }
  };

  useEffect(() => {
    if (countries.fetchedfalse === false) {
      dispatch(getCountriesAPI(existentState, formData.channel, history));
    }
    setItaly();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStatusForm(checkingForm(nameForm));
    if (
      component.name !== "residence" &&
      component.name !== "domicile" &&
      component.name !== "birthplace"
    )
      dispatch(disabledBtn(checkingForm(statusForm)));
  }, [statusForm, signStatus[nameForm]]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="nation form-group md-5 select-arrows">
      {selectedNationFlagLoading && <FullPageLoader />}
      {inputValue === formData[paramNation].description && (
        <span
          className={
            "flag-nation-input " +
            (Object.entries(formData[paramNation]).length === 0 ? "d-none" : "")
          }
        >
          <img
            id="it-ar-nation-flagNation"
            src={require(
              `../../../images/svg/flagNation/${
                Object.entries(formData[paramNation]).length === 0
                  ? "- "
                  : formData[paramNation].sign
              }.svg`,
            )}
            alt=""
            width="24px"
            height="17px"
            onLoad={() => setSelectedNationFlagLoading(false)}
          />
        </span>
      )}
      <input
        autoComplete="off"
        role="presentation"
        className={
          "form-control " +
          (formStatus[paramNation].length > 0 && flag.nation
            ? formStatus[paramNation] === "err.required" &&
              component.name !== "summary"
              ? " is-present"
              : " is-invalid"
            : "") +
          (Object.entries(formData[paramNation]).length === 0 ||
          inputValue !== formData[paramNation].description
            ? " notExistFlag"
            : " existFlag")
        }
        type="text"
        required
        id={paramNation}
        name={"FieldParames"}
        ref={myRefNation}
        maxLength="50"
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={(e) => onBlur(e.target.value)}
        onKeyDown={(e) => onKeyPressed(e)}
        value={inputValue || ""}
      />
      {isListCountriesVisible && (
        <ul id="scrollList" className="list-group opened-nation-list">
          {listaCountries.map((option, index) => (
            <li
              className={
                index === listArrowPosition
                  ? "list-group-item col-12 no-border selected"
                  : "list-group-item col-12 no-border"
              }
              key={option.description}
              onClick={(e) => {
                selectFromList(option, false);
              }}
              onMouseDown={(e) => {
                selectFromList(option, false);
              }}
              id={option.description}
            >
              <span className="flag-nation-select">
                <img
                  id="it-ar-nation-flag-nation-select"
                  src={require(
                    `../../../images/svg/flagNation/${option.sign}.svg`,
                  )}
                  alt=""
                  width="100%"
                />
              </span>{" "}
              {option.description}
            </li>
          ))}
        </ul>
      )}
      <label className="form-control-label" id="it-ar-nation-country">
        <Translate id="lbl.country" />
      </label>
      {abilityCheck ? (
        <MessageError
          id="it-ar-nation-msgParamNation"
          currentValue={formData[paramNation]}
          currentName={paramNation}
        />
      ) : (
        ""
      )}
    </div>
  );
}
