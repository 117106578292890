import { Translate } from "react-localize-redux";

export default function ConfirmDataInstructionsBlock() {
  return (
    <div className="confirm-unmatching-data-instructions">
      <h3 className="confirm-your-data-title">
        <Translate id="text.confirmYourIdentityData" />
      </h3>

      <ul className="confirm-your-data-instruction-list">
        <li className="confirm-your-data-instruction-element">
          <Translate id="text.confirmYourIdentityDataInstruction1" />
        </li>
        <li className="confirm-your-data-instruction-element">
          <Translate id="text.confirmYourIdentityDataInstruction2" />
        </li>
      </ul>
    </div>
  );
}
