import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { isIOS } from "../../../utility/mobileDetection";
import ProhibitedToMinorsBanner from "../../prohibitedToMinorsBanner/prohibitedToMinorsBanner.component";

export default function RegistrationOCRSelectionBottomBanner() {
  const { channel } = useSelector(formDataSelectors.getFormData);
  const isAppIOS = isIOS() && channel !== "62";

  return (
    <div
      className={`bottom-banner-container ${
        isAppIOS ? "bottom-banner-container--app-ios" : ""
      }`}
    >
      <ProhibitedToMinorsBanner />
    </div>
  );
}
