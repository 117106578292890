import React from "react";
import { Translate } from "react-localize-redux";
import PopupModal from "../../../popupModal/popupModal";
import verifiedLogo from "../../../../images/svg/popupModal/verifiedLogo.svg";

const TYPCModalFinalStep = ({ onClose }) => {
  return (
    <div id="typ-ccm-final-step">
      <PopupModal.Body>
        <div className="logo-container">
          <img src={verifiedLogo} alt="" />
        </div>
        <p className="modal-text">
          <Translate id="text.emailTYP.success" />
        </p>
      </PopupModal.Body>
      <PopupModal.Footer>
        <button onClick={onClose}>
          <Translate id="lbl.buttonDiscard" />
        </button>
      </PopupModal.Footer>
    </div>
  );
};

export default TYPCModalFinalStep;
