export const OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_RECHARGE_LIMIT_STEP =
  "OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_RECHARGE_LIMIT_STEP";
export const OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_SUMMARY =
  "OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_SUMMARY";
export const CLOSE_RECHARGE_LIMIT_APPROVAL_POPUP =
  "CLOSE_RECHARGE_LIMIT_APPROVAL_POPUP";
export const FETCH_RECHARGE_LIMIT_START = "FETCH_RECHARGE_LIMIT_START";
export const FETCH_RECHARGE_LIMIT_SUCCESS = "FETCH_RECHARGE_LIMIT_SUCCESS";
export const FETCH_RECHARGE_LIMIT_ERROR = "FETCH_RECHARGE_LIMIT_ERROR";

export const openRechargeLimitApprovalPopupInRechargeLimitStep = () => ({
  type: OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_RECHARGE_LIMIT_STEP,
});

export const openRechargeLimitApprovalPopupInSummary = () => ({
  type: OPEN_RECHARGE_LIMIT_APPROVAL_POPUP_IN_SUMMARY,
});

export const closeRechargeLimitApprovalPopup = () => ({
  type: CLOSE_RECHARGE_LIMIT_APPROVAL_POPUP,
});

export const fetchRechargeLimitStart = () => ({
  type: FETCH_RECHARGE_LIMIT_START,
});

export const fetchRechargeLimitSuccess = ({ approvalAmount, maxAmount }) => ({
  type: FETCH_RECHARGE_LIMIT_SUCCESS,
  payload: { approvalAmount, maxAmount },
});

export const fetchRechargeLimitError = (error) => ({
  type: FETCH_RECHARGE_LIMIT_ERROR,
});
