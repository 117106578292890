export const ENABLE_PHONE_ERROR_VIEW = "ENABLE_PHONE_ERROR_VIEW";
export const DISABLE_PHONE_ERROR_VIEW = "DISABLE_PHONE_ERROR_VIEW";

export function enablePhoneErrorView() {
  return { type: ENABLE_PHONE_ERROR_VIEW };
}

export function disablePhoneErrorView() {
  return { type: DISABLE_PHONE_ERROR_VIEW };
}
