import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { ReactComponent as CheckOff } from "../../../images/svg/icon/check_off.svg";
import { ReactComponent as CheckOn } from "../../../images/svg/icon/check_on.svg";
import { getBonusDetails } from "../../../services/bonusController";
import { setBonusDetail } from "../../../store/bonusDetail/bonusDetail.action";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../../store/wizard/wizard.action";
import { checkingForm } from "../../../utility/checkingForm/checkingForm.container";
import { bonusTitle } from "../welcomeBonus.helpers";
import { useSelectedBonusDetection } from "../welcomeBonus.hooks";

export default function WelcomeBonusCard({
  bonusItem,
  onClickInfoLink,
  onClickChangeButton,
}) {
  const dispatch = useDispatch();

  const { bonusCode, promoCode } = useSelector(formDataSelectors.getFormData);

  const detectSelectedBonus = useSelectedBonusDetection();

  const isAtLeastTabletBreakpoint = useMediaQuery("TabletMedium");

  const selected = detectSelectedBonus(bonusItem);

  const id = `radio_bonus_${bonusItem.style?.toLowerCase() ?? ""}`;
  const className = `mask ${bonusItem.style?.toLowerCase() ?? ""} ${
    selected ? " active " : ""
  }`;

  const selectBonus = () => {
    if (bonusItem.bonusCode === bonusCode.toUpperCase()) {
      return;
    }

    dispatch(
      utagLink({
        reg_bonus: bonusItem.bonusCode,
        reg_event: "bonus-cambio",
      }),
    );

    if (bonusItem.bonusCode.toUpperCase() !== promoCode.toUpperCase()) {
      dispatch(
        addFormData({
          promoCode: "",
          customizesBonus: false,
          bonusCode: bonusItem.bonusCode?.toUpperCase() ?? "",
        }),
      );

      dispatch(disabledBtn(checkingForm("welcomeBonus")));
    }
  };

  const handleMoreLinkClick = async (item) => {
    dispatch(utagLink({ reg_bonus: "bonus-link", reg_event: item.bonusCode }));

    dispatch(
      utagLink({
        event_type: "vedi_dettaglio_bonus_benvenuto",
        cta_label: "vedidettaglio",
        conversion_pagename: "scelta-bonus",
      }),
    );

    try {
      const bonusDetail = await getBonusDetails(item.slug);
      dispatch(setBonusDetail(bonusDetail));

      onClickInfoLink();
    } catch (err) {
      console.error(err);
    }
  };

  const handleClickChangeButton = () => {
    dispatch(disabledBtn(true));
    onClickChangeButton();
  };

  const handleClickCard = () => {
    if (!isAtLeastTabletBreakpoint) {
      selectBonus(bonusItem);
    }
  };

  const handleClickCheck = () => {
    if (isAtLeastTabletBreakpoint) {
      selectBonus(bonusItem);
    }
  };

  return (
    <div className="maskContainer" onClick={handleClickCard}>
      <div id={id} className={className}>
        <div>
          <i
            className="check-mask"
            id="it-ar-welcomeBonus-check-mask"
            onClick={handleClickCheck}
          >
            {selected ? <CheckOn /> : <CheckOff />}
          </i>

          <h5 id="it-ar-welcomeBonus-bonusTitleLightBox">
            {bonusTitle(bonusItem.style)}
          </h5>

          <h3 id="it-ar-welcomeBonus-bonusAmount">{bonusItem.title}</h3>

          <p
            id="it-ar-welcomeBonus-bonusDescription"
            className={
              "cont-description mt-md-0b " +
              (!!bonusItem.urlReadMore ? "" : " desk-without-link")
            }
          >
            {bonusItem.description}
          </p>

          {!!bonusItem.urlReadMore && (
            <button
              id="it-ar-welcomeBonus-bonusMoreInfoLink"
              onClick={() => handleMoreLinkClick(bonusItem)}
              className="d-md-inline"
            >
              {!!bonusItem.detailLabel ? (
                bonusItem.detailLabel
              ) : (
                <Translate id="text.linkBonus" />
              )}
            </button>
          )}

          {(bonusCode === bonusItem.bonusCode.toUpperCase() ||
            bonusItem.bonusCode.toUpperCase() === promoCode) && (
            <button
              className="element-button-secondary-activ"
              onClick={handleClickChangeButton}
            >
              <Translate id="btn.bonus.changeCode" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
