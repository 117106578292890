import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { Translate } from "react-localize-redux";
import { validateUsernameSelectors } from "../../../store/validateUsername/validateUsername.selectors";

export default function SuggestList({ checkUsername }) {
  const dispatch = useDispatch();

  const { errorListUsername } = useSelector(
    validateUsernameSelectors.getValidateUsername,
  );

  const selectSeggestion = (suggestion) => {
    dispatch(addFormData({ username: suggestion }));
    checkUsername(suggestion);
  };

  return (
    <div className="mt-1">
      <div className="emailPrediction">
        <div className="predictions">
          <label
            className="fake-tooltip-title"
            id="it-ar-userdata-alreadypresent"
          >
            <Translate id="err.username.alreadypresent" />
          </label>
          {errorListUsername?.map((username) => (
            <button
              type="button"
              className="email-btn not-selected"
              key={username}
              onClick={() => selectSeggestion(username)}
              onMouseDown={() => selectSeggestion(username)}
            >
              {username}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
