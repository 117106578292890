import { Translate } from "react-localize-redux";

export default function SummaryTextBlock() {
  return (
    <div>
      <h1 className="sub-title-form">
        <Translate id="text.titleSummary" />
      </h1>

      <p className="label-form">
        <Translate id="text.summary" />
      </p>
    </div>
  );
}
