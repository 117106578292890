import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getFollowUpData } from "../../services/followUpController";
import { followUpParamsToFormData } from "../../utility/followUp/followUp";
import { getFlow } from "../../utility/wizardConfig/wizardConfig";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";
import { getComponentToShow } from "./followUp.helpers";

const FollowUp = ({
  addFormData,
  markVisitedFollowUpLink,
  setComponent,
  defaultClassicFlowName,
}) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    markVisitedFollowUpLink();
  }, [markVisitedFollowUpLink]);

  useEffect(() => {
    const execute = async () => {
      try {
        const data = await getFollowUpData(id);
        const formData = followUpParamsToFormData(data);

        addFormData(formData);

        const wizard = await getFlow(
          data.flowName === "SPID" ? defaultClassicFlowName : data.flowName,
        );

        const componentToShow = getComponentToShow(
          wizard,
          data.flowName !== "SPID" ? data.componentName : null,
        );
        const component = componentToShow == null ? wizard[0] : componentToShow;
        const url = component.path;

        setComponent(component);
        history.push(url);
      } catch (error) {
        console.error(error);
        getFlow(null, null, history);
      }
    };

    execute();
  }, [addFormData, defaultClassicFlowName, history, id, setComponent]);

  return <FullPageLoader />;
};

export default FollowUp;
