export function moveCursorAtTheEnd(element) {
  if (!element.value.length) return;

  if (element.type === "email") {
    setTimeout(() => {
      element.type = "text";
      execMoveCursorAtTheEnd(element);
      element.type = "email";
    }, 0);
  } else {
    setTimeout(() => {
      execMoveCursorAtTheEnd(element);
    }, 0);
  }
}

function execMoveCursorAtTheEnd(element) {
  element.setSelectionRange(element.value.length, element.value.length);
}
