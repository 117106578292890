import { Translate } from "react-localize-redux";
import { ReactComponent as Next } from "../../images/svg/icon/next.svg";
import { ReactComponent as ArrowLeft } from "../../images/svg/wizardBtn/arrowLeft.svg";

export default function SignButtonUI({
  className,
  onClickBack,
  onClickSubmit,
  disabled,
  signUpTranslateId = "lbl.signIn",
}) {
  return (
    <div
      id="it-ar-signbutton-container"
      className={`sign-up-button-container ${className}`}
    >
      <div id="btn_indietro_container" className="p-0">
        <button id="btn_indietro" className="btn-back" onClick={onClickBack}>
          <i className="icon-btn-left">
            <ArrowLeft />
          </i>
        </button>
      </div>

      <button
        type="submit"
        disabled={disabled}
        className="btn sign-up-button"
        id="signUpBtn"
        onClick={onClickSubmit}
      >
        <span className="sign-up-button__text">
          <Translate id={signUpTranslateId} />
        </span>

        <span className="sign-up-button__icon-next">
          <Next />
        </span>
      </button>
    </div>
  );
}
