import { connect } from "react-redux";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import * as formStatusAction from "../../store/formStatus/formStatus.action";
import * as formDataAction from "../../store/formData/formData.action";
import {
  stepBack,
  disabledBtn,
  setComponent,
  setBonus,
  setIsVisiblePhonePopup,
  setVerifyPhone,
  setIsPhonePopupOtpSended,
  setIsOptNotValid,
  setOtpBlockStatus,
} from "../../store/wizard/wizard.action";
import WizardBtn from "./wizardBtn.component";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagView, utagLink } from "../../store/utagStore/utagStore.action";
import {
  wizardSelectorsBonus,
  wizardSelectorsBtn,
  wizardSelectorsFlow,
  wizardSelectorsFlusso,
  wizardSelectorsIsVisiblePhonePopup,
  wizardSelectorsOtpBlockStatus,
  wizardSelectorsVerifyPhone,
} from "../../store/wizard/wizard.selectors";

const mapStateToProps = (state) => ({
  btnDisabled: wizardSelectorsBtn.getWizardBtn(state),
  formData: formDataSelectors.getFormData(state),
  formStatus: formStatusSelectors.getFormStatus(state),
  spidStatus: spidStatusSelectors.getSpidStatus(state),
  bonus: wizardSelectorsBonus.getBonus(state),
  flow: wizardSelectorsFlow.getWizardFlow(state),
  flusso: wizardSelectorsFlusso.getFlusso(state),
  isVisiblePhonePopup:
    wizardSelectorsIsVisiblePhonePopup.getWizardIsVisiblePhonePopup(state),
  isVerifiedPhone: wizardSelectorsVerifyPhone.getWizardIsVerifyPhone(state),
  otpBlockStatus: wizardSelectorsOtpBlockStatus.getWizardOtpBlockStatus(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    stepBack: (step) => dispatch(stepBack(step)),
    disabledBtn: (status) => dispatch(disabledBtn(status)),
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    utagView: (state) => dispatch(utagView(state)),
    utagLink: (data) => dispatch(utagLink(data)),
    setComponent: (component) => dispatch(setComponent(component)),
    setBonus: (bonus) => dispatch(setBonus(bonus)),
    setIsVisiblePhonePopup: (isVisiblePhonePopup) =>
      dispatch(setIsVisiblePhonePopup(isVisiblePhonePopup)),
    setIsPhonePopupOtpSended: (isPhonePopupOtpSended) =>
      dispatch(setIsPhonePopupOtpSended(isPhonePopupOtpSended)),
    setIsOtpNotValid: (isOtpNotValid) =>
      dispatch(setIsOptNotValid(isOtpNotValid)),
    setOtpBlockStatus: (otpBlockStatus) =>
      dispatch(setOtpBlockStatus(otpBlockStatus)),
    setVerifyPhone: (isVerifiedPhone) =>
      dispatch(setVerifyPhone(isVerifiedPhone)),
  };
};

const WizardBtnContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WizardBtn);
export default WizardBtnContainer;
