import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../../store/formData/formData.action";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { utagView } from "../../../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../../../store/wizard/wizard.action";
import { wizardSelectorsFlusso } from "../../../../store/wizard/wizard.selectors";
import { getRegFlusso } from "../../../../utility/analytics";
import { checkingForm } from "../../../../utility/checkingForm/checkingForm.container";
import PopupModal from "../../../popupModal/popupModal";
import PromoCodePopupBody from "./partials/promoCodePopupBody/promoCodePopupBody.component";
import PromoCodePopupFooter from "./partials/promoCodePopupFooter/promoCodePopupFooter.component";

const validBonusDescription =
  "Il codice promozioni inserito è corretto, completa la registrazione per riscattare il tuo bonus";

export default function PromoCodePopup({
  bonusDesc,
  bonusValidityErr,
  checkBonusByCodeAPI,
  clearBonusInput,
  onClose,
  confirmPromoCode,
  inputRef,
  inputValueIsChangedFromLastCheck,
  promoCode,
  setInputValueIsChangedFromLastCheck,
  validationFunction,
}) {
  const [statusForm, setStatusForm] = useState(true);

  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const checkBonusCodeFunction = async () => {
    const popupSuccessCallback = (
      newPromo,
      fdPromoCode,
      fdPromoCodeUrl,
      setBonusDescription,
    ) => {
      if (fdPromoCodeUrl !== "") {
        dispatch(addFormData({ promoCodeUrl: "" }));
      }
      if (newPromo === fdPromoCode) {
        setBonusDescription(validBonusDescription);
      }
    };

    await checkBonusByCodeAPI(popupSuccessCallback);
    setStatusForm(checkingForm("welcomeBonus"));
  };

  useEffect(() => {
    if (formData.promoCodeUrl !== "" && formData.promoCodeUrl !== undefined) {
      checkBonusCodeFunction();
    }
  }, [formData.findBonus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStatusForm(checkingForm("welcomeBonus"));
    dispatch(disabledBtn(statusForm));

    if (statusForm) {
      const step =
        formData.promoCode && formData.promoCodeUrl === ""
          ? "promo-code-typ"
          : "promo-code";

      dispatch(
        utagView({
          reg_step: step,
          reg_flusso: getRegFlusso(flusso, formData.fromPdv),
          promo_code: formData.promoCode,
        }),
      );
    }
  }, [statusForm]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PopupModal>
      <PopupModal.Header closeMe={onClose}>
        <Translate id="lbl.promocode.title" />
      </PopupModal.Header>

      <PopupModal.Body>
        <PromoCodePopupBody
          bonusDesc={bonusDesc}
          bonusValidityErr={bonusValidityErr}
          checkBonusCodeFunction={checkBonusCodeFunction}
          clearBonusInput={clearBonusInput}
          inputRef={inputRef}
          inputValueIsChangedFromLastCheck={inputValueIsChangedFromLastCheck}
          promoCode={promoCode}
          setInputValueIsChangedFromLastCheck={
            setInputValueIsChangedFromLastCheck
          }
          setStatusForm={setStatusForm}
          validationFunction={validationFunction}
        />
      </PopupModal.Body>

      <PopupModal.Footer className="popup-promo-footer">
        <PromoCodePopupFooter
          bonusDesc={bonusDesc}
          bonusValidityErr={bonusValidityErr}
          checkBonusCodeFunction={checkBonusCodeFunction}
          confirmPromoCode={confirmPromoCode}
          inputValueIsChangedFromLastCheck={inputValueIsChangedFromLastCheck}
          onClose={onClose}
          promoCode={promoCode}
        />
      </PopupModal.Footer>
    </PopupModal>
  );
}
