import { FETCH_QUESTION_LIST_START } from "./securityList.action";
import { RECEIVE_QUESTIONLIST } from "./securityList.action";
import { FETCH_QUESTION_LIST_ERROR } from "./securityList.action";

const initialState = {
  questionList: [],
  fetching: false,
  fetched: false,
  error: null,
};
export const questionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUESTION_LIST_START: {
      return { ...state, fetching: true };
    }
    case FETCH_QUESTION_LIST_ERROR: {
      return { ...state, fetching: false, error: action.payload };
    }
    case RECEIVE_QUESTIONLIST: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        questionList: action.payload,
      };
    }
    default:
      return state;
  }
};
