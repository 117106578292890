import { Translate } from "react-localize-redux";
import bonusImg from "../../../images/png/bonus/bonusDetails/bonus-promo.png";

export default function ProgressBarBonusStep() {
  return (
    <>
      <img src={bonusImg} alt="bonus-img" />
      <span>
        <Translate id="lbl.bonusBar" />
      </span>
    </>
  );
}
