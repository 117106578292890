import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRegFlusso } from "../../../hooks/useAnalytics";
import ticket from "../../../images/png/ticket.png";
import { checkInfoGC } from "../../../services/loyaltyLottery";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import MessageError from "../../elementForm/messageError/messageError.component";
import PopupModal from "../../popupModal/popupModal";
import { useLoyaltyPopupFormError } from "../loyaltyPopup.hooks";

export default function ValidateReceiptPopup({ closeModal, onSubmit }) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const reg_flusso = useRegFlusso();

  const { addAndTrackLoyaltyFormError, removeLoyaltyFormError } =
    useLoyaltyPopupFormError();

  const [playCodeValue, setPlayCodeValue] = useState("");
  const [validTicketDays, setValidTicketDays] = useState("");

  useEffect(() => {
    const ticketDays = async () => {
      try {
        const data = await checkInfoGC(
          formData.channel,
          formData.ipAddress,
          "days_ticket_validity",
        );
        setValidTicketDays(data.infoGen);
      } catch (error) {
        setValidTicketDays("");
      }
    };

    ticketDays();
  }, [formData.channel, formData.ipAddress]);

  useEffect(() => {
    if (
      validTicketDays === "" &&
      formStatus.playCode === "err.gameCodePlayed.daysPassed"
    ) {
      addAndTrackLoyaltyFormError("err.gameCodePlayed.expiredDays");
    }
  }, [formStatus.playCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (event) => {
    const value = event.target.value;

    if (value.length > 25) {
      return;
    }

    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    setPlayCodeValue(sanitizedValue);

    dispatch(
      addFormData({
        loyalty: {
          ...formData.loyalty,
          lotteryReceipt: {
            playCode: sanitizedValue,
            flagPdv: "0",
          },
        },
      }),
    );

    if (value.length === 25) {
      removeLoyaltyFormError();
    }
  };

  const onBlur = (value) => {
    if (value.length < 1) {
      addAndTrackLoyaltyFormError("err.required");
    } else if (value.length >= 1 && value.length < 25) {
      addAndTrackLoyaltyFormError("err.gameCodePlayed.wrongCode");
    }
  };

  const onFocus = (value) => {
    if (value.length < 1) {
      removeLoyaltyFormError();
    }
  };

  const handleClose = () => {
    dispatch(
      utagLink({
        reg_event: "codice-giocata-cta-click",
        reg_cta: "chiudi",
        conversion_pagename: "registrazione:inserisci codice giocata:loyalty",
        reg_flusso,
      }),
    );

    closeModal();
  };

  const handleClickInsertButton = () => {
    dispatch(
      utagLink({
        reg_event: "codice-giocata-cta-click",
        reg_cta: "inserisci",
        conversion_pagename: "registrazione:inserisci codice giocata:loyalty",
        reg_flusso,
      }),
    );

    onSubmit(playCodeValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !disabled) {
      onSubmit();
    }
  };

  const disabled = !!formStatus.playCode || playCodeValue.length !== 25;

  return (
    <div id="confirm-code-modal">
      <PopupModal>
        <PopupModal.Header closeMe={handleClose}>
          <Translate id="text.insert.gameCode" />
        </PopupModal.Header>

        <PopupModal.Body>
          {validTicketDays && (
            <h6>
              <Translate
                id="text.havePlayedRecently"
                data={{ days: validTicketDays }}
              />
            </h6>
          )}
          <p>
            <Translate id="text.useGameCodeReceived" />
          </p>

          <div className="popupmodal-img">
            <img alt="ticket" src={ticket} />
          </div>

          <div
            id="it-ar-loyalty-form-group1"
            className={"form-group form-group-fc mb-0"}
          >
            <input
              maxLength={25}
              autoComplete="on"
              className={
                "form-control " +
                (formStatus.playCode !== "" ? "is-invalid" : "")
              }
              type="text"
              required
              name="Codice giocata SuperEnalotto"
              id="it-ar-fiscalCode-fiscalCode"
              value={playCodeValue}
              onChange={onChange}
              onBlur={() => onBlur(playCodeValue)}
              onFocus={() => onFocus(playCodeValue)}
              onKeyDown={handleKeyDown}
            />
            <label
              className="form-control-label"
              id="it-ar-fiscalCode-lblfiscalcode"
              htmlFor="it-ar-fiscalCode-fiscalCode"
            >
              <Translate id="lbl.gameCodeSuperEnalotto" />
            </label>

            {formStatus.playCode !== "" ? (
              <MessageError
                currentValue={playCodeValue}
                currentName="playCode"
                translationData={
                  formStatus.playCode === "err.gameCodePlayed.daysPassed"
                    ? { days: validTicketDays }
                    : ""
                }
              />
            ) : (
              <p className="info-codice-loyalty">
                <Translate id="text.loyaltyInput.length" />
              </p>
            )}
          </div>
        </PopupModal.Body>

        <PopupModal.Footer>
          <button id="close-loyalty-btn" onClick={handleClose}>
            <Translate id="lbl.buttonDiscard" />
          </button>

          <button
            disabled={disabled}
            id="insert-loyalty-btn"
            onClick={handleClickInsertButton}
          >
            <Translate id="lbl.insert" />
          </button>
        </PopupModal.Footer>
      </PopupModal>
    </div>
  );
}
