import moment from "moment";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRegBonus, useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import { generateFiscalCode } from "../../services/generateFiscalCode";
import { validateFiscalCode } from "../../services/validatorController";
import { allCitiesSelectors } from "../../store/allCities/allCities.selectors";
import { countriesSelectors } from "../../store/countries/countries.selectors";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { utagView } from "../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import { wizardSelectorsComponent } from "../../store/wizard/wizard.selectors";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import { getRecaptchaToken, isRecaptchaError } from "../../utility/recaptcha";
import { formatBirthDate, getBirthCityObj } from "./fiscalCode.helpers";

const errorPageRedirectErrorList = [
  "err.fiscalcode.alreadypresent",
  "err.bill.activation.already.submitted",
  "err.fiscalcode.activationalreadystarted",
];

export function useFiscalCode() {
  const dispatch = useDispatch();

  const history = useHistory();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const { countriesFalse } = useSelector(countriesSelectors.getCountries);
  const allCities = useSelector(allCitiesSelectors.getAllCities);

  const getErrorInputClassName = (canShowError = true) => {
    if (!canShowError) {
      return "";
    }

    if (!!formStatus.fiscalCode && canShowError) {
      if (
        formStatus.fiscalCode === "err.required" &&
        component.name !== "summary"
      ) {
        return "is-present";
      } else if (
        !errorPageRedirectErrorList.includes(formStatus.fiscalCode) ||
        component.name === "summary"
      ) {
        return "is-invalid";
      }
    }

    return "";
  };

  const generateFiscalCodeApi = async () => {
    try {
      const data = await generateFiscalCode(
        moment(formData.birthDate).format("DD/MM/YYYY"),
        formData.birthCityFiscalCode,
        formData.birthState.fiscalCode,
        formData.gender,
        formData.name,
        formData.surname,
        formData.channel,
      );

      return data.fiscalCode;
    } catch (err) {
      if (err.response?.status === 422) {
        dispatch(addFormStatus({ fiscalCode: "err.fiscalCode.genericError" }));
      } else {
        history.push("/errore-registrazione");
      }
    }

    return null;
  };

  const validateFiscalCodeAPI = async (fiscalCode) => {
    const recaptchaToken = await getRecaptchaToken("VERIFY_FISCALCODE");

    try {
      const data = await validateFiscalCode(
        fiscalCode,
        formData.channel,
        formData.ipAddress,
        formData.pdvCode.accountCode,
        recaptchaToken,
      );

      const birthState = countriesFalse.find(
        (country) => country.fiscalCode === data.userTips.countryFiscalCode,
      );

      dispatch(
        addFormData({
          ...getBirthCityObj(data.userTips, allCities),
          birthDate:
            component.name !== "summary" || !formData.birthDate
              ? formatBirthDate(data.userTips.birthDate)
              : formData.birthDate,
          birthState,
          gender: data.userTips.gender,
        }),
      );
      dispatch(addFormStatus({ birthDate: "" }));

      return { valid: true, errorMessage: null, error: null };
    } catch (err) {
      if (err.response?.status !== 422) {
        console.error(err);
        return { valid: false, errorMessage: null, error: err };
      }

      const nameError = Object.keys(err.response.data.error.errorInfos)[0];
      const errorMessage = err.response.data.error.errorInfos[nameError][0];

      if (
        errorMessage === "err.bill.activation.already.submitted" &&
        formData.fromPdv
      ) {
        return { valid: true, errorMessage, error: err };
      }

      addFormStatus({ fiscalCode: errorMessage });
      return { valid: false, errorMessage, error: err };
    }
  };

  const handleContinueButtonActivation = useCallback(() => {
    const errorStatus = !!formStatus.fiscalCode
      ? !errorPageRedirectErrorList.includes(formStatus.fiscalCode)
      : checkingForm("fiscalCode");
    dispatch(disabledBtn(errorStatus));
  }, [dispatch, formStatus.fiscalCode]);

  return {
    getErrorInputClassName,
    generateFiscalCodeApi,
    validateFiscalCodeAPI,
    handleContinueButtonActivation,
  };
}

export function useFiscalCodeNextStep() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { validateFiscalCodeAPI } = useFiscalCode();

  const formData = useSelector(formDataSelectors.getFormData);
  const reg_bonus = useRegBonus();

  const { goToNextStep } = useFlow();

  const reg_flusso = useRegFlusso();

  const handleFiscalCodeNextStep = async () => {
    const { valid, errorMessage, error } = await validateFiscalCodeAPI(
      formData.fiscalCode,
    );

    if (valid) {
      goToNextStep();
    } else if (
      errorMessage === "err.fiscalcode.alreadypresent" ||
      errorMessage === "err.fiscalcode.activationalreadystarted" ||
      (errorMessage === "err.bill.activation.already.submitted" &&
        !formData.fromPdv)
    ) {
      dispatch(
        utagView({
          reg_step: "popup-conto-gia-aperto",
          error_message: errorMessage,
          ...(reg_bonus ? { reg_bonus } : {}),
          reg_flusso,
          reg_doc: formData.documentTypeObj?.description ?? "",
        }),
      );

      dispatch(addFormStatus({ fiscalCode: errorMessage }));
      history.push("/errore?type=fiscal-code");
    } else if (
      errorMessage === "err.fiscalcode.recentlyclosedaccount" ||
      errorMessage === "err.fiscalcode.multipleclosedaccounts"
    ) {
      dispatch(addFormStatus({ fiscalCode: errorMessage }));
      history.push("/errore?type=fiscal-code");
    } else if (isRecaptchaError(error)) {
      console.error("Recaptcha on fiscal code validation");
      dispatch(addFormStatus({ fiscalCode: errorMessage }));
    } else {
      dispatch(addFormStatus({ fiscalCode: errorMessage }));
      history.push("/errore-registrazione");
    }
  };

  return { handleFiscalCodeNextStep };
}
