import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";

export default function ResidenceHeaderForm() {
  return (
    <div className="header-form" id="it-ar-residence-header-form">
      <h5 className="sub-title-form" id="it-ar-residence-sub-title">
        <Title />
      </h5>
      <h1
        className="label-form margin-bot-standard"
        id="it-ar-residence-dontWorryForSerenade"
      >
        <Translate id="text.dontWorryForSerenade_C1" />
      </h1>
    </div>
  );
}

function Title() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  switch (flusso) {
    case "C1":
      return <Translate id="text.yourResidenceAddress_C1" />;
    case "SPID":
      return <Translate id="text.yourResidenceAddress_SPID" />;
    case "PDV":
      return <Translate id="lbl.residenceDomicile" />;
    default:
      return null;
  }
}
