import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { utagView } from "../../store/utagStore/utagStore.action";
import Header from "../header/header.component";
import ManualRegistrationCard from "./manualRegistrationCard/manualRegistrationCard.component";
import OcrPrivacyDisclaimer from "./ocrPrivacyDisclaimer/ocrPrivacyDisclaimer.component";
import RegistrationOCRSelectionBottomBanner from "./registrationOCRSelectionBottomBanner/registrationOCRSelectionBottomBanner.component";
import ScanDocumentCard from "./scanDocumentCard/scanDocumentCard.component";

export default function RegistrationOCRSelection() {
  const dispatch = useDispatch();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(
        utagView({
          reg_step: "step_0",
          conversion_pagename: "step_0",
        }),
      );
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [dispatch]);

  return (
    <>
      <Header />

      <main className="ocr-selection-container">
        <div className="ocr-selection-subcontainer">
          <h3 className="title">
            <Translate id="text.registrationOCR.selectHowToInsertData" />
          </h3>

          <ScanDocumentCard />

          <ManualRegistrationCard />
        </div>

        <OcrPrivacyDisclaimer />

        <RegistrationOCRSelectionBottomBanner />
      </main>
    </>
  );
}
