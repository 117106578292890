import { useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as Id } from "../../../images/svg/summary/id.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../summary.hooks";
import SummaryInfoBlock from "../summaryInfoBlock/summaryInfoBlock.component";
import { documentLinkTranslateId } from "./summaryDocument.config";
import SummaryDocumentPopup from "./summaryDocumentPopup/summaryDocumentPopup.component";

export default function SummaryDocument() {
  const [showPopup, setShowPopup] = useState(false);

  const { documentTypeObj, number } = useSelector(
    formDataSelectors.getFormData,
  );

  const { trackOpenPopup } = useSummaryAnalyticsTrackLinks();

  const { getCurrentDocumentFormData } = useCurrentData();

  const documentFormDataBeforeEditRef = useRef(getCurrentDocumentFormData());

  const description = `${
    (showPopup
      ? documentFormDataBeforeEditRef.current.documentTypeObj?.description
      : documentTypeObj?.description) ?? "-"
  } ${showPopup ? documentFormDataBeforeEditRef.current.number : number}`;

  const handleOpenPopup = () => {
    trackOpenPopup(documentLinkTranslateId);
    documentFormDataBeforeEditRef.current = getCurrentDocumentFormData();
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <SummaryInfoBlock
        icon={<Id />}
        title={<Translate id={documentLinkTranslateId} />}
        description={description}
        onClick={handleOpenPopup}
      />

      <SummaryDocumentPopup show={showPopup} onClose={handleClosePopup} />
    </>
  );
}
