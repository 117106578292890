import { useStore } from "react-redux";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { useFiscalCode } from "../fiscalCode/fiscalCode.hooks";

export const FISCAL_CODE_GEN_DEBOUNCE_TIME = 500;

export function useFiscalCodeGeneration() {
  const { getState } = useStore();

  const { generateFiscalCodeApi } = useFiscalCode();

  const generateFiscalCode = async () => {
    const formStatus = formStatusSelectors.getFormStatus(getState());
    const formData = formDataSelectors.getFormData(getState());

    const formHasErrors = !!formStatus.birthDate || !!formStatus.birthTown;

    const formHasNecessaryData =
      !!formData.name &&
      !!formData.surname &&
      !!formData.birthDate &&
      !!formData.gender &&
      !!formData.birthTown &&
      !!formData.birthCityFiscalCode &&
      !!formData.birthState?.fiscalCode;

    if (formHasErrors || !formHasNecessaryData) {
      return;
    }

    const fiscalCode = await generateFiscalCodeApi();

    return fiscalCode;
  };

  return generateFiscalCode;
}
