export default function SummaryInfoBlock({
  icon,
  title,
  description,
  onClick,
}) {
  return (
    <div className="summary-info-block">
      <div className="summary-info-block-icon">{icon}</div>

      <div className="summary-element-title" onClick={onClick}>
        {title}
      </div>

      <div className="summary-element-data">{description}</div>
    </div>
  );
}
