import { forwardRef, useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRegBonus, useRegFlusso } from "../../hooks/useAnalytics";
import useQueryParams from "../../hooks/useQueryParams";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { addSpidStatus } from "../../store/spidStatus/spidStatus.action";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import formIsValid from "../../utility/formIsValid/formIsValid.container";
import FiscalCodeInputFieldBare from "../inputFields/fiscalCodeInputFieldBare/fiscalCodeInputFieldBare.component";
import { useFiscalCode } from "./fiscalCode.hooks";
import CalculateFiscalCodeButton from "./partials/calculateFiscalCodeButton/calculateFiscalCodeButton.component";
import CalculateFiscalCodePopup, {
  confirmFiscalCodeQueryParamName,
} from "./partials/calculateFiscalCodePopup/calculateFiscalCodePopup.component";
import FiscalCodeHeaderForm from "./partials/fiscalCodeHeaderForm.component";

const FiscalCode = forwardRef(function FiscalCode(
  { onOpenCalcFiscalCodePopup, onCloseCalcFiscalCodePopup },
  ref,
) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);

  const {
    getErrorInputClassName,
    generateFiscalCodeApi,
    handleContinueButtonActivation,
  } = useFiscalCode();

  const [canShowError, setCanShowError] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [previousValue, setPreviousValue] = useState(formData.fiscalCode);
  const [showGenerateFiscalCodeModal, setShowGenerateFiscalCodeModal] =
    useState(false);

  const { searchParams } = useQueryParams();

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();

  const showConfirmFiscalCodeText =
    searchParams.get(confirmFiscalCodeQueryParamName) === "true";

  const isValidCheck = (value) => {
    const error = formIsValid("fiscalCode", value);
    dispatch(addFormStatus(error));
    dispatch(disabledBtn(!!error.fiscalCode));

    if (flusso === "SPID") {
      if (error === "err.fiscalcode.length") {
        dispatch(
          utagLink({
            reg_event: "form-error",
            error_message: "il codice fiscale deve contenere 16 caratteri",
          }),
        );
      } else if (
        error === "err.fiscalcode.badcontrolchar" ||
        error === "err.fiscalcode.bad"
      ) {
        dispatch(
          utagLink({
            reg_event: "form-error",
            error_message: "il codice fiscale inserito non è corretto",
          }),
        );
      }
    }
  };

  const isValid = (value) => {
    const valueUpperCase = value.toUpperCase();

    const isInvalidFormat =
      (!!valueUpperCase && !/[a-zA-Z0-9]/.test(valueUpperCase)) ||
      valueUpperCase.length > 16;

    if (isInvalidFormat) return;

    if (valueUpperCase.length < 16) {
      setCanShowError(false);
      dispatch(addFormData({ fiscalCode: valueUpperCase }));
      isValidCheck(valueUpperCase);
      handleContinueButtonActivation();
      return;
    }

    dispatch(addFormStatus({ fiscalCode: "" }));
    dispatch(addFormData({ fiscalCode: valueUpperCase }));
    isValidCheck(valueUpperCase);
    setCanShowError(true);
  };

  const handleBlur = () => {
    setCanShowError(true);

    if (formData.fiscalCode !== "" && formStatus.fiscalCode === "") {
      if (
        formData.fiscalCodePdv === formData.fiscalCode &&
        formData.fiscalCodePdv.length >= 15
      ) {
        dispatch(
          addFormData({
            birthTown: formData.birthTownPdv,
            birthProvince: formData.birthProvincePdv,
            birthCityFiscalCode: formData.birthCityFiscalCodePdv,
            birthState: formData.birthStatePdv,
            birthDate: formData.birthDatePdv,
            email: formData.emailPdv,
            fiscalCode: formData.fiscalCodePdv,
            gender: formData.genderPdv,
            name: formData.namePdv,
            phoneNumber: formData.phoneNumberPdv,
            surname: formData.surnamePdv,
            username: formData.usernamePdv,
          }),
        );
      }

      handleContinueButtonActivation();
    }

    if (previousValue !== formData.fiscalCode && component.name === "summary") {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "fiscalCode",
        }),
      );
    }

    setPreviousValue(formData.fiscalCode);
  };

  const generateFiscalCode = async () => {
    const fiscalCode = await generateFiscalCodeApi();

    if (!!fiscalCode) {
      isValid(fiscalCode);
    } else {
      handleContinueButtonActivation();
    }
  };

  const errorInputClassName = getErrorInputClassName(
    canShowError && !showGenerateFiscalCodeModal,
  );

  const openCalcFiscalCodePopup = () => {
    onOpenCalcFiscalCodePopup?.();
    setShowGenerateFiscalCodeModal(true);
  };

  useEffect(() => {
    const effectFn = async () => {
      if (
        component.name === "summary" &&
        formData.birthDate !== "" &&
        formData.birthCityFiscalCode !== "" &&
        formData.birthState.fiscalCode &&
        formData.gender !== "" &&
        formData.name !== "" &&
        formData.surname !== "" &&
        isMounted
      ) {
        await generateFiscalCode();
      } else {
        setIsMounted(true);
      }
    };

    effectFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.name,
    formData.surname,
    formData.birthDate,
    formData.gender,
    formData.birthCityFiscalCode,
  ]);

  useEffect(() => {
    if (
      component.name === "fiscalCode" &&
      !spidStatus.fromSpidFirstLandingFiscalCode
    ) {
      const reg_step =
        component.name === "fiscalCode" ? "calcolo-cf-3" : "codice-fiscale";

      dispatch(
        utagView({
          reg_step,
          conversion_pagename: reg_step,
          ...(reg_bonus ? { reg_bonus } : {}),
          reg_flusso,
        }),
      );
    } else if (spidStatus.fromSpidFirstLandingFiscalCode) {
      dispatch(
        utagView({
          reg_section: "registrazione",
          reg_step: "codice-fiscale",
          reg_flusso,
        }),
      );
    }
    return () => {
      if (spidStatus.fromSpidFirstLandingFiscalCode) {
        dispatch(addSpidStatus({ fromSpidFirstLandingFiscalCode: false }));
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if fiscalCode was already compiled, because of the back button, or for
  // any other compilation flow, check if it's valid on component mount
  useEffect(() => {
    if (formData.fiscalCode) {
      isValid(formData.fiscalCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="container step-container fiscalCode"
      id="it-ar-fiscalCode-container"
    >
      <div className={component.name === "fiscalCode" ? "row" : ""}>
        <div
          className={
            component.name === "fiscalCode"
              ? "col-12 col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : ""
          }
        >
          {component.name === "fiscalCode" && <FiscalCodeHeaderForm />}

          <div className="cf-with-calc-block">
            <FiscalCodeInputFieldBare
              ref={ref}
              errorInputClassName={errorInputClassName}
              onBlur={handleBlur}
              onChange={(e) => isValid(e.target.value)}
              bottomComponent={
                showConfirmFiscalCodeText && (
                  <p className="generated-cf">
                    <Translate id="lbl.fiscalcodeMsgAB" />
                  </p>
                )
              }
            />

            {component.name !== "summary" && (
              <CalculateFiscalCodeButton onClick={openCalcFiscalCodePopup} />
            )}
          </div>
        </div>
      </div>

      <CalculateFiscalCodePopup
        show={showGenerateFiscalCodeModal}
        onConfirm={async () => {
          await generateFiscalCode();
          setShowGenerateFiscalCodeModal(false);
          onCloseCalcFiscalCodePopup?.();
        }}
        onClose={() => setShowGenerateFiscalCodeModal(false)}
      />
    </div>
  );
});

export default FiscalCode;
