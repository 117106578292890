import { FETCH_COUNTRIES_START } from "./countries.action";
import { RECEIVE_COUNTRIES_TRUE } from "./countries.action";
import { FETCH_COUNTRIES_ERROR } from "./countries.action";
import { RECEIVE_COUNTRIES_FALSE } from "./countries.action";

const initialState = {
  countriesTrue: [],
  countriesFalse: [],
  fetching: false,
  fetchedTrue: false,
  fetchedFalse: false,
  error: null,
};

export const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_START: {
      return { ...state, fetching: true };
    }
    case FETCH_COUNTRIES_ERROR: {
      return { ...state, fetching: false, error: action.payload };
    }
    case RECEIVE_COUNTRIES_TRUE: {
      return {
        ...state,
        fetching: false,
        fetchedTrue: true,
        countriesTrue: action.payload,
      };
    }
    case RECEIVE_COUNTRIES_FALSE: {
      return {
        ...state,
        fetching: false,
        fetchedFalse: true,
        countriesFalse: action.payload,
      };
    }
    default:
      return state;
  }
};
