import React from "react";
import { Translate } from "react-localize-redux";

const SubNote = ({ text }) => (
  <p className="sub-note">
    <Translate id={text} />
  </p>
);

export default SubNote;
