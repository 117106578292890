import { useEffect } from "react";
import { useSelector } from "react-redux";
import { followUpSelectors } from "../store/followUp/followUp.selectors";
import { formDataSelectors } from "../store/formData/formData.selectors";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
  wizardSelectorsSignUpStatus,
} from "../store/wizard/wizard.selectors";
import { sendResumeRegistrationDataEmail } from "../utility/followUp/followUp";
import { isMobile } from "../utility/mobileDetection";

/**
 * Sends a follow up email to the user if the follow up strategy is enabled and the user aborts the sign up process.
 */
export function useFollowUpStrategy() {
  const formData = useSelector(formDataSelectors.getFormData);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const signUpCompleted = useSelector(
    wizardSelectorsSignUpStatus.getSignUpCompleted,
  );
  const visitedFollowUpLink = useSelector(
    followUpSelectors.getVisitedFollowUpLink,
  );
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const followUpStrategyEnabled = component.useFollowUpStrategy;
  const componentName = component.componentName;

  useEffect(() => {
    if (!followUpStrategyEnabled || isMobile()) {
      return;
    }

    const eventListener = () => {
      sendResumeRegistrationDataEmail(
        signUpCompleted,
        formData,
        visitedFollowUpLink,
        componentName,
        flusso,
      );
    };

    window.addEventListener("beforeunload", eventListener);
    return () => window.removeEventListener("beforeunload", eventListener);
  }, [
    componentName,
    flusso,
    followUpStrategyEnabled,
    formData,
    signUpCompleted,
    visitedFollowUpLink,
  ]);
}
