import { usePdvFlowDetection } from "../../hooks/useFlow";
import WelcomeBonusSignUp from "./welcomeBonusSignUp/welcomeBonusSignUp.component";
import WelcomeBonusWizard from "./welcomeBonusWizard.component";

export default function WelcomeBonus() {
  const isPdvFlow = usePdvFlowDetection();

  return (
    <>
      <WelcomeBonusWizard />

      {isPdvFlow && <WelcomeBonusSignUp />}
    </>
  );
}
