import { SET_BONUS_DETAIL } from "./bonusDetail.action";

const initialState = {
  bonusDetail: null,
};

export function bonusDetailReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BONUS_DETAIL:
      return { bonusDetail: action.payload };
    default:
      return state;
  }
}
