import { connect } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { utagView } from "../../../../store/utagStore/utagStore.action";
import {
  setIsOptNotValid,
  setOtpBlockStatus,
  setIsPhonePopupOtpSended,
} from "../../../../store/wizard/wizard.action";
import TYPCModalEmailStep from "./typcModalEmailStep.component";

const mapStateToProps = (state) => {
  return {
    formData: formDataSelectors.getFormData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsOtpSended: (isPhoneOtpSended) =>
      dispatch(setIsPhonePopupOtpSended(isPhoneOtpSended)),
    setIsOtpNotValid: (isOtpNotValid) =>
      dispatch(setIsOptNotValid(isOtpNotValid)),
    setOtpBlockStatus: (otpBlockStatus) =>
      dispatch(setOtpBlockStatus(otpBlockStatus)),
    utagView: (data) => dispatch(utagView(data)),
  };
};

const TYPCModalEmailStepContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TYPCModalEmailStep);
export default TYPCModalEmailStepContainer;
