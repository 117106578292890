import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useHistory } from "react-router-dom";
import { useRegBonus, useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import filter from "../../utility/filter";
import MessageAlert from "../elementForm/messageAlert/messageAlert.container";

const SecurityQuestion = (props) => {
  const { formData, questionList, component } = props;

  const { goToNextStep } = useFlow();

  const [statusForm, setStatusForm] = useState(true);
  let [listQuestion, setListQuestion] = useState();
  let [active, setActive] = useState(false);
  let [flag, setFlag] = useState(false);
  let [flagError, setFlagError] = useState(false);
  let [previousValue, setPreviousValue] = useState({ answer: formData.answer });

  const history = useHistory();

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();

  const selectQuestion = (index) => {
    let value = listQuestion[index].textQuestion;

    if (formData.securityQuestion !== value && component.name === "summary") {
      props.utagLink({
        reg_event: "riepilogo_modifica",
        reg_campo: "securityQuestion",
      });
    }

    props.addFormData({ securityQuestion: value });
  };

  const toogleListOpen = () => {
    setActive(!active);
  };

  const isValid = (name, value) => {
    setFlag(false);
    let filterData = filter(name, value, "regex");
    if (filterData.newValue.length > 50) {
      filterData.newValue = filterData.newValue.substring(0, 50);
    }

    props.addFormData({ [name]: filterData.newValue.substring(0, 50) });

    setStatusForm(checkingForm("securityQuestion"));

    if (filterData.boolean) {
      setFlagError(false);
      props.disabledBtn(false);
    } else {
      if (value !== "") {
        setFlagError(true);
        props.disabledBtn(true);
      } else {
        setFlagError(false);
        props.disabledBtn(true);
      }
    }
  };

  const onKeyPressed = (key) => {
    if (key === "Enter" && component.name !== "summary") {
      setStatusForm(checkingForm("securityQuestion"));
      if (!statusForm) {
        goToNextStep();
      }
    }
  };

  const handleBlur = (value, name) => {
    setFlag(true);

    if (
      previousValue[name] !== formData[name] &&
      component.name === "summary"
    ) {
      props.utagLink({
        reg_event: "riepilogo_modifica",
        reg_campo: name,
      });
    }
    setPreviousValue({ ...previousValue, [name]: formData[name] });
  };

  useEffect(() => {
    if (active) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [active]);

  useEffect(() => {
    setStatusForm(checkingForm("securityQuestion"));
    props.disabledBtn(statusForm);
  }, [statusForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (questionList.fetched === false) {
      props.getQuestionListAPI(formData.channel, history);
    } else {
      setListQuestion(questionList.questionList);
      if (formData.securityQuestion === "") {
        if (!questionList.questionList[0]) return;
        props.addFormData({
          securityQuestion: questionList.questionList[0].textQuestion,
        });
      }
    }
  }, [questionList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.hideUtag) return;
    if (component.name !== "summary") {
      props.utagView({
        reg_step: "domanda-sicurezza",
        ...(reg_bonus ? { reg_bonus } : {}),
        reg_flusso,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        "container step-container securityQuestion " +
        (component.name === "summary" ? "mt-3" : null)
      }
      id="it-ar-securityquestion-container"
    >
      <div className={"row"}>
        <div
          className={
            "col-12" +
            (component.name !== "summary"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : "")
          }
        >
          <div
            id="it-ar-securityquestion-d-none"
            className={"overlay d-sm-none " + (active ? "" : "d-none")}
            onClick={() => setActive(false)}
            onTouchMove={() => setActive(false)}
            onTouchStart={() => setActive(false)}
          ></div>
          <form>
            <div
              className="header-form"
              id="it-ar-securityquestion-header-form"
            >
              <h5 className="title-form" id="it-ar-securityquestion-title-form">
                <Translate id="lbl.accessData" />
              </h5>
              <h2
                className="sub-title-form"
                id="it-ar-securityquestion-sub-title-form"
              >
                <Translate id="lbl.securityQuestion" />
              </h2>
              <p
                className="label-form margin-bot-standard"
                id="it-ar-securityquestion-label-form"
              >
                <Translate id="text.subTitleSecurityQuestion" />
              </p>
            </div>
          </form>
          <div
            className={
              "form-group  " +
              (component.name === "summary" ? "accordion-form question" : "")
            }
          >
            <label className="form-control-label isSpecial">
              <Translate id="lbl.securityQuestion" />
            </label>
            <div
              id="it-ar-securityquestion-form-group1"
              className={
                component.name === "summary"
                  ? " form-control"
                  : "select-customer select-document "
              }
              onMouseDown={() => toogleListOpen()}
              tabIndex={0}
              onBlur={() => setActive(false)}
            >
              <label>{formData.securityQuestion}</label>
            </div>
            <ul
              className={"list-group opened-list " + (active ? "" : "d-none")}
            >
              {questionList.questionList.map((value, index) => (
                <li
                  className="list-group-item col-12"
                  onMouseDown={() => selectQuestion(index)}
                  onClick={() => selectQuestion(index)}
                  key={index}
                >
                  {value.textQuestion}
                </li>
              ))}
            </ul>
          </div>

          <div
            id="it-ar-securityquestion-form-group2"
            className={
              "form-group  mt-1 " +
              (component.name === "summary" ? "accordion-form " : "")
            }
          >
            <input
              autoComplete="off"
              className={
                "form-control" +
                (flag && formData.answer === "" ? " is-present" : "") +
                (flagError ? " is-invalid" : "")
              }
              required
              type="text"
              maxLength="50"
              spellCheck="false"
              autoCorrect="off"
              name="answer"
              id="it-ar-securityquestion-answer"
              value={formData.answer}
              onKeyDown={(e) => onKeyPressed(e.key, e.target.value)}
              onChange={(e) => isValid(e.target.name, e.target.value)}
              onBlur={(e) => handleBlur(e.target.value, e.target.name)}
            />
            <label
              className="form-control-label"
              id="it-ar-securityquestion-lblSecurityAnswer"
            >
              <Translate id="lbl.securityAnswer" />
            </label>
            {flagError ? (
              <p className="errorAnswer">
                <Translate id="err.securityQuestion" />
              </p>
            ) : (
              ""
            )}
            {flag && formData.answer === "" ? <MessageAlert /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityQuestion;
