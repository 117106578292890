import React from "react";
import close from "../../../images/svg/icon/close-upfile-white.svg";

const MoreInfoModal = (props) => {
  const closeModal = () => {
    props.closeModal();
  };

  return (
    <div className="more-info-modal">
      <div className="pep-modal">
        <div className="conferma-header">
          <h4>Maggiori informazioni</h4>
          <button onClick={() => closeModal()}>
            <img src={close} alt="close" />
          </button>
        </div>
        <div className="block">
          <p>
            <b>Art. 1 comma 2 lettera dd) del D.lgs. 231/2007 e s.m.i.</b>
          </p>
          <p className="mb-3">
            <i>dd) persone politicamente esposte:</i> le persone fisiche che
            occupano o hanno cessato di occupare da meno di un anno importanti
            cariche pubbliche, nonché i loro familiari e coloro che con i
            predetti soggetti intrattengono notoriamente stretti legami, come di
            seguito elencate:
          </p>
          <ol className="p-0">
            <li>
              Sono persone fisiche che occupano o hanno occupato{" "}
              <b>importanti cariche pubbliche</b> coloro che ricoprono o hanno
              ricoperto la carica di:
              <ol>
                <li>
                  Presidente della Repubblica, Presidente del Consiglio,
                  Ministro, Vice-Ministro e Sottosegretario, Presidente di
                  Regione, assessore regionale, Sindaco di capoluogo di
                  provincia o citta' metropolitana, Sindaco di comune con
                  popolazione non inferiore a 15.000 abitanti nonche' cariche
                  analoghe in Stati esteri;{" "}
                </li>
                <li>
                  deputato, senatore, parlamentare europeo, consigliere
                  regionale nonche' cariche analoghe in Stati esteri;{" "}
                </li>
                <li>
                  membro degli organi direttivi centrali di partiti politici;{" "}
                </li>
                <li>
                  giudice della Corte Costituzionale, magistrato della Corte di
                  Cassazione o della Corte dei conti, consigliere di Stato e
                  altri componenti del Consiglio di Giustizia Amministrativa per
                  la Regione siciliana nonche' cariche analoghe in Stati esteri;{" "}
                </li>
                <li>
                  membro degli organi direttivi delle banche centrali e delle
                  autorita' indipendenti;{" "}
                </li>
                <li>
                  ambasciatore, incaricato d'affari ovvero cariche equivalenti
                  in Stati esteri, ufficiale di grado apicale delle forze armate
                  ovvero cariche analoghe in Stati esteri;{" "}
                </li>
                <li>
                  componente degli organi di amministrazione, direzione o
                  controllo delle imprese controllate, anche indirettamente,
                  dallo Stato italiano o da uno Stato estero ovvero partecipate,
                  in misura prevalente o totalitaria, dalle Regioni, da comuni
                  capoluoghi di provincia e citta' metropolitane e da comuni con
                  popolazione complessivamente non inferiore a 15.000 abitanti;{" "}
                </li>
                <li>
                  direttore generale di ASL e di azienda ospedaliera, di azienda
                  ospedaliera universitaria e degli altri enti del servizio
                  sanitario nazionale.{" "}
                </li>
                <li>
                  direttore, vicedirettore e membro dell'organo di gestione o
                  soggetto svolgenti funzioni equivalenti in organizzazioni
                  internazionali;
                </li>
              </ol>
            </li>
            <li>
              Sono <b>familiari di persone politicamente esposte:</b>
              <br />i genitori, il coniuge o la persona legata in unione civile
              o convivenza di fatto o istituti assimilabili alla persona
              politicamente esposta, i figli e i loro coniugi nonche' le persone
              legate ai figli in unione civile o convivenza di fatto o istituti
              assimilabili;
            </li>
            <li>
              Sono <b>soggetti con</b> i quali le persone politicamente esposte
              intrattengono notoriamente <b>stretti legami:</b>
              <ol>
                <li>
                  le persone fisiche legate alla persona politicamente esposta
                  per via della titolarita' effettiva congiunta di enti
                  giuridici o di altro stretto rapporto di affari;
                </li>
                <li>
                  le persone fisiche che detengono solo formalmente il controllo
                  totalitario di un'entita' notoriamente costituita, di fatto,
                  nell'interesse e a beneficio di una persona politicamente
                  esposta;
                </li>
              </ol>
            </li>
          </ol>
          <button onClick={() => closeModal()}>Ok</button>
        </div>
      </div>
    </div>
  );
};
export default MoreInfoModal;
