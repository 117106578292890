const RegexCheck = (name, value) => {
  switch (name) {
    case "name":
      return !/[^a-zA-Z-' àáèéìíòóùú,.]/.test(value);
    case "surname":
      return !/[^a-zA-Z-' àáèéìíòóùú.]/.test(value);
    case "address":
      return /^[a-zA-Zàáèéìíòóùú0-9 /']*$/.test(value);
    case "city":
      return /^[a-zA-Z\-. ']*$/.test(value);
    case "cap":
      return /^[0-9]{0,5}$/.test(value);
    case "state":
      return /^[a-zA-Zàáèéìíòóùú\s]*$/.test(value);
    case "domicileState":
      return /^[a-zA-Zàáèéìíòóùú\s]*$/.test(value);
    case "domicileAddress":
      return /^[a-zA-Zàáèéìíòóùú0-9 /']*$/.test(value);
    case "domicileCity":
      return /^[a-zA-Z\-. ']*$/.test(value);
    case "domicileCap":
      return /^[0-9]{0,5}$/.test(value);
    case "capEstero":
      return /^[a-zA-Z0-9]{0,12}$/.test(value);
    case "email":
      return /^[a-zA-Zàáèéìíòóùú@0-9./'_-]*$/.test(value);
    case "addressEstero":
      return /^[a-zA-Zàáèéìíòóùú0-9 ./'-]*$/.test(value);
    case "cityEstero":
      return /^[a-zA-Zàáèéìíòóùú0-9 .'-]*$/.test(value);
    case "capEsteroDomi":
      return /^[a-zA-Z0-9]{0,12}$/.test(value);
    case "addressEsteroDomi":
      return /^[a-zA-Zàáèéìíòóùú0-9 ./'-]*$/.test(value);
    case "cityEsteroDomi":
      return /^[a-zA-Zàáèéìíòóùú0-9 .'-]*$/.test(value);
    case "birthTown":
      return /^[a-zA-Zàáèéìíòóùú0-9 .'-]*$/.test(value);
    case "birthState":
      return /^[a-zA-Zàáèéìíòóùú\s]*$/.test(value);
    case "answer":
      return /^[0-9a-zA-Z ]+$/.test(value);
    case "documentTown":
      return /^[a-zA-Zàáèéìíòóùú .'-]*$/.test(value);
    case "documentTownFree":
      return /^[a-zA-Zàáèéìíòóùú0-9 .'-]*$/.test(value);
    default:
      break;
  }
};

export function isValidEmailFormat(email) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
}

export default RegexCheck;
