import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as Checked } from "../../../../images/svg/icon/checked.svg";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import PopupModal from "../../../popupModal/popupModal";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupDetailFooter from "../../summaryPopupFooter/summaryPopupDetailFooter.component";
import SummaryPopupInfoBlock from "../../summaryPopupInfoBlock/summaryPopupInfoBlock.component";
import TextWithNationFlag from "../../textWithNationFlag/textWithNationFlag.component";
import { contactsLinkTranslateId } from "../summaryContacts.config";

export default function SummaryContactsPopupDetailContent({
  onClose,
  onPressEdit,
}) {
  const { email, phonePrefix } = useSelector(formDataSelectors.getFormData);

  const { trackClosePopup } = useSummaryAnalyticsTrackLinks();

  const handleClose = () => {
    trackClosePopup(contactsLinkTranslateId);
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleClose}>
        <Translate id="lbl.contacts" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body popup-contacts-body">
          <SummaryPopupInfoBlock
            title={<Translate id="lbl.email" />}
            data={email ?? ""}
          />

          <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
            <SummaryPopupInfoBlock
              title={<Translate id="lbl.prefix" />}
              data={
                <TextWithNationFlag
                  sign={phonePrefix?.sign}
                  text={`+${phonePrefix?.callingCodes ?? ""}`}
                />
              }
            />

            <SummaryPopupInfoBlock
              title={<Translate id="lbl.telephone" />}
              data={<PhoneNumberBlock />}
            />
          </div>
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryPopupDetailFooter onClose={onClose} onPressEdit={onPressEdit} />
      </PopupModal.Footer>
    </>
  );
}

function PhoneNumberBlock() {
  const { phoneNumber, phoneNumberCertified } = useSelector(
    formDataSelectors.getFormData,
  );

  return (
    <div className="phone-number-block">
      <span>{phoneNumber}</span>

      {!!phoneNumberCertified && (
        <>
          <span className="phone-number-block__icon">
            <Checked />
          </span>
          <div className="phone-number-block__verified">
            <Translate id="lbl.numVerificato" />
          </div>
        </>
      )}
    </div>
  );
}
