import { useRef } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import SingleInputDate from "../../commons/singleInputDate/singleInputDate.component";

export default function BirthDateInputField({ onChange }) {
  const singleInputDateRef = useRef(null);

  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const handleSingleInputDateKeyDown = (event) => {
    if (event.key === "Enter") {
      singleInputDateRef.current?.blur();
    }
  };

  return (
    <SingleInputDate
      ref={singleInputDateRef}
      currentName="birthDate"
      label="lbl.dateBirth"
      autoComplete="off"
      onKeyDown={handleSingleInputDateKeyDown}
      afterChange={onChange}
      customAlert={
        <>
          <Translate id={formStatus.birthDate} />{" "}
          {formStatus.birthDate === "err.date.lessThen"
            ? `${new Date().getFullYear() - 100}.`
            : null}
        </>
      }
    />
  );
}
