import { debounce } from "lodash";
import { useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFlow } from "../../hooks/useFlow";
import {
  getLotteryPdvPrize,
  validatePdvAccount,
} from "../../services/loyaltyLottery";
import { checkPinActivationPdv } from "../../services/validatorController";
import { allCitiesSelectors } from "../../store/allCities/allCities.selectors";
import { countriesSelectors } from "../../store/countries/countries.selectors";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { incrementPdvKoCheckCrendentialsCounter } from "../../store/vivochaProactiveChat/vivochaProactiveChat.action";
import { isVivochaError } from "../../utility/vivochaProactiveChat/vivochaProactiveChat";

export function useActiveAccountHelper() {
  const dispatch = useDispatch();

  const { getState } = useStore();

  const history = useHistory();

  const { goToNextStep } = useFlow();

  const { channel, ipAddress } = useSelector(formDataSelectors.getFormData);

  const callCheckPinActivationPdvApi = usePinActivationPdvApiCheck(history);

  const getLotteryPdvPrizeAPI = async (lotteryReceipt) => {
    const { loyalty } = formDataSelectors.getFormData(getState());

    try {
      const data = await getLotteryPdvPrize(
        channel,
        ipAddress,
        lotteryReceipt.playCode,
      );

      dispatch(
        addFormData({
          loyalty: {
            ...loyalty,
            lotteryReceipt: lotteryReceipt,
            gameCodeExist: true,
            sisalBonusPoints: data.prizeAmount,
            sgadId: data.sgadId,
          },
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const callValidatePdvAccountApi = async (accountCode) => {
    const PAYMENT_PDV_METHOD = 5;

    const { accountPin, chargingPin, loyalty } =
      formDataSelectors.getFormData(getState());

    try {
      const data = await validatePdvAccount(
        channel,
        ipAddress,
        accountCode,
        accountPin,
        PAYMENT_PDV_METHOD,
        chargingPin,
      );

      if (data.lotteryReceipt.playCode) {
        dispatch(
          addFormData({
            loyalty: {
              ...loyalty,
              lotteryReceipt: data.lotteryReceipt,
              gameCodeExist: true,
            },
          }),
        );
        getLotteryPdvPrizeAPI(data.lotteryReceipt);
      }
    } catch (error) {
      history.push("/errore-registrazione");
    }
  };

  const isFormCorrectlyFilledIn = () => {
    const { usernamePdv, accountPin } =
      formDataSelectors.getFormData(getState());

    return (
      6 <= usernamePdv.length &&
      usernamePdv.length <= 16 &&
      accountPin.length === 4
    );
  };

  const hasFormErrors = () => {
    const formStatus = formStatusSelectors.getFormStatus(getState());

    return (
      !!formStatus.usernamePdv ||
      !!formStatus.accountPin ||
      !!formStatus.chargingPin
    );
  };

  const isFormValid = () => {
    return isFormCorrectlyFilledIn() && !hasFormErrors();
  };

  const validateAndGoToNextStep = async () => {
    const formStatus = formStatusSelectors.getFormStatus(getState());

    if (formStatus.accountPin === "err.pdv.ticket") {
      isRegistrationExpired(history);
    } else {
      const data = await callCheckPinActivationPdvApi();

      if (data?.status === "SUCCESS" && !data.pdvInfo.rechargePinRequired) {
        callValidatePdvAccountApi(data.pdvInfo.accountCode);

        goToNextStep();
      }
    }
  };

  return {
    callValidatePdvAccountApi,
    isFormValid,
    isFormCorrectlyFilledIn,
    validateAndGoToNextStep,
  };
}

const isRegistrationExpired = (history) => {
  history.push("/registrazione-scaduta");
};

export function usePinActivationPdvApiCheck() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getState } = useStore();

  const { channel, ipAddress } = useSelector(formDataSelectors.getFormData);
  const { allCitiesFalse } = useSelector(allCitiesSelectors.getAllCities);
  const { countriesFalse } = useSelector(countriesSelectors.getCountries);

  const callCheckPinActivationPdvApi = async (
    checkRechargePinRequirement = true,
  ) => {
    const { usernamePdv, accountPin, chargingPin } =
      formDataSelectors.getFormData(getState());

    try {
      const data = await checkPinActivationPdv(
        channel,
        usernamePdv,
        accountPin,
        chargingPin,
        ipAddress,
      );

      if (checkRechargePinRequirement && data.pdvInfo.rechargePinRequired) {
        dispatch(
          addFormStatus({ chargingPin: "err.pdv.charging.pinRequired" }),
        );
        return data;
      }

      const city =
        data.pdvInfo.birthCity !== "ESTERO"
          ? allCitiesFalse.find((c) => c.id === data.pdvInfo.cityId)
          : undefined;

      const birthState = countriesFalse.find(
        (c) => c.description === data.pdvInfo.birthCountry,
      );

      if (!!city || data.pdvInfo.birthCity === "ESTERO") {
        dispatch(
          addFormData({
            birthTown: !!city ? city.description : data.pdvInfo.birthCity,
            birthCityFiscalCode: !!city
              ? city.fiscalCode
              : data.pdvInfo.birthCity,
            birthState,
            birthDate: data.pdvInfo.birthDate,
            cityEsteroBirth: !!city ? "" : data.pdvInfo.birthCity,
            email: data.pdvInfo.email,
            fiscalCode: data.pdvInfo.fiscalCode,
            gender: data.pdvInfo.gender,
            name: data.pdvInfo.name,
            phoneNumber: data.pdvInfo.phoneNumber,
            surname: data.pdvInfo.surname,
            username: data.pdvInfo.userName,
            birthProvince: !!city ? city.province : data.pdvInfo.birthCity,
            birthId: !!city ? city.id : "",
            birthTownPdv: !!city ? city.description : data.pdvInfo.birthCity,
            birthProvincePdv: !!city ? city.province : data.pdvInfo.birthCity,
            birthCityFiscalCodePdv: !!city
              ? city.fiscalCode
              : data.pdvInfo.birthCity,
            birthStatePdv: birthState,
            birthDatePdv: data.pdvInfo.birthDate,
            emailPdv: data.pdvInfo.email,
            fiscalCodePdv: data.pdvInfo.fiscalCode,
            genderPdv: data.pdvInfo.gender,
            namePdv: data.pdvInfo.name,
            phoneNumberPdv: data.pdvInfo.phoneNumber,
            surnamePdv: data.pdvInfo.surname,
            usernamePdv: data.pdvInfo.userName,
            fromPdv: true,
            pdvCode: {
              accountCode: data.pdvInfo.accountCode,
              oldPin: accountPin,
            },
            codiceRice: data.pdvInfo.codiceRice,
          }),
        );
      }

      return data;
    } catch (err) {
      if (err.response?.status === 500 && chargingPin.length === 0) {
        dispatch(
          addFormStatus({
            usernamePdv: "err.generic.pdv.mismatch",
            accountPin: "err.generic.pdv.mismatch",
          }),
        );
      } else if (err.response?.status === 422) {
        const errorInfos = err.response.data.error.errorInfos;

        if (!errorInfos) return null;

        if (errorInfos["generic.fields"]) {
          const messageError = errorInfos["generic.fields"][0].toString();

          dispatch(
            addFormStatus({
              usernamePdv: messageError,
              accountPin: messageError,
            }),
          );

          if (isVivochaError(messageError)) {
            dispatch(incrementPdvKoCheckCrendentialsCounter());
          }
        } else if (errorInfos["generic"]) {
          const messageError = errorInfos["generic"][0].toString();

          dispatch(
            addFormStatus({
              usernamePdv: messageError,
              chargingPin: messageError,
              accountPin: messageError,
            }),
          );
        } else if (errorInfos["chargingPin"]) {
          const messageError = errorInfos["chargingPin"][0].toString();

          dispatch(addFormStatus({ chargingPin: messageError }));
        } else if (errorInfos["ticket"]) {
          const messageError = errorInfos["ticket"][0].toString();

          if (messageError === "err.pdv.ticket") {
            isRegistrationExpired(history);
          }

          dispatch(
            addFormStatus({
              usernamePdv: messageError,
              accountPin: messageError,
              chargingPin: messageError,
            }),
          );
        }
      } else {
        console.error(err);
      }

      return null;
    }
  };

  return callCheckPinActivationPdvApi;
}

export function useDebouncedCheckValidityApi() {
  const dispatch = useDispatch();

  const callCheckPinActivationPdvApi = usePinActivationPdvApiCheck();

  const checkValidityViaApi = async () => {
    const responseData = await callCheckPinActivationPdvApi(false);

    if (responseData?.status === "SUCCESS") {
      dispatch(addFormStatus({ usernamePdv: "", accountPin: "" }));

      if (!responseData.pdvInfo.rechargePinRequired) {
        dispatch(addFormStatus({ chargingPin: "" }));
      }
    }
  };

  const debouncedCheckValidityViaApi = useRef(
    debounce(checkValidityViaApi, 500),
  ).current;

  return debouncedCheckValidityViaApi;
}

export function getAnalyticErrorMessage(chargingPinStatus) {
  let errorText = "";
  switch (chargingPinStatus) {
    case "err.pdv.charging.pinRequired":
    case "err.required":
      errorText = "inserisci pin ricarica";
      break;
    case "err.pdv.charging.pin":
      errorText = "controlla pin ricarica";
      break;
    default:
      errorText = "username pin non corrispondenti";
  }
  return errorText;
}
