import { connect } from "react-redux";
import TermsAndConditions from "./termsAndConditions.component";
import * as formStatusAction from "../../store/formStatus/formStatus.action";
import * as formDataAction from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { utagLink } from "../../store/utagStore/utagStore.action";

const mapStateToProps = (state) => {
  return {
    formStatus: formStatusSelectors.getFormStatus(state),
    formData: formDataSelectors.getFormData(state),
    step: state.wizard.step,
    component: wizardSelectorsComponent.getComponent(state),
    flusso: wizardSelectorsFlusso.getFlusso(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    disabledBtn: (status) => dispatch(disabledBtn(status)),
    utagLink: (data) => dispatch(utagLink(data)),
  };
};

const TermsAndConditionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditions);
export default TermsAndConditionsContainer;
