import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import PopupModal from "../../popupModal/popupModal";
import { dateFormatter } from "../summary.helpers";
import { useSummaryAnalyticsTrackLinks } from "../summary.hooks";
import SummaryPopupInfoBlock from "../summaryPopupInfoBlock/summaryPopupInfoBlock.component";
import TextWithNationFlag from "../textWithNationFlag/textWithNationFlag.component";
import { personalDataLinkTranslateId } from "./summaryPersonalData.config";

export default function SummaryPersonalDataPopup({ show, onClose }) {
  const {
    name,
    surname,
    fiscalCode,
    birthDate,
    gender,
    birthTown,
    birthProvince,
    birthState,
  } = useSelector(formDataSelectors.getFormData);

  const { trackClosePopup } = useSummaryAnalyticsTrackLinks();

  const handleCloseIcon = () => {
    trackClosePopup(personalDataLinkTranslateId);
    onClose();
  };

  return (
    <PopupModal show={show}>
      <PopupModal.Header closeMe={handleCloseIcon}>
        <Translate id="lbl.personalData" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body">
          <SummaryPopupInfoBlock
            title={<Translate id="lbl.name" />}
            data={name}
          />

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.surname" />}
            data={surname}
          />

          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <SummaryPopupInfoBlock
              title={<Translate id="lbl.birthDate" />}
              data={birthDate ? dateFormatter.format(new Date(birthDate)) : "-"}
            />

            <SummaryPopupInfoBlock
              title={<Translate id="lbl.sex" />}
              data={
                gender === "M" ? (
                  <Translate id="lbl.male2" />
                ) : (
                  <Translate id="lbl.female2" />
                )
              }
            />
          </div>

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.birthCountry" />}
            data={
              <TextWithNationFlag
                sign={birthState.sign}
                text={birthState.description}
              />
            }
          />

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.birthTown" />}
            data={`${birthTown} (${birthProvince})`}
          />

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.fiscalcode" />}
            data={fiscalCode}
          />
        </div>
      </PopupModal.Body>

      <PopupModal.Footer className="popup-personal-data-footer">
        <button onClick={onClose}>
          <Translate id="lbl.button.ok" />
        </button>
      </PopupModal.Footer>
    </PopupModal>
  );
}
