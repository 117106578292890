import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { wizardSelectorsComponent } from "../store/wizard/wizard.selectors";

export function useBeforeUnloadPopup() {
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const componentName = component.name;

  useLayoutEffect(() => {
    if (canShowPopup(componentName)) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    return disableBeforeUnloadPopup;
  }, [componentName]);
}

export function disableBeforeUnloadPopup() {
  window.removeEventListener("beforeunload", onBeforeUnload);
}

function onBeforeUnload(event) {
  event.returnValue = "Are you sure you want to leave?";
}

function canShowPopup(componentName) {
  const ignorePopupRouteList = [
    "errore-registrazione",
    "verifica-email",
    "questionario",
    "summary",
    "seleziona-registrazione",
    "seleziona-registrazione-ocr",
    "registrazione-spid",
    "spid-result-callback",
    "riprendi-registrazione",
  ];

  return (
    componentName !== "inizio" &&
    !ignorePopupRouteList.some((value) =>
      window.location.pathname.includes(value),
    )
  );
}
