import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import BirthDateInputField from "../../inputFields/birthDateInputField/birthDateInputField.component";
import {
  FISCAL_CODE_GEN_DEBOUNCE_TIME,
  useFiscalCodeGeneration,
} from "../unmatchingPersonalDataPage.hooks";

export default function UnmatchingPersonalDataBirthDate() {
  const dispatch = useDispatch();

  const { birthDate } = useSelector(formDataSelectors.getFormData);

  const generateFiscalCode = useFiscalCodeGeneration();

  const recalcFiscalCode = async (event) => {
    const value = event.target.value;

    if (value.length !== 10) return;

    const fiscalCode = await generateFiscalCode();
    if (fiscalCode) {
      dispatch(addFormData({ fiscalCode }));
    }
  };

  const handleChange = debounce(
    recalcFiscalCode,
    FISCAL_CODE_GEN_DEBOUNCE_TIME,
  );

  return <BirthDateInputField key={birthDate} onChange={handleChange} />;
}
