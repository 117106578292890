import { v4 as uuidv4 } from "uuid";
import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export const getDocumentType = async (channel) => {
  // let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/utils/docsType`;
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/utils/docsTypeFiltered`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};
