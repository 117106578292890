import { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllCitiesAPI } from "../../../store/allCities/allCities.action";
import { allCitiesSelectors } from "../../../store/allCities/allCities.selectors";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { stepNext } from "../../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsStep,
} from "../../../store/wizard/wizard.selectors";
import { checkingForm } from "../../../utility/checkingForm/checkingForm.container";
import filter from "../../../utility/filter";
import formIsValid from "../../../utility/formIsValid/formIsValid.container";
import { moveCursorAtTheEnd } from "../../../utility/input/input";
import MessageError from "../../elementForm/messageError/messageError.component";
import TownLiContent from "./partials/townLiContent.component";

const MAX_INITIAL_LOADED_CITIES = 300;

export default function Town({
  inputId,
  labelId,
  nameForm,
  paramTown,
  paramProvinceCode,
  paramCityFiscalCode,
  paramId,
  showOnlyActiveCities,
  onSelectTown,
  townEmptyFromSpid,
  scrollIntoViewOnOpen,
}) {
  const scrollIntoViewTimeoutIdRef = useRef(null);

  const dispatch = useDispatch();

  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const formData = useSelector(formDataSelectors.getFormData);
  const step = useSelector(wizardSelectorsStep.getWizardStep);
  const allCities = useSelector(allCitiesSelectors.getAllCities);
  const component = useSelector(wizardSelectorsComponent.getComponent);

  const cities = showOnlyActiveCities
    ? allCities.allCitiesTrue
    : allCities.allCitiesFalse;

  const [listaCities, setListaCities] = useState([]);
  const [flag, setFlag] = useState({ town: false });

  const ulCitiesRef = useRef(null);

  const lablelCitiesRef = useRef(null);

  const history = useHistory();

  const scrollIntoView = () => {
    scrollIntoViewTimeoutIdRef.current = setTimeout(() => {
      lablelCitiesRef.current.scrollIntoView({
        inline: "start",
        behavior: "smooth",
      });
    }, 500);
  };

  const setDropdownElements = (dropdownElements) => {
    setListaCities(dropdownElements);
  };

  const actionUtagLink = () => {
    if (component.name === "summary") {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: paramTown,
        }),
      );
    }
  };

  const onChange = (name, value) => {
    if (/[0-9]/.test(value)) {
      return;
    }

    clearTimeout(scrollIntoViewTimeoutIdRef.current);

    setFlag({ town: false });
    let filterDataTown = filter(name, value, "regex");
    const currVal = formStatus[paramTown + "isChanging"];
    dispatch(addFormStatus({ [paramTown + "isChanging"]: !currVal }));

    if (filterDataTown.boolean) {
      dispatch(addFormData({ [name]: filterDataTown.newValue }));
      dispatch(addFormData({ [paramProvinceCode]: "" }));
      dispatch(addFormData({ [paramId]: "" }));

      if (value.length < 2 && paramTown === "documentTown") {
        addFormStatusError(paramTown, "err." + [paramTown] + ".length");
      } else {
        addFormStatusError(paramTown, "err." + [paramTown] + ".bad");
      }
    }

    if (filterDataTown.newValue.length > 0) {
      setDropdownElements(
        getFilteredCities(value).slice(0, MAX_INITIAL_LOADED_CITIES),
      );

      if (scrollIntoViewOnOpen) scrollIntoView();
    } else {
      const errorOnChange = formIsValid(name, value.toUpperCase());
      dispatch(addFormStatus(errorOnChange));
      setDropdownElements([]);
    }
  };

  const addFormStatusError = (name, value) => {
    let errors = {};
    errors[name] = value;
    dispatch(addFormStatus(errors));
  };

  const selectFromList = (selectedCityId, name) => {
    const selectedCity = cities.find((item) => item.id === selectedCityId);

    if (selectedCity == null) {
      return;
    }

    dispatch(
      addFormData({
        [name]: selectedCity.description.toUpperCase(),
        [paramId]: selectedCity.id,
      }),
    );

    setFlag({ town: false });
    setDropdownElements([]);
  };

  const onKeyPressed = (key, value) => {
    if (key === "Enter" && component.name !== "summary") {
      validateCity(value);
      if (!checkingForm(nameForm)) {
        dispatch(stepNext(step));
      }
    }
  };

  const onBlur = (value) => {
    const currVal = formStatus[paramTown + "isChanging"];
    dispatch(addFormStatus({ [paramTown + "isChanging"]: !currVal }));

    setFlag({ town: true });
    setDropdownElements([]);

    if (formData[paramTown].length === 0) {
      validateCity(value);
    } else {
      validateCity(formData[paramTown]);
    }
  };

  const validateCity = (value, pID) => {
    const city =
      formData[paramId] !== "" && !pID
        ? cities.find((item) => item.id === formData[paramId])
        : cities.find((item) => item.description === value.toUpperCase());

    if (city != null) {
      actionUtagLink();
      addFormStatusError(paramTown, "");
      dispatch(
        addFormData({
          [paramTown]: city.description,
          [paramProvinceCode]: city.province,
          [paramCityFiscalCode]: city.fiscalCode,
          [paramId]: city.id,
        }),
      );
      onSelectTown?.();
    }
  };

  const getFilteredCities = (typedTown = formData[paramTown]) => {
    return cities.filter((item) =>
      item.description.startsWith(typedTown.toUpperCase()),
    );
  };

  const handleClick = (value) => {
    const currVal = formStatus[paramTown + "isChanging"];
    dispatch(addFormStatus({ [paramTown + "isChanging"]: !currVal }));

    if (value === "") {
      return;
    }

    if (formData[paramTown].length === 0) {
      validateCity(value);
    } else {
      dispatch(addFormData({ [paramProvinceCode]: "" }));

      setDropdownElements(
        getFilteredCities().slice(0, MAX_INITIAL_LOADED_CITIES),
      );

      if (scrollIntoViewOnOpen) scrollIntoView();
    }
  };

  const handleScroll = () => {
    const list = ulCitiesRef.current;
    if (list == null) {
      return;
    }

    const scrollEnd =
      list.scrollHeight > 0 &&
      list.offsetHeight + list.scrollTop >= list.scrollHeight;

    if (scrollEnd) {
      setDropdownElements(getFilteredCities());
    }
  };

  useEffect(() => {
    if (allCities.fetched === false) {
      dispatch(getAllCitiesAPI(formData.channel, history));
    }

    if (formData[paramTown] === "" && formData[paramCityFiscalCode] !== "") {
      let index = cities
        .map((e) => {
          return e.fiscalCode;
        })
        .indexOf(formData[paramCityFiscalCode]);
      dispatch(
        addFormData({
          [paramTown]: cities[index]?.description || "",
        }),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      formStatus.documentTown === "err.documentTown.bad" &&
      formData.documentTown !== "" &&
      component.name === "ocrForm"
    ) {
      dispatch(addFormData({ [paramTown]: formData.city }));
      validateCity(formData.documentTown);
    } else if (
      formStatus.documentTown === "err.empty.value" &&
      component.name === "ocrForm"
    ) {
      dispatch(addFormData({ [paramTown]: formData.city }));
      validateCity(formData.documentTown);
    }
  }, [formData.ocrData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formData.documentTown === "" && component.name === "ocrForm") {
      dispatch(addFormData({ [paramTown]: formData.city }));
      if (formData.documentTown === "") {
        validateCity(formData.city);
      } else {
        validateCity(formData.documentTown);
      }
    } else if (formData.documentTown !== "" && component.name === "ocrForm") {
      validateCity(formData.documentTown, true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFocus = (e) => {
    moveCursorAtTheEnd(e.target);
  };

  useEffect(() => {
    if (townEmptyFromSpid) {
      setFlag({ town: true });
    }
  }, [townEmptyFromSpid]);

  return (
    <div
      className={
        "form-group town " +
        (component.name === "summary" ? "accordion-form mt-0" : " ")
      }
    >
      <Translate>
        {({ translate }) => (
          <input
            onFocus={handleFocus}
            inputMode="text"
            placeholder={translate("text.searchTown")}
            autoComplete="off"
            role="presentation"
            className={
              "form-control " +
              (formStatus[paramTown].length > 0 && flag.town
                ? formStatus[paramTown] === "err.required" &&
                  component.name !== "summary"
                  ? " is-present"
                  : " is-invalid"
                : "") +
              (Object.entries(formData[paramTown]).length === 0 ||
              formData[paramTown] !== formData[paramTown].description
                ? " notExistCity"
                : " existCity")
            }
            type="text"
            required
            id={inputId}
            name={paramTown}
            maxLength="50"
            onChange={(e) => onChange(e.target.name, e.target.value)}
            onBlur={(e) => onBlur(e.target.value)}
            onKeyDown={(e) => onKeyPressed(e.key, e.target.value)}
            onMouseDown={(e) => handleClick(e.target.value)}
            value={
              formData[paramProvinceCode] &&
              formData[paramProvinceCode].length > 0
                ? `${formData[paramTown]} (${formData[paramProvinceCode]})`
                : formData[paramTown]
            }
          />
        )}
      </Translate>
      <label
        className="form-control-label"
        id="it-ar-town-documentTown"
        ref={lablelCitiesRef}
        htmlFor={inputId}
      >
        <Translate id={labelId} />
      </label>
      <ul
        id="itemGrid"
        className="list-group opened-list"
        onScroll={handleScroll}
        ref={ulCitiesRef}
      >
        {listaCities.map((option) => (
          <li
            className="list-group-item col-12"
            key={option.id}
            onMouseDown={(e) => selectFromList(e.target.id, [paramTown])}
            onClick={(e) => selectFromList(e.target.id, [paramTown])}
            id={option.id}
          >
            <TownLiContent
              town={option.description}
              province={option.province}
              typedTown={formData[paramTown]}
            />
          </li>
        ))}
      </ul>

      {flag.town && (
        <MessageError
          id="it-ar-town-msgParamTown"
          currentValue={formData[paramTown]}
          currentName={[paramTown]}
        />
      )}
    </div>
  );
}
