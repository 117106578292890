import { Translate } from "react-localize-redux";
import Logo from "../../../images/svg/Inizio/homeLogo.png";

export default function LinkPdv({ onClick, className }) {
  return (
    <div onClick={onClick} className={`divFlexlinkPdv ${className ?? ""}`}>
      <div className="linkIconContainer">
        <img className="linkIcon" src={Logo} alt="attivo" />
      </div>

      <h1 className="textLinkPdv">
        <Translate id="text.alreadyOpenOnPdv" />{" "}
        <button id="btn_attiva_conto_gioco" className="buttonLinkPdv">
          <Translate id="lbl.activeHere" />
        </button>
      </h1>
    </div>
  );
}
