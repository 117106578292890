import { ReactComponent as Next } from "../../../images/svg/icon/next.svg";

export default function SubmitBtn({ className, text, ...rest }) {
  return (
    <button type="submit" className={`submit-btn ${className ?? ""}`} {...rest}>
      {text}
      <span className="submit-btn__icon-next">
        <Next />
      </span>
    </button>
  );
}
