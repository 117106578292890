import { getTranslate } from "react-localize-redux";
import { useSelector } from "react-redux";

export function useTranslate() {
  const localize = useSelector((state) => state.localize);

  const translate = getTranslate(localize);

  const getTranslatedString = (translateId) => {
    const localizedElement = translate(translateId);
    return getStringFromLocalizedElement(localizedElement);
  };

  return { translate, getTranslatedString };
}

function getStringFromLocalizedElement(localizedElement) {
  const stripTags = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    return div.innerText;
  };

  if (typeof localizedElement === "string") {
    return localizedElement;
  } else if (
    typeof localizedElement === "object" &&
    localizedElement.props?.dangerouslySetInnerHTML?.__html
  ) {
    return stripTags(localizedElement.props.dangerouslySetInnerHTML.__html);
  } else {
    throw new Error("Unsuported translation type");
  }
}
