import { Redirect, Route } from "react-router-dom";

export default function FeatureEnabledRoute({
  component: Component,
  enabled,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        enabled === false ? <Redirect to="/" /> : <Component {...routeProps} />
      }
    />
  );
}
