import { connect } from "react-redux";
import { addFormData } from "../../store/formData/formData.action";
import { wizardSelectorsFlow } from "../../store/wizard/wizard.selectors";
import FollowUp from "./followUp.component";
import { markVisitedFollowUpLink } from "../../store/followUp/followUp.action";
import { setComponent } from "../../store/wizard/wizard.action";

const mapStateToProps = (state) => ({
  defaultClassicFlowName:
    wizardSelectorsFlow.getWizardDefaultClassicFlowName(state),
});

const mapDispatchTopProps = (dispatch) => ({
  addFormData: (data) => dispatch(addFormData(data)),
  markVisitedFollowUpLink: () => dispatch(markVisitedFollowUpLink()),
  setComponent: (component) => dispatch(setComponent(component)),
});

const FollowUpContainer = connect(
  mapStateToProps,
  mapDispatchTopProps,
)(FollowUp);
export default FollowUpContainer;
