import { useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useRegFlusso } from "../../hooks/useAnalytics";
import { useTranslate } from "../../hooks/useTranslate";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import {
  wizardSelectorsBonus,
  wizardSelectorsComponent,
} from "../../store/wizard/wizard.selectors";
import {
  extractAccessDataFromFormData,
  extractAddressDataFromFormData,
  extractBonusDataFromFormData,
  extractContactsFromFormData,
  extractDocumentDataFromFormData,
  extractRechargeLimitsData,
} from "./summary.helpers";

export function useCurrentData() {
  const { getState } = useStore();

  return {
    getCurrentAccessDataFormData: () => {
      const formData = formDataSelectors.getFormData(getState());
      return extractAccessDataFromFormData(formData);
    },
    getCurrentAddressFormData: () => {
      const formData = formDataSelectors.getFormData(getState());
      return extractAddressDataFromFormData(formData);
    },
    getCurrentContactsFormData: () => {
      const formData = formDataSelectors.getFormData(getState());
      return extractContactsFromFormData(formData);
    },
    getCurrentDocumentFormData: () => {
      const formData = formDataSelectors.getFormData(getState());
      return extractDocumentDataFromFormData(formData);
    },
    getCurrentRechargeLimitsFormData: () => {
      const formData = formDataSelectors.getFormData(getState());
      return extractRechargeLimitsData(formData);
    },
    getCurrentBonusFormData: () => {
      const formData = formDataSelectors.getFormData(getState());
      return extractBonusDataFromFormData(formData);
    },
    getCurrentBonusWizard: () => {
      const bonus = wizardSelectorsBonus.getBonus(getState());
      return bonus;
    },
  };
}

export function useSummaryAnalyticsTrackView() {
  const dispatch = useDispatch();

  const { promoCode, bonusCode, documentTypeObj } = useSelector(
    formDataSelectors.getFormData,
  );

  const reg_flusso = useRegFlusso();

  const reg_doc = documentTypeObj?.description ?? "";

  useEffect(() => {
    dispatch(
      utagView({
        reg_step: "riepilogo",
        reg_bonus: promoCode || bonusCode,
        reg_flusso,
        reg_doc,
      }),
    );
  }, [bonusCode, dispatch, promoCode, reg_doc, reg_flusso]);
}

export function useSummaryAnalyticsTrackLinks() {
  const dispatch = useDispatch();

  const { getTranslatedString } = useTranslate();

  const trackOpenPopup = (linkTranslateId) => {
    dispatch(
      utagLink({
        event_type: "reg_mod_campo_apri",
        cta_label: getTranslatedString(linkTranslateId),
      }),
    );
  };

  const trackClosePopup = (linkTranslateId) => {
    dispatch(
      utagLink({
        event_type: "reg_mod_campo_chiudi",
        cta_label: getTranslatedString(linkTranslateId),
      }),
    );
  };

  const trackEditData = (linkTranslateId) => {
    dispatch(
      utagLink({
        event_type: "reg_mod_campo_modifica",
        cta_label: getTranslatedString(linkTranslateId),
      }),
    );
  };

  const trackCancelEditData = (linkTranslateId) => {
    dispatch(
      utagLink({
        event_type: "reg_mod_campo_annulla",
        cta_label: getTranslatedString(linkTranslateId),
      }),
    );
  };

  const trackConfirmEditData = (linkTranslateId) => {
    dispatch(
      utagLink({
        event_type: "reg_mod_campo_conferma",
        cta_label: getTranslatedString(linkTranslateId),
      }),
    );
  };

  return {
    trackOpenPopup,
    trackClosePopup,
    trackEditData,
    trackCancelEditData,
    trackConfirmEditData,
  };
}

// This function is a workaround that can be used to track error fields in an
// isolated hook that is only called on specific components, regardless of how the
// subcomponents are implemented. This is necessary because components are
// currently shared between the step page and the summary page. The correct way
// should be to implement specific components for the summary page.
export function useSummaryFormErrorsTracking(
  formStatusFieldKey,
  triggerSwitch = true,
) {
  const dispatch = useDispatch();

  const { name: componentName } = useSelector(
    wizardSelectorsComponent.getComponent,
  );
  const { [formStatusFieldKey]: formStatusFieldValue } = useSelector(
    formStatusSelectors.getFormStatus,
  );

  const isSummaryPage = componentName === "summary";

  const error_message = useErrorMessage(formStatusFieldValue);

  useEffect(() => {
    if (!isSummaryPage || !triggerSwitch || !error_message) return;

    dispatch(
      utagLink({
        error_field: formStatusFieldKey,
        error_message: error_message,
      }),
    );
  }, [
    dispatch,
    error_message,
    formStatusFieldKey,
    isSummaryPage,
    triggerSwitch,
  ]);
}

function useErrorMessage(formStatusField) {
  const { getTranslatedString } = useTranslate();

  if (!formStatusField) return null;

  const translatedString = getTranslatedString(formStatusField);
  return translatedString.startsWith("Missing translationId")
    ? formStatusField
    : translatedString;
}
