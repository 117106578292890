import { validateUsername } from "../../services/validatorController";
import { isRecaptchaError } from "../../utility/recaptcha";
import * as formStatusAction from "../formStatus/formStatus.action";
export const SET_USERNAME = "SET_USERNAME";
export const SET_VALIDATION = "SET_VALIDATION";
export const RESET_VALIDATE_USERNAME = "RESET_VALIDATE_USERNAME";
export const FETCH_VALIDATE_USERNAME_START = "FETCH_VALIDATE_USERNAME_START";
export const FETCH_VALIDATE_USERNAME_ERROR = "FETCH_VALIDATE_USERNAME_ERROR";
export const MARK_USERNAME_AS_VALIDATED = "MARK_USERNAME_AS_VALIDATED";
export const MARK_USERNAME_AS_NOT_VALIDATED = "MARK_USERNAME_AS_NOT_VALIDATED";

export const validateUsernameAPI = (
  channel,
  username,
  birthDate,
  history,
  recaptchaToken,
) => {
  return async (dispatch) => {
    const usernameOkCallback = () => {
      dispatch(resetValidateUsername());
      dispatch(markUsernameAsValidated());
    };

    dispatch(fetchValidateUsernameStart());
    try {
      const result = await validateUsername(
        channel,
        username,
        birthDate,
        recaptchaToken,
      );
      return usernameOkCallback(result);
    } catch (error) {
      if (error.response.data.status === "FAILURE_WITH_OPTIONS") {
        dispatch(setValidation(error.response.data.error));
        dispatch(setUsername(error.response.data.proposalUsernameList));
      } else if (error.response.status === 422) {
        // avoid blocking the user if a recaptcha error is thrown. The user
        // will get a SGAD error at the end anyways.
        if (isRecaptchaError(error)) {
          usernameOkCallback();
          return;
        }

        dispatch(
          formStatusAction.addFormStatus({
            username: "err.username.genericError",
          }),
        );
        dispatch(fetchValidateUsernameError(error));
      } else {
        history.push("/errore-registrazione");
      }
    }
  };
};

export const setUsername = (state) => {
  return {
    type: SET_USERNAME,
    payload: state,
  };
};

export const setValidation = (state) => {
  return {
    type: SET_VALIDATION,
    payload: state,
  };
};

export const resetValidateUsername = () => {
  return {
    type: RESET_VALIDATE_USERNAME,
  };
};

export const fetchValidateUsernameStart = () => {
  return {
    type: FETCH_VALIDATE_USERNAME_START,
  };
};

export const fetchValidateUsernameError = (state) => {
  return {
    type: FETCH_VALIDATE_USERNAME_ERROR,
    payload: state,
  };
};

export function markUsernameAsValidated() {
  return { type: MARK_USERNAME_AS_VALIDATED };
}

export function markUsernameAsNotValidated() {
  return { type: MARK_USERNAME_AS_NOT_VALIDATED };
}
