import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { getSpidData } from "../../services/spidController";
import { getAllCitiesAPI } from "../../store/allCities/allCities.action";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import {
  addSpidStatus,
  setEmailCertified,
} from "../../store/spidStatus/spidStatus.action";
import { utagLink } from "../../store/utagStore/utagStore.action";
import { setFlusso, setVerifyPhone } from "../../store/wizard/wizard.action";
import {
  SpidErrors,
  documentSpidErrors,
  fiscalCodeSpidErrors,
  residenceSpidErrors,
} from "../../utility/spid/constants";
import { isNumberCertified, spidDataToFormData } from "../../utility/spid/spid";
import { getFlow } from "../../utility/wizardConfig/wizardConfig";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";

function hasSpidErrors(obj) {
  return obj.errors && obj.errors.length > 0;
}

export default function SpidCallback() {
  const dispatch = useDispatch();

  const { channel } = useSelector(formDataSelectors.getFormData);

  const { searchParams } = useQueryParams();
  const spidResultId = searchParams.get("spidResultId");

  const history = useHistory();

  useEffect(() => {
    const errorsTracingFunction = (spidStatus) => {
      const utagErrorMessage =
        spidStatus.showEmail && spidStatus.showPhone
          ? "numero di cellulare e indirizzo email già associati ad un conto gioco"
          : spidStatus.showEmail
            ? "indirizzo email già associato a un conto gioco"
            : "numero di cellulare già associato ad un conto gioco";

      dispatch(
        utagLink({
          error_field: spidStatus.showEmail ? "email" : "cellulare",
          error_message: utagErrorMessage,
          reg_event: "form-error",
        }),
      );
    };

    const addSpidErrors = (spidErrors) => {
      const spidStatus = {
        showEmail: false,
        showPhone: false,
        fromSpidFirstLandingContacts: false,
        fromSpidFirstLandingResidence: false,
        fromSpidFirstLandingDocument: false,
        notItalianPrefix: false,
        spidCallbackError: false,
      };

      const errors = {};

      for (const spidError of spidErrors) {
        if (spidError.code === SpidErrors.errEmailAlreadyPresent) {
          errors.email = spidError.code;
          spidStatus.fromSpidFirstLandingContacts = true;
          spidStatus.showEmail = true;
        }

        if (
          spidError.code === SpidErrors.errPhoneNumberAlreadyPresent ||
          spidError.code === SpidErrors.errPhoneNumberNoItalianPrefix
        ) {
          errors.phoneNumber = spidError.code;
          spidStatus.fromSpidFirstLandingContacts = true;
          spidStatus.showPhone = true;
          if (spidError.code === SpidErrors.errPhoneNumberNoItalianPrefix) {
            dispatch(addFormData({ phoneNumber: "", phonePrefix: "" }));
            spidStatus.notItalianPrefix = true;
          }
        }

        if (fiscalCodeSpidErrors.includes(spidError.code)) {
          if (spidError.code === SpidErrors.errFiscalCodeBelfioreCode) {
            spidStatus.fromSpidFirstLandingFiscalCode = true;
            spidStatus.fiscalCodeBelfiore = true;
          } else {
            errors.fiscalCode = spidError.code;
            spidStatus.spidCallbackError = spidError.code;
          }
        }

        if (residenceSpidErrors.includes(spidError.code)) {
          spidStatus.fromSpidFirstLandingResidence = true;
        }

        if (documentSpidErrors.includes(spidError.code)) {
          spidStatus.fromSpidFirstLandingDocument = true;
          if (spidError.code === SpidErrors.errDocumentExpired) {
            errors.expirationDate = "err.expirationDate.expired";
            errors.documentSpidError = "err.document.expired";
          } else if (spidError.code === SpidErrors.errDocumentMissingData) {
            errors.documentSpidError = "err.document.missingData";
          } else if (spidError.code === SpidErrors.errDocumentNotAccepted) {
            errors.documentSpidError = "err.document.notAccepted";
          } else if (spidError.code === SpidErrors.errDocumentGeneric) {
            errors.documentSpidError = "err.document.genericErr";
          }
        }
      }

      errorsTracingFunction(spidStatus);
      dispatch(addSpidStatus(spidStatus));
      dispatch(addFormStatus(errors));
    };

    const goToFatalErrorPage = (spidCallbackError) => {
      dispatch(addSpidStatus({ spidCallbackError }));
      history.push("/errore");
    };

    Promise.all([
      dispatch(getAllCitiesAPI(channel, history)),
      getSpidData(spidResultId),
    ])
      .then(([cities, spidResponse]) => {
        const spidData = spidResponse.payload;

        const formData = spidDataToFormData(spidData, cities);
        dispatch(addFormData(formData));

        dispatch(setVerifyPhone(isNumberCertified(spidResponse)));

        const spidResponseHasErrors = hasSpidErrors(spidResponse);

        if (spidResponseHasErrors) {
          const fatalError = spidResponse.errors.find((error) => {
            return (
              error.code === SpidErrors.errSpidAgid ||
              error.code === SpidErrors.errSpidAuth
            );
          });
          if (fatalError) {
            goToFatalErrorPage(fatalError.code);
            return;
          }

          addSpidErrors(spidResponse.errors);

          const fiscalCodeFatalError = spidResponse.errors.some(
            (value) =>
              fiscalCodeSpidErrors.includes(value.code) &&
              value.code !== SpidErrors.errFiscalCodeBelfioreCode,
          );
          if (fiscalCodeFatalError) {
            history.push("/errore");
            return;
          }
        }

        const hasEmailError =
          spidResponseHasErrors &&
          spidResponse.errors.some(
            (value) => value.code === SpidErrors.errEmailAlreadyPresent,
          );
        dispatch(setEmailCertified(!hasEmailError));

        dispatch(setFlusso("SPID"));
        if (spidResponseHasErrors) {
          getFlow("SPID", spidResponse.errors, history);
        } else {
          getFlow("SPID", null, history);
        }

        history.push("/");
      })
      .catch((error) => {
        dispatch(setFlusso("SPID"));
        if (
          hasSpidErrors(error) &&
          error.errors.some((error) => error.code === SpidErrors.errSpidAgid)
        ) {
          goToFatalErrorPage(SpidErrors.errSpidAgid);
        } else {
          // default for any other error, including failing any other API call
          goToFatalErrorPage(SpidErrors.errSpidAuth);
        }
      });
  }, [channel, dispatch, history, spidResultId]);

  return <FullPageLoader />;
}
