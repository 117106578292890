import { useSelector } from "react-redux";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { useState } from "react";
import InfoWithPdf from "../infoWithPdf/infoWithPdf.component";
import DocumentViewer from "../documentViewer/documentViewer.component";

export default function PrivacyDisclaimer({
  className,
  onClick,
  pdfHeaderTitle,
  translateId = "text.personalDataPrivacy",
}) {
  const { isMobile } = useSelector(formDataSelectors.getFormData);

  const [pdfActive, setPdfActive] = useState({ status: false, value: "" });

  return (
    <>
      <InfoWithPdf
        className={className}
        isMobile={isMobile}
        link="link.informationPrivacy"
        text={translateId}
        setPdfActive={setPdfActive}
        onClick={onClick}
      />

      <DocumentViewer
        pdfActive={pdfActive}
        setPdfActive={setPdfActive}
        pdfHeaderTitle={pdfHeaderTitle}
      />
    </>
  );
}
