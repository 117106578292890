import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

/**
 *
 * @returns {{
 *   searchParams: URLSearchParams,
 *   setSearchParams: (name: string, value: string | null) => void
 * }}
 */
export default function useQueryParams() {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  /**
   * @param {string} name
   * @param {string | null} value
   */
  const setSearchParams = (name, value) => {
    if (value != null) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return { searchParams, setSearchParams };
}
