import { Translate } from "react-localize-redux";

export default function Gender({ selected, onSelectMale, onSelectFemale }) {
  return (
    <div className="fiscal-code-gender">
      <button
        className={`male ${selected === "M" ? "selected" : ""}`}
        onClick={onSelectMale}
      >
        <Translate id="lbl.male2" />
      </button>

      <button
        className={`female ${selected === "F" ? "selected" : ""}`}
        onClick={onSelectFemale}
      >
        <Translate id="lbl.female2" />
      </button>
    </div>
  );
}
