import { Translate } from "react-localize-redux";

export default function SummaryPopupEditFooter({
  onCancel,
  onConfirm,
  confirmDisabled,
}) {
  return (
    <>
      <button className="secondary-button" onClick={onCancel}>
        <Translate id="lbl.cancel" />
      </button>
      <button onClick={onConfirm} disabled={confirmDisabled}>
        <Translate id="text.pepModalConfirm" />
      </button>
    </>
  );
}
