import {
  STEP_NEXT,
  STEP_BACK,
  DISABLED_BTN,
  IS_LOADING,
  SET_COMPONENT,
  SET_VISIBLEPHONEPOPUP,
  SET_BONUS,
  FLOW,
  FLUSSO,
  SET_VERIFIEDPHONE,
  SET_SMSENABLED,
  SET_ISPHONEPOPUPOTPSENDED,
  SET_OTPBLOCKSTATUS,
  SET_ISOTPNOTVALID,
  MARK_SIGNUP_COMPLETED,
  SET_DEFAULT_FLOW_NAME,
  SET_DEFAULT_CLASSIC_FLOW_NAME,
} from "./wizard.action";

const initialState = {
  step: 0,
  disabled: false,
  isLoading: true,
  setComponent: {},
  isVisiblePhonePopup: false,
  isPhonePopupOtpSended: false,
  isOtpNotValid: false,
  otpBlockStatus: { blocked: false, invalided: false },
  isVerifiedPhone: false,
  setBonus: null,
  flow: [],
  flusso: null,
  isSmsEnabled: false,
  setSmsEnabled: false,
  signUpCompleted: false,
  defaultFlowName: "C1",
  defaultClassicFlowName: "C1",
};

export const wizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case STEP_NEXT:
      const stepNext = ++action.step;
      window.scrollTo(0, 0);
      return { ...state, step: stepNext };

    case STEP_BACK:
      const stepBack = --action.step;
      window.scrollTo(0, 0);
      return { ...state, step: stepBack };

    case DISABLED_BTN:
      return { ...state, disabled: action.disabled };

    case IS_LOADING:
      return { ...state, isLoading: action.isLoading };

    case SET_COMPONENT:
      return { ...state, setComponent: action.setComponent };

    case SET_VISIBLEPHONEPOPUP:
      return {
        ...state,
        setIsVisiblePhonePopup: action.setIsVisiblePhonePopup,
      };

    case SET_ISPHONEPOPUPOTPSENDED:
      return {
        ...state,
        isPhonePopupOtpSended: action.isPhonePopupOtpSended,
      };

    case SET_ISOTPNOTVALID:
      return {
        ...state,
        isOtpNotValid: action.isOtpNotValid,
      };

    case SET_OTPBLOCKSTATUS:
      return {
        ...state,
        otpBlockStatus: action.otpBlockStatus,
      };

    case SET_SMSENABLED:
      return { ...state, setSmsEnabled: action.setSmsEnabled };

    case SET_VERIFIEDPHONE:
      return { ...state, isVerifiedPhone: action.setVerifyPhone };

    case SET_BONUS:
      return { ...state, setBonus: action.setBonus };

    case FLOW:
      return { ...state, flow: action.flow };

    case FLUSSO:
      return { ...state, flusso: action.flusso };

    case MARK_SIGNUP_COMPLETED:
      return { ...state, signUpCompleted: true };

    case SET_DEFAULT_FLOW_NAME:
      return { ...state, defaultFlowName: action.defaultFlowName };

    case SET_DEFAULT_CLASSIC_FLOW_NAME:
      return {
        ...state,
        defaultClassicFlowName: action.defaultClassicFlowName,
      };

    default:
      return state;
  }
};
