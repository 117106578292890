const getSpidStatus = (state) => {
  return state.spidStatusReducer.spidStatus;
};

const getSpidFiscalCodeFlowStatus = (state) => {
  return state.spidStatusReducer.spidFiscalCodeFlowStatus;
};

const isEmailCertified = (state) => {
  return state.spidStatusReducer.emailCertified;
};

const isFromSpidFirstLandingDocument = (state) => {
  return getSpidStatus(state).fromSpidFirstLandingDocument;
};

export const spidStatusSelectors = {
  getSpidStatus,
  getSpidFiscalCodeFlowStatus,
  isEmailCertified,
  isFromSpidFirstLandingDocument,
};
