import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../../store/formData/formData.action";
import { utagLink } from "../../../../store/utagStore/utagStore.action";
import { countriesSelectors } from "../../../../store/countries/countries.selectors";
import { getPrefixes } from "../../../../services/geoController";
import { Translate } from "react-localize-redux";

export default function Prefix({
  formData,
  component,
  handleChangeTelephone,
  handleBlurPhoneNumber,
}) {
  const dispatch = useDispatch();
  const listCountries = useSelector(countriesSelectors.getCountries);

  const [active, setActive] = useState(false);
  const [prefixesList, setPrefixesList] = useState([]);
  const focusList = React.createRef();

  useEffect(() => {
    if (active) document.body.classList.add("no-scroll");
    else document.body.classList.remove("no-scroll");
  }, [active]);

  const selectedList = async (value) => {
    setActive(!active);
    if (value !== formData.phonePrefix && component.name === "summary") {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "phonePrefix",
        })
      );
    }
    await dispatch(addFormData({ phonePrefix: value }));
    await handleChangeTelephone(formData.phoneNumber);
    await handleBlurPhoneNumber(formData.phoneNumber);
  };

  const showList = () => {
    if (component.name === "summary") return;
    setActive(!active);
    focusList.current.focus();
  };

  const list = () => {
    let listCallingCodes = [];
    prefixesList
      .filter((c) => c.callingCodes !== undefined)
      .forEach((element) =>
        element.callingCodes.forEach((el) =>
          listCallingCodes.push({
            callingCodes: el.replace(/\s/g, ""),
            sign: element.sign,
          })
        )
      );
    return listCallingCodes.sort((a, b) =>
      a.callingCodes > b.callingCodes
        ? 1
        : b.callingCodes > a.callingCodes
          ? -1
          : 0
    );
  };

  useEffect(() => {
    if (component.name === "contacts") {
      getPrefixes(formData.channel).then((data) => {
        const newData = data.whiteListImplModels.map(
          (element) => element.prefixes[0]
        );
        setPrefixesList(newData);
      });
    }
  }, [component.name, formData.channel]);

  useEffect(() => {
    if (Object.entries(formData.phonePrefix).length === 0) {
      let index = listCountries.countriesTrue
        .map((e) => {
          return e.fiscalCode;
        })
        .indexOf("Z000");
      if (!listCountries.countriesTrue[index]) return;
      dispatch(
        addFormData({
          phonePrefix: {
            callingCodes: listCountries.countriesTrue[index].callingCodes[0],
            sign: listCountries.countriesTrue[index].sign,
          },
        })
      );
    }
  }, [listCountries, dispatch, formData.phonePrefix]);

  return (
    <div
      className={
        "col-prefix " +
        (component.name === "summary"
          ? " col-md-2 mb-1 mt-md-2 mt-0 col-2 accordion-form"
          : " col-md-4 col-4")
      }
      ref={focusList}
      tabIndex={0}
      id="it-ar-contacts-setActive"
      onBlur={() => setActive(false)}
    >
      <div
        className={
          "form-control form-group select-customer" +
          (component.name === "summary" ? " remove-border" : "")
        }
        id="it-ar-contacts-select-customer"
        onMouseDown={() => showList()}
      >
        <label className="w-100" id="it-ar-contacts-phonePrefix">
          <span
            className="flag-nation-select"
            id="it-ar-contacts-flag-nation-select"
          >
            <img
              id="it-ar-contacts-flagNation"
              src={require(
                `../../../../images/svg/flagNation/${
                  Object.entries(formData.phonePrefix).length === 0
                    ? "- "
                    : formData.phonePrefix.sign
                }.svg`
              )}
              alt=""
              width="24px"
              height="17px"
            />
          </span>
          <span style={{ verticalAlign: "middle" }}>
            {Object.entries(formData.phonePrefix).length === 0
              ? "---"
              : "+" + formData.phonePrefix.callingCodes}
          </span>
        </label>
      </div>

      <label className="form-control-label isSpecial">
        <Translate id="lbl.prefix" />
      </label>
      <ul
        id="list_paesi"
        className={"list-group mt-sm-0 opened-list " + (active ? "" : "d-none")}
      >
        {list().map((item, index) => {
          return item.callingCodes.length > 0 ? (
            <li
              className="list-group-item col-12"
              key={index}
              id="it-ar-contacts-callingCodes"
              onClick={() => selectedList(item)}
            >
              <span className="flag-nation-select">
                <img
                  id="it-ar-contacts-flag-nation-select"
                  src={require(
                    `../../../../images/svg/flagNation/${item.sign}.svg`
                  )}
                  alt=""
                  width="100%"
                />
              </span>
              +{item.callingCodes}
            </li>
          ) : (
            ""
          );
        })}
      </ul>
      <div
        id="it-ar-contacts-overlay"
        className="overlay d-none"
        onClick={() => setActive(false)}
        onTouchMove={() => setActive(false)}
        onTouchStart={() => setActive(false)}
      ></div>
    </div>
  );
}
