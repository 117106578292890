import { useRef } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { rechargeLimitsContainsError } from "../../../utility/checkingForm/checkingForm.container";
import PopupModal from "../../popupModal/popupModal";
import RechargeLimit from "../../rechargeLimit/rechargeLimit.component";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../summary.hooks";
import SummaryPopupEditFooter from "../summaryPopupFooter/summaryPopupEditFooter.component";
import { rechargeLimitslinkTranslateId } from "./summaryRechargeLimits.config";

export default function SummaryRechargeLimitsPopup({ show, onClose }) {
  const dispatch = useDispatch();

  const { trackClosePopup } = useSummaryAnalyticsTrackLinks();

  const { getCurrentRechargeLimitsFormData } = useCurrentData();

  const rechargeLimitsFormDataBeforeEditRef = useRef(
    getCurrentRechargeLimitsFormData(),
  );

  const restoreState = () => {
    if (!!rechargeLimitsFormDataBeforeEditRef.current) {
      dispatch(addFormData(rechargeLimitsFormDataBeforeEditRef.current));
    }
  };

  const handleCancelEdit = () => {
    restoreState();
    onClose();
  };

  const handleCloseIcon = () => {
    trackClosePopup(rechargeLimitslinkTranslateId);
    restoreState();
    onClose();
  };

  return (
    <PopupModal show={show}>
      <PopupModal.Header closeMe={handleCloseIcon}>
        <Translate id="lbl.rechargeLimit" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body popup-recharge-limits-body">
          <div className="recharge-limits-summary-title">
            <Translate id="text.rechargeLimitsSummaryTitle" />
          </div>
          <RechargeLimit />
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryRechargePopupEditFooter
          onConfirm={onClose}
          onCancel={handleCancelEdit}
        />
      </PopupModal.Footer>
    </PopupModal>
  );
}

function SummaryRechargePopupEditFooter({ onCancel, onConfirm }) {
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const { trackCancelEditData, trackConfirmEditData } =
    useSummaryAnalyticsTrackLinks();

  const confirmDisabled = rechargeLimitsContainsError(formData, formStatus);

  const handleConfirm = () => {
    trackConfirmEditData(rechargeLimitslinkTranslateId);
    onConfirm();
  };

  const handleCancel = () => {
    trackCancelEditData(rechargeLimitslinkTranslateId);
    onCancel();
  };

  return (
    <SummaryPopupEditFooter
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmDisabled={confirmDisabled}
    />
  );
}
