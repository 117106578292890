import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  backBrowser,
  cleanUrl,
  getDocumentType,
  getLabelsAPI,
  initializeLocalizeRedux,
  initSpidOrClassicReg,
  queryString,
  savePublicIp,
  setDefaultFlowNames,
  setServicesStatus,
  setSessionIdAndUserAgent,
} from "../utility/initialize";

export function useInit() {
  const history = useHistory();

  useEffect(() => {
    queryString();
    Promise.all([setServicesStatus(), setDefaultFlowNames()]).finally(() => {
      initSpidOrClassicReg(history);
      cleanUrl(history);
    });
    savePublicIp();
    setSessionIdAndUserAgent();
    initializeLocalizeRedux();
    getLabelsAPI();
    const unlistenRouterFn = backBrowser(history);
    getDocumentType(history);

    return () => {
      unlistenRouterFn();
    };
  }, [history]);
}
