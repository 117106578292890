import { Translate } from "react-localize-redux";
import ArrowDown from "../../../images/svg/icon/arrow-down-black.svg";

export default function WelcomeBonusSeeOthers({ onClick, open }) {
  return (
    <div>
      <h4 className="vedi-altri-bonus-dis" onClick={onClick}>
        <Translate id="text.bonus.seeOthers" />{" "}
        <img
          src={ArrowDown}
          width="24"
          className={open ? "rotate" : ""}
          alt="arrow-down"
        />
      </h4>
    </div>
  );
}
