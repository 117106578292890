import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useFlow } from "../../hooks/useFlow";
import { useOneTagStartLoadScript } from "../../hooks/useOneTagScript";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { isIOS } from "../../utility/mobileDetection";
import LinkPdv from "../elementForm/linkPdv/linkPdv.component";
import ProhibitedToMinorsBanner from "../prohibitedToMinorsBanner/prohibitedToMinorsBanner.component";

export default function Inizio() {
  const { goToNextStep } = useFlow();

  const dispatch = useDispatch();

  const { channel } = useSelector(formDataSelectors.getFormData);

  useEffect(() => {
    dispatch(utagView({ reg_step: "step_0", reg_flusso: "standard" }));
  }, [dispatch]);

  useOneTagStartLoadScript();

  const isAppIOS = isIOS() && channel !== "62";

  const handleClick = () => {
    window.scrollTo(0, 0);

    goToNextStep();
  };

  return (
    <div className="wizardInizio">
      <div className="InizioBack">
        <div className="container" id="it-ar-inizio-form-group">
          <div className="container_inizio" id="it-ar-inizio">
            <div className="inizio_card">
              <h3 className="inizio_card_header">
                <Translate id="lbl.inizioHeader" />
              </h3>
              <p className="inizio_card_subheader">
                <Translate id="lbl.inizioSubheader" />
              </p>
              <ul>
                <li>
                  <Translate id="lbl.inizio_listNo1" />
                </li>
                <li>
                  <Translate id="lbl.inizio_listNo2" />
                </li>
                <li>
                  <Translate id="lbl.inizio_listNo3" />
                </li>
              </ul>

              <div className="register_button">
                <button
                  type="submit"
                  className="btn mb-2 full_green"
                  onClick={handleClick}
                >
                  <span>
                    <Translate id="lbl.signIn" />
                  </span>
                </button>
              </div>
            </div>

            <LinkPdvCard />
          </div>
        </div>
      </div>

      <div
        className={`inizio-footer ${isAppIOS ? "inizio-footer--app-ios" : ""}`}
      >
        <ProhibitedToMinorsBanner />
      </div>
    </div>
  );
}

function LinkPdvCard() {
  const dispatch = useDispatch();

  const { goToSpecificStep } = useFlow();

  const handleClick = () => {
    window.scrollTo(0, 0);
    dispatch(utagLink({ reg_event: "cta_cf_pdv" }));
    goToSpecificStep("/registrazione-pdv");
  };

  return (
    <div className="linkPdv">
      <LinkPdv onClick={handleClick} />
    </div>
  );
}
