import { connect } from "react-redux";
import { utagView, utagLink } from "../../store/utagStore/utagStore.action";
import QuestionarioPep from "./questionarioPep.component";

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    utagView: (data) => dispatch(utagView(data)),
    utagLink: (data) => dispatch(utagLink(data)),
  };
};

const QuestionarioPepContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionarioPep);
export default QuestionarioPepContainer;
