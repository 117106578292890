import { v4 as uuidv4 } from "uuid";
import { instancePost } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export const postJumioInfo = async (billCodeId, channel, ipAddress) => {
  const url = `${REACT_APP_URL}${PORT}/ocr-ms/ms/jumio/ext/info`;
  const data = {
    requestId: uuidv4(),
    billCodeId,
    ipAddress,
    channel,
    condition: false,
  };
  const res = await instancePost.post(url, data);
  return res.data;
};

export const postJumioDocumentStatus = async (channel, ipAddress, token) => {
  const url = `${REACT_APP_URL}${PORT}/ocr-ms/ms/docStatus`;
  const option = {
    headers: {
      authorization: `Bearer\u0020${token}`,
    },
  };
  const data = {
    requestId: uuidv4(),
    ipAddress,
    channel,
  };
  const res = await instancePost.post(url, data, option);
  return res.data;
};
