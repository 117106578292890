import { Translate } from "react-localize-redux";

export default function InfoWithPdf({
  isMobile,
  link,
  text,
  className,
  setPdfActive,
  onClick,
}) {
  return (
    <p className={`info-privacy-disclaimer ${className ?? ""}`}>
      <Translate>
        {({ translate }) =>
          !isMobile ? (
            <a
              rel="noopener noreferrer"
              href={translate(link)}
              target="_blank"
              className="link-text"
              onClick={onClick}
            >
              <Translate id={text} />
            </a>
          ) : (
            <span
              className="mobile-link"
              onClick={() => {
                onClick?.();
                setPdfActive({ status: true, value: translate(link) });
              }}
            >
              <Translate id={text} />
            </span>
          )
        }
      </Translate>
    </p>
  );
}
