import { SpidErrors } from "../../utility/spid/constants";

export const spidFCFlowErrorsWhoPass = [
  SpidErrors.errFiscalCodeUserSelfExcluded,
  SpidErrors.errFiscalCodeBelfioreCode,
];

export const SpidFCFlowErrorsWithMessage = {
  errFiscalCodeLength: "err.fiscalcode.length",
  errFiscalCodeBad: "err.fiscalcode.bad",
  errFiscalCodeBadControlChar: "err.fiscalcode.badcontrolchar",
};

export const spidFCFlowCleanFormDataObject = {
  name: "",
  surname: "",
  gender: "",
  cityEsteroBirth: "",
  birthId: "",
  birthDate: "",
  birthProvince: "",
  birthState: {
    description: "ITALIA",
    fiscalCode: "Z000",
    sign: "IT",
    callingCodes: ["39"],
    actual: true,
  },
  birthCityFiscalCode: "",
  birthTown: "",
};
