import { useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as Mail } from "../../../images/svg/summary/mail.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../summary.hooks";
import SummaryInfoBlock from "../summaryInfoBlock/summaryInfoBlock.component";
import { contactsLinkTranslateId } from "./summaryContacts.config";
import SummaryContactsPopup from "./summaryContactsPopup/summaryContactsPopup.component";

export default function SummaryContacts() {
  const [showPopup, setShowPopup] = useState(false);

  const { email, phonePrefix, phoneNumber } = useSelector(
    formDataSelectors.getFormData,
  );

  const { trackOpenPopup } = useSummaryAnalyticsTrackLinks();

  const { getCurrentContactsFormData } = useCurrentData();

  const contactsFormDataBeforeEditRef = useRef(getCurrentContactsFormData());

  const description = `${
    showPopup ? contactsFormDataBeforeEditRef.current.email : email
  }, +${phonePrefix?.callingCodes ?? ""}${phoneNumber}`;

  const handleOpenPopup = () => {
    trackOpenPopup(contactsLinkTranslateId);
    contactsFormDataBeforeEditRef.current = getCurrentContactsFormData();
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <SummaryInfoBlock
        icon={<Mail />}
        title={<Translate id={contactsLinkTranslateId} />}
        description={description}
        onClick={handleOpenPopup}
      />

      <SummaryContactsPopup show={showPopup} onClose={handleClosePopup} />
    </>
  );
}
