import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addFormData } from "../../../../store/formData/formData.action";
import PopupModal from "../../../popupModal/popupModal";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../../summary.hooks";
import { contactsLinkTranslateId } from "../summaryContacts.config";
import SummaryContactsPopupDetailContent from "./summaryContactsPopupDetailContent.component";
import SummaryContactsPopupEditContent from "./summaryContactsPopupEditContent.component";

export default function SummaryContactsPopup({ show, onClose }) {
  const dispatch = useDispatch();

  const { getCurrentContactsFormData } = useCurrentData();

  const contactsFormDataBeforeEditRef = useRef(getCurrentContactsFormData());

  const [editMode, setEditMode] = useState(false);

  const { trackEditData } = useSummaryAnalyticsTrackLinks();

  const handlePressEdit = () => {
    trackEditData(contactsLinkTranslateId);
    contactsFormDataBeforeEditRef.current = getCurrentContactsFormData();
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);

    if (!!contactsFormDataBeforeEditRef.current) {
      dispatch(addFormData(contactsFormDataBeforeEditRef.current));
    }
  };

  const handleCloseEdit = () => {
    setEditMode(false);
    onClose();
  };

  return (
    <PopupModal show={show}>
      {editMode ? (
        <SummaryContactsPopupEditContent
          onClose={handleCloseEdit}
          onCancelEdit={handleCancelEdit}
          previousData={contactsFormDataBeforeEditRef.current}
        />
      ) : (
        <SummaryContactsPopupDetailContent
          onClose={onClose}
          onPressEdit={handlePressEdit}
        />
      )}
    </PopupModal>
  );
}
