import { forwardRef, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../../store/wizard/wizard.action";
import { wizardSelectorsComponent } from "../../../store/wizard/wizard.selectors";
import { shouldShowInvalidDateFormatError } from "../../../utility/formIsValid/dateIsValid";
import formIsValid from "../../../utility/formIsValid/formIsValid.container";
import { moveCursorAtTheEnd } from "../../../utility/input/input";
import BaseErrorMessage from "../baseErrorMessage/baseErrorMessage.component";
import {
  addSlashesToDateInputValue,
  allowOnlyNumbers,
  convertFromFormData,
  convertToFormData,
} from "./singleInputDate.helpers";
import { useSummaryFormErrorsTracking } from "../../summary/summary.hooks";

function SingleInputDate(props, ref) {
  const {
    currentName,
    label,
    customAlert,
    canShowErrorBeforeFirstFocus,
    afterBlur,
    afterChange,
    hasErrFromSpid,
    ...restOfProps
  } = props;

  const dispatch = useDispatch();

  const component = useSelector(wizardSelectorsComponent.getComponent);
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const formStatusCurrentName = formStatus[currentName];

  const [inputValue, setInputValue] = useState(
    convertFromFormData(formData[currentName]),
  );
  const [canShowError, setCanShowError] = useState(
    canShowErrorBeforeFirstFocus ||
      (formStatusCurrentName.length > 0 &&
        formStatusCurrentName !== "err.required"),
  );

  const previousFormStatusErrorRef = useRef(formStatusCurrentName);

  useSummaryFormErrorsTracking(currentName, canShowError);

  const trackErrors = () => {
    if (
      formStatusCurrentName &&
      formStatusCurrentName !== "err.required" &&
      formStatusCurrentName !== previousFormStatusErrorRef.current &&
      component.name !== "summary"
    ) {
      dispatch(
        utagLink({
          error_field: currentName,
          error_message: formStatusCurrentName,
          reg_event: "form-error",
        }),
      );
    }

    previousFormStatusErrorRef.current = formStatusCurrentName;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && component.name === "summary") {
      ref.current.blur();
    }
  };

  const handleChange = (e) => {
    const numericValue = allowOnlyNumbers(inputValue, e.target.value);
    const newValue = addSlashesToDateInputValue(inputValue, numericValue);

    setInputValue(newValue);

    const error = formIsValid(currentName, newValue);

    if (newValue.length !== 10) {
      if (error[currentName] !== formStatusCurrentName) {
        dispatch(addFormStatus(error));
      }
      dispatch(addFormStatus({ [currentName + "isChanging"]: true }));
      setCanShowError(shouldShowInvalidDateFormatError(newValue));
    } else {
      dispatch(
        addFormStatus({ [currentName + "isChanging"]: false, ...error }),
      );
      setCanShowError(true);
      dispatch(addFormData({ [currentName]: convertToFormData(newValue) }));
    }

    afterChange?.(e);
  };

  const handleFocus = (e) => {
    moveCursorAtTheEnd(e.target);
    dispatch(addFormStatus({ [currentName + "isChanging"]: true }));
    dispatch(disabledBtn(true));
    setCanShowError(false);
  };

  const handleBlur = () => {
    const error = formIsValid(currentName, inputValue);
    dispatch(addFormStatus({ [currentName + "isChanging"]: false, ...error }));
    setCanShowError(true);

    trackErrors();

    if (!!inputValue) {
      dispatch(addFormData({ [currentName]: convertToFormData(inputValue) }));
    }

    afterBlur?.();
  };

  const getInputClassName = () => {
    const baseClassName = "form-control";
    const modifier =
      showError && formStatus[currentName]
        ? formStatus[currentName] === "err.required" &&
          component.name !== "summary"
          ? "is-present"
          : "is-invalid"
        : "";
    return modifier ? `${baseClassName} ${modifier}` : baseClassName;
  };

  const containerId =
    component.name !== "summary"
      ? "massagio_errore_alert"
      : "messagio_errore_fail";

  const containerClassName =
    component.name !== "summary" ? "mb-3 invalid-alert" : "mb-3 invalid-error";

  const showError =
    (canShowError &&
      formStatusCurrentName.length > 0 &&
      !formStatusCurrentName.endsWith(".noVisualError")) ||
    hasErrFromSpid;

  return (
    <div className="form-group-relative single-input-date">
      <div className="form-group md-5">
        <input
          ref={ref}
          type="tel"
          value={inputValue}
          className={getInputClassName()}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="gg/mm/aaaa"
          required
          maxLength={10}
          id={currentName}
          {...restOfProps}
        />
        <label className="form-control-label" htmlFor={currentName}>
          <Translate id={label} />
        </label>
        {showError && (
          <BaseErrorMessage
            containerId={containerId}
            containerClassName={containerClassName}
            message={customAlert ?? <Translate id={formStatus[currentName]} />}
          />
        )}
      </div>
    </div>
  );
}

export default forwardRef(SingleInputDate);
