import { connect } from "react-redux";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagView } from "../../store/utagStore/utagStore.action";
import { wizardSelectorsComponent } from "../../store/wizard/wizard.selectors";
import Sex from "./sex.component";

const mapStateToProps = (state, ownProps) => {
  return {
    formStatus: formStatusSelectors.getFormStatus(state),
    component: wizardSelectorsComponent.getComponent(state),
    spidFiscalCodeFlowStatus:
      spidStatusSelectors.getSpidFiscalCodeFlowStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    utagView: (data) => dispatch(utagView(data)),
  };
};

const SexContainer = connect(mapStateToProps, mapDispatchToProps)(Sex);
export default SexContainer;
