import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useHistory } from "react-router-dom";
import Document from "../../component/document/document.container";
import { useRegBonus, useRegFlusso } from "../../hooks/useAnalytics";
import AlertBox from "../commons/alertBox/alertBox";
import { spidErrTitleAndDesc } from "./identification.config";
import IdentificationHeaderForm from "./identificationHeaderForm/identificationHeaderForm.component";

const Identification = (props) => {
  const { documentType, formData, disabledBtn, component, flusso, formStatus } =
    props;

  let [active, setActive] = useState(false);

  const history = useHistory();

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();

  const addDocumentType = (index, id) => {
    if (id !== formData.documentType) {
      if (component.name === "summary") {
        props.utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "documentType",
        });
      }

      let defaultIssuerId;
      switch (id) {
        case "10": // Carta Identità Cartacea
        case "11": // Carta Identità Elettronica
          defaultIssuerId = 1; // Comune
          break;
        case "12": // Patente Cartacea
        case "13": // Patente Elettronica
          defaultIssuerId = 4; // Motorizzazione
          break;
        case "14": // Passaporto Italiano
          defaultIssuerId = 2; // Questura
          break;
        case "15": // Tessera Riconosc. Mod.AT
        case "16": // Tessera Riconosc. Mod.BT
          defaultIssuerId = 5; // Altro emittente
          break;
        default:
          break;
      }
      let defaultIssuerPosition = documentType.documentType[
        index
      ].availableIssuers
        .map(function (x) {
          return x.id;
        })
        .indexOf(defaultIssuerId);

      props.addFormData({
        documentTypeObj: documentType.documentType[index],
        documentType: id,
        releasedByEntityObj:
          documentType.documentType[index].availableIssuers[
            defaultIssuerPosition
          ],
        releasedByEntity:
          documentType.documentType[index].availableIssuers[
            defaultIssuerPosition
          ].id,
        frontFileName: "",
        backFileName: "",
      });
    }
  };

  useEffect(() => {
    if (documentType.fetched === false) {
      props.getDocumentTypeAPI(formData.channel, history)();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    formData.documentType === null && documentType.documentType.length > 0
      ? props.addFormData({
          documentTypeObj: documentType.documentType[0],
          documentType: documentType.documentType[0].id,
        })
      : props.addFormData({ documentTypeObj: formData.documentTypeObj });
  }, [documentType.documentType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [active]);

  useEffect(() => {
    disabledBtn(false);
    if (props.hideUtag) return;
    if (component.name === "ocrForm") {
      props.utagView(
        {
          reg_step: "documento-selezione",
          conversion_pagename: "documento-selezione",
          ...(reg_bonus ? { reg_bonus } : {}),
          reg_flusso,
        },
        { delay: 500 },
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { title, desc } = spidErrTitleAndDesc(formStatus.documentSpidError);

  return (
    <div
      className="container step-container identification"
      id="it-ar-identification-container"
    >
      <div className="row">
        <div
          className={
            "col-12" +
            (component.name === "ocrForm"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : "")
          }
        >
          <form>
            {flusso === "SPID" && !!formStatus.documentSpidError && (
              <div className="alert-box-document">
                <AlertBox titleId={title} textId={desc} isWarningBox />
              </div>
            )}

            {flusso === "C1" || flusso === "SPID" || flusso === "PDV" ? (
              <IdentificationHeaderForm />
            ) : null}

            <div id="it-ar-identification-form-group1" className="form-group">
              <label className="documentType-label">
                <Translate id="text.registrationOCR.documentType" />
              </label>

              <div className="documentType-buttons-container">
                {documentType.documentType.map((d, index) => (
                  <button
                    key={"document-type-button-" + d.id}
                    className={`documentType-button ${
                      formData.documentType === d.id.toString() ||
                      formData.documentType === d.id
                        ? "selected"
                        : ""
                    }`}
                    children={d.description}
                    onClick={(e) => {
                      e.preventDefault();
                      addDocumentType(index, d.id.toString());
                    }}
                  />
                ))}
              </div>
            </div>

            {(formData.frontFileName !== "" || formData.backFileName !== "") &&
            component.name !== "summary" ? (
              <Document hideTitle={true} />
            ) : null}
          </form>
          <div
            id="it-ar-identification-overlay"
            className={"overlay d-sm-none " + (active ? "" : "d-none")}
            onClick={() => setActive(false)}
            onTouchMove={() => setActive(false)}
            onTouchStart={() => setActive(false)}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Identification;
