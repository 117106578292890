import React, { useEffect, useState, useRef } from "react";
import { Translate } from "react-localize-redux";
import Nation from "../elementForm/nation/nation.component";
import MessageError from "../elementForm/messageError/messageError.component";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import formIsValid from "../../utility/formIsValid/formIsValid.container";
import filter from "../../utility/filter";
import Town from "../elementForm/town/town.component";
import { moveCursorAtTheEnd } from "../../utility/input/input";
import { useSummaryFormErrorsTracking } from "../summary/summary.hooks";
import { useDispatch, useSelector } from "react-redux";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { addFormData } from "../../store/formData/formData.action";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { useRegBonus, useRegFlusso } from "../../hooks/useAnalytics";

export default function Domicile({ hideUtag }) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const [statusForm, setStatusForm] = useState(true);
  const indirizzoDomEsteroRef = useRef();
  const indirizzoDomRef = useRef();
  const capDomEsteroRef = useRef();
  const capDomRef = useRef();

  let [flag, setFlag] = useState({
    domicileCap: false,
    capEsteroDomi: false,
    domicileCity: false,
    domicileAddress: false,
    cityEsteroEsteroDomi: false,
    addressEsteroDomi: false,
  });
  let [previousValue, setPreviousValue] = useState({
    domicileCap: formData.domicileCap,
    capEsteroDomi: formData.capEsteroDomi,
    domicileCity: formData.domicileCity,
    domicileAddress: formData.domicileAddress,
    cityEsteroEsteroDomi: formData.cityEsteroEsteroDomi,
    addressEsteroDomi: formData.addressEsteroDomi,
  });

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();

  useSummaryFormErrorsTracking("domicileCap", flag.domicileCap);
  useSummaryFormErrorsTracking("capEsteroDomi", flag.capEsteroDomi);
  useSummaryFormErrorsTracking("domicileAddress", flag.domicileAddress);
  useSummaryFormErrorsTracking("addressEsteroDomi", flag.addressEsteroDomi);

  const isValid = (name, value) => {
    dispatch(addFormData({ [name]: value }));
    if (flusso === "C1") {
      setStatusForm(checkingForm("residence") || checkingForm("domicile"));
    } else {
      setStatusForm(checkingForm("domicile"));
    }
  };

  const handleKeyDownComder = (event) => {
    if (event.key === "Enter") {
      indirizzoDomEsteroRef.current.focus();
    }
  };

  const handleKeyDownIder = (event) => {
    if (event.key === "Enter") {
      capDomEsteroRef.current.focus();
    }
  };

  const handleKeyDownCapder = (event) => {
    if (event.key === "Enter") {
      capDomEsteroRef.current.blur();
    }
  };

  //////////////////////////////////////////////////////

  const handleKeyDownIdr = (event) => {
    if (event.key === "Enter") {
      capDomRef.current.focus();
    }
  };

  const handleKeyDownCapdr = (event) => {
    if (event.key === "Enter") {
      capDomRef.current.blur();
    }
  };

  const onChange = async (name, value) => {
    switch (name) {
      case "domicileCap":
        let filterDatadomicileCap = filter(name, value, "regex");
        if (filterDatadomicileCap.boolean) {
          setFlag({ ...flag, domicileCap: false });
          await isValid(name, filterDatadomicileCap.newValue);
          let error = formIsValid(name, filterDatadomicileCap.newValue);
          dispatch(addFormStatus(error));
          setStatusForm(checkingForm("domicile"));
          if (filterDatadomicileCap.newValue.length === 5) {
            setFlag({ ...flag, domicileCap: true });
          }
        }
        break;
      case "domicileAddress":
        setFlag({ ...flag, domicileAddress: false });
        let filterDatadomicileAddress = filter(name, value, "regex");
        if (filterDatadomicileAddress.newValue.length > 50) {
          filterDatadomicileAddress.newValue =
            filterDatadomicileAddress.newValue.substring(0, 50);
        }
        if (filterDatadomicileAddress.boolean)
          if (/^\s+/.test(filterDatadomicileAddress.newValue)) {
            isValid(name, filterDatadomicileAddress.newValue.trim());
          } else isValid(name, filterDatadomicileAddress.newValue);
        break;
      default:
        return name;
    }
  };

  const inputTrimmer = (name, value) => {
    if (/\s+$/.test(value)) {
      dispatch(addFormData({ [name]: value.trim() }));
    } else dispatch(addFormData({ [name]: value }));
  };

  const handleBlur = (name, value, classList) => {
    let filterData = filter(name, value);
    if (
      name === "domicileAddress" ||
      name === "addressEsteroDomi" ||
      name === "cityEsteroDomi"
    )
      inputTrimmer(name, filterData.newValue);
    else dispatch(addFormData({ [name]: filterData.newValue }));

    if (
      previousValue[name] !== formData[name] &&
      component.name === "summary"
    ) {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: name,
        }),
      );
    }
    setPreviousValue({ ...previousValue, [name]: formData[name] });

    switch (name) {
      case "domicileCap":
        if (filterData.newValue.length === 0) {
          addFormStatusError("domicileCap", "err.required");
        }
        setFlag({ ...flag, domicileCap: true });
        break;
      case "cityEsteroDomi":
        filterData.newValue.length >= 2
          ? addFormStatusError("cityEsteroDomi", "")
          : addFormStatusError("cityEsteroDomi", "err.cityEsteroDomicile.bad");
        setFlag({ ...flag, cityEsteroDomi: true });
        break;
      case "capEsteroDomi":
        if (filterData.newValue.length === 0) {
          addFormStatusError("capEsteroDomi", "err.cap.length");
        }
        setFlag({ ...flag, capEsteroDomi: true });
        break;
      case "domicileAddress":
        if (filterData.newValue.length === 0) {
          addFormStatusError("domicileAddress", "err.required");
          setFlag({ ...flag, domicileAddress: true });
        } else {
          addFormStatusError("domicileAddress", "");
        }
        break;
      case "addressEsteroDomi":
        if (filterData.newValue.length === 0) {
          addFormStatusError("addressEsteroDomi", "err.required");
          setFlag({ ...flag, addressEsteroDomi: true });
        } else {
          addFormStatusError("addressEsteroDomi", "");
        }
        break;
      default:
        setFlag({ ...flag, [name]: true });
        return name;
    }
  };

  const addFormStatusError = (name, value) => {
    let errors = {};
    errors[name] = value;
    dispatch(addFormStatus(errors));
    if (
      flusso === "C1" ||
      flusso === "D" ||
      flusso === "D1" ||
      flusso === "E" ||
      flusso === "E1"
    ) {
      setStatusForm(checkingForm("residence") || checkingForm("domicile"));
    } else {
      setStatusForm(checkingForm("domicile"));
    }
  };

  const onChangeEstero = async (name, value) => {
    let filterData = filter(name, value, "regex");
    if (name === "capEsteroDomi") {
      if (filterData.boolean) {
        setFlag({ ...flag, capEsteroDomi: false });
        await isValid(name, filterData.newValue);
        let error = formIsValid(name, filterData.newValue);
        dispatch(addFormStatus(error));
        setStatusForm(checkingForm("domicile"));
        if (filterData.newValue.length === 0)
          addFormStatusError("capEsteroDomi", "err.capEsteroDomi.length");
        else {
          setFlag({ ...flag, capEsteroDomi: true });
        }
      }
    } else {
      setFlag({ ...flag, [name]: false });
      let max = 50;
      if (filterData.newValue.length > max) {
        filterData.newValue = filterData.newValue.substring(0, max);
      }
      if (filterData.boolean) {
        if (/^\s+/.test(filterData.newValue)) {
          isValid(name, filterData.newValue.trim());
        } else isValid(name, filterData.newValue);
        setStatusForm(checkingForm("domicile"));
        if (filterData.newValue.length >= 2) {
          addFormStatusError({ [name]: "" });
        } else {
          if (name === "cityEsteroDomi")
            addFormStatusError({ [name]: "err.cityEsteroDomi.bad" });
        }
      }
    }
    setStatusForm(checkingForm("domicile"));
  };

  useEffect(() => {
    let status;
    if (
      flusso === "C1" ||
      flusso === "D" ||
      flusso === "D1" ||
      flusso === "E" ||
      flusso === "E1"
    ) {
      status =
        checkingForm("residence") ||
        checkingForm("domicile") ||
        formStatus.domicileStateIsChanging ||
        formStatus.stateIsChanging;
      setStatusForm(status);
    } else {
      status = checkingForm("domicile") || formStatus.domicileStateIsChanging;
      setStatusForm(status);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusForm,
    formData.domicileState.fiscalCode,
    formStatus.domicileStateIsChanging,
    formStatus.stateIsChanging,
  ]);

  useEffect(() => {
    if (hideUtag) return;
    if (component.name !== "summary") {
      dispatch(
        utagView({
          reg_step: "domicilio",
          ...(reg_bonus ? { reg_bonus } : {}),
          reg_flusso,
        }),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id="it-ar-domicile-container"
      className={
        "container step-container domicile" +
        (formData.sameResidenceAndDomicile && component.name !== "summary"
          ? " d-none"
          : "")
      }
    >
      <div className={"row"}>
        <div
          className={
            (component.name === "residence" || component.name === "summary"
              ? "col-12"
              : "") +
            (component.name === "residence"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : "")
          }
        >
          <div>
            {flusso === "C1" || flusso === "SPID" ? (
              <div className="header-form" id="it-ar-domicile-header-form">
                {component.name !== "residence" && (
                  <h2 className="sub-title-form" id="it-ar-domicile-sub-title">
                    <Translate id="text.yourDomicileAddress" />
                  </h2>
                )}
                <h1
                  className={`label-form-ch margin-bot-standard${
                    flusso === "C1" ? " bold" : ""
                  }`}
                  id="it-ar-domicile-dontWorryForSerenade"
                >
                  <Translate id="text.differenceDomiResi" />
                </h1>
              </div>
            ) : null}

            <div
              className={
                "form-group pt-1 pt-md-0 " +
                (component.name !== "summary" ? "" : "")
              }
              id="it-ar-domicile-form-group1"
            >
              <Nation
                paramNation="domicileState"
                nameForm="domicile"
                existentState="true"
              />
            </div>
            <div
              id="it-ar-domicile-d-none"
              className={
                formData.domicileState.fiscalCode === "Z000" ? "" : "d-none"
              }
            >
              <div
                id="it-ar-domicile-form-group2"
                className={
                  "form-group pt-1 pt-md-0 " +
                  (component.name === "summary" ? "accordion-form " : "")
                }
              >
                <Town
                  inputId="domicileCity"
                  nameForm="domicile"
                  paramTown="domicileCity"
                  paramProvinceCode="domicileProvince"
                  paramCityFiscalCode="domicileCityFiscalCode"
                  labelId="lbl.domicileTown"
                  showOnlyActiveCities
                />
              </div>

              <div className="form-row " id="it-ar-domicile-form-row1">
                <div
                  id="it-ar-domicile-form-group3"
                  className={
                    "form-group col-8 col-md-8" +
                    (component.name === "summary" ? "accordion-form " : "")
                  }
                >
                  <input
                    ref={indirizzoDomRef}
                    className={
                      "form-control address-input" +
                      (formStatus.domicileAddress.length > 0 &&
                      flag.domicileAddress
                        ? formStatus.domicileAddress === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="text"
                    id="domicileAddress"
                    name="FieldCDomicile"
                    required
                    maxLength="50"
                    spellCheck="false"
                    autoCorrect="off"
                    autoComplete="street-address"
                    value={formData.domicileAddress}
                    onKeyDown={handleKeyDownIdr}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                    onBlur={(e) =>
                      handleBlur(
                        e.target.id,
                        e.target.value.trim(),
                        e.target.classList,
                      )
                    }
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-domicile-lblDomicileAddress"
                    htmlFor="domicileAddress"
                  >
                    <Translate id="lbl.domicileAddress" />
                  </label>
                  {flag.domicileAddress ? (
                    <MessageError
                      id="it-ar-domicile-msgDomicileAddress"
                      currentValue={formData.domicileAddress}
                      currentName={"domicileAddress"}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  id="it-ar-domicile-accordion-form"
                  className={
                    "form-group col-4 col-md-4 input-cap " +
                    (component.name !== "summary" ? "accordion-form " : "")
                  }
                >
                  <input
                    ref={capDomRef}
                    className={
                      "form-control input-cap " +
                      (formStatus.domicileCap.length > 0 && flag.domicileCap
                        ? formStatus.domicileCap === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="tel"
                    required
                    id="domicileCap"
                    name="FieldADomicile"
                    value={
                      formData.domicileCap.length > 0
                        ? formData.domicileCap
                        : ""
                    }
                    onKeyDown={handleKeyDownCapdr}
                    onBlur={(e) =>
                      handleBlur(
                        e.target.id,
                        e.target.value,
                        e.target.classList,
                      )
                    }
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                    autoComplete="postal-code"
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-domicile-lblDomicileCap"
                    htmlFor="domicileCap"
                  >
                    <Translate id="lbl.domicileCap" />
                  </label>
                  {flag.domicileCap ? (
                    <MessageError
                      id="it-ar-domicile-msgDomicileCap"
                      currentValue={formData.domicileCap}
                      currentName={"domicileCap"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                formData.domicileState.fiscalCode !== "Z000" ? "" : "d-none"
              }
            >
              <div
                id="it-ar-domicile-form-group5"
                className={
                  "form-group " +
                  (component.name === "summary" ? "accordion-form" : "")
                }
              >
                <input
                  autoComplete="off"
                  className={
                    "form-control " +
                    (formStatus.cityEsteroDomi.length > 0 && flag.cityEsteroDomi
                      ? formStatus.cityEsteroDomi === "err.required" &&
                        component.name !== "summary"
                        ? " is-present"
                        : " is-invalid"
                      : "")
                  }
                  type="text"
                  required
                  id="cityEsteroDomi"
                  name="FieldBEsteroDomi"
                  maxLength="50"
                  spellCheck="false"
                  autoCorrect="off"
                  onFocus={(e) => moveCursorAtTheEnd(e.target)}
                  onKeyDown={handleKeyDownComder}
                  onChange={(e) => onChangeEstero(e.target.id, e.target.value)}
                  value={formData.cityEsteroDomi}
                  onBlur={(e) =>
                    handleBlur(
                      e.target.id,
                      e.target.value.trim(),
                      e.target.classList,
                    )
                  }
                />
                <label
                  className="form-control-label"
                  id="it-ar-domicile-lblcityEsteroDomi"
                  htmlFor="cityEsteroDomi"
                >
                  <Translate id="lbl.domicileTown" />
                </label>
                {flag.cityEsteroDomi ? (
                  <MessageError
                    currentValue={formData.cityEsteroDomi}
                    id="it-ar-domicile-msgcityEsteroDomi"
                    currentName={"cityEsteroDomi"}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="form-row">
                <div
                  id="it-ar-domicile-form-group6"
                  className="form-group col-8 col-md-8"
                >
                  <input
                    ref={indirizzoDomEsteroRef}
                    autoComplete="street-address"
                    className={
                      "form-control address-input" +
                      (formStatus.addressEsteroDomi.length > 0 &&
                      flag.addressEsteroDomi
                        ? formStatus.addressEsteroDomi === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="text"
                    id="addressEsteroDomi"
                    name="FieldCEsteroDomi"
                    maxLength="50"
                    spellCheck="false"
                    autoCorrect="off"
                    required
                    value={formData.addressEsteroDomi}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownIder}
                    onChange={(e) =>
                      onChangeEstero(e.target.id, e.target.value)
                    }
                    onBlur={(e) =>
                      handleBlur(
                        e.target.id,
                        e.target.value.trim(),
                        e.target.classList,
                      )
                    }
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-domicile-lblFieldCEsteroDomi"
                    htmlFor="addressEsteroDomi"
                  >
                    <Translate id="lbl.domicileAddress" />
                  </label>
                  {flag.addressEsteroDomi ? (
                    <MessageError
                      id="it-ar-domicile-msgFieldCEsteroDomi"
                      currentValue={formData.addressEsteroDomi}
                      currentName={"addressEsteroDomi"}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  id="it-ar-domicile-form-group4"
                  className={
                    "form-group col-4 col-md-4 input-cap " +
                    (component.name === "summary" ? "accordion-form " : "")
                  }
                >
                  <input
                    ref={capDomEsteroRef}
                    autoComplete="postal-code"
                    className={
                      "form-control " +
                      (formStatus.capEsteroDomi.length > 0 && flag.capEsteroDomi
                        ? formStatus.capEsteroDomi === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="tel"
                    required
                    id="capEsteroDomi"
                    name="FieldAEsteroDomi"
                    value={formData.capEsteroDomi}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownCapder}
                    onChange={(e) =>
                      onChangeEstero(e.target.id, e.target.value)
                    }
                    onBlur={(e) =>
                      handleBlur(
                        e.target.id,
                        e.target.value,
                        e.target.classList,
                      )
                    }
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-domicile-lblCapEsteroDomi"
                    htmlFor="capEsteroDomi"
                  >
                    <Translate id="lbl.domicileCap" />
                  </label>
                  {flag.capEsteroDomi ? (
                    <MessageError
                      currentValue={formData.capEsteroDomi}
                      id="it-ar-domicile-msgCapEsteroDomi"
                      currentName={"capEsteroDomi"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
