import { FETCH_DOCUMENT_TYPE_START } from "./documentType.action";
import { RECEIVE_DOCTYPES } from "./documentType.action";
import { FETCH_DOCUMENT_TYPE_ERROR } from "./documentType.action";

const initialState = {
  documentType: [],
  fetching: false,
  fetched: false,
  error: null,
};
export const documentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TYPE_START: {
      return { ...state, fetching: true };
    }
    case FETCH_DOCUMENT_TYPE_ERROR: {
      return { ...state, fetching: false, error: action.payload };
    }
    case RECEIVE_DOCTYPES: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        documentType: action.payload,
      };
    }
    default:
      return state;
  }
};
