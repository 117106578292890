export const FORM_STATUS = "FORM_STATUS";
export const RESET_FORM_STATUS = "RESET_FORM_STATUS";

export const addFormStatus = (state) => {
  return {
    type: FORM_STATUS,
    formStatus: state,
  };
};

export const resetFormStatus = () => ({ type: RESET_FORM_STATUS });
