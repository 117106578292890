import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import ErrorImg from "../../../images/svg/errorCard/errorCircle.svg";
import { utagLink, utagView } from "../../../store/utagStore/utagStore.action";
import Background from "../../commons/background/background.component";
import PreFooter from "../../elementForm/preFooter/preFooter.component";
import Header from "../../header/header.component";
import { useDispatch } from "react-redux";
import { useRegistrationSelectionNavigation } from "../../../hooks/useFlow";

export default function ActiveAccountExpired() {
  const { goToRegistrationSelection } = useRegistrationSelectionNavigation();

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      utagLink({
        reg_event: "pre-registrazione scaduta|registrati online|pdv",
        conversion_pagename: "pre-registrazione scaduta pdv",
        reg_flusso: "pdv",
      }),
    );
    goToRegistrationSelection();
  };

  useEffect(() => {
    dispatch(
      utagView({
        reg_step: "pre-registrazione scaduta pdv",
        conversion_pagename: "pre-registrazione scaduta pdv",
        reg_flusso: "pdv",
      }),
    );
  }, [dispatch]);

  return (
    <div className="errorCardsPageExpReg error-card-bonus-page">
      <Background />

      <Header />

      <div className="cardsContainer expired-reg">
        <div className={`errorCardContainerReg`}>
          <img className="logo" src={ErrorImg} alt="logo" />
          <h2 className={`title title-large`}>
            <Translate id={"text.expiredRegTitle"} />
          </h2>
          <p className={"text advisoryText mr-bt"}>
            <Translate id={"text.expiredRegParagraphParthOne"} />
          </p>
          <h2 className={`title textParagraphTitle`}>
            <Translate id={"text.expiredRegRecharge"} />
          </h2>
          <p className={"text advisoryText mr-bt"}>
            <Translate id={"text.expiredRegParagraphParthTwo"} />
          </p>{" "}
          <h2 className={`title textParagraphTitle`}>
            <Translate id={"text.expiredRegSuggestion"} />
          </h2>
          <p className={"text advisoryText"}>
            <Translate id={"text.expiredRegParagraphParthThree"} />
          </p>
          <button className={`button`} onClick={handleClick}>
            <Translate id={"lbl.signInOnline"} />
          </button>
        </div>
      </div>

      <PreFooter />
    </div>
  );
}
