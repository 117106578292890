import { Translate } from "react-localize-redux";
import Chat from "../../../../images/svg/icon/chat.svg";
import PreFooterContact from "../preFooterContact/preFooterContact.component";

export default function PreFooterChat({ utagLink }) {
  const startChat = () => {
    utagLink({ reg_contatto: "contatto", reg_event: "chat" });
    window.vivochaEngageChat();
  };

  return (
    <PreFooterContact
      title={<Translate id="text.writeIn" />}
      iconSrc={Chat}
      linkComponent={
        <button className="preFooter-caption-action" onClick={startChat}>
          <Translate id="text.liveChat" />
        </button>
      }
    />
  );
}
