const getRechargeLimit = (state) => state.rechargeLimitReducer;

const getShowApprovalPopupInRechargeLimitStep = (state) => {
  return getRechargeLimit(state).showApprovalPopupInRechargeLimitStep;
};

const getShowApprovalPopupInSummary = (state) => {
  return getRechargeLimit(state).showApprovalPopupInSummary;
};

const getRechargeLimitApprovalAmount = (state) => {
  return getRechargeLimit(state).approvalAmount;
};

const getRechargeLimitMaxAmount = (state) => {
  return getRechargeLimit(state).maxAmount;
};

const getRechargeLimitFetching = (state) => {
  return getRechargeLimit(state).fetching;
};

const getHasShownApprovalPopup = (state) => {
  return getRechargeLimit(state).hasShownApprovalPopup;
};

export const rechargeLimitSelectors = {
  getShowApprovalPopupInRechargeLimitStep,
  getShowApprovalPopupInSummary,
  getRechargeLimitApprovalAmount,
  getRechargeLimitMaxAmount,
  getRechargeLimitFetching,
  getHasShownApprovalPopup,
};
