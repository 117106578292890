import React, { useState, useEffect } from "react";
import { Translate } from "react-localize-redux";
import { checkingForm } from "../../../utility/checkingForm/checkingForm.container";

const CheckSex = (props) => {
  let { formData, disabledBtn, component, flusso } = props;

  const [statusForm, setStatusForm] = useState(true);

  const focusList = React.createRef();
  const [active, setActive] = useState(false);

  const showList = () => {
    setActive(!active);
    focusList.current.focus();
  };

  const check = async (value, name) => {
    setActive(!active);

    if (formData[name] !== value && component.name === "summary") {
      props.utagLink({
        reg_event: "riepilogo_modifica",
        reg_campo: name,
      });
    }

    await props.addFormData({ [name]: value });
    if (
      flusso !== "C1" &&
      flusso !== "D" &&
      flusso !== "D1" &&
      flusso !== "E" &&
      flusso !== "E1"
    )
      setStatusForm(checkingForm("gender"));
    if (
      flusso === "C1" ||
      flusso === "D" ||
      flusso === "D1" ||
      flusso === "E" ||
      flusso === "E1"
    )
      setStatusForm(checkingForm("gender&birthplace"));
  };

  useEffect(() => {
    if (
      flusso !== "C1" &&
      flusso !== "D" &&
      flusso !== "D1" &&
      flusso !== "E" &&
      flusso !== "E1"
    )
      setStatusForm(checkingForm("gender"));
    if (
      flusso === "C1" ||
      flusso === "D" ||
      flusso === "D1" ||
      flusso === "E" ||
      flusso === "E1"
    )
      setStatusForm(checkingForm("gender&birthplace"));
    disabledBtn(statusForm);
  }, [statusForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active && component.name === "summary")
      document.body.classList.add("no-scroll");
    else document.body.classList.remove("no-scroll");
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="check-sex mt-0">
      {component.name === "summary" ? (
        <>
          <div
            className={"overlay d-sm-none " + (active ? "" : "d-none")}
            onClick={() => setActive(false)}
            onTouchMove={() => setActive(false)}
            onTouchStart={() => setActive(false)}
          ></div>
          <label
            id="it-ar-checkSex-lblSex"
            className={"form-control-label sex mb-0"}
          >
            <Translate id="lbl.sex" />
          </label>
          <div className="form-group mt-1 mt-md-0 mb-0 mb-md-0">
            <div className="form-row">
              <div
                className="col-12"
                ref={focusList}
                id="it-ar-checkSex-setActive"
                tabIndex={0}
                onBlur={() => setActive(false)}
              >
                <div
                  id="it-ar-checkSex-select-customer"
                  className="form-control pt-0 pb-0 border-0 px-0"
                  onMouseDown={() => showList()}
                >
                  <label
                    id="it-ar-checkSex-gender"
                    className={"form-control w-100 dropdown-sex"}
                  >
                    {formData.gender === "M" ? "Maschile" : "Femminile"}
                  </label>
                </div>
                <ul
                  className={
                    "list-group opened-list mw-100 " + (active ? "" : "d-none")
                  }
                >
                  <li
                    className="list-group-item col-12"
                    id="it-ar-checkSex-male"
                    name="gender"
                    value="M"
                    onClick={(e) => check("M", "gender")}
                    key={0}
                  >
                    Maschile
                  </li>
                  <li
                    className="list-group-item cl-12"
                    id="it-ar-checkSex-female"
                    name="gender"
                    value="F"
                    onClick={(e) => check("F", "gender")}
                    key={1}
                  >
                    Femminile
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <label className="form-control-label sex" id="it-ar-checkSex-lblSex">
            <Translate id="lbl.sex" />
          </label>
          <div className="d-flex m-0 form-group">
            <div
              className="form-check form-check-sex form-check-inline form-checkFirst"
              style={{
                flexGrow: component.name === "newSummary" ? 1 : "inherit",
              }}
            >
              <input
                type="radio"
                onClick={(e) => check(e.target.value, e.target.name)}
                name="gender"
                id="inlineRadio1"
                value="M"
                defaultChecked={formData.gender === "M"}
              />
              <label
                id="it-ar-checkSex-male"
                className={
                  "form-check-label" +
                  (formData.gender === "M" ? " selected" : "")
                }
                htmlFor="inlineRadio1"
                style={{
                  flexGrow: component.name === "newSummary" ? 1 : "inherit",
                }}
              >
                <Translate id="lbl.male" />
              </label>
            </div>
            <div
              className="form-check form-check-sex form-check-inline"
              style={{
                flexGrow: component.name === "newSummary" ? 1 : "inherit",
              }}
            >
              <input
                type="radio"
                onClick={(e) => check(e.target.value, e.target.name)}
                name="gender"
                id="inlineRadio2"
                value="F"
                defaultChecked={formData.gender === "F"}
              />
              <label
                id="it-ar-checkSex-female"
                className={
                  "form-check-label" +
                  (formData.gender === "F" ? " selected" : "")
                }
                htmlFor="inlineRadio2"
                style={{
                  flexGrow: component.name === "newSummary" ? 1 : "inherit",
                }}
              >
                <Translate id="lbl.female" />
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CheckSex;
