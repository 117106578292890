import { useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as Padlock } from "../../../images/svg/summary/padlock.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../summary.hooks";
import SummaryInfoBlock from "../summaryInfoBlock/summaryInfoBlock.component";
import { accessDataLinkTranslateId } from "./summaryAccessData.config";
import SummaryAccessDataPopup from "./summaryAccessDataPopup/summaryAccessDataPopup.component";

export default function SummaryAccessData() {
  const [showPopup, setShowPopup] = useState(false);

  const { username, password } = useSelector(formDataSelectors.getFormData);

  const { trackOpenPopup } = useSummaryAnalyticsTrackLinks();

  const { getCurrentAccessDataFormData } = useCurrentData();

  const accessDataFormDataBeforeEditRef = useRef(
    getCurrentAccessDataFormData(),
  );

  const description = `${
    showPopup ? accessDataFormDataBeforeEditRef.current.username : username
  }, ${(showPopup
    ? accessDataFormDataBeforeEditRef.current.password
    : password
  ).replace(/./g, "*")}`;

  const handleOpenPopup = () => {
    trackOpenPopup(accessDataLinkTranslateId);
    accessDataFormDataBeforeEditRef.current = getCurrentAccessDataFormData();
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <SummaryInfoBlock
        icon={<Padlock />}
        title={<Translate id={accessDataLinkTranslateId} />}
        description={description}
        onClick={handleOpenPopup}
      />

      <SummaryAccessDataPopup show={showPopup} onClose={handleClosePopup} />
    </>
  );
}
