import { v4 as uuidv4 } from "uuid";
import { instanceGet, instancePost } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export async function validateLotteryReceipt(channel, ipAddress, playCode) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/validateLotteryReceipt`;
  const data = {
    params: {
      requestId: uuidv4(),
      channel,
      ipAddress,
      playCode,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
}

export async function getLotteryPdvPrize(channel, ipAddress, playCode) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/loyalty/lotteryPdvPrize`;
  const data = {
    params: {
      requestId: uuidv4(),
      channel,
      ipAddress,
      playCode,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
}

export async function checkInfoGC(channel, ipAddress, infoGen) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/checkInfoGC`;
  const data = {
    params: {
      requestId: uuidv4(),
      channel,
      ipAddress,
      infoGen,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
}

export async function validatePdvAccount(
  channel,
  ipAddress,
  accountCode,
  oldPin,
  paymentCircuitId,
  chargingPin,
) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/validatePdvAccount`;

  const data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      ipAddress: ipAddress,
      accountCode: accountCode,
      oldPin: oldPin,
      paymentCircuitId: paymentCircuitId,
      chargingPin: chargingPin,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
}

export async function postGeneratePrize(accountId, amount, sgadId) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/loyalty/generatePrize`;

  const data = {
    requestId: uuidv4(),
    accountId,
    amount,
    sgadId,
  };

  const res = await instancePost.post(url, data);
  return res.data;
}
export async function postLotteryReceipt(
  channel,
  ipAddress,
  playCode,
  accountCode
) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/loyalty/addLotteryReceipt`;

  const data = {
    requestId: uuidv4(),
    channel,
    ipAddress,
    playCode,
    accountCode,
  };

  const res = await instancePost.post(url, data);
  return res.data;
}