import { Translate } from "react-localize-redux";
import { usePdvFlowDetection } from "../../../../../../hooks/useFlow";

export default function PromoCodePopupNote() {
  const isPdvFlow = usePdvFlowDetection();

  return (
    <div className="promo-popup-body-note">
      <Translate
        id={isPdvFlow ? "lbl.promocode.notePdv" : "lbl.promocode.note"}
      />
    </div>
  );
}
