import { useDispatch } from "react-redux";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import PrivacyDisclaimer from "../../privacyDisclaimer/privacyDisclaimer.component";

export default function OcrPrivacyDisclaimer() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      utagLink({
        reg_event: "informativa-privacy",
        conversion_pagename: "step_0",
        reg_cta: "informativa sulla privacy",
      }),
    );
  };

  return (
    <PrivacyDisclaimer
      className="ocr-selection-privacy-disclaimer"
      onClick={handleClick}
    />
  );
}
