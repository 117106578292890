import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as Home } from "../../../images/svg/summary/home.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import Domicile from "../../domicile/domicile.component";
import Residence from "../../residence/residence.component";
import OcrSummaryInfoBlock from "../ocrSummaryInfoBlock/ocrSummaryInfoBlock.component";
import OcrSummarySection from "../ocrSummarySection/ocrSummarySection.component";

export default function OcrSummaryAddress() {
  const { sameResidenceAndDomicile } = useSelector(
    formDataSelectors.getFormData,
  );

  return (
    <OcrSummarySection
      className="ocr-summary-address"
      infoBlock={
        <OcrSummaryInfoBlock
          title={<Translate id="lbl.residenceDomicile" />}
          icon={<Home />}
        />
      }
    >
      <Residence />

      {!sameResidenceAndDomicile && <Domicile />}
    </OcrSummarySection>
  );
}
