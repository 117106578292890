import { connect } from "react-redux";
import { utagLink } from "../../../../store/utagStore/utagStore.action";
import PreFooterPhone from "./preFooterPhone.component";

const mapDispatchToProps = (dispatch) => ({
  utagLink: (data) => dispatch(utagLink(data)),
});

const PreFooterPhoneContainer = connect(
  null,
  mapDispatchToProps,
)(PreFooterPhone);

export default PreFooterPhoneContainer;
