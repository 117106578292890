import {
  MARK_USERNAME_AS_NOT_VALIDATED,
  MARK_USERNAME_AS_VALIDATED,
  SET_USERNAME,
  SET_VALIDATION,
} from "./validateUsername.action";
import { RESET_VALIDATE_USERNAME } from "./validateUsername.action";
import { FETCH_VALIDATE_USERNAME_START } from "./validateUsername.action";
import { FETCH_VALIDATE_USERNAME_ERROR } from "./validateUsername.action";

const initialState = {
  errorListUsername: [],
  fetching: false,
  error: null,
};
export const validateUsernameReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VALIDATE_USERNAME_START: {
      return { ...state, fetching: true };
    }
    case FETCH_VALIDATE_USERNAME_ERROR: {
      return { ...state, fetching: false, error: action.payload };
    }
    case SET_USERNAME: {
      return { ...state, fetching: false, errorListUsername: action.payload };
    }
    case SET_VALIDATION: {
      return { ...state, fetching: false, alreadyExist: action.payload };
    }
    case RESET_VALIDATE_USERNAME: {
      return { ...state, fetching: false, errorListUsername: [] };
    }
    case MARK_USERNAME_AS_VALIDATED:
      return { ...state, usernameHasBeenValidated: true };
    case MARK_USERNAME_AS_NOT_VALIDATED:
      return { ...state, usernameHasBeenValidated: false };
    default:
      return state;
  }
};
