import React, { useEffect } from "react";
import formIsValid from "../../../utility/formIsValid/formIsValid.container";
import { Translate } from "react-localize-redux";

const MessageAlert = (props) => {
  let { currentValue, currentName, addFormStatus } = props;
  let { customAlert } = props;
  useEffect(() => {
    const error = formIsValid(currentName, currentValue);
    addFormStatus(error);
  }, [addFormStatus, currentValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="mb-3 invalid-alert" id="massagio_errore_alert">
        {customAlert ? (
          <Translate id={customAlert} />
        ) : (
          <Translate id="err.required" />
        )}
      </div>
    </>
  );
};
export default MessageAlert;
