import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN_REDIRECT } from "../../../config";
import EmailLogo from "../../../images/svg/thankyouPage/spid/email.svg";
import IdDocumentLogo from "../../../images/svg/thankyouPage/spid/idDocument.svg";
import VerifiedLogo from "../../../images/svg/thankyouPage/spid/verified.svg";
import Timeglass from "../../../images/svg/timeglass.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";
import { getRegFlusso } from "../../../utility/analytics";
import {
  useAuthAction,
  useCustomRedirect,
  useDocValidationStatusCheck,
} from "../thankYouPage.hooks";

export default function ThankYouPageCompletionCtaBlocks({
  emailVerified,
  onClickVerifyEmail,
  completionPercentage,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { phoneNumberCertified, fromPdv } = useSelector(
    formDataSelectors.getFormData,
  );
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const { authenticate } = useAuthAction();
  const { customRedirect } = useCustomRedirect();

  const docValidationStatus = useDocValidationStatusCheck();

  const blocks = getBlocks(
    phoneNumberCertified,
    emailVerified,
    docValidationStatus,
  );

  const handleClick = async (redirect, event) => {
    await authenticate();

    dispatch(
      utagLink({
        reg_cta: event === "carica-doc-identita" ? "carica" : "verifica",
        reg_flusso: getRegFlusso(flusso, fromPdv),
        reg_event: `registrazione|typ${completionPercentage}|${event}`,
      }),
    );

    switch (event) {
      case "carica-doc-identita":
        history.push("/carica-documento");
        break;
      case "verifica-email":
        onClickVerifyEmail();
        break;
      default:
        customRedirect(redirect);
    }
  };

  return (
    <div className="typ-spid-progress-status">
      {blocks.map((row) => (
        <div className="typ-spid-progress-status-row" key={row.key}>
          <div className="typ-spid-progress-status-info">
            <img src={row.imgPath} alt="name" />

            <div className="typ-spid-progress-status-info-box">
              <span className="typ-spid-progress-status-info-name">
                {row.name}
              </span>
            </div>
          </div>

          <div className="typ-spid-progress-status-actions">
            {row.inProgress ? (
              <span className="typ-spid-progress-status-verified">
                <Translate id="text.inProgress" />
              </span>
            ) : row.verified ? (
              <span className="typ-spid-progress-status-verified">
                <Translate
                  id={
                    row.key === "id_document"
                      ? "text.typ.spid.validated"
                      : "text.typ.spid.verified"
                  }
                />
              </span>
            ) : (
              <button
                className="typ-spid-progress-status-button"
                onClick={() => handleClick(row.link, row.linkLabel)}
              >
                {row.actionLabel}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

/**
 * @param {boolean} phoneNumberCertified
 * @param {boolean} emailVerified
 * @param {"IN_PROGRESS" | "VALIDATED" | "TO_BE_UPLOADED"} docValidationStatus
 */
function getBlocks(phoneNumberCertified, emailVerified, docValidationStatus) {
  const blocks = [
    {
      key: "phone_number",
      imgPath: VerifiedLogo,
      name: <Translate id="text.typ.spid.phoneNumber" />,
      verified: phoneNumberCertified,
      actionLabel: <Translate id="lbl.verify" />,
      inProgress: false,
    },
    {
      key: "email",
      imgPath: emailVerified ? VerifiedLogo : EmailLogo,
      name: <Translate id="text.typ.spid.emailAddress" />,
      verified: emailVerified,
      actionLabel: <Translate id="lbl.verify" />,
      link: `${LOGIN_REDIRECT}/area-riservata/profilo/#userEmailCard`,
      linkLabel: "verifica-email",
      inProgress: false,
    },
    {
      key: "id_document",
      imgPath: getDocIcon(docValidationStatus),
      name: <Translate id="text.typ.spid.document" />,
      verified:
        docValidationStatus === "VALIDATED" ||
        docValidationStatus === "IN_PROGRESS",
      actionLabel: <Translate id="text.typ.spid.upload" />,
      link: `${LOGIN_REDIRECT}/area-riservata/profilo`,
      linkLabel: "carica-doc-identita",
      inProgress: docValidationStatus === "IN_PROGRESS",
    },
  ];

  return blocks.sort((a, b) => Number(b.verified) - Number(a.verified));
}

/**
 * @param {"IN_PROGRESS" | "VALIDATED" | "TO_BE_UPLOADED"} docValidationStatus
 */
function getDocIcon(docValidationStatus) {
  switch (docValidationStatus) {
    case "IN_PROGRESS":
      return Timeglass;
    case "VALIDATED":
      return VerifiedLogo;
    default:
      return IdDocumentLogo;
  }
}
