import Background from "../commons/background/background.component";
import PreFooter from "../elementForm/preFooter/preFooter.component";
import Header from "../header/header.component";
import ErrorCardGeneric from "./errorCardGeneric/errorCardGeneric.component";
import {
  useAnalyticsUtagView,
  useErrorPageType,
} from "./errorCardOcrPage.hooks";
import ErrorCardUnsupportedDocuments from "./errorCardUnsupportedDocuments/errorCardUnsupportedDocuments.component";

export default function ErrorCardOcrPage() {
  const type = useErrorPageType();

  useAnalyticsUtagView();

  return (
    <div className="error-card-ocr-page">
      <Background />

      <Header />

      <div className="error-card-container">
        {type === "unsupportedDocumentError" ? (
          <ErrorCardUnsupportedDocuments />
        ) : (
          <ErrorCardGeneric />
        )}

        <PreFooter />
      </div>
    </div>
  );
}
