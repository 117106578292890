import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { spidStatusSelectors } from "../../../store/spidStatus/spidStatus.selectors";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";

export default function FiscalCodeHeaderForm() {
  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const getSubtitleTranslateId = () => {
    switch (flusso) {
      case "SPID":
        return spidStatus.fiscalCodeBelfiore
          ? "text.saveTime.belfiore"
          : "text.saveTime";
      case "PDV":
        return "text.fiscalCode.youCanCopyFromHealthcareCard";
      default:
        return "text.saveTime";
    }
  };

  return (
    <div className="header-form" id="it-ar-fiscalCode-header-form">
      <h2 className="sub-title-form" id="it-ar-fiscalCode-sub-title-form">
        {flusso === "PDV" ? (
          <Translate id="lbl.fiscalcode" />
        ) : (
          <CustomerTitle />
        )}
      </h2>

      <p className="label-form margin-bot-standard">
        <Translate id={getSubtitleTranslateId()} />
      </p>
    </div>
  );
}

function CustomerTitle() {
  const formData = useSelector(formDataSelectors.getFormData);
  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);

  return (
    <>
      <Translate id="lbl.hello" />{" "}
      {formData.isMobile && formData.name.length > 18
        ? formData.name.charAt(0).toUpperCase() +
          formData.name.slice(1, 18).toLowerCase().concat("...")
        : formData.name.charAt(0).toUpperCase() +
          formData.name.slice(1).toLowerCase()}
      <Translate
        id={
          spidStatus.fiscalCodeBelfiore
            ? "text.insertFiscalCode.belfiore"
            : "text.insertFiscalCode"
        }
      />
    </>
  );
}
