import moment from "moment";

export const shouldShowInvalidDateFormatError = (date) => {
  if (!date) {
    return false;
  } else if (date.length > 10) {
    return true;
  }

  const acceptedCharacters = [
    /^[0-3]$/,
    /^[0-9]$/,
    /^\/$/,
    /^[0-1]$/,
    /^[0-9]$/,
    /^\/$/,
    /^[1-2]$/,
    /^[0-9]$/,
    /^[0-9]$/,
    /^[0-9]$/,
  ];

  const invalidFromRegex = !date
    .split("")
    .every((char, index) => acceptedCharacters[index].test(char));

  const invalidFromDayDate = () => {
    const dayChar2 = date.charAt(1);
    if (dayChar2 === "") {
      // user is still typing
      return false;
    }

    const dayChar1 = date.charAt(0);

    switch (dayChar1) {
      case "0":
        return dayChar2 === "0";
      case "3":
        return dayChar2 !== "0" && dayChar2 !== "1";
      default:
        return false;
    }
  };

  const invalidFromMonth = () => {
    const monthChar2 = date.charAt(4);
    if (monthChar2 === "") {
      // user is still typing
      return false;
    }

    const monthChar1 = date.charAt(3);

    switch (monthChar1) {
      case "0":
        return monthChar2 === "0";
      case "1":
        return monthChar2 !== "0" && monthChar2 !== "1" && monthChar2 !== "2";
      default:
        return false;
    }
  };

  const invalidFromDateParsing =
    date.length === 10 && !moment(date, "DD/MM/YYYY", true).isValid();

  return (
    invalidFromRegex ||
    invalidFromDayDate() ||
    invalidFromMonth() ||
    invalidFromDateParsing
  );
};
