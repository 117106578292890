export const SPID_STATUS = "SPID_STATUS";
export const SPID_FISCALCODE_FLOW_STATUS = "SPID_FISCALCODE_FLOW_STATUS";
export const SET_EMAIL_CERTIFIED = "SET_EMAIL_CERTIFIED";
export const CLEAN_SPID_STATUS = "CLEAN_SPID_STATUS";

export const addSpidStatus = (state) => {
  return {
    type: SPID_STATUS,
    spidStatus: state,
  };
};

export const addSpidFiscalCodeFlowStatus = (state) => {
  return {
    type: SPID_FISCALCODE_FLOW_STATUS,
    spidFiscalCodeFlowStatus: state,
  };
};

export const setEmailCertified = (certified) => ({
  type: SET_EMAIL_CERTIFIED,
  payload: certified,
});

export const cleanSpidStatus = () => ({
  type: CLEAN_SPID_STATUS,
});
