const errorCodeLoyalty = {
  "err.codiceGiocata.invalid": {
    translationId: "err.gameCodePlayed.wrongCode",
  },
  "err.codiceGiocata.expired": {
    translationId: "err.gameCodePlayed.daysPassed",
  },
  "err.codiceGiocata.exists": {
    translationId: "err.gameCodePlayed.isUsed",
  },
  "err.codiceGiocata.flag.not.exists": {
    translationId: "err.gameCodePlayed.wrongCode",
  },
};

export function getErrorTranslationId(errCode) {
  const defaultTranslationId = "text.loyaltyInput.length";

  if (errorCodeLoyalty.hasOwnProperty(errCode)) {
    return errorCodeLoyalty[errCode]?.translationId || defaultTranslationId;
  } else {
    return defaultTranslationId;
  }
}
