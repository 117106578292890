export const MODAL_STATUS = {
  OFF: "",
  PROFILING: "profiling",
  TREATMENT: "treatment",
};

export const modalText = {
  [MODAL_STATUS.PROFILING]: {
    title: "text.privacy.info.modal.title.profiling",
    text: "text.termsandconditionPersonalProfilingInfo_B",
  },
  [MODAL_STATUS.TREATMENT]: {
    title: "text.privacy.info.modal.title.treatment",
    text: "text.termsandconditionPersonalDataInfo_B",
  },
};
