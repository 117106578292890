import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import RegistrationOCRSelectionCard from "../registrationOCRSelectionCard/registrationOCRSelectionCard.component";

export default function ScanDocumentCardPdv() {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleClick = async () => {
    dispatch(
      utagLink({
        reg_event: "inserimento-dati",
        conversion_pagename:
          "registrazione:pdv:step_0:apri un conto rivenditore",
        reg_cta: "scansiona documento",
        reg_flusso: "pdv rivenditori",
      }),
    );

    history.push("/seleziona-documento/pdv");
  };

  return (
    <RegistrationOCRSelectionCard
      primary
      title={<Translate id="text.registrationOCR.scanDocument" />}
      description={
        <Translate id="text.registrationOCR.scanDocumentDescriptionPdv" />
      }
      onClick={handleClick}
    />
  );
}
