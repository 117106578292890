import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formDataSelectors } from "../store/formData/formData.selectors";
import { wizardSelectorsFlusso } from "../store/wizard/wizard.selectors";
import { getRegFlusso } from "../utility/analytics";

export function useRegFlusso() {
  const { fromPdv } = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  return getRegFlusso(flusso, fromPdv);
}

export function useRegDoc() {
  const { documentType } = useSelector(formDataSelectors.getFormData);

  const getRegDoc = () => {
    switch (documentType) {
      case 10: // Carta d'identità
      case 11: // Carta d'identità elettronica
        return "carta d'identità";
      case 12: // Patente di guida
      case 13: // Patente di guida elettronica
        return "patente";
      case 14: // Passaporto
        return "passaporto";
      default:
        return null;
    }
  };

  return getRegDoc();
}

export function useRegBonus() {
  const { promoCode, bonusCode } = useSelector(formDataSelectors.getFormData);
  if (promoCode) return promoCode;
  else if (bonusCode) return bonusCode;
  else return null;
}

export function useOcrRegFlussoFromPathParam() {
  const { regType } = useParams();

  if (!regType) {
    throw new Error(
      "regType is not defined, cannot get regFlusso from path param",
    );
  }

  return regType === "cliente" ? "ocr" : "ocr rivenditori";
}
