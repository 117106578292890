import { useDispatch } from "react-redux";
import { utagLink } from "../../store/utagStore/utagStore.action";
import PreFooter from "../elementForm/preFooter/preFooter.component";
import SignButton from "../signButton/signButton.component";
import { useSummaryAnalyticsTrackView } from "./summary.hooks";
import SummaryAccessData from "./summaryAccessData/summaryAccessData.component";
import SummaryAddress from "./summaryAddress/summaryAddress.component";
import SummaryContacts from "./summaryContacts/summaryContacts.component";
import SummaryDocument from "./summaryDocument/summaryDocument.component";
import SummaryPersonalData from "./summaryPersonalData/summaryPersonalData.component";
import SummaryRecaptcha from "./summaryRecaptcha/summaryRecaptcha.component";
import SummaryRechargeLimits from "./summaryRechargeLimits/summaryRechargeLimits.component";
import SummaryTextBlock from "./summaryTextBlock/summaryTextBlock.component";
import SummaryWelcomeBonus from "./summaryWelcomeBonus/summaryWelcomeBonus.component";

export default function Summary() {
  const dispatch = useDispatch();

  useSummaryAnalyticsTrackView();

  const handleSignupClick = () => {
    dispatch(
      utagLink({
        event_type: "cta_registrazione",
        cta_label: "Registrati",
      }),
    );
  };

  return (
    <div className="summary-container">
      <SummaryTextBlock />
      <SummaryPersonalData />
      <SummaryAddress />
      <SummaryContacts />
      <SummaryAccessData />
      <SummaryDocument />
      <SummaryWelcomeBonus />
      <SummaryRechargeLimits />
      <SummaryRecaptcha />
      <div className="summary-sign-up-button-container">
        <SignButton onClick={handleSignupClick} />
      </div>
      <PreFooter />
    </div>
  );
}
