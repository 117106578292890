import { connect } from "react-redux";
import Identification from "./identification.component";
import * as formStatusAction from "../../store/formStatus/formStatus.action";
import * as formDataAction from "../../store/formData/formData.action";
import { getDocumentTypeAPI } from "../../store/documentType/documentType.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { documentTypeSelectors } from "../../store/documentType/documentType.selectors";
import {
  stepNext,
  stepBack,
  disabledBtn,
} from "../../store/wizard/wizard.action";
import {
  wizardSelectorsBtn,
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { utagView, utagLink } from "../../store/utagStore/utagStore.action";

const mapStateToProps = (state) => {
  return {
    formStatus: formStatusSelectors.getFormStatus(state),
    formData: formDataSelectors.getFormData(state),
    btnDisabled: wizardSelectorsBtn.getWizardBtn(state),
    documentType: documentTypeSelectors.getDocumentType(state),
    component: wizardSelectorsComponent.getComponent(state),
    flusso: wizardSelectorsFlusso.getFlusso(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    stepNext: (step) => dispatch(stepNext(step)),
    stepBack: (step) => dispatch(stepBack(step)),
    disabledBtn: (status) => dispatch(disabledBtn(status)),
    getDocumentTypeAPI: (channel, history) =>
      getDocumentTypeAPI(dispatch, channel, history),
    utagView: (data, options) => {
      dispatch(utagView(data, options));
    },
    utagLink: (data) => dispatch(utagLink(data)),
  };
};

const IdentificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Identification);
export default IdentificationContainer;
