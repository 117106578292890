import { ReactComponent as ErrorCircle } from "../../../images/svg/errorCard/errorCircle.svg";

export default function ErrorCard({ title, text, actions, note }) {
  return (
    <div className="error-card">
      <div className="error-card__img">
        <ErrorCircle />
      </div>

      <div className="error-card__text">
        <h2 className="error-card__title">{title}</h2>

        <p className="error-card__description">{text}</p>
      </div>

      <div className="error-card__actions">{actions}</div>

      {note}
    </div>
  );
}
