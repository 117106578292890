import React, { useState, useEffect } from "react";
import { Translate } from "react-localize-redux";
import MessageError from "../elementForm/messageError/messageError.component";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import { postOcrData } from "../../services/postOcrData";
import moment from "moment";
import LoadingGif from "../elementForm/loadingGif/loadingGif.container";
import Upload from "../../images/svg/icon/upload_dark.svg";

const Document = (props) => {
  const { formData, formStatus, step, addFormStatus, addFormData, component } =
    props;
  let [frontFileNameSize, setFrontFileNameSize] = useState("");
  let [backFileNameSize, setBackFileNameSize] = useState("");
  let [frontFilePreview, setFrontFilePreview] = useState("");
  let [backFilePreview, setBackFilePreview] = useState("");
  const [statusForm, setStatusForm] = useState(true);

  const uploadFile = (params, name, isOnChange) => {
    let file = params;
    if (isOnChange) {
      file = params[0];
    }

    if (file.length !== 0) {
      let estensione = file.name.split(".")[file.name.split(".").length - 1];
      estensione = estensione.toLowerCase();

      props.addFormData({ hasDocFile: false, uploadDocsId: "" });
      if ((file.size / 1000000).toFixed(2) < 30) {
        if (
          estensione !== "jpg" &&
          estensione !== "png" &&
          estensione !== "jpeg" &&
          estensione !== "pdf"
        ) {
          props.addFormStatus({ [name]: "err.wrongFileExt" });
          props.addFormData({ [name]: "" });
          reset(name);
        } else {
          addFormData({ [name]: file });
          addFormStatus({ [name]: "" });
          if (isOnChange) {
            if (name === "frontFileName") {
              props.utagLink({
                reg_event: "upload_step_2",
                reg_campo: formData.documentTypeObj
                  ? formData.documentTypeObj.description
                  : "",
              });
            } else {
              props.utagLink({
                reg_event: "upload_step_3",
                reg_campo: formData.documentTypeObj
                  ? formData.documentTypeObj.description
                  : "",
              });
            }
            if (component.name === "summary") {
              props.utagLink({
                reg_event: "riepilogo_modifica",
                reg_campo: name,
              });
            }
          }
          conversion(file, name);
          if (formData.frontFileName !== "") {
            ocrStart();
          } else if (
            formData.frontFileName !== "" &&
            formData.backFileName !== ""
          ) {
            ocrStart();
          } else if (formData.backFileName !== "") {
            ocrStart();
          }
        }
      } else {
        props.addFormStatus({ [name]: "err.bigFileSize" });
        props.addFormData({ [name]: "" });
        reset(name);
      }
    }
    setStatusForm(checkingForm("document"));
  };

  const conversion = (file, name) => {
    if (name === "frontFileName") {
      (file.size / 1000000).toFixed(1) > 0.1
        ? setFrontFileNameSize((file.size / 1000000).toFixed(1) + " Mb")
        : setFrontFileNameSize((file.size / 1000).toFixed(1) + " kb");
      setFrontFilePreview(URL.createObjectURL(file));
    } else {
      (file.size / 1000000).toFixed(1) > 0.1
        ? setBackFileNameSize((file.size / 1000000).toFixed(1) + " Mb")
        : setBackFileNameSize((file.size / 1000).toFixed(1) + " kb");
      setBackFilePreview(URL.createObjectURL(file));
    }
  };

  const ocrStart = () => {
    props.addFormStatus({ frontFileName: "" });
    props.addFormStatus({ backFileName: "" });
    props.addFormData({ isLoadingOcr: true });
    props.addFormData({ hasDocFile: true });
    if (formData.releasedByEntityObj === null) {
      props.addFormData({
        releasedByEntityObj:
          formData.documentTypeObj != null
            ? formData.documentTypeObj.availableIssuers[0]
            : undefined,
      });
      props.addFormData({
        releasedByEntity:
          formData.documentTypeObj != null
            ? formData.documentTypeObj.availableIssuers[0].id
            : undefined,
      });
    }
    //postOcrDataApi(formData.documentTypeObj.id, formData.fiscalCode, formData.frontFileName, formData.backFileName, formData.channel) //TODO
  };

  async function postOcrDataApi(docType, fiscalCode, front, back, channel) {
    if (component.name !== "summary")
      props.addFormData({
        number: "",
        releaseDate: "",
        expirationDate: "",
        documentCityFiscalCode: "",
        documentProvinceCode: "",
        documentTown: "",
        uploadDocsId: "",
      });
    await postOcrData(docType, fiscalCode, front, back, channel)
      .then((data) => {
        props.addFormData({ uploadDocsId: data.processId });
        if (component.name !== "summary") {
          if (data.ocr && data.documentData) {
            let dataRilascio = data.documentData.dataRilascio || "";
            let dataScadenza = data.documentData.dataScadenza || "";
            props.addFormData({
              number: data.documentData.numeroDocumento || "",
              releaseDate:
                dataRilascio !== ""
                  ? moment(dataRilascio, "DD/MM/YYYY").format(
                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
                    )
                  : "",
              expirationDate:
                dataScadenza !== ""
                  ? moment(dataScadenza, "DD/MM/YYYY").format(
                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
                    )
                  : "",
              documentTown: (
                data.documentData.enteRilascio || ""
              ).toUpperCase(),
            });

            if (data.documentData.numeroDocumento !== "")
              props.addFormStatus({ number: "" });
            if (dataRilascio !== "") props.addFormStatus({ releaseDate: "" });
            if (dataScadenza !== "")
              props.addFormStatus({ expirationDate: "" });
          }

          props.addFormData({ ocrData: data.ocr });
        }
        setTimeout(() => props.addFormData({ isLoadingOcr: false }), 11000);
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          props.addFormData({ uploadDocsId: "" });
          props.addFormData({ hasDocFile: false });
          props.addFormData({ frontFileName: "" });
          props.addFormData({ backFileName: "" });
          setBackFilePreview("");
          setFrontFilePreview("");
          setBackFileNameSize("");
          setFrontFileNameSize("");
          props.addFormStatus({ visualError: true });
          props.addFormData({ errorPageText: err.message });
          setTimeout(props.addFormData({ isLoadingOcr: false }), 11000);
        } else if (err.response && err.response.status === 422) {
          let errors = {};
          errors.ocr = "err.ocr.genericError";
          props.addFormStatus(errors);
          setTimeout(props.addFormData({ isLoadingOcr: false }), 11000);
        } else if (err.response && err.response.status === 413) {
          props.addFormData({ uploadDocsId: "" });
          props.addFormData({ hasDocFile: false });
          props.addFormData({ frontFileName: "" });
          props.addFormData({ backFileName: "" });
          setBackFilePreview("");
          setFrontFilePreview("");
          setBackFileNameSize("");
          setFrontFileNameSize("");
          props.addFormStatus({ visualError: true });
          props.addFormData({ errorPageText: err.message });
          setTimeout(props.addFormData({ isLoadingOcr: false }), 11000);
        } else {
          props.addFormData({ uploadDocsId: "" });
          props.addFormData({ hasDocFile: false });
          props.addFormData({ frontFileName: "" });
          props.addFormData({ backFileName: "" });
          setBackFilePreview("");
          setFrontFilePreview("");
          setBackFileNameSize("");
          setFrontFileNameSize("");
          props.addFormStatus({ visualError: true });
          props.addFormData({ errorPageText: err.message });
          setTimeout(props.addFormData({ isLoadingOcr: false }), 11000);
        }
      });
  }

  const reset = (name) => {
    props.addFormData({ [name]: "" });
    if (name === "frontFileName") {
      setFrontFileNameSize("");
      setFrontFilePreview("");
      if (formData.frontFileName === "" && formData.backFileName === "") {
        props.disabledBtn(true);
        props.addFormData({ hasDocFile: false });
        props.addFormData({ uploadDocsId: "" });
      }
    } else if (name === "backFileName") {
      setBackFileNameSize("");
      setBackFilePreview("");
      if (formData.frontFileName === "" && formData.backFileName === "") {
        props.disabledBtn(true);
        props.addFormData({ hasDocFile: false });
        props.addFormData({ uploadDocsId: "" });
      }
    }

    setStatusForm(checkingForm("document"));
  };

  useEffect(() => {
    props.disabledBtn(statusForm);
    if (formData.frontFileName !== "" || formData.backFileName !== "") {
      props.disabledBtn(false);
    }
  }, [statusForm]);

  useEffect(() => {
    if (formData.frontFileName !== "") {
      conversion(formData.frontFileName, "frontFileName");
    }
    if (formData.backFileName !== "") {
      conversion(formData.backFileName, "backFileName");
    }
    setStatusForm(checkingForm("document"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      formData.frontFileName !== "" &&
      formData.uploadDocsId === "" &&
      formData.backFileName !== ""
    ) {
      uploadFile(formData.backFileName, "backFileName", false);
      postOcrDataApi(
        formData.documentTypeObj.id,
        formData.fiscalCode,
        formData.frontFileName,
        formData.backFileName,
        formData.channel,
      );
      // }
    }
  }, [formData.backFileName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formData.backFileName !== "" && formData.uploadDocsId === "") {
      uploadFile(formData.frontFileName, "frontFileName", false);
      postOcrDataApi(
        formData.documentTypeObj.id,
        formData.fiscalCode,
        formData.frontFileName,
        formData.backFileName,
        formData.channel,
      );
      //}
    } else if (
      formData.frontFileName &&
      formData.frontFileName.name &&
      formData.uploadDocsId === ""
    ) {
      //all'avvio mi chiama upload in modo da avere subito la chiamata
      uploadFile(formData.frontFileName, "frontFileName", false);
      postOcrDataApi(
        formData.documentTypeObj.id,
        formData.fiscalCode,
        formData.frontFileName,
        formData.backFileName,
        formData.channel,
      );
      // }
    }
  }, [formData.frontFileName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="document container" id="it-ar-document-container">
        {formStatus.visualError ? (
          <LoadingGif
            step={step}
            hasTimeout={true}
            addFormData={addFormData}
            component={component}
            addFormStatus={addFormStatus}
            utagLink={props.utagLink}
          />
        ) : formData.isLoadingOcr ? (
          <LoadingGif
            step={step}
            hasTimeout={false}
            addFormData={addFormData}
            component={component}
            addFormStatus={addFormStatus}
            utagLink={props.utagLink}
          />
        ) : (
          <div className={"row"}>
            <div
              className={
                "col-12" +
                (component.name !== "summary" && !props.hideTitle
                  ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
                  : "")
              }
            >
              {props.hideTitle ? null : (
                <div className="header-form" id="it-ar-document-header-form">
                  <h2
                    className="sub-title-form"
                    id="it-ar-document-sub-title-form"
                  >
                    <Translate id="text.documentTypeSelect" />
                  </h2>
                  <p
                    className="label-form margin-bot-standard"
                    id="it-ar-document-label-form"
                  >
                    <Translate id="text.rememberBackFrontDocument" />
                  </p>
                </div>
              )}
              <div className="row">
                <div
                  id="it-ar-document-col-summary-input1"
                  className={
                    (component.name === "summary"
                      ? "col-12 summary-input"
                      : "col-12" + (props.hideTitle ? " p-0" : "")) +
                    (component.name === "summary" ? " col-md-6" : "")
                  }
                >
                  <div
                    id="it-ar-document-form-group-upload1"
                    className={
                      "form-group mb-0" +
                      (formData.frontFileName === ""
                        ? ""
                        : " form-group-upload-full") +
                      (formStatus.frontFileName.length > 0
                        ? formStatus.frontFileName === "err.required"
                          ? ""
                          : " is-invalid"
                        : "")
                    }
                  >
                    <div
                      className={
                        "input-group-upload " +
                        (frontFilePreview !== "" ? "uploaded-image" : "")
                      }
                      id="it-ar-document-input-group-upload1"
                    >
                      <div
                        className={"custom-file"}
                        id="it-ar-document-custom-file1"
                      >
                        <input
                          type="file"
                          name="frontFileName"
                          id="it-ar-document-frontFileName"
                          key={Date.now()}
                          className="custom-file-input"
                          onChange={(e) =>
                            uploadFile(e.target.files, e.target.name, true)
                          }
                          accept="application/pdf,image/jpeg,image/png" //reference task:DMC-5086
                        />
                        <div
                          id="it-ar-document-document-icon1"
                          className={
                            "icon" +
                            (frontFilePreview === ""
                              ? " upload-icon"
                              : " document-icon")
                          }
                        >
                          <img
                            id="it-ar-document-uploadfrontfilename"
                            src={
                              frontFilePreview !== ""
                                ? frontFilePreview
                                : Upload
                            }
                            alt=""
                          />
                        </div>
                        <div
                          className="upload-text-container"
                          id="it-ar-document-upload-text-container1"
                        >
                          <label
                            className={
                              "upload-text upload-text-title " +
                              (formData.frontFileName === ""
                                ? "uploadAnother"
                                : "")
                            }
                            id="it-ar-document-uploadanother1"
                          >
                            {formData.frontFileName === "" ? (
                              <Translate id="text.uploadAnother" />
                            ) : (
                              formData.frontFileName.name
                            )}
                          </label>
                          <label
                            className={
                              "upload-text upload-text-subtitle " +
                              (frontFileNameSize === "" ? "uploadFile" : "")
                            }
                            id="it-ar-document-uploadsizelimits1"
                          >
                            {frontFileNameSize === "" ? (
                              <Translate id="text.uploadSizeLimits" />
                            ) : (
                              frontFileNameSize
                            )}
                          </label>
                        </div>
                        <span
                          className={
                            (formData.frontFileName !== ""
                              ? "discard-icon"
                              : "d-none") +
                            (formStatus.frontFileName !== "" &&
                            formStatus.frontFileName !== "err.required"
                              ? " error-icon"
                              : "")
                          }
                          onClick={() => reset("frontFileName")}
                        />
                        {component.name === "summary" ? (
                          <label className="form-control-label isSpecial upload">
                            Fronte Documento
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <MessageError
                      currentValue={formStatus.frontFileName}
                      currentName={"frontFileName"}
                    />
                  </div>
                </div>

                {formData.documentTypeObj.id !== 14 ? (
                  <div
                    id="it-ar-document-col-summary-input2"
                    className={
                      (component.name === "summary"
                        ? "col-12 summary-input"
                        : "col-12" + (props.hideTitle ? " p-0" : "")) +
                      (component.name === "summary" ? " col-md-6" : "")
                    }
                  >
                    <div
                      id="it-ar-document-form-group-upload2"
                      className={
                        "form-group mb-0" +
                        (formData.backFileName === ""
                          ? ""
                          : " form-group-upload-full") +
                        (formStatus.backFileName.length > 0
                          ? formStatus.backFileName === "err.required"
                            ? ""
                            : " is-invalid"
                          : "")
                      }
                    >
                      <div
                        className={
                          "input-group-upload " +
                          (backFilePreview !== "" ? "uploaded-image" : "")
                        }
                        id="it-ar-document-input-group-upload2"
                      >
                        <div
                          className={"custom-file"}
                          id="it-ar-document-custom-file2"
                        >
                          <input
                            type="file"
                            name="backFileName"
                            id="it-ar-document-backFileName"
                            key={Date.now()}
                            className="custom-file-input"
                            onChange={(e) =>
                              uploadFile(e.target.files, e.target.name, true)
                            }
                            accept="application/pdf,image/jpeg,image/png" //reference task:DMC-5086
                          />
                          <div
                            id="it-ar-document-document-icon2"
                            className={
                              "icon" +
                              (backFilePreview === ""
                                ? " upload-icon "
                                : " document-icon")
                            }
                          >
                            <img
                              id="it-ar-document-uploadbackfilename"
                              src={
                                backFilePreview !== ""
                                  ? backFilePreview
                                  : Upload
                              }
                              alt=""
                            />
                          </div>
                          <div
                            className="upload-text-container"
                            id="it-ar-document-upload-text-container2"
                          >
                            <label
                              className={
                                "upload-text upload-text-title " +
                                (formData.backFileName === ""
                                  ? "uploadAnother"
                                  : "")
                              }
                              id="it-ar-document-uploadanother2"
                            >
                              {formData.backFileName === "" ? (
                                <Translate id="text.uploadAnother" />
                              ) : (
                                formData.backFileName.name
                              )}
                            </label>
                            <label
                              className={
                                "upload-text upload-text-subtitle " +
                                (backFileNameSize === "" ? "uploadFile" : "")
                              }
                              id="it-ar-document-uploadsizelimits2"
                            >
                              {backFileNameSize === "" ? (
                                <Translate id="text.uploadSizeLimits" />
                              ) : (
                                backFileNameSize
                              )}
                            </label>
                          </div>
                          <span
                            className={
                              (formData.backFileName !== ""
                                ? "discard-icon"
                                : "d-none") +
                              (formStatus.backFileName !== "" &&
                              formStatus.backFileName !== "err.required"
                                ? " error-icon"
                                : "")
                            }
                            onClick={() => reset("backFileName")}
                          />
                          {component.name === "summary" ? (
                            <label className="form-control-label isSpecial upload">
                              Retro Documento
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <MessageError
                        currentValue={formStatus.backFileName}
                        currentName={"backFileName"}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Document;
