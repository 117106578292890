import { useSelector } from "react-redux";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";
import { Translate } from "react-localize-redux";

export default function IdentificationHeaderForm() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  return (
    <div className="header-form" id="it-ar-identification-container">
      <h2
        className={`sub-title-form ${
          flusso === "SPID" ? "sub-title-form-spid" : ""
        }`}
        id="it-ar-identification-sub-title-form"
      >
        <Title />
      </h2>

      <p
        className="label-form margin-bot-standard"
        id="it-ar-identification-label-form"
      >
        <Translate id="text.subTitleDocument" />
      </p>
    </div>
  );
}

function Title() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  switch (flusso) {
    case "C1":
      return <Translate id="text.subTitleOcrEmpty" />;
    case "SPID":
      return <Translate id="text.subTitleOcr_SPID" />;
    case "PDV":
      return <Translate id="text.subTitleOcr_PDV" />;
    default:
      return null;
  }
}
