import { useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as PersonalData } from "../../../images/svg/summary/personal-data.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { useSummaryAnalyticsTrackLinks } from "../summary.hooks";
import SummaryInfoBlock from "../summaryInfoBlock/summaryInfoBlock.component";
import { personalDataLinkTranslateId } from "./summaryPersonalData.config";
import SummaryPersonalDataPopup from "./summaryPersonalDataPopup.component";

export default function SummaryPersonalData() {
  const [showPopup, setShowPopup] = useState(false);

  const { name, surname, fiscalCode } = useSelector(
    formDataSelectors.getFormData,
  );

  const { trackOpenPopup } = useSummaryAnalyticsTrackLinks();

  const description = `${name} ${surname}, ${fiscalCode}`;

  const handleOpenPopup = () => {
    trackOpenPopup(personalDataLinkTranslateId);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <SummaryInfoBlock
        icon={<PersonalData />}
        title={<Translate id={personalDataLinkTranslateId} />}
        description={description}
        onClick={handleOpenPopup}
      />

      <SummaryPersonalDataPopup show={showPopup} onClose={handleClosePopup} />
    </>
  );
}
