import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFlow } from "../../../hooks/useFlow";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { wizardSelectorsFlow } from "../../../store/wizard/wizard.selectors";
import { getFlow } from "../../../utility/wizardConfig/wizardConfig";
import RegistrationOCRSelectionCard from "../registrationOCRSelectionCard/registrationOCRSelectionCard.component";

export default function ManualRegistrationCard() {
  const history = useHistory();

  const dispatch = useDispatch();

  const defaultClassicFlowName = useSelector(
    wizardSelectorsFlow.getWizardDefaultClassicFlowName,
  );

  const { goToSpecificStep } = useFlow();

  const handleClick = async () => {
    dispatch(
      utagLink({
        reg_event: "inserimento-dati",
        conversion_pagename: "step_0",
        reg_cta: "inserisci i dati manualmente",
      }),
    );

    await getFlow(defaultClassicFlowName, null, history);

    // skip page "inizio"
    goToSpecificStep("/nome-cognome");
  };

  return (
    <RegistrationOCRSelectionCard
      title={<Translate id="text.registrationOCR.insertDataManually" />}
      description={
        <Translate id="text.registrationOCR.insertDataManuallyDescription" />
      }
      onClick={handleClick}
    />
  );
}
