import store from "../../store.config";
import { documentTypeSelectors } from "../../store/documentType/documentType.selectors";
import { SpidErrors } from "./constants";

function getDocumentTypeObj(state, documentTypeId) {
  return documentTypeSelectors
    .getDocumentType(state)
    .documentType.find((value) => {
      const valueInt = parseInt(value.id);
      const documentTypeIdInt = parseInt(documentTypeId);
      return (
        Number.isInteger(valueInt) &&
        Number.isInteger(documentTypeIdInt) &&
        valueInt === documentTypeIdInt
      );
    });
}

function getCityId(cities, city) {
  if (city == null) {
    return null;
  }
  const foundCity = cities.find(
    ({ description }) =>
      description.toLowerCase().trim() === city.toLowerCase().trim(),
  );
  return foundCity?.id ?? null;
}

function addressWithMaxLength(address) {
  return address != null ? address.substring(0, 35) : address;
}

export function isNumberCertified(spidResponse) {
  return (
    !spidResponse.errors ||
    !spidResponse.errors.some(
      (e) =>
        e.code === SpidErrors.errPhoneNumberAlreadyPresent ||
        e.code === SpidErrors.errPhoneNumberNoItalianPrefix,
    )
  );
}

export const spidDataToFormData = (spidData, cities) => {
  const state = store.getState();

  return {
    name: spidData.name,
    surname: spidData.familyName,
    birthId: spidData.birthId || getCityId(cities, spidData.placeOfBirth),
    birthTown: spidData.birthTown,
    birthProvince: spidData.birthProvince,
    birthCityFiscalCode: spidData.birthCityFiscalCode,
    birthDate: spidData.birthDate,
    birthState: spidData.birthState,
    gender: spidData.gender,
    fiscalCode: spidData.fiscalNumber,
    phoneNumber: spidData.mobilePhone,
    phoneNumberCertified: isNumberCertified(spidData),
    email: spidData.email,
    address: addressWithMaxLength(spidData.address),
    phonePrefix: spidData.phonePrefix,
    domicileState: spidData.domicileState,
    state: spidData.state,
    province: spidData.province || "",
    city: spidData.city || "",
    cityFiscalCode: spidData.cityFiscalCode || "",
    cap: spidData.cap || "",
    domicileProvince: spidData.domicileProvince || "",
    domicileCity: spidData.domicileCity || "",
    domicileCityFiscalCode: spidData.domicileCityFiscalCode || "",
    domicileCap: spidData.domicileCap || "",
    documentType: spidData.documentType,
    documentId: getCityId(cities, spidData.documentTown),
    documentTypeObj: getDocumentTypeObj(state, spidData.documentType),
    number: spidData.number,
    releaseDate: spidData.releaseDate,
    expirationDate: spidData.expirationDate,
    releasedByEntity: spidData.releasedByEntity,
    documentTown: spidData.documentTown,
    documentProvinceCode: spidData.documentProvinceCode,
    documentCityFiscalCode: spidData.documentCityFiscalCode,
    spidProcess: spidData.spidProcess,
  };
};
