import { Translate } from "react-localize-redux";
import { ReactComponent as PersonalData } from "../../../images/svg/summary/personal-data.svg";
import FiscalCode from "../../fiscalCode/fiscalCode.component";
import NameInputField from "../../inputFields/nameInputField/nameInputField.component";
import SurnameInputField from "../../inputFields/surnameInputField/surnameInputField.component";
import OcrSummaryInfoBlock from "../ocrSummaryInfoBlock/ocrSummaryInfoBlock.component";
import OcrSummarySection from "../ocrSummarySection/ocrSummarySection.component";
import { useDispatch, useSelector } from "react-redux";
import { utagView } from "../../../store/utagStore/utagStore.action";
import { useRegDoc, useRegFlusso } from "../../../hooks/useAnalytics";
import { useEffect, useRef } from "react";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";

export default function OcrSummaryPersonalData() {
  return (
    <OcrSummarySection
      className="ocr-summary-personal-data"
      infoBlock={
        <OcrSummaryInfoBlock
          title={<Translate id="lbl.personalData" />}
          icon={<PersonalData />}
        />
      }
    >
      <NameInputField />

      <SurnameInputField />

      <OcrFiscalCode />
    </OcrSummarySection>
  );
}

function OcrFiscalCode() {
  const dispatch = useDispatch();

  /** @type {React.RefObject<HTMLInputElement>} */
  const ref = useRef(null);

  const reg_doc = useRegDoc();

  const reg_flusso = useRegFlusso();

  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  useEffect(() => {
    if (!ref.current?.value) {
      ref.current.focus();
    }
  }, []);

  const handleOpenCalcFiscalCodePopup = () => {
    const stepPrefix = flusso === "OCRPDV" ? "registrazione:pdv ocr" : "ocr";

    dispatch(
      utagView({
        reg_step: `${stepPrefix}:calcolo cf`,
        conversion_pagename: `${stepPrefix}:calcolo cf`,
        reg_doc,
        country: "italia",
        reg_flusso,
      }),
    );
  };

  return (
    <FiscalCode
      ref={ref}
      onOpenCalcFiscalCodePopup={handleOpenCalcFiscalCodePopup}
    />
  );
}
