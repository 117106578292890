import { useEffect, useState } from "react";

export default function FlagImg({ country, ...rest }) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const setSvg = async () => {
      const importedIcon = await import(
        `../../images/svg/flagNation/${country}.svg`
      );
      setSrc(importedIcon.default);
    };

    if (country) {
      setSvg();
    }
  }, [country]);

  return <img src={src} alt={country} {...rest} />;
}
