import { connect } from "react-redux";
import { documentTypeSelectors } from "../../store/documentType/documentType.selectors";
import * as formDataAction from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import * as formStatusAction from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { addSpidStatus } from "../../store/spidStatus/spidStatus.action";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import OcrForm from "./ocrForm.component";

const mapStateToProps = (state) => {
  return {
    formStatus: formStatusSelectors.getFormStatus(state),
    formData: formDataSelectors.getFormData(state),
    documentType: documentTypeSelectors.getDocumentType(state),
    component: wizardSelectorsComponent.getComponent(state),
    flusso: wizardSelectorsFlusso.getFlusso(state),
    fromSpidFirstLandingDocument:
      spidStatusSelectors.isFromSpidFirstLandingDocument(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    disabledBtn: (status) => dispatch(disabledBtn(status)),
    utagView: (data) => dispatch(utagView(data)),
    utagLink: (data, options) => dispatch(utagLink(data, options)),
    addSpidStatus: (data) => dispatch(addSpidStatus(data)),
  };
};

const OcrFormContainer = connect(mapStateToProps, mapDispatchToProps)(OcrForm);
export default OcrFormContainer;
