export default function BaseErrorMessage({
  containerId,
  containerClassName,
  message,
}) {
  return (
    <div className="invalid-feedback">
      <div id={containerId} className={containerClassName}>
        {message}
      </div>
    </div>
  );
}
