function getOcrState(state) {
  return state.ocrReducer;
}

export function selectJumioAccountId(state) {
  return getOcrState(state).jumioAccountId;
}

export function selectJumioSdkToken(state) {
  return getOcrState(state).jumioSdkToken;
}
