import moment from "moment";
import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRegistrationSelectionNavigation } from "../../hooks/useFlow";
import { generateFiscalCode } from "../../services/generateFiscalCode";
import { validateFiscalCode } from "../../services/validatorController";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addSpidFiscalCodeFlowStatus } from "../../store/spidStatus/spidStatus.action";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import { getFiscalCodeError } from "../../utility/formIsValid/formIsValid.container";
import { isIOS } from "../../utility/mobileDetection";
import { getRecaptchaToken } from "../../utility/recaptcha";
import Background from "../commons/background/background.component";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";
import Header from "../header/header.component";
import PrivacyDisclaimer from "../privacyDisclaimer/privacyDisclaimer.component";
import ProhibitedToMinorsBanner from "../prohibitedToMinorsBanner/prohibitedToMinorsBanner.component";
import SpidFiscalCodeForm from "./spiFiscalCodeForm/spidFiscalCodeForm.component";
import {
  SpidFCFlowErrorsWithMessage,
  spidFCFlowCleanFormDataObject,
  spidFCFlowErrorsWhoPass,
} from "./spidFiscalCode.helpers";

export default function SpidFiscalCode() {
  const dispatch = useDispatch();

  const history = useHistory();

  const formData = useSelector(formDataSelectors.getFormData);
  const spidFiscalCodeFlowStatus = useSelector(
    spidStatusSelectors.getSpidFiscalCodeFlowStatus,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const { goToRegistrationSelection } = useRegistrationSelectionNavigation();

  const isAppIOS = isIOS() && formData.channel !== "62";

  const generateFiscalCodeApi = async () => {
    try {
      const data = await generateFiscalCode(
        moment(formData.birthDate).format("DD/MM/YYYY"),
        formData.birthCityFiscalCode,
        formData.birthState.fiscalCode,
        formData.gender,
        formData.name,
        formData.surname,
        formData.channel,
      );

      handleChange(data.fiscalCode);

      dispatch(addFormData(spidFCFlowCleanFormDataObject));
    } catch (err) {
      if (err.response?.status !== 422) {
        console.error(err);
        history.push("/errore-registrazione");
      }
    }
  };

  const validateFiscalCodeApi = async (fc) => {
    setIsLoading(true);

    const recaptchaToken = await getRecaptchaToken("VERIFY_FISCALCODE");

    try {
      await validateFiscalCode(
        fc,
        formData.channel,
        formData.ipAddress,
        undefined,
        recaptchaToken,
      );

      setError("");
    } catch (err) {
      if (!(err.response && err.response.status === 422)) {
        history.push("/errore-registrazione");
        return;
      }

      const nameError = Object.keys(err.response.data.error.errorInfos)[0];
      const errorMessage = err.response.data.error.errorInfos[nameError][0];

      if (Object.keys(SpidFCFlowErrorsWithMessage).includes(errorMessage)) {
        setError(errorMessage);
        return;
      }

      setError("");

      if (!spidFCFlowErrorsWhoPass.includes(errorMessage)) {
        dispatch(addSpidFiscalCodeFlowStatus({ error: errorMessage }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (value) => {
    if (/[^a-zA-Z0-9]/.test(value)) return;
    if (value.length > 16) return;

    setInputValue(value);

    if (value.length < 16) {
      setError(SpidFCFlowErrorsWithMessage.errFiscalCodeLength);
      return;
    }

    const frontEndError = getFiscalCodeError(value);

    if (frontEndError) {
      setError(frontEndError);
    } else {
      validateFiscalCodeApi(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      utagLink({
        reg_cta: "continua con CF",
        reg_flusso: "spid",
        reg_event: "continua-codice-fiscale",
      }),
    );

    dispatch(addSpidFiscalCodeFlowStatus({ showGenerationStep: false }));
    history.push(
      spidFiscalCodeFlowStatus.error.length ? "/errore" : "/registrazione-spid",
    );
  };

  const handleSkipCheckClick = () => {
    dispatch(
      utagLink(
        {
          reg_cta: "continua senza codice fiscale",
          reg_flusso: "spid",
          reg_event: "codice-fiscale-facoltativo",
        },
        false,
      ),
    );

    history.push("/registrazione-spid");
  };

  useEffect(() => {
    dispatch(
      utagView({
        reg_section: "registrazione-spid",
        reg_step: "codice-fiscale",
        reg_flusso: "spid",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      !(checkingForm("personaData") || checkingForm("gender&birthplace")) &&
      spidFiscalCodeFlowStatus.showGenerationStep
    ) {
      generateFiscalCodeApi();
    } else {
      dispatch(addFormData(spidFCFlowCleanFormDataObject));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callback = () => {
      dispatch(addSpidFiscalCodeFlowStatus({ showGenerationStep: false }));

      goToRegistrationSelection();
    };

    window.addEventListener("popstate", callback);
    return () => {
      window.removeEventListener("popstate", callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="spid-fc">
      {isLoading && <FullPageLoader />}

      <Background showOnMobile />

      <Header />

      <div className="spid-fc-container">
        <div className="spid-fc-card">
          <h3 className="spid-fc-card-title">
            <Translate id="text.spid.card.cf.title" />
          </h3>

          <p className="spid-fc-card-text">
            <Translate id="text.spid.card.cf.body" />
          </p>

          <SpidFiscalCodeForm
            handleSubmit={handleSubmit}
            inputValue={inputValue}
            error={error}
            handleChange={handleChange}
          />

          <button
            className="spid-fc-card-skip-fc-check-button"
            onClick={handleSkipCheckClick}
          >
            <Translate id="text.spid.card.cf.skip" />
          </button>
        </div>

        <SpidPrivacyDisclaimer />
      </div>

      <div
        className={`spid-fc-footer ${
          isAppIOS ? "spid-fc-footer--app-ios" : ""
        }`}
      >
        <ProhibitedToMinorsBanner />
      </div>
    </div>
  );
}

function SpidPrivacyDisclaimer() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      utagLink({
        reg_event: "spid-informativa-privacy",
        conversion_pagename: "codice-fiscale",
        reg_flusso: "spid",
        reg_cta: "informativa sulla privacy",
      }),
    );
  };

  return (
    <PrivacyDisclaimer
      onClick={handleClick}
      className="spid-fc-privacy-disclaimer"
    />
  );
}
