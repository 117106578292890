import {
  INCREMENT_PDV_KO_CHECK_CRENDENTIALS_COUNTER,
  INCREMENT_PHONE_NUMBER_ALREADY_PRESENT_COUNTER,
} from "./vivochaProactiveChat.action";

const initialState = {
  pdvKoCheckCrendentialsCounter: 0,
  phoneNumberAlreadyPresent: 0,
};

export const vivochaProactiveChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_PDV_KO_CHECK_CRENDENTIALS_COUNTER: {
      return {
        ...state,
        pdvKoCheckCrendentialsCounter: state.pdvKoCheckCrendentialsCounter + 1,
      };
    }
    case INCREMENT_PHONE_NUMBER_ALREADY_PRESENT_COUNTER: {
      return {
        ...state,
        phoneNumberAlreadyPresent: state.phoneNumberAlreadyPresent + 1,
      };
    }
    default:
      return state;
  }
};
