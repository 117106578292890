import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";

export default function PersonalDataHeaderForm() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const titleTranslateId =
    flusso === "PDV" ? "text.customerName" : "text.whatsyname";

  const subtitleTranslateId =
    flusso === "PDV"
      ? "text.subtitlePersonalDataPdv"
      : "text.subtitlePersonalData";

  return (
    <div className="header-form" id="it-ar-personalData-header-form">
      <h2 className="sub-title-form" id="it-ar-personalData-sub-title-form">
        <Translate id={titleTranslateId} />
      </h2>
      <p className="label-form margin-bot-standard">
        <Translate id={subtitleTranslateId} />
      </p>
    </div>
  );
}
