import React from "react";
import { Translate } from "react-localize-redux";
import logo from "../../images/svg/18logo.svg";

export default function ProhibitedToMinorsBanner() {
  return (
    <div className="prohibited-to-minors-banner">
      <img src={logo} alt="18logo" />
      <Translate id="text.gameIsProhibitedToMinors" />
    </div>
  );
}
