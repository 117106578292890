import { ReactComponent as Next } from "../../../images/svg/icon/next.svg";

export default function RegistrationOCRSelectionCard({
  primary,
  title,
  description,
  onClick,
}) {
  return (
    <button className="ocr-selection-card" onClick={onClick}>
      <h3 className="ocr-selection-card__title">{title}</h3>
      <div className="ocr-selection-card__description">{description}</div>
      <div
        className={`ocr-selection-card__icon ${
          primary ? "ocr-selection-card__icon--primary" : ""
        }`}
      >
        <div style={{ width: "14px", display: "flex", alignItems: "center" }}>
          <Next />
        </div>
      </div>
    </button>
  );
}
