import axios from "axios";
import { REACT_APP_URL } from "./config";
import store from "./store.config";
import * as wizardAction from "./store/wizard/wizard.action";
import * as formDataAction from "./store/formData/formData.action";
export const BASE_URL = REACT_APP_URL;

export const instanceGet = axios.create({});

export const instancePost = axios.create({
  headers: { "Content-Type": "application/json" },
});

instanceGet.interceptors.request.use(async (config) => {
  store.dispatch(wizardAction.setIsLoading(true));
  return config;
});

instanceGet.interceptors.response.use(async (config) => {
  store.dispatch(wizardAction.setIsLoading(false));
  return config;
});

instancePost.interceptors.request.use(async (config) => {
  store.dispatch(wizardAction.setIsLoading(true));
  return config;
});

instancePost.interceptors.response.use(async (config) => {
  store.dispatch(wizardAction.setIsLoading(false));
  return config;
});

instanceGet.interceptors.response.use(undefined, (err) => {
  store.dispatch(wizardAction.setIsLoading(false));
  store.dispatch(
    formDataAction.addFormData({ errorPageServices: err.toString() }),
  );
  return Promise.reject(err);
});

instancePost.interceptors.response.use(undefined, (err) => {
  store.dispatch(wizardAction.setIsLoading(false));
  store.dispatch(
    formDataAction.addFormData({ errorPageServices: err.toString() }),
  );
  return Promise.reject(err);
});
