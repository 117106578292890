export const FORM_DATA = "FORM_DATA";
export const RESET_FORM_DATA = "RESET_FORM_DATA";

export const addFormData = (state) => {
  return {
    type: FORM_DATA,
    formData: state,
  };
};

export const resetFormData = () => ({ type: RESET_FORM_DATA });
