import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export const getFollowUpData = async (registrationDataId) => {
  const queryParams = new URLSearchParams({ id: registrationDataId });
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/utils/getFollowUpData?${queryParams}`;

  const res = await instanceGet.get(url);
  return res.data;
};

export const sendFollowUpDataEmail = (data) => {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/utils/followUp`;

  return navigator.sendBeacon(url, JSON.stringify(data));
};
