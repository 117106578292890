import { useCallback, useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRegBonus, useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import { useRechargeLimitModalCleanup } from "../../hooks/useRechargeLimitModalCleanup";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { closeRechargeLimitApprovalPopup } from "../../store/rechargeLimit/rechargeLimit.action";
import { rechargeLimitSelectors } from "../../store/rechargeLimit/rechargeLimit.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { getRegFlusso } from "../../utility/analytics";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import SubNote from "../commons/subNote/subNote.component";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";
import { useRechargeLimitsFetch } from "./rechargeLimit.hooks";
import RechargeLimitEvaluationModal from "./rechargeLimitEvalutationModal/rechargeLimitEvaluationModal.component";
import RechargeLimitMessageError from "./rechargeLimitMessageError/rechargeLimitMessageError.component";

const rechargeAmounts = ["100", "200", "300", "500", "1000"];

const valueRecharge = [...rechargeAmounts, "Altro"];
const valueRechargeNumber = [...rechargeAmounts, ""];

export default function RechargeLimit({ hideUtag }) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const { goToNextStep } = useFlow();

  const [showError, setShowError] = useState(false);

  const [previousValue, setPreviousValue] = useState(formData.rechargeLimit);
  const [showInputField, setShowInputField] = useState(
    formData.rechargeLimit !== ""
      ? !valueRechargeNumber.includes(formData.rechargeLimit)
      : false,
  );

  const showApprovalPopup = useSelector(
    rechargeLimitSelectors.getShowApprovalPopupInRechargeLimitStep,
  );

  useRechargeLimitsFetch();
  useRechargeLimitModalCleanup();

  const isLoading = useSelector(
    rechargeLimitSelectors.getRechargeLimitFetching,
  );

  const approvalAmount = useSelector(
    rechargeLimitSelectors.getRechargeLimitApprovalAmount,
  );
  const maxAmount = useSelector(
    rechargeLimitSelectors.getRechargeLimitMaxAmount,
  );

  const maxRechargeLimit = maxAmount / 100;
  const approvalRechargeLimit = approvalAmount / 100;

  const setValueRecharge = (index) => {
    const newValue = valueRechargeNumber[index];
    if (
      !isNaN(parseInt(newValue)) ||
      (valueRechargeNumber.includes(formData.rechargeLimit) &&
        formData.rechargeLimit !== "")
    ) {
      setShowError(false);
      dispatch(addFormData({ rechargeLimit: newValue }));
      dispatch(addFormStatus({ rechargeLimit: "" }));
    }
    setShowInputField(!newValue.length ? true : false);
    checkFlusso();

    if (!newValue.length) {
      dispatch(
        utagLink({
          reg_cta: "altro",
          reg_flusso: getRegFlusso(flusso, formData.fromPdv),
          reg_event: "registrazione|limite-ricarica|altro",
        }),
      );
    }
  };

  const handleChange = (value) => {
    if (/^[0-9.]*$/.test(value)) {
      var newArray = value.split(".").join("");
      var newValuePoint = "";
      for (let i = newArray.length; i > 0; i--) {
        if (i % 3 === 0) {
          newValuePoint += "." + newArray[newArray.length - i];
        } else {
          newValuePoint += newArray[newArray.length - i];
        }
      }
      if (newArray.length % 3 === 0) {
        newValuePoint = newValuePoint.slice(1);
      }
      if (newArray[0] === "0") {
        newValuePoint = newValuePoint.slice(1);
      }
      isValid(newValuePoint);
    }
  };

  const checkFlusso = useCallback(() => {
    dispatch(disabledBtn(checkingForm("rechargeLimit")));
  }, [dispatch]);

  const getError = (value) => {
    const limitStr = value.split(".").join("");
    const limit = parseInt(limitStr);

    if (limit < 10) {
      return "err.rechargeLimit.minNew";
    } else if (limit > maxRechargeLimit) {
      return "err.rechargeLimit.maxNew";
    } else {
      return "";
    }
  };

  const isValid = (value) => {
    dispatch(addFormData({ rechargeLimit: value }));
    const error = getError(value);
    dispatch(addFormStatus({ rechargeLimit: error }));
    checkFlusso();
  };

  const handleFocus = () => setShowError(true);

  const handleBlur = () => {
    setShowError(true);
    if (
      previousValue.rechargeLimit !== formData.rechargeLimit &&
      component.name === "summary"
    ) {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "rechargeLimit",
        }),
      );
    }
    setPreviousValue(formData.rechargeLimit);
  };

  const onKeyPressed = async (key) => {
    if (key === "Enter" && component.name !== "summary") {
      if (!checkingForm("termsAndConditionsRechargeLimit")) {
        goToNextStep();
      }
    }
  };

  const isButtonSelected = (indexButton) => {
    return valueRechargeNumber[indexButton] === ""
      ? showInputField
      : showInputField
        ? false
        : formData.rechargeLimit === valueRechargeNumber[indexButton];
  };

  const handleRechargeLimitApprovalConfirm = () => {
    dispatch(closeRechargeLimitApprovalPopup());
    goToNextStep();
  };

  const handleRechargeLimitApprovalCancel = () => {
    dispatch(
      addFormData({
        rechargeLimit: approvalRechargeLimit.toLocaleString("it-IT"),
      }),
    );
    dispatch(closeRechargeLimitApprovalPopup());
  };

  useAnalyticsUtagView(hideUtag);

  // Run validation on mount
  useEffect(() => {
    checkFlusso();
  }, [checkFlusso]);

  return (
    <div
      className="container step-container recharge-limit"
      id="it-ar-rechargelimit-container"
    >
      {isLoading && <FullPageLoader />}
      <div className={component.name !== "summary" ? "row" : undefined}>
        <div
          className={
            component.name !== "summary"
              ? "col-12 col-lg-6 offset-0 offset-lg-3 px-sm-0 "
              : undefined
          }
        >
          <form>
            <div className="header-form" id="it-ar-rechargelimit-header-form">
              <h2
                className="sub-title-form"
                id="it-ar-rechargelimit-sub-title-form"
              >
                <Translate id="text.subTextLimitRechargeWeekly" />
              </h2>
              <p
                className="label-form margin-bot-standard"
                id="it-ar-rechargelimit-label-form"
              >
                <Translate id="text.pLimitRechargeWeekly" />
              </p>
            </div>
          </form>

          <div
            id="it-ar-rechargelimit-distance-btn"
            className="recharge-buttons-grid"
          >
            {valueRecharge.map((value, index) => (
              <button
                key={value}
                type="button"
                className={`btn-recharge p-md-2 ${
                  isButtonSelected(index) ? "btn-selected" : "btn-blank"
                }`}
                id={"btn_limite_ricarica_" + valueRechargeNumber[index]}
                onClick={() => setValueRecharge(index)}
              >
                {isNaN(parseInt(value)) ? value : value + "€"}
              </button>
            ))}
          </div>

          <div className="row" id="it-ar-rechargelimit-row1">
            {showInputField && (
              <div
                id="it-ar-rechargelimit-form-group1"
                className="form-group col-12"
              >
                <input
                  autoComplete="off"
                  autoFocus
                  className={
                    "form-control pl-md-4 setPadding" +
                    (formStatus.rechargeLimit.length > 0 && showError
                      ? formStatus.rechargeLimit === "err.required" &&
                        component.name !== "summary"
                        ? " is-present"
                        : " is-invalid"
                      : "")
                  }
                  required
                  type="tel"
                  name="limit"
                  id="it-ar-rechargelimit-limit"
                  value={formData.rechargeLimit}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => handleChange(e.target.value)}
                  onKeyDown={(e) => onKeyPressed(e.key)}
                />
                <label
                  className="form-control-label"
                  htmlFor="it-ar-rechargelimit-limit"
                >
                  <Translate id="test.insertAmount" />
                </label>

                <span
                  className={
                    "currency " +
                    (formData.rechargeLimit ? "" : "notSelectedYet")
                  }
                >
                  €
                </span>

                {showError && (
                  <RechargeLimitMessageError
                    formStatusError={formStatus.rechargeLimit}
                    maxRechargeLimit={maxRechargeLimit}
                  />
                )}
              </div>
            )}

            <div className="form-group col-12 recharge-limit-sub-note">
              <SubNote text="text.noteLimitRechargeWeekly" />
            </div>
          </div>
        </div>
      </div>

      <RechargeLimitEvaluationModal
        show={showApprovalPopup}
        onConfirm={handleRechargeLimitApprovalConfirm}
        onCancel={handleRechargeLimitApprovalCancel}
      />
    </div>
  );
}

function useAnalyticsUtagView(hideUtag) {
  const dispatch = useDispatch();

  const component = useSelector(wizardSelectorsComponent.getComponent);

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();
  const reg_doc = useRegDoc();

  useEffect(() => {
    if (hideUtag || component.name === "summary") return;

    dispatch(
      utagView({
        conversion_pagename: "limite-ricarica",
        reg_step: "limite-ricarica",
        ...(reg_bonus ? { reg_bonus } : {}),
        reg_flusso,
        country: "italia",
        reg_doc,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
