import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { useBeforeUnloadPopup } from "../../hooks/useBeforeUnloadPopup";
import { utagView } from "../../store/utagStore/utagStore.action";
import PreFooter from "../elementForm/preFooter/preFooter.component";
import ErrorCard from "../errorCard/errorCard.component";
import Header from "../header/header.component";
import FiscalCodeInputField from "../inputFields/fiscalCodeInputField/fiscalCodeInputField.component";
import NameInputField from "../inputFields/nameInputField/nameInputField.component";
import SurnameInputField from "../inputFields/surnameInputField/surnameInputField.component";
import ConfirmDataInstructionsBlock from "./confirmDataBlock/confirmDataBlock.component";
import UnmatchingPersonalDataBirthDate from "./unmatchingPersonalDataBirthDate/unmatchingPersonalDataBirthDate.component";
import UnmatchingPersonalDataBirthPlace from "./unmatchingPersonalDataBirthPlace/unmatchingPersonalDataBirthPlace.component";
import UnmatchingPersonalDataSex from "./unmatchingPersonalDataSex/unmatchingPersonalDataSex.component";
import UnmatchingPersonalDataSignupButton from "./unmatchingPersonalDataSignupButton/unmatchingPersonalDataSignupButton.component";

export default function UnmatchingPersonalDataPage() {
  useBeforeUnloadPopup();

  useAnalyticsUtagView();

  return (
    <div className="unmatching-personal-data">
      <Header />

      <main className="main-content">
        <ErrorCard
          className="error-card-unmatching-data"
          hideButton
          titleId="text.dnc.card.title"
          textId="text.fiscalCodeDataUnmatchingPersonalDataDescription"
        />

        <div className="fiscal-code-block">
          <h2 className="form-subtitle">
            <Translate id="text.confirmYourFiscalCode" />
          </h2>
        </div>

        <FiscalCodeInputField />

        <ConfirmDataInstructionsBlock />

        <NameInputField />

        <SurnameInputField />

        <UnmatchingPersonalDataBirthDate />

        <UnmatchingPersonalDataSex />

        <UnmatchingPersonalDataBirthPlace />

        <PreFooter />

        <UnmatchingPersonalDataSignupButton />
      </main>
    </div>
  );
}

function useAnalyticsUtagView() {
  const dispatch = useDispatch();

  const reg_flusso = useRegFlusso();

  const reg_doc = useRegDoc();

  useEffect(() => {
    dispatch(
      utagView({
        conversion_pagename: "dati-non-corrispondenti",
        reg_flusso,
        country: "italia",
        reg_doc,
      }),
    );
  }, [dispatch, reg_doc, reg_flusso]);
}
