import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";
import ErrorCard from "../errorCard/errorCard.component";
import { useInsertManuallyAction } from "../errorCardOcrPage.hooks";

export default function ErrorCardGeneric() {
  return (
    <ErrorCard
      title="Si è verificato un errore"
      text={
        <>
          Non è stato possibile completare la richiesta.{" "}
          <strong>Ti invitiamo a riprovare</strong>.
          <br />
          <br />
          Se il problema persiste, puoi inserire manualmente i tuoi dati
          personali.
        </>
      }
      actions={<ErrorCardGenericActions />}
    />
  );
}

function ErrorCardGenericActions() {
  const history = useHistory();
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const handleClickRetry = async () => {
    history.push(
      flusso === "OCRPDV"
        ? "/registrazione-semplificata-pdv"
        : "/seleziona-ocr",
    );
  };

  const handleClickInsertManually = useInsertManuallyAction();

  return (
    <>
      <button className="error-card__btn-primary" onClick={handleClickRetry}>
        Riprova
      </button>
      <button
        className="error-card__btn-secondary"
        onClick={handleClickInsertManually}
      >
        Inserisci manualmente i dati
      </button>
    </>
  );
}
