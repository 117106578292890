import { Translate } from "react-localize-redux";
import { useHistory, useParams } from "react-router-dom";
import BackBtn from "../../elementForm/backBtn/backBtn.component";
import SubmitBtn from "../../elementForm/submitBtn/submitBtn.component";

export default function DocumentSelectionNav({ disabled }) {
  const history = useHistory();

  const { regType } = useParams();

  const goBack = () => {
    history.push(
      regType === "pdv" ? "/registrazione-semplificata-pdv" : "/seleziona-ocr",
    );
  };

  return (
    <div className="document-selection-nav">
      <BackBtn onClick={goBack} />

      <SubmitBtn text={<Translate id="lbl.continuous" />} disabled={disabled} />
    </div>
  );
}
