import React from "react";
import ActiveAccount from "../../component/activeAccount/activeAccount.component";
import SecurityQuestion from "../../component/securityQuestion/securityQuestion.container";
import Document from "../../component/document/document.container";
import OcrForm from "../../component/ocrForm/ocrForm.container";
import Domicile from "../../component/domicile/domicile.component";
import FiscalCodeWizard from "../../component/fiscalCodeWizard/fiscalCodeWizard.component";
import PersonalData from "../../component/personalData/personalData.component";
import Birthplace from "../../component/birthplace/birthplace.component";
import Sex from "../../component/sex/sex.container";
import Residence from "../../component/residence/residence.component";
import Contacts from "../../component/contacts/contacts.component";
import UserData from "../../component/userData/userData.component";
import Identification from "../../component/identification/identification.container";
import WelcomeBonus from "../../component/welcomeBonus/welcomeBonus.component";
import RechargeLimit from "../../component/rechargeLimit/rechargeLimit.component";
import TermsAndConditions from "../../component/termsAndConditions/termsAndConditions.container";
import Summary from "../../component/summary/summary.component";
import Inizio from "../../component/inizio/inizio.component";
import store from "../../store.config";
import { getFlows } from "./../../services/flowController";
import { defaultFlow } from "./mocked-flow-default";
import {
  setFlusso,
  setFlow,
  setComponent,
} from "../../store/wizard/wizard.action";
import { readCookie } from "../cookie";
import { canRedirectAutomatically } from "../route";
import generateSpidFlow from "../spid/generateSpidFlow";
import OcrDataSummary from "../../component/ocrDataSummary/ocrDataSummary.component";

const stepJson = {
  ActiveAccount: <ActiveAccount key={0} />,
  Bonus: <WelcomeBonus key={1} />,
  PersonalData: <PersonalData key={2} />,
  FiscalCode: <FiscalCodeWizard key={3} />,
  Sex: <Sex key={4} />,
  Birthplace: <Birthplace key={5} />,
  Residence: <Residence key={6} />,
  Domicile: <Domicile key={7} />,
  Contacts: <Contacts key={8} />,
  UserData: <UserData key={9} />,
  SecurityQuestion: <SecurityQuestion key={10} />,
  Identification: <Identification key={11} />,
  Document: <Document key={12} />,
  OcrForm: <OcrForm key={13} />,
  RechargeLimit: <RechargeLimit key={14} />,
  TermsAndConditions: <TermsAndConditions key={15} />,
  Summary: <Summary key={16} />,
  Inizio: <Inizio key={17} />,
  OcrDataSummary: <OcrDataSummary key={18} />,
};
const generateSteps = (resp) => {
  if (!resp || !resp.status === "SUCCESS" || resp.flow.steps.length < 1) {
    return [];
  }
  return resp.flow.steps.map((step) => ({
    useOtp: resp.flow.useOtp ? resp.flow.useOtp : false,
    step: step.step,
    component: createArrayComponents(step.components),
    name: step.name,
    path: step.path,
    nextComponentName: step.nextComponentName,
    componentName: step.componentName,
    previousComponentName: step.previousComponentName
      ? step.previousComponentName
      : false,
    dependencies: step.dependencies ? step.dependencies : false,
    group: step.group ? step.group : "A",
    displayStep: step.displayStep ? step.displayStep : false,
    nextStep: step.nextStep ? step.nextStep : false,
    isLast: "isLast" in step ? step.isLast : null,
    useFollowUpStrategy: resp.flow.useFollowUpStrategy,
  }));
};

export const createArrayComponents = (components) => {
  if (!components || components.length < 1) {
    return null;
  } else if (components.length === 1) {
    return stepJson[components[0]];
  } else {
    return components.map((component, index) => {
      return React.cloneElement(stepJson[component], { hideUtag: index > 0 });
    });
  }
};

export function redirectToErrorPage(history) {
  if (!/(errore-registrazione)/.test(history.location.pathname)) {
    history.push("/errore-registrazione");
  }
}

export function getCurrentStoredFlowName() {
  const flowNameFromCookie = readCookie("FLOW");
  const flowName = flowNameFromCookie
    ? flowNameFromCookie
    : store.getState().wizard.flusso;
  return flowName != null ? flowName : null;
}

export const wizardConfReq = (channel, flowName) => {
  return getFlows(flowName ? flowName : getCurrentStoredFlowName(), channel)
    .then((data) => {
      const flow = data && Object.keys(data).length > 0 ? data : defaultFlow;
      store.dispatch(setFlusso(flow.flow.name));
      return generateSteps(flow);
    })
    .catch((err) => {
      store.dispatch(setFlusso(defaultFlow.flow.name));
      return generateSteps(defaultFlow);
    });
};

// get flow
const channel = store.getState().formDataReducer.formData.channel;

export async function getFlow(
  flowName,
  spidErrors,
  history,
  forceGoToFirstStep = false,
) {
  try {
    const wizard = await wizardConfReq(channel, flowName);

    const newFlow =
      flowName === "SPID" && spidErrors
        ? generateSpidFlow(spidErrors, wizard, {
            useOtp: wizard[0].step,
            useFollowUpStrategy: wizard[0].useFollowUpStrategy,
          })
        : wizard;

    store.dispatch(setFlow(newFlow));

    if (forceGoToFirstStep || canRedirectAutomatically()) {
      const pdv = store.getState().formDataReducer.formData.fromPdv;

      const pdvComponent = newFlow.find(
        (value) => value.path === "/registrazione-pdv",
      );

      const component = pdv ? pdvComponent : newFlow[0];
      const url = pdv ? pdvComponent.path : newFlow[0].path;

      store.dispatch(setComponent(component));
      history.push(url);
    }

    return newFlow;
  } catch (err) {
    console.error(err);
    redirectToErrorPage(history);
  }
}
