import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { utagLink } from "../../store/utagStore/utagStore.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { getRegFlusso } from "../../utility/analytics";
import LeaveConfirmPopup from "../leaveConfirmPopup/leaveConfirmPopup.component";

export default function SpidLeavingPopup({
  spidLeavingPopupIsOpen,
  setSpidLeavingPopupIsOpen,
}) {
  const dispatch = useDispatch();

  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const { fromPdv, cancelcallbackurl } = useSelector(
    formDataSelectors.getFormData,
  );

  useEffect(() => {
    if (spidLeavingPopupIsOpen && flusso === "SPID") {
      dispatch(
        utagLink({
          reg_flusso: getRegFlusso(flusso, fromPdv),
          reg_event: `registrazione|${component.path.substring(
            1,
          )}|overlay-anti-abbandono`,
        }),
      );
    }
  }, [component.path, dispatch, flusso, fromPdv, spidLeavingPopupIsOpen]);

  return (
    <LeaveConfirmPopup
      onClose={() => setSpidLeavingPopupIsOpen(false)}
      onClickLeave={() => {
        window.location.href = cancelcallbackurl;
      }}
      onClickContinue={() => {
        setSpidLeavingPopupIsOpen(false);
        utagLink({
          reg_cta: "continua",
          reg_flusso: getRegFlusso(flusso, fromPdv),
          reg_event: `registrazione|${component.path.substring(
            1,
          )}|overlay-anti-abbandono|continua`,
        });
      }}
      text={<Translate id="spid-leaving-text" />}
    />
  );
}
