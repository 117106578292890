import { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { usePdvFlowDetection } from "../../hooks/useFlow";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { addSpidStatus } from "../../store/spidStatus/spidStatus.action";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { getRegFlusso } from "../../utility/analytics";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import filter from "../../utility/filter";
import formIsValid from "../../utility/formIsValid/formIsValid.container";
import { moveCursorAtTheEnd } from "../../utility/input/input";
import MessageError from "../elementForm/messageError/messageError.component";
import Nation from "../elementForm/nation/nation.component";
import Town from "../elementForm/town/town.component";
import { useSummaryFormErrorsTracking } from "../summary/summary.hooks";
import ResidenceHeaderForm from "./residenceHeaderForm/residenceHeaderForm.component";

export default function Residence({ hideUtag }) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const [statusForm, setStatusForm] = useState(true);
  const indirizzoResEsteroRef = useRef();
  const indirizzoResRef = useRef();
  const capResEsteroRef = useRef();
  const capResRef = useRef();

  let [flag, setFlag] = useState({
    cap: false,
    capEstero: false,
    city: false,
    address: false,
    cityEstero: false,
    addressEstero: false,
  });
  let [previousValue, setPreviousValue] = useState({
    cap: formData.cap,
    capEstero: formData.capEstero,
    city: formData.city,
    address: formData.address,
    cityEstero: formData.cityEstero,
    addressEstero: formData.addressEstero,
  });

  useSummaryFormErrorsTracking("cap", flag.cap);
  useSummaryFormErrorsTracking("capEstero", flag.capEstero);
  useSummaryFormErrorsTracking("address", flag.address);
  useSummaryFormErrorsTracking("addressEstero", flag.addressEstero);

  const isPdvFlow = usePdvFlowDetection();

  const isValid = (name, value) => {
    dispatch(addFormData({ [name]: value }));
    if (!formData.sameResidenceAndDomicile) {
      setStatusForm(checkingForm("residence") || checkingForm("domicile"));
    } else {
      setStatusForm(checkingForm("residence"));
    }
  };

  const onChange = async (name, value) => {
    switch (name) {
      case "cap":
        let filterDataCap = filter(name, value, "regex");
        if (filterDataCap.boolean) {
          setFlag({ ...flag, cap: false });
          await isValid(name, filterDataCap.newValue);
          let error = formIsValid(name, filterDataCap.newValue); //TODO:gestire la doppia casistica
          dispatch(addFormStatus(error));
          setStatusForm(checkingForm("residence"));
          if (filterDataCap.newValue.length === 5) {
            setFlag({ ...flag, cap: true });
          }
        }
        break;
      case "address":
        setFlag({ ...flag, address: false });
        let filterDataAddress = filter(name, value, "regex");
        if (filterDataAddress.newValue.length > 50) {
          filterDataAddress.newValue = filterDataAddress.newValue.substring(
            0,
            50,
          );
        }
        if (filterDataAddress.boolean)
          if (/^\s+/.test(filterDataAddress.newValue)) {
            isValid(name, filterDataAddress.newValue.trim());
          } else isValid(name, filterDataAddress.newValue);
        break;
      default:
        return name;
    }
  };

  const onChangeEstero = async (name, value) => {
    let filterData = filter(name, value, "regex");
    if (name === "capEstero") {
      if (filterData.boolean) {
        setFlag({ ...flag, capEstero: false });
        await isValid(name, filterData.newValue);
        let error = formIsValid(name, filterData.newValue);
        dispatch(addFormStatus(error));
        if (filterData.newValue.length === 0)
          addFormStatusError("capEstero", "err.capEstero.length");
        else {
          setFlag({ ...flag, capEstero: true });
        }
      }
    } else {
      setFlag({ ...flag, [name]: false });
      let max = 50;
      if (filterData.newValue.length > max) {
        filterData.newValue = filterData.newValue.substring(0, max);
      }
      if (filterData.boolean) {
        if (/^\s+/.test(filterData.newValue)) {
          isValid(name, filterData.newValue.trim());
        } else isValid(name, filterData.newValue);
        setStatusForm(checkingForm("residence"));
        if (filterData.newValue.length >= 2) {
          addFormStatusError({ [name]: "" });
        } else {
          if (name === "cityEstero")
            addFormStatusError({ [name]: "err.cityEstero.bad" });
        }
      }
    }
    setStatusForm(checkingForm("residence"));
  };

  const inputTrimmer = (name, value) => {
    if (/\s+$/.test(value)) {
      dispatch(addFormData({ [name]: value.trim() }));
    } else {
      dispatch(addFormData({ [name]: value }));
    }
  };

  const handleKeyDownComrer = (event) => {
    if (event.key === "Enter") {
      indirizzoResEsteroRef.current.focus();
    }
  };

  const handleKeyDownIrer = (event) => {
    if (event.key === "Enter") {
      capResEsteroRef.current.focus();
    }
  };

  const handleKeyDownCaprer = (event) => {
    if (event.key === "Enter") {
      capResEsteroRef.current.blur();
    }
  };

  ///////////////////////////////////////////

  const handleKeyDownIrr = (event) => {
    if (event.key === "Enter") {
      capResRef.current.focus();
    }
  };

  const handleKeyDownCaprr = (event) => {
    if (event.key === "Enter") {
      capResRef.current.blur();
    }
  };

  const handleBlur = (name, value) => {
    let filterData = filter(name, value);
    if (name === "address" || name === "addressEstero" || name === "cityEstero")
      inputTrimmer(name, value);
    else {
      dispatch(addFormData({ [name]: filterData.newValue }));
    }

    if (
      previousValue[name] !== formData[name] &&
      component.name === "summary"
    ) {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: name,
        }),
      );
    }
    setPreviousValue({ ...previousValue, [name]: formData[name] });

    switch (name) {
      case "cap":
        if (filterData.newValue.length === 0) {
          addFormStatusError("cap", "err.required");
        }
        setFlag({ ...flag, cap: true });
        break;
      case "cityEstero":
        filterData.newValue.length >= 2
          ? addFormStatusError("cityEstero", "")
          : filterData.newValue.length === 0
            ? addFormStatusError("cityEstero", "err.required")
            : addFormStatusError("cityEstero", "err.cityEstero.bad");
        setFlag({ ...flag, cityEstero: true });
        break;
      case "capEstero":
        if (filterData.newValue.length === 0) {
          addFormStatusError("capEstero", "err.required");
        }
        setFlag({ ...flag, capEstero: true });
        break;
      case "address":
        if (filterData.newValue.length === 0) {
          addFormStatusError("address", "err.required");
          setFlag({ ...flag, address: true });
        } else {
          addFormStatusError("address", "");
        }
        break;
      case "addressEstero":
        if (filterData.newValue.length === 0) {
          addFormStatusError("addressEstero", "err.required");
          setFlag({ ...flag, addressEstero: true });
        } else {
          addFormStatusError("addressEstero", "");
        }
        break;
      default:
        setFlag({ ...flag, [name]: true });
        return name;
    }
  };

  const switchResidence = () => {
    dispatch(
      addFormData({
        sameResidenceAndDomicile: !formData.sameResidenceAndDomicile,
      }),
    );

    if (formData.sameResidenceAndDomicile === false) {
      setStatusForm(checkingForm("domicile"));
      dispatch(disabledBtn(checkingForm("domicile")));
    } else {
      if (
        formData.cap !== "" &&
        formData.city !== "" &&
        formData.address !== ""
      ) {
        dispatch(disabledBtn(checkingForm("residence")));
      }
    }
  };

  const addFormStatusError = (name, value) => {
    let errors = {};
    errors[name] = value;
    dispatch(addFormStatus(errors));
    setStatusForm(checkingForm("residence"));
  };

  useEffect(() => {
    let status;
    if (!formData.sameResidenceAndDomicile) {
      status =
        checkingForm("residence") ||
        checkingForm("domicile") ||
        formStatus.stateIsChanging ||
        formStatus.domicileStateIsChanging;
    } else {
      status = checkingForm("residence") || formStatus.stateIsChanging;
    }

    setStatusForm(status);
    dispatch(disabledBtn(status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusForm,
    formData,
    formStatus.stateIsChanging,
    formStatus.domicileStateIsChanging,
  ]);

  useEffect(() => {
    if (hideUtag) return;

    if (component.name === "residence") {
      if (!spidStatus.fromSpidFirstLandingResidence) {
        dispatch(
          utagView({
            reg_step: "residenza",
            conversion_pagename: "residenza",
            reg_flusso: getRegFlusso(flusso, formData.fromPdv),
          }),
        );
      } else {
        dispatch(
          utagView({
            reg_section: "registrazione",
            reg_step: "residenza",
            conversion_pagename: "residenza",
            reg_flusso: getRegFlusso(flusso, formData.fromPdv),
          }),
        );
      }
    }

    return () => {
      if (spidStatus.fromSpidFirstLandingResidence) {
        dispatch(addSpidStatus({ fromSpidFirstLandingResidence: false }));
      }
    };
  }, [component]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      spidStatus.fromSpidFirstLandingResidence &&
      !formData.sameResidenceAndDomicile
    ) {
      dispatch(
        utagView({
          reg_section: "registrazione",
          reg_step: "domicilio",
          conversion_pagename: "domicilio",
          reg_flusso: getRegFlusso(flusso, formData.fromPdv),
        }),
      );
    }
  }, [
    flusso,
    formData.fromPdv,
    formData.sameResidenceAndDomicile,
    spidStatus.fromSpidFirstLandingResidence,
    dispatch,
  ]);

  return (
    <div
      className="container step-container residence"
      id="it-ar-residence-container"
    >
      <div className={"row"}>
        <div
          className={
            (component.name === "residence" || component.name === "summary"
              ? "col-12"
              : "") +
            (component.name === "residence"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : "")
          }
        >
          <div>
            {flusso === "C1" || flusso === "SPID" || flusso === "PDV" ? (
              <ResidenceHeaderForm />
            ) : null}

            <div
              id="it-ar-residence-form-group"
              className={
                "form-group pt-1 pt-md-0 " +
                (component.name !== "summary" ? "accordion-form" : "")
              }
            >
              <Nation
                paramNation="state"
                nameForm="residence"
                existentState="true"
              />
            </div>
            <div
              id="it-ar-residence-d-none"
              className={formData.state.fiscalCode === "Z000" ? "" : "d-none"}
            >
              <div
                id="it-ar-residence-input-town"
                className={
                  "form-group pt-1 pt-md-0 " +
                  (component.name !== "summary" ? "accordion-form" : " ")
                }
              >
                <Town
                  inputId="residenceCity"
                  nameForm="residence"
                  paramTown="city"
                  paramProvinceCode="province"
                  paramCityFiscalCode="cityFiscalCode"
                  labelId="lbl.residenceTown"
                  showOnlyActiveCities
                />
              </div>
            </div>
            <div
              id="it-ar-residence-d-none"
              className={formData.state.fiscalCode === "Z000" ? "" : "d-none"}
            >
              <div className="form-row" id="it-ar-residence-form-row">
                <div
                  className="form-group col-8 col-md-8"
                  id="it-ar-residence-form-group2"
                >
                  <input
                    ref={indirizzoResRef}
                    autoComplete="street-address"
                    className={
                      "form-control address-input" +
                      (formStatus.address.length > 0 && flag.address
                        ? formStatus.address === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="text"
                    id="address"
                    name="FieldC"
                    maxLength="50"
                    spellCheck="false"
                    autoCorrect="off"
                    required
                    value={formData.address}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownIrr}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                    onBlur={(e) =>
                      handleBlur(e.target.id, e.target.value.trim())
                    }
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-residence-lblResidenceAddress"
                    htmlFor="address"
                  >
                    <Translate id="lbl.residenceAddress" />
                  </label>
                  {flag.address ? (
                    <MessageError
                      currentValue={formData.address}
                      currentName={"address"}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  id="it-ar-residence-form-group"
                  className={
                    "form-group col-4 col-md-4 input-cap " +
                    (component.name !== "summary" ? "accordion-form " : " ")
                  }
                >
                  <input
                    ref={capResRef}
                    autoComplete="postal-code"
                    className={
                      "form-control " +
                      (formStatus.cap.length > 0 && flag.cap
                        ? formStatus.cap === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type={formData.state.fiscalCode === "Z000" ? "tel" : "text"}
                    required
                    id="cap"
                    name="FieldA"
                    value={formData.cap}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownCaprr}
                    onBlur={(e) =>
                      handleBlur(
                        e.target.id,
                        e.target.value,
                        e.target.classList,
                      )
                    }
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-residence-lblResidenceCap"
                    htmlFor="cap"
                  >
                    <Translate id="lbl.residenceCap" />
                  </label>
                  {flag.cap ? (
                    <MessageError
                      currentValue={formData.cap}
                      currentName={"cap"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div
              className={formData.state.fiscalCode !== "Z000" ? "" : "d-none"}
            >
              <div
                id="it-ar-residence-form-group4"
                className={
                  "form-group " +
                  (component.name !== "summary" ? "accordion-form" : " ")
                }
              >
                <input
                  autoComplete="new-password"
                  className={
                    "form-control " +
                    (formStatus.cityEstero.length > 0 && flag.cityEstero
                      ? formStatus.cityEstero === "err.required" &&
                        component.name !== "summary"
                        ? " is-present"
                        : " is-invalid"
                      : "")
                  }
                  type="text"
                  required
                  id="cityEstero"
                  name="FieldBEstero"
                  maxLength="50"
                  spellCheck="false"
                  autoCorrect="off"
                  onFocus={(e) => moveCursorAtTheEnd(e.target)}
                  onKeyDown={handleKeyDownComrer}
                  onChange={(e) => onChangeEstero(e.target.id, e.target.value)}
                  value={formData.cityEstero}
                  onBlur={(e) => handleBlur(e.target.id, e.target.value.trim())}
                />
                <label
                  className="form-control-label"
                  id="it-ar-residence-lblResidenceTown"
                  htmlFor="cityEstero"
                >
                  <Translate id="lbl.residenceTown" />
                </label>
                {flag.cityEstero ? (
                  <MessageError
                    currentValue={formData.cityEstero}
                    currentName={"cityEstero"}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="form-row">
                <div
                  className="form-group col-8 col-md-8"
                  id="it-ar-residence-form-group5"
                >
                  <input
                    ref={indirizzoResEsteroRef}
                    autoComplete="street-address"
                    className={
                      "form-control address-input" +
                      (formStatus.addressEstero.length > 0 && flag.addressEstero
                        ? formStatus.addressEstero === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="text"
                    id="addressEstero"
                    name="FieldCEstero"
                    maxLength="50"
                    spellCheck="false"
                    autoCorrect="off"
                    required
                    value={formData.addressEstero}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownIrer}
                    onChange={(e) =>
                      onChangeEstero(e.target.id, e.target.value)
                    }
                    onBlur={(e) =>
                      handleBlur(e.target.id, e.target.value.trim())
                    }
                    style={{
                      paddingRight: "10px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-residence-lblResidenceAddress"
                    htmlFor="addressEstero"
                  >
                    <Translate id="lbl.residenceAddress" />
                  </label>
                  {flag.addressEstero ? (
                    <MessageError
                      currentValue={formData.addressEstero}
                      currentName={"addressEstero"}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  id="it-ar-residence-form-group3"
                  className={
                    "form-group col-4 col-md-4 input-cap " +
                    (component.name !== "summary" ? "accordion-form " : " ")
                  }
                >
                  <input
                    ref={capResEsteroRef}
                    autoComplete="new-password"
                    className={
                      "form-control " +
                      (formStatus.capEstero.length > 0 && flag.capEstero
                        ? formStatus.capEstero === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="tel"
                    required
                    id="capEstero"
                    name="FieldAEstero"
                    value={formData.capEstero}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownCaprer}
                    onChange={(e) =>
                      onChangeEstero(e.target.id, e.target.value)
                    }
                    onBlur={(e) => handleBlur(e.target.id, e.target.value)}
                  />
                  <label
                    className="form-control-label"
                    id="it-ar-residence-lblResidenceCap"
                    htmlFor="capEstero"
                  >
                    <Translate id="lbl.residenceCap" />
                  </label>
                  {flag.capEstero ? (
                    <MessageError
                      currentValue={formData.capEstero}
                      currentName={"capEstero"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form-group" id="it-ar-residence-form-group6">
              <div className="form-check">
                <label
                  className="linkResidence"
                  htmlFor="differentDomicileCheck"
                >
                  <input
                    autoComplete="new-password"
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="differentDomicileCheck"
                    checked={formData.sameResidenceAndDomicile}
                    onChange={() => switchResidence()}
                  />
                  <span
                    className="checkmark"
                    id="it-ar-residence-lblResidenceEqualDomi"
                  />
                  <Translate
                    id={
                      isPdvFlow
                        ? "lbl.residenceEqualDomiPdv"
                        : "lbl.residenceEqualDomi"
                    }
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
