import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRegFlusso } from "../../hooks/useAnalytics";
import { Breakpoint } from "../../hooks/useMediaQuery";
import useQueryParams from "../../hooks/useQueryParams";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { utagView } from "../../store/utagStore/utagStore.action";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";
import FiscalCode from "../fiscalCode/fiscalCode.component";
import { useFiscalCode } from "../fiscalCode/fiscalCode.hooks";
import { confirmFiscalCodeQueryParamName } from "../fiscalCode/partials/calculateFiscalCodePopup/calculateFiscalCodePopup.component";
import { addSpidStatus } from "../../store/spidStatus/spidStatus.action";

export default function FiscalCodeWizard() {
  const ref = useRef(null);

  const dispatch = useDispatch();

  const reg_flusso = useRegFlusso();

  const { fiscalCode } = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const { setSearchParams } = useQueryParams();

  const { handleContinueButtonActivation } = useFiscalCode();

  useEffect(() => {
    if (window.screen.width >= Breakpoint.Laptop) {
      ref.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (flusso === "SPID") {
      dispatch(addSpidStatus({ fromSpidFirstLandingFiscalCode: true }));
    }
  }, [dispatch, flusso]);

  // disable continue button on component mount if fiscalCode is not present
  useEffect(() => {
    if (!fiscalCode) {
      handleContinueButtonActivation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseCalcFiscalCodePopup = () => {
    setSearchParams(confirmFiscalCodeQueryParamName, "true");
  };

  const handleOpenCalcFiscalCodePopup = () => {
    if (flusso === "SPID") {
      dispatch(
        utagView({
          reg_section: "registrazione",
          reg_step: "codice-fiscale:calcola-1",
          reg_flusso,
        }),
      );
    }
  };

  return (
    <>
      <FiscalCode
        ref={ref}
        onOpenCalcFiscalCodePopup={handleOpenCalcFiscalCodePopup}
        onCloseCalcFiscalCodePopup={handleCloseCalcFiscalCodePopup}
      />

      <div style={{ height: "12px" }} />
    </>
  );
}
