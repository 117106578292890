import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useOcrRegFlussoFromPathParam } from "../../../hooks/useAnalytics";
import { postFastfillOcr } from "../../../services/jumioOcrController";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { setJumioSdkToken } from "../../../store/ocr/ocr.actions";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import DocumentSelectionCTA from "../documentSelectionCta/documentSelectionCta.component";
import DocumentSelectionNav from "../documentSelectionNav/documentSelectionNav.component";

export default function DocumentSelectionForm() {
  const history = useHistory();

  const dispatch = useDispatch();

  const [doc, setDoc] = useState(null);

  const { channel, ipAddress } = useSelector(formDataSelectors.getFormData);

  const { regType } = useParams();

  const reg_flusso = useOcrRegFlussoFromPathParam();

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    const reg_cta = getAnalyticsDocumentType(value);

    dispatch(
      utagLink({
        reg_event: "ocr-seleziona-documento",
        conversion_pagename: "ocr:step1:seleziona documento",
        reg_cta,
        reg_flusso,
      }),
    );

    setDoc(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const documentType = getJumioCredentialType(doc);

    try {
      const { sdkToken } = await postFastfillOcr({
        billCodeId: uuidv4(),
        channel,
        ipAddress,
        documentType,
      });

      dispatch(setJumioSdkToken(sdkToken));

      history.push(`/ocr/${regType}`);
    } catch (error) {
      console.error("error", error);
      history.push("/errore-registrazione");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="document-selection-form">
      <fieldset>
        <DocumentSelectionCTA
          value="id-card"
          selected={doc === "id-card"}
          onChange={handleChange}
          text="Carta d'identità elettronica"
        />

        <DocumentSelectionCTA
          value="passport"
          selected={doc === "passport"}
          onChange={handleChange}
          text="Passaporto"
        />

        <DocumentSelectionCTA
          value="driving-license"
          selected={doc === "driving-license"}
          onChange={handleChange}
          text="Patente di guida"
        />
      </fieldset>

      <DocumentSelectionNav disabled={doc == null} />
    </form>
  );
}

function getJumioCredentialType(docType) {
  switch (docType) {
    case "id-card":
      return "ID_CARD";
    case "passport":
      return "PASSPORT";
    case "driving-license":
      return "DRIVING_LICENSE";
    default:
      throw new Error("Unsupported document type");
  }
}

function getAnalyticsDocumentType(docType) {
  switch (docType) {
    case "id-card":
      return "carta d'identità";
    case "passport":
      return "passaporto";
    case "driving-license":
      return "patente";
    default:
      throw new Error("Unsupported document type");
  }
}
