import { Translate } from "react-localize-redux";
import { usePdvFlowDetection } from "../../../../../../hooks/useFlow";
import { useSignUpPdv } from "../../../../../signButton/signButton.hooks";

export default function PromoCodePopupFooter({
  bonusDesc,
  bonusValidityErr,
  checkBonusCodeFunction,
  confirmPromoCode,
  inputValueIsChangedFromLastCheck,
  onClose,
  promoCode,
}) {
  const isFlowWithDirectSignUp = usePdvFlowDetection();

  const { signUpPdv, isLoading } = useSignUpPdv();

  const handleClickConfirm = async (e) => {
    e.preventDefault();

    if (inputValueIsChangedFromLastCheck) {
      checkBonusCodeFunction();
    } else if (isFlowWithDirectSignUp) {
      await signUpPdv();
    } else {
      confirmPromoCode();
    }
  };

  const hasVerifiedBonusCode =
    bonusDesc !== "" && !bonusValidityErr && !inputValueIsChangedFromLastCheck;

  const getProceedButtonTranslateId = () => {
    if (!hasVerifiedBonusCode) {
      return "lbl.verify";
    } else if (isFlowWithDirectSignUp) {
      return "text.registrationOCR.completeSignup";
    } else {
      return "lbl.promocode.continue";
    }
  };

  return (
    <>
      {!hasVerifiedBonusCode && (
        <button className="outline-button" onClick={onClose}>
          <Translate id="lbl.cancel" />
        </button>
      )}

      <button
        disabled={isLoading || !promoCode.length || bonusValidityErr}
        onClick={handleClickConfirm}
      >
        <Translate id={getProceedButtonTranslateId()} />
      </button>
    </>
  );
}
