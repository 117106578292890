import { Translate } from "react-localize-redux";

export default function ClassicRegistrationCard({
  title,
  description,
  onClick,
  badge,
}) {
  return (
    <div
      onClick={onClick}
      className="card-spid-wrapper card-spid-wrapper--classic"
    >
      {badge}

      <div className="spid-wrapper-title">{title}</div>

      <div className="spid-wrapper-info">{description}</div>

      <div className="spid-wrapper-button">
        <button className="btn registration-button registration-button--primary">
          <Translate id="lbl.signIn" />
        </button>
      </div>
    </div>
  );
}
