import { isEqual } from "lodash";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../../store/formStatus/formStatus.selectors";
import { documentContainsError } from "../../../../utility/checkingForm/checkingForm.container";
import Identification from "../../../identification/identification.container";
import OcrForm from "../../../ocrForm/ocrForm.container";
import PopupModal from "../../../popupModal/popupModal";
import { extractDocumentDataFromFormData } from "../../summary.helpers";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupEditFooter from "../../summaryPopupFooter/summaryPopupEditFooter.component";
import { documentLinkTranslateId } from "../summaryDocument.config";

export default function SummaryDocumentPopupEditContent({
  onClose,
  onCancelEdit,
  previousData,
}) {
  const handleCloseIcon = () => {
    onCancelEdit();
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleCloseIcon}>
        <Translate id="lbl.document" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body popup-document-body">
          <Identification />
          <OcrForm />
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryDocumentPopupEditFooter
          onConfirm={onClose}
          onCancel={onCancelEdit}
          previousData={previousData}
        />
      </PopupModal.Footer>
    </>
  );
}

function SummaryDocumentPopupEditFooter({ onCancel, onConfirm, previousData }) {
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const { trackCancelEditData, trackConfirmEditData } =
    useSummaryAnalyticsTrackLinks();

  const dirty = isDocumentFormDirty(formData, previousData);

  const confirmDisabled = !dirty || documentContainsError(formData, formStatus);

  const handleConfirm = () => {
    trackConfirmEditData(documentLinkTranslateId);
    onConfirm();
  };

  const handleCancel = () => {
    trackCancelEditData(documentLinkTranslateId);
    onCancel();
  };

  return (
    <SummaryPopupEditFooter
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmDisabled={confirmDisabled}
    />
  );
}

function isDocumentFormDirty(formData, previousData) {
  const current = extractDocumentDataFromFormData(formData);
  const previous = extractDocumentDataFromFormData(previousData);
  return !isEqual(current, previous);
}
