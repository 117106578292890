export default function ThankYouPageProgressWidget({ completionPercentage }) {
  const style = {
    background: completionPercentage >= 100 ? "#0a7d41" : "#f0f2f4",
  };
  return (
    <div className="typ-spid-progressbar">
      <div className="typ-spid-left" style={style} />
      <div className="typ-spid-right" style={style}>
        <div className="typ-spid-back" style={style} />
      </div>

      <div className="typ-spid-barOverflow">
        <div
          className="typ-spid-bar"
          style={{
            transform: `rotate(${45 + completionPercentage * 1.8}deg)`,
          }}
        />
      </div>

      <span>{completionPercentage}%</span>
    </div>
  );
}
