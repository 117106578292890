import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import BirthPlace from "../../birthplace/birthplace.component";
import { useFiscalCodeGeneration } from "../unmatchingPersonalDataPage.hooks";

export default function UnmatchingPersonalDataBirthPlace() {
  const dispatch = useDispatch();

  const { birthState } = useSelector(formDataSelectors.getFormData);

  const generateFiscalCode = useFiscalCodeGeneration();

  const recalcFiscalCode = async () => {
    const fiscalCode = await generateFiscalCode();
    if (fiscalCode) {
      dispatch(addFormData({ fiscalCode }));
    }
  };

  return (
    <BirthPlace
      keyNation={birthState?.fiscalCode}
      onSelectTown={recalcFiscalCode}
    />
  );
}
