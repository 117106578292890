import { getAllCities } from "../../services/geoController";
export const FETCH_ALL_CITIES_START = "FETCH_ALL_CITIES_START";
export const RECEIVE_ALLCITIES_TRUE = "RECEIVE_ALLCITIES_TRUE";
export const FETCH_ALL_CITIES_ERROR = "FETCH_ALL_CITIES_ERROR";
export const RECEIVE_ALLCITIES_FALSE = "RECEIVE_ALLCITIES_FALSE";

export function getAllCitiesAPI(channel, history) {
  return async (dispatch) => {
    dispatch(fetchAllCitiesStart());

    try {
      const response = await getAllCities(false, channel);

      dispatch(receiveAllCities_false(response.cities));

      const actualCities = response.cities.filter((city) => city.actual);
      dispatch(receiveAllCities_true(actualCities));

      return response.cities;
    } catch (err) {
      if (err.response && err.response.error === 422)
        dispatch(fetchAllCitiesError(err));
      else {
        history.push("/errore-registrazione");
      }
    }
  };
}

export const fetchAllCitiesStart = () => {
  return {
    type: FETCH_ALL_CITIES_START,
  };
};

export const receiveAllCities_true = (state) => {
  return {
    type: RECEIVE_ALLCITIES_TRUE,
    payload: state,
  };
};

export const receiveAllCities_false = (state) => {
  return {
    type: RECEIVE_ALLCITIES_FALSE,
    payload: state,
  };
};

export const fetchAllCitiesError = (state) => {
  return {
    type: FETCH_ALL_CITIES_ERROR,
    formData: state,
  };
};
