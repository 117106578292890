import { pick } from "lodash";

export const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

export function isForeign(state) {
  return state?.fiscalCode !== "Z000";
}

export function extractAccessDataFromFormData(formData) {
  return pick(formData, ["username", "password"]);
}

export function extractAddressDataFromFormData(formData) {
  return pick(formData, [
    "sameResidenceAndDomicile",
    "state",
    "city",
    "cityFiscalCode",
    "province",
    "address",
    "cap",
    "domicileState",
    "domicileCity",
    "domicileCityFiscalCode",
    "domicileProvince",
    "domicileAddress",
    "domicileCap",
    "addressEstero",
    "capEstero",
    "cityEstero",
    "addressEsteroDomi",
    "capEsteroDomi",
    "cityEsteroDomi",
  ]);
}

export function extractContactsFromFormData(formData) {
  return pick(formData, ["email"]);
}

export function extractDocumentDataFromFormData(formData) {
  return pick(formData, [
    "documentId",
    "number",
    "releaseDate",
    "expirationDate",
    "documentCityFiscalCode",
    "documentProvinceCode",
    "releasedByEntity",
    "documentType",
    "documentTypeObj",
    "releasedByEntityObj",
    "documentTown",
  ]);
}

export function extractRechargeLimitsData(formData) {
  return pick(formData, ["rechargeLimit"]);
}

export function extractBonusDataFromFormData(formData) {
  return pick(formData, ["bonusCode", "promoCode"]);
}
