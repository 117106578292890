import { Translate } from "react-localize-redux";

export default function SummaryPopupDetailFooter({ onPressEdit, onClose }) {
  return (
    <>
      <button className="secondary-button" onClick={onPressEdit}>
        <Translate id="lbl.editData" />
      </button>
      <button onClick={onClose}>
        <Translate id="lbl.button.ok" />
      </button>
    </>
  );
}
