import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { setComponent } from "../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlow,
  wizardSelectorsFlusso,
} from "../store/wizard/wizard.selectors";

export function useFlow() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { getState } = useStore();

  const component = useSelector(wizardSelectorsComponent.getComponent);

  const goToSpecificStep = (componentName) => {
    if (!componentName) {
      console.error(
        `Couldn't move to selected component ${componentName}: not found`,
      );
      return;
    }

    const flow = wizardSelectorsFlow.getWizardFlow(getState());

    const val = flow.find((v) => v.path === componentName);
    if (val) {
      dispatch(setComponent(val));
    }
    history.push(componentName);
  };

  return {
    goToNextStep: () => goToSpecificStep(component.nextComponentName),
    goToPreviousStep: () => goToSpecificStep(component.previousComponentName),
    goToSpecificStep,
  };
}

export function useRegistrationSelectionNavigation() {
  const history = useHistory();

  /**
   * Navigates to the registration selection page.
   * @param {URLSearchParams} [searchParams] - The search parameters for the URL.
   */
  const goToRegistrationSelection = (searchParams) => {
    history.push({
      pathname: "/seleziona-registrazione",
      search: searchParams?.toString(),
    });
  };

  return { goToRegistrationSelection };
}

export function usePdvFlowDetection() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  return flusso === "PDV" || flusso === "OCRPDV";
}
