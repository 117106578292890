import loyaltyLottery from "../../../images/svg/loyalty-lottery.svg";

export default function LoyaltyCardConfirm({ title, description }) {
  return (
    <div id="newLoyalty-lottery" className="banner-loyalty-lottery no-shadow">
      <div className="banner-img-bonus">
        <img alt="loyalty" src={loyaltyLottery} />
      </div>

      <div className="banner-text-bonus">
        {title != null && <p className="title">{title}</p>}

        <p className="body">{description}</p>
      </div>
    </div>
  );
}
