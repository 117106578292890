import { v4 as uuidv4 } from "uuid";
import { instanceGet, instancePost } from "../axios.config";
import moment from "moment";
import { PORT, REACT_APP_URL } from "../config";

export const checkPinActivationPdv = async (
  channel,
  username,
  accountPin,
  chargingPin,
  ipAddress,
) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/checkPinActivationPdv`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      username: username,
      accountPin: accountPin,
      chargingPin: chargingPin,
      ipAddress: ipAddress,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const validateFiscalCode = async (
  fiscalCode,
  channel,
  ipAddress,
  codiceConto,
  recaptchaToken,
) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/v3/validateFiscalCode`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel,
      fiscalCode,
      ipAddress,
      codiceConto,
    },
    headers: { recaptchaToken },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const validateEmail = async (email, channel, recaptchaToken) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/v3/validateEmail`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      email: email.toLowerCase(),
    },
    headers: {
      recaptchaToken: recaptchaToken,
    },
  };
  const res = await instanceGet.get(url, data);
  return res;
};

export const validatePhoneNumber = async (
  phonenumber,
  channel,
  ipAddress,
  recaptchaToken,
) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/v2/validatePhoneNumber`;
  let data = {
    params: {
      ipAddress: ipAddress,
      requestId: uuidv4(),
      channel: channel,
      cellulare: phonenumber,
    },
    headers: { recaptchaToken },
  };
  const res = await instanceGet.get(url, data);
  return res;
};

export const validateUsername = async (
  channel,
  username,
  birthDate,
  recaptchaToken,
) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/validator/v2/validateUsername`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel,
      username,
      birthDate: moment(birthDate).format("DD/MM/YYYY"),
    },
    headers: { recaptchaToken },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const validatePassword = async (
  birthDay,
  birthMonth,
  birthYear,
  name,
  password,
  surname,
  channel,
) => {
  let url = `${REACT_APP_URL}${PORT}/gestisci-password-ms/ms/validator/validatePassword`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      birthDay: birthDay,
      birthMonth: birthMonth,
      birthYear: birthYear,
      name: name,
      password: password,
      surname: surname,
    },
  };
  const res = await instancePost.post(url, data);
  return res.data;
};

export const confirmEmail = async ({ srv, req, cod, ctr_req, channel }) => {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/signup/confirm`;
  const data = {
    userService: {
      serviceId: srv,
      demandId: req,
      validationCode: cod,
      contractId: ctr_req,
    },
    requestId: uuidv4(),
    channel: channel,
  };

  const res = await instancePost.post(url, data);
  return res.data;
};
