import React, { useState } from "react";
import { Translate } from "react-localize-redux";
import PopupModal from "../../popupModal/popupModal";
import TYPCModalEmailStep from "./typcModalEmailStep/typcModalEmailStep.container";
import TYPCModalOtpStep from "./typcModalOtpStep/typcModalOtpStep.container";
import TYPCModalFinalStep from "./typcModalFinalStep/typcModalFinalStep.component";
import FullPageLoader from "../../elementForm/fullPageLoader/fullPageLoader.component";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";

const STEP_LIST = ["EMAIL", "OTP", "CHECK_COMPLETED"];
const [EMAIL_STEP, OTP_STEP, CHECK_COMPLETED_STEP] = [...STEP_LIST];
const FIRST_STEP = EMAIL_STEP;
const FINAL_STEP = STEP_LIST.at(-1);

export default function TYPContactCheckModal({
  isEmailVerified,
  onClose,
  setIsEmailVerified,
}) {
  const formData = useSelector(formDataSelectors.getFormData);

  const [step, setStep] = useState(FIRST_STEP);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(formData?.email ?? "");

  const nextStep = () => {
    if (step !== FINAL_STEP) {
      setStep(STEP_LIST[STEP_LIST.indexOf(step) + 1]);
    }
  };

  const mapSteps = () => {
    switch (step) {
      case EMAIL_STEP:
        return (
          <TYPCModalEmailStep
            email={email}
            nextStep={nextStep}
            setEmail={setEmail}
            setIsLoading={setIsLoading}
          />
        );
      case OTP_STEP:
        return (
          <TYPCModalOtpStep
            email={email}
            nextStep={nextStep}
            setIsEmailVerified={setIsEmailVerified}
            setIsLoading={setIsLoading}
          />
        );
      case CHECK_COMPLETED_STEP:
        return <TYPCModalFinalStep onClose={onClose} />;
      default:
        break;
    }
  };

  return (
    <div id="typ-ccm">
      {isLoading && <FullPageLoader />}
      <PopupModal>
        <PopupModal.Header closeMe={onClose}>
          <Translate
            id={
              isEmailVerified
                ? "lbl.emailTYP.modal.header.verified"
                : "text.emailTYP.modal.header"
            }
          />
        </PopupModal.Header>
        {mapSteps()}
      </PopupModal>
    </div>
  );
}
