import { Translate } from "react-localize-redux";

export default function ValidationCheckRow({ label, rowClassName }) {
  return (
    <div className="col-auto check-container px-1">
      <h5 className={`check-topic ${rowClassName}`}>
        <Translate id={label} />
      </h5>
    </div>
  );
}
