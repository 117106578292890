import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import RightDesktopGift from "../../../images/svg/thankyouPage/right-desktop-gift.svg";
import RightMobileGift from "../../../images/svg/thankyouPage/right-mobile-gift.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";

export default function ThankYouPageBonusBanner({ onClickRecharge }) {
  const { bonusList, bonusCode, promoCode, customizesBonus, isMobile } =
    useSelector(formDataSelectors.getFormData);

  const bonus = bonusList.find((b) => b.bonusCode === bonusCode);

  if (!bonus && !customizesBonus) return null;

  return (
    <div className="sisal-thank-you-banner">
      <div className={"sisal-thank-you-banner-right-gift"}>
        <img
          alt="bonusRightGift"
          className="sisal-thank-you-banner-gift-img"
          src={isMobile ? RightMobileGift : RightDesktopGift}
        />
      </div>

      <div className="sisal-thank-you-banner-tag">
        <Translate id="text.typ.spid.welcomeBonus" />
      </div>

      <span className="sisal-thank-you-banner-title">
        {bonus ? bonus.title : promoCode}
      </span>

      <div className="sisal-thank-you-banner-description">
        <span className="sisal-thank-you-banner-text">
          {bonus
            ? bonus.description
            : "Segui le indicazioni per ottenere il bonus"}{" "}
        </span>

        <button
          className="sisal-thank-you-banner-link"
          onClick={onClickRecharge}
        >
          <Translate id="text.typ.spid.reload" />
        </button>
      </div>
    </div>
  );
}
