import { useDispatch } from "react-redux";
import { useRegFlusso } from "../../hooks/useAnalytics";
import { useTranslate } from "../../hooks/useTranslate";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { utagLink } from "../../store/utagStore/utagStore.action";

export function useLoyaltyPopupFormError() {
  const dispatch = useDispatch();

  const { getTranslatedString } = useTranslate();

  const reg_flusso = useRegFlusso();

  return {
    addAndTrackLoyaltyFormError: (errorMessageTranslateId) => {
      dispatch(addFormStatus({ playCode: errorMessageTranslateId }));

      dispatch(
        utagLink({
          error_field: "inserimento codice giocata",
          error_message: getTranslatedString(errorMessageTranslateId),
          reg_event: "form-error",
          reg_flusso,
        }),
      );
    },
    removeLoyaltyFormError: () => dispatch(addFormStatus({ playCode: "" })),
  };
}
