import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOcrRegFlussoFromPathParam } from "../../hooks/useAnalytics";
import { utagView } from "../../store/utagStore/utagStore.action";
import Header from "../header/header.component";
import DocumentSelectionForm from "./documentSelectionForm/documentSelectionForm.component";
import DocumentSelectionHeadForm from "./documentSelectionHeadForm/documentSelectionHeadForm.component";

export default function DocumentSelectionPage() {
  useAnalyticsUtagView();

  return (
    <>
      <Header />

      <main className="document-selection-container">
        <DocumentSelectionHeadForm />

        <DocumentSelectionForm />
      </main>
    </>
  );
}

function useAnalyticsUtagView() {
  const dispatch = useDispatch();

  const reg_flusso = useOcrRegFlussoFromPathParam();

  useEffect(() => {
    dispatch(
      utagView(
        {
          reg_step: "ocr:step1:seleziona documento",
          conversion_pagename: " ocr:step1:seleziona documento",
          reg_flusso,
        },
        { delay: 1000 }
      )
    );
  }, [dispatch, reg_flusso]);
}
