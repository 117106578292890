import store from "../../store.config";
import { setStatusSection } from "../../store/signCheck/signCheck.action";

export const checkingForm = (nameForm) => {
  const formStatus = store.getState().formStatusReducer.formStatus;
  const formData = store.getState().formDataReducer.formData;

  let status = true;

  switch (nameForm) {
    case "activeAccount":
      if (
        formData.usernamePdv.length >= 6 &&
        formData.usernamePdv.length <= 16 &&
        formData.accountPin.length > 0 &&
        formData.chargingPin.length === 6
      ) {
        return (
          formStatus.usernamePdv !== "" ||
          formStatus.accountPin !== "" ||
          formStatus.chargingPin !== ""
        );
      }
      break;
    case "securityQuestion":
      if (/^[0-9a-zA-Z ]+$/.test(formData.answer)) {
        store.dispatch(
          setStatusSection({
            securityQuestion:
              formData.answer === "" || formData.answer.trim() === "",
          }),
        );
      } else {
        store.dispatch(setStatusSection({ securityQuestion: true }));
        return true;
      }
      return formData.answer === "" || formData.answer.trim() === "";
    case "personaData":
      let statusPersonaData =
        formStatus.name !== "" ||
        formStatus.surname !== "" ||
        formData.name === "" ||
        formData.surname === "";
      store.dispatch(setStatusSection({ personaData: statusPersonaData }));
      return statusPersonaData;
    case "fiscalCode":
      const statusFiscalCode =
        formData.fiscalCode.length !== 16 || formStatus.fiscalCode !== "";

      store.dispatch(setStatusSection({ fiscalCode: statusFiscalCode }));
      if (!statusFiscalCode) {
        store.dispatch(
          setStatusSection({
            gender: statusFiscalCode,
            birthPlace: statusFiscalCode,
          }),
        );
      }

      return statusFiscalCode;
    case "gender":
      let statusGender = true;

      if (
        formData.birthDate !== "" &&
        formStatus.birthDate === "" &&
        formData.gender !== ""
      ) {
        statusGender = false;
      }

      store.dispatch(setStatusSection({ gender: statusGender }));
      return statusGender;
    case "ocr":
      const ocr = documentContainsError(formData, formStatus);
      store.dispatch(setStatusSection({ ocr }));
      return ocr;
    case "document":
      if (
        parseInt(formData.documentType, 10) === 14 ||
        (formData.frontFileName && formData.frontFileName.name)
      ) {
        let statusDocument =
          formData.frontFileName === "" || formStatus.frontFileName !== "";
        return statusDocument; //attiva bottone
      } else if (
        parseInt(formData.documentType, 10) === 14 ||
        (formData.backFileName && formData.backFileName.name)
      ) {
        let statusDocument =
          formData.backFileName === "" || formStatus.backFileName !== "";
        return statusDocument; //attiva bottone
      } else {
        let statusDocument =
          formData.frontFileName === "" ||
          formStatus.frontFileName !== "" ||
          formData.backFileName === "" ||
          formStatus.backFileName !== "";
        return statusDocument;
      }
    case "contacts":
      const contacts = contactsContainsError(formData, formStatus);
      store.dispatch(setStatusSection({ contacts }));
      return contacts;
    case "userData":
      const userData = userDataContainsError(formData, formStatus);
      store.dispatch(setStatusSection({ userData }));
      return userData;
    case "birthPlace":
      if (
        Object.entries(formData.birthState).length === 0 ||
        formStatus.birthState !== ""
      ) {
        store.dispatch(setStatusSection({ birthPlace: true }));
        return true;
      } else {
        if (formData.birthState.fiscalCode === "Z000") {
          let statusBirthplace = true;

          if (
            formData.birthTown !== "" &&
            formStatus.birthTown === "" &&
            store
              .getState()
              .allCitiesReducer.allCitiesFalse.filter(
                (c) => c.description === formData.birthTown,
              ).length
          ) {
            statusBirthplace = false;
          }

          store.dispatch(setStatusSection({ birthPlace: statusBirthplace }));

          return statusBirthplace;
        } else {
          let statusEsteroBirthplace = true;

          if (
            formData.cityEsteroBirth !== "" &&
            formStatus.cityEsteroBirth === "" &&
            formData.cityEsteroBirth.length >= 2
          ) {
            statusEsteroBirthplace = false;
          }

          store.dispatch(
            setStatusSection({ birthPlace: statusEsteroBirthplace }),
          );
          return statusEsteroBirthplace;
        }
      }
    case "gender&birthplace":
      if (
        (formStatus.birthPlace && formStatus.birthPlace !== "") ||
        (formStatus.gender && formStatus.gender !== "") ||
        (formStatus.birthTown && formStatus.birthTown !== "") ||
        (formData.birthState?.fiscalCode &&
          formData.birthState.fiscalCode !== "Z000" &&
          formData.cityEsteroBirth?.length < 2)
      ) {
        return true;
      } else if (
        (formStatus.birthPlace === "" || !formStatus.birthPlace) &&
        (formStatus.birthDate === "" || !formStatus.birthDate) &&
        (formStatus.gender === "" || !formStatus.gender) &&
        formData.birthDate !== "" &&
        formData.gender !== "" &&
        ((formData.birthState?.fiscalCode === "Z000" &&
          formData.birthTown !== "" &&
          store
            .getState()
            .allCitiesReducer.allCitiesFalse.filter(
              (c) => c.description === formData.birthTown,
            ).length) ||
          (formData.birthState?.fiscalCode !== "Z000" &&
            formData.cityEsteroBirth?.length >= 2))
      ) {
        return false;
      }
      return true;
    case "residence":
      const residence = residenceContainsErrors(formData, formStatus);
      store.dispatch(setStatusSection({ residence }));
      return residence;
    case "domicile":
      const domicile = domicileContainsErrors(formData, formStatus);
      store.dispatch(setStatusSection({ domicile }));
      return domicile;
    case "rechargeLimit":
      const rechargeLimit = rechargeLimitsContainsError(formData, formStatus);
      store.dispatch(setStatusSection({ rechargeLimit }));
      return rechargeLimit;
    case "termsAndConditions":
      let statusTermConditions =
        formData.gameContract === false ||
        formData.termsAndConditions === false ||
        formData.privacyPolicy === false ||
        formData.personalDataProfilingTreatmentConsent === null ||
        formData.personalDataTreatmentConsent === null;
      store.dispatch(
        setStatusSection({ termsAndConditions: statusTermConditions }),
      );
      return statusTermConditions;
    case "termsAndConditionsRechargeLimit":
      let rechargeLimitStatus =
        formData.rechargeLimit === "" || formStatus.rechargeLimit !== "";
      store.dispatch(setStatusSection({ rechargeLimit: rechargeLimitStatus }));
      let termsAndConditionsStatus = formData.termsAndConditions === false;
      store.dispatch(
        setStatusSection({ termsAndConditions: termsAndConditionsStatus }),
      );
      let statusTermConditionsB =
        formData.gameContract === false ||
        formData.termsAndConditions === false ||
        formData.privacyPolicy === false;
      store.dispatch(
        setStatusSection({ termsAndConditions: statusTermConditionsB }),
      );
      return (
        rechargeLimitStatus || termsAndConditionsStatus || statusTermConditionsB
      );
    case "welcomeBonus":
      const welcomeBonus = welcomeBonusContainsError(formData);
      store.dispatch(setStatusSection({ welcomeBonus }));
      return welcomeBonus;
    case "documentTown":
      store.dispatch(
        setStatusSection({
          documentTown:
            formData.documentTown === "" && formStatus.documentTown !== "",
        }),
      );
      return formData.documentTown === "" && formStatus.promoCode !== "";
    default:
      break;
  }

  return status;
};

export function contactsContainsError(formData, formStatus) {
  return (
    formStatus.email !== "" ||
    formStatus.phoneNumber !== "" ||
    formData.email === "" ||
    formData.phoneNumber === "" ||
    formData.phonePrefix === ""
  );
}

export function residenceContainsErrors(formData, formStatus) {
  if (Object.entries(formData.state).length === 0 || formStatus.state !== "") {
    return true;
  }

  if (formData.state.fiscalCode === "Z000") {
    return (
      formData.cap.length !== 5 ||
      formStatus.cap !== "" ||
      formData.province === "" ||
      formData.city === "" ||
      formStatus.city !== "" ||
      formData.address === ""
    );
  } else {
    return (
      formData.capEstero.length === 0 ||
      formStatus.capEstero !== "" ||
      formData.cityEstero === "" ||
      formData.addressEstero === "" ||
      formStatus.cityEstero !== ""
    );
  }
}

export function domicileContainsErrors(formData, formStatus) {
  if (formData.sameResidenceAndDomicile) {
    return false;
  }

  if (
    Object.entries(formData.domicileState).length === 0 ||
    formStatus.domicileState !== ""
  ) {
    return true;
  }

  if (formData.domicileState.fiscalCode === "Z000") {
    return (
      formData.domicileCap.length !== 5 ||
      formStatus.domicileCap !== "" ||
      formData.domicileProvince === "" ||
      formData.domicileCity === "" ||
      formStatus.domicileCity !== "" ||
      formData.domicileAddress === ""
    );
  } else {
    return (
      formData.capEsteroDomi.length === 0 ||
      formStatus.capEsteroDomi !== "" ||
      formData.cityEsteroDomi === "" ||
      formStatus.cityEsteroDomi !== "" ||
      formData.addressEsteroDomi === ""
    );
  }
}

export function userDataContainsError(formData, formStatus) {
  return (
    formData.username === "" ||
    formData.password === "" ||
    formStatus.username !== "" ||
    formStatus.password !== ""
  );
}

export function documentContainsError(formData, formStatus) {
  return (
    formData.documentTown === "" ||
    formData.number === "" ||
    formData.number.length < 6 ||
    formData.releaseDate === "" ||
    formData.expirationDate === "" ||
    formStatus.documentTown !== "" ||
    formStatus.number !== "" ||
    formStatus.releaseDate !== "" ||
    formStatus.expirationDate !== ""
  );
}

export function rechargeLimitsContainsError(formData, formStatus) {
  return (
    formData.rechargeLimit == null ||
    formData.rechargeLimit === "" ||
    formStatus.rechargeLimit !== ""
  );
}

export function welcomeBonusContainsError(formData) {
  return formData.bonusCode === "" && formData.promoCode === "";
}
