import { omit } from "lodash";
import { readCookie } from "../cookie";
import { sendFollowUpDataEmail } from "../../services/followUpController";

const getFarm = (akaalbAreaPrivataK8sCookie) => {
  const s = akaalbAreaPrivataK8sCookie.split("farm-")[1];
  if (s == null) {
    console.error("No farm found in cookie");
    return "";
  }
  return s.charAt(0);
};

const purgeNullValues = (obj) => {
  return Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

export const prepareFollowUpParams = (formData, componentName, flowName) => {
  const akaalbAreaPrivataK8sCookie = readCookie("akaalb_areaprivata_k8s");
  if (!akaalbAreaPrivataK8sCookie) {
    console.error("No akaalb_areaprivata_k8s cookie found");
  }

  return {
    ...omit(formData, ["username", "password"]),
    farm: akaalbAreaPrivataK8sCookie ? getFarm(akaalbAreaPrivataK8sCookie) : "",
    componentName,
    flowName,
    phoneNumberCertified: false,
    jwt: "",
  };
};

export const followUpParamsToFormData = (followUpParams) => {
  return omit(purgeNullValues(followUpParams), ["flowName"]);
};

export const sendResumeRegistrationDataEmail = (
  signupCompleted,
  formData,
  visitedFollowUpLink,
  componentName,
  flowName,
) => {
  if (signupCompleted || !formData.email || visitedFollowUpLink) {
    return;
  }
  const data = prepareFollowUpParams(formData, componentName, flowName);
  sendFollowUpDataEmail(data);
};
