import { FORM_STATUS, RESET_FORM_STATUS } from "./formStatus.action";
const initialState = {
  formStatus: {
    name: "",
    visualError: false,
    surname: "",
    fiscalCode: "",
    birthTown: "",
    birthplace_nation: "",
    birthplace_province: "",
    birthplace_city: "",
    birthDate: "",
    generatedFiscalCode: "",
    username: "",
    password: "",
    birthState: "",
    state: "",
    domicileState: "",
    domicileCap: "",
    domicileProvince: "",
    isBounceClickEmail: false,
    domicileAddress: "",
    domicileCity: "",
    rechargeLimit: "",
    cap: "",
    city: "",
    province: "",
    address: "",
    email: "",
    phoneNumber: "",
    document: {
      type: "",
      number: "",
      releaseDate: "",
      releaseDateFE: "",
      dateEnd: "",
      dateEndFE: "",
      releaseFrom: "",
    },
    number: "",
    frontFileName: "",
    backFileName: "",
    releaseDate: "",
    expirationDate: "",
    hasDocFile: "",
    capEstero: "",
    cityEstero: "",
    addressEstero: "",
    cityEsteroBirth: "",
    addressEsteroDomi: "",
    cityEsteroDomi: "",
    capEsteroDomi: "",
    documentTown: "",
    usernamePdv: "",
    accountPin: "",
    chargingPin: "",
    bonusCode: "",
    playCode: "",
  },
};
export const formStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_STATUS:
      const newObj = Object.assign(state.formStatus, action.formStatus);
      return { ...state, formStatus: { ...newObj } };
    case RESET_FORM_STATUS:
      return { ...state, formStatus: { ...initialState.formStatus } };
    default:
      return state;
  }
};
