import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import useQueryParams from "../../hooks/useQueryParams";
import { getDefaultClassicFlowName } from "../../services/flowController";
import { utagView } from "../../store/utagStore/utagStore.action";
import { getFlow } from "../../utility/wizardConfig/wizardConfig";

export function useAnalyticsUtagView() {
  const dispatch = useDispatch();

  const reg_doc = useRegDoc();

  const reg_flusso = useRegFlusso();

  const type = useErrorPageType();

  useEffect(() => {
    const getRegStep = () => {
      switch (reg_flusso) {
        case "ocr rivenditori":
          return type === "unsupportedDocumentError"
            ? "registrazione:pdv ocr:error_page:doc_non_supportato"
            : "registrazione:pdv ocr:error_page:errore_generico";
        default:
          return type === "unsupportedDocumentError"
            ? "registrazione:ocr:error_page:doc_non_supportato"
            : "registrazione:ocr:error_page:errore_generico";
      }
    };

    const reg_step = getRegStep();

    dispatch(
      utagView({
        reg_step,
        conversion_pagename: reg_step,
        reg_doc,
        country: "italia",
        reg_flusso,
      }),
    );
  }, [dispatch, reg_doc, reg_flusso, type]);
}

export function useErrorPageType() {
  const { searchParams } = useQueryParams();

  const type = searchParams.get("type");

  return type === "documento-non-supportato"
    ? "unsupportedDocumentError"
    : "genericError";
}

export function useInsertManuallyAction() {
  const history = useHistory();
  const { goToSpecificStep } = useFlow();

  const handleClickInsertManually = async () => {
    await getFlow(getDefaultClassicFlowName, null, history);
    // skip page "inizio"
    goToSpecificStep("/nome-cognome");
  };

  return handleClickInsertManually;
}
