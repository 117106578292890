import { forwardRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../../store/wizard/wizard.action";
import { wizardSelectorsComponent } from "../../../store/wizard/wizard.selectors";
import { checkingForm } from "../../../utility/checkingForm/checkingForm.container";
import filter from "../../../utility/filter";
import { moveCursorAtTheEnd } from "../../../utility/input/input";
import MessageError from "../../elementForm/messageError/messageError.component";

function NameInputFieldUI(
  {
    canShowNameError = true,
    setCanShowNameError = () => {},
    onKeyDown = () => {},
  },
  ref,
) {
  const dispatch = useDispatch();

  const component = useSelector(wizardSelectorsComponent.getComponent);
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const [previousValue, setPreviousValue] = useState(formData.name);

  const updateState = (value) => {
    dispatch(addFormData({ name: value }));

    const check = filter("name", value, "formIsValid");
    dispatch(addFormStatus(check.error));

    const isInvalid = checkingForm("personaData");
    dispatch(disabledBtn(isInvalid));
  };

  const handleChange = (event) => {
    const value = event.target.value;

    const check = filter("name", value, "regex");
    if (check.boolean) {
      if (/^\s+/.test(check.newValue)) {
        updateState(check.newValue.substring(0, 50).trim());
      } else {
        updateState(check.newValue.substring(0, 50));
      }
    }
  };

  const handleBlur = (e) => {
    const value = e.target.value.trim();

    dispatch(addFormData({ name: value }));
    setCanShowNameError(true);

    if (previousValue !== formData.name && component.name === "summary") {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "name",
        }),
      );
    }
    setPreviousValue({ ...previousValue, name: formData.name });
  };

  const handleFocus = (event) => {
    moveCursorAtTheEnd(event.target);

    if (canShowNameError) {
      setCanShowNameError(false);
    }
  };

  return (
    <div
      id="it-ar-personalData-form-group1"
      className={
        "form-group " + (component.name === "summary" ? "accordion-form " : "")
      }
    >
      <input
        ref={ref}
        autoComplete="off"
        className={
          "form-control" +
          (formStatus.name.length > 0 && canShowNameError
            ? formStatus.name === "err.required" && component.name !== "summary"
              ? " is-present"
              : " is-invalid"
            : "")
        }
        required
        type="text"
        name="name"
        id="it-ar-personalData-name"
        value={formData.name}
        maxLength={50}
        spellCheck="false"
        autoCorrect="off"
        onKeyDown={onKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        style={{
          paddingRight: "10px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      />
      <label
        className="form-control-label"
        id="it-ar-personalData-form-control-label-surname"
        htmlFor="it-ar-personalData-name"
      >
        <Translate id="lbl.name" />
      </label>

      {canShowNameError && (
        <MessageError currentValue={formData.name} currentName="name" />
      )}
    </div>
  );
}

const NameInputField = forwardRef(NameInputFieldUI);

export default NameInputField;
