export function loadOneTagAllPagesScript() {
  return loadOneTagScript({ segmentGroup: "595", scriptId: "DT9-all-pages" });
}

export function loadOneTagLandingPageScript() {
  return loadOneTagScript({ segmentId: "115514" });
}

export function loadOneTagStartSignupScript() {
  return loadOneTagScript({
    segmentId: "115515",
    scriptId: "DT9-signup-start",
  });
}

export function loadOneTagCompletedSignupScript(accountId) {
  return loadOneTagScript({ segmentId: "115516", accountId });
}

export function removeOneTagScript(script) {
  document.body.removeChild(script);
}

function loadOneTagScript({ segmentId, segmentGroup, scriptId, accountId }) {
  const scriptUrl = new URL("https://onetag-sys.com/audience-router/");
  scriptUrl.searchParams.set("tag", "script");
  if (segmentGroup != null) {
    scriptUrl.searchParams.set("segment_group", segmentGroup);
  }
  if (segmentId != null) {
    scriptUrl.searchParams.set("segment_id", segmentId);
  }
  if (accountId != null) {
    scriptUrl.searchParams.set("account_id", accountId);
  }

  const script = document.createElement("script");
  script.async = true;
  script.referrerPolicy = "no-referrer-when-downgrade";
  script.src = scriptUrl.href;
  if (scriptId != null) {
    script.setAttribute("id", scriptId);
  }

  document.body.appendChild(script);

  return script;
}
