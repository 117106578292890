import { useSelector } from "react-redux";
import { spidStatusSelectors } from "../../../store/spidStatus/spidStatus.selectors";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";
import { Translate } from "react-localize-redux";

export default function ContactsHeaderForm() {
  const titleTranslationId = useContactsTitleTranslationId();
  const subtitleTranslationId = useContactsSubtitleTranslationId();

  return (
    <div className="header-form" id="it-ar-contacts-header-form">
      <h2 className="sub-title-form" id="it-ar-contacts-sub-title">
        <Translate id={titleTranslationId} />
      </h2>

      <p
        className="label-form margin-bot-standard"
        id="it-ar-contacts-recoverPw"
      >
        <Translate id={subtitleTranslationId} />
      </p>
    </div>
  );
}

export function useContactsTitleTranslationId() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);

  switch (flusso) {
    case "C1":
    case "PDV":
      return "text.yourContacts_C1";
    case "SPID":
      if (
        spidStatus.showEmail &&
        spidStatus.showPhone &&
        !spidStatus.notItalianPrefix
      ) {
        return "text.contacts.spid.title.both";
      } else if (spidStatus.showEmail) {
        return "text.contacts.spid.title.email";
      } else if (spidStatus.notItalianPrefix) {
        return "text.contacts.spid.title.notItalianPrefix";
      } else if (spidStatus.showPhone) {
        return "text.contacts.spid.title.phone";
      } else {
        return "text.yourContacts";
      }
    default:
      return "text.yourContacts";
  }
}

export function useContactsSubtitleTranslationId() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);

  switch (flusso) {
    case "C1":
      return "text.recoverPw_C1";
    case "OCRPDV":
    case "PDV":
      return "text.recoverPwdPdv";
    case "SPID":
      if (
        spidStatus.showEmail &&
        spidStatus.showPhone &&
        !spidStatus.notItalianPrefix
      ) {
        return "text.contacts.spid.subtitle.both";
      } else if (spidStatus.showEmail) {
        return "text.contacts.spid.subtitle.email";
      } else if (spidStatus.notItalianPrefix) {
        return "text.contacts.spid.subtitle.notItalianPrefix";
      } else if (spidStatus.showPhone) {
        return "text.contacts.spid.subtitle.phone";
      } else {
        return "text.recoverPw";
      }
    default:
      return "text.recoverPw";
  }
}
