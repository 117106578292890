import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRegDoc, useRegFlusso } from "../../../hooks/useAnalytics";
import { utagLink, utagView } from "../../../store/utagStore/utagStore.action";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";
import LeaveConfirmPopup from "../../leaveConfirmPopup/leaveConfirmPopup.component";

export default function OcrSummaryLeaveConfirmPopup({ closePopup }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const reg_doc = useRegDoc();

  const reg_flusso = useRegFlusso();

  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  useEffect(() => {
    if (reg_doc) {
      const reg_step =
        reg_flusso === "ocr rivenditori"
          ? "registrazione:pdv ocr:popup continua registrazione"
          : "ocr:popup continua registrazione";

      dispatch(
        utagView({
          reg_step,
          conversion_pagename: reg_step,
          reg_doc,
          country: "italia",
          reg_flusso,
        }),
      );
    }
  }, [dispatch, reg_doc, reg_flusso]);

  const handleClickLeave = () => {
    dispatch(
      utagLink({
        ...getCommonAnalyticsButtonData(flusso),
        reg_cta: "abbandona",
      }),
    );

    history.push("/seleziona-ocr");
  };

  const handleClickContinue = () => {
    dispatch(
      utagLink({
        ...getCommonAnalyticsButtonData(flusso),
        reg_cta: "continua",
      }),
    );

    closePopup();
  };

  return (
    <LeaveConfirmPopup
      onClose={closePopup}
      onClickContinue={handleClickContinue}
      onClickLeave={handleClickLeave}
      text={<Translate id="text.registrationOCR.leaveConfirmPopup" />}
    />
  );
}

function getCommonAnalyticsButtonData(flusso) {
  if (flusso === "OCRPDV") {
    return {
      reg_event: "pdv-ocr-continua-registrazione",
      conversion_pagename: "registrazione:pdv ocr:popup continua registrazione",
    };
  } else {
    return {
      reg_event: "ocr-continua-registrazione",
      conversion_pagename: "ocr:popup continua registrazione",
    };
  }
}
