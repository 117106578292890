import moment from "moment";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import ValidationCheckRow from "../validationCheckRow/validationCheckRow.component";
import ValidationCheck from "../validationCheck/validationCheck.component";

export default function ValidationCheckPassword({ password }) {
  const formData = useSelector(formDataSelectors.getFormData);

  const valName = formData.name.toLowerCase();
  const valSurName = formData.surname.toLowerCase();
  const valUsername = formData.username.toLowerCase();
  const birthYear = moment(formData.birthDate).format("YYYY");

  const getRowClassName = (passesValidation) => {
    if (password == null || password === "") {
      return "";
    }
    return passesValidation ? "success" : "not-success";
  };

  return (
    <ValidationCheck>
      <ValidationCheckRow
        label="text.passwordCharBounds"
        rowClassName={getRowClassName(
          password.length >= 8 && password.length <= 20,
        )}
      />
      <ValidationCheckRow
        label="text.atLeastOneNumber"
        rowClassName={getRowClassName(/[0-9]/.test(password))}
      />
      <ValidationCheckRow
        label="text.upperAndLowerCase"
        rowClassName={getRowClassName(
          /[a-z]/.test(password) && /[A-Z]/.test(password),
        )}
      />
      <ValidationCheckRow
        label="text.specialCharMatch"
        rowClassName={getRowClassName(
          /[!#$/^&*()=:.;,?+\-<>[%\]_\]\\]/.test(password),
        )}
      />
      <ValidationCheckRow
        label="text.personalDataMatch"
        rowClassName={getRowClassName(
          !(
            password.toLowerCase().includes(valName) ||
            password.toLowerCase().includes(valSurName) ||
            (valUsername === ""
              ? false
              : password.toLowerCase().includes(valUsername)) ||
            password.includes(birthYear) ||
            password.includes(formData.phoneNumber)
          ),
        )}
      />
    </ValidationCheck>
  );
}
