export const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const deleteCookie = (cookie) => {
  if (!readCookie(cookie)) return;
  const paths = ["/", "/registrazioneJwt"];
  for (const path of paths) {
    document.cookie = `${cookie}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:01 UTC;`;
  }
};

export const setCookie = (variable, value, expiresSeconds) => {
  const expiresString =
    expiresSeconds != null ? `; max-age=${expiresSeconds}` : "";
  document.cookie = `${variable}=${value}${expiresString};`;
};

export const readQueryParamFromCookie = (queryParam) => {
  const urlSearchParams = readQueryParamsFromCookie();
  return urlSearchParams ? urlSearchParams.get(queryParam) : null;
};

export const readQueryParamsFromCookie = () => {
  const parametersCookie = readCookie("ParametersCookie");
  if (parametersCookie == null) {
    return new URLSearchParams();
  }
  const decodedQueryString = decodeURIComponent(parametersCookie);
  return new URLSearchParams(decodedQueryString);
};
