export function getComponentToShow(wizard, componentNameFromFollowUpResponse) {
  const userLeftComponentIndex = wizard.findIndex(
    (value) => value.componentName === componentNameFromFollowUpResponse,
  );
  const usernamePasswordComponentIndex = wizard.findIndex(
    (value) => value.componentName === "UserData",
  );
  const contactsComponentIndex = wizard.findIndex(
    (value) => value.componentName === "Contacts",
  );

  const componentIndex =
    componentNameFromFollowUpResponse != null
      ? Math.min(
          userLeftComponentIndex,
          usernamePasswordComponentIndex,
          contactsComponentIndex,
        )
      : Math.min(usernamePasswordComponentIndex, contactsComponentIndex);

  const component = wizard[componentIndex];
  return component;
}
