import { combineReducers } from "redux";
// Add all reducers here

import { formStatusReducer } from "./store/formStatus/formStatus.reducer";
import { documentTypeReducer } from "./store/documentType/documentType.reducer";
import { wizardReducer } from "./store/wizard/wizard.reducer";
import { formDataReducer } from "./store/formData/formData.reducer";
import { localizeReducer } from "react-localize-redux";
import { validateUsernameReducer } from "./store/validateUsername/validateUsername.reducer";
import { allCitiesReducer } from "./store/allCities/allCities.reducer";
import { countriesReducer } from "./store/countries/countries.reducer";
import { signCheckReducer } from "./store/signCheck/signCheck.reducer";
import { questionListReducer } from "./store/securityList/securityList.reducer";
import { utagReducer } from "./store/utagStore/utagStore.reducer";
import { followUpReducer } from "./store/followUp/followUp.reducer";
import { vivochaProactiveChatReducer } from "./store/vivochaProactiveChat/vivochaProactiveChat.reducer";
import { spidStatusReducer } from "./store/spidStatus/spidStatus.reducer";
import { servicesReducer } from "./store/services/services.reducer";
import { rechargeLimitReducer } from "./store/rechargeLimit/rechargeLimit.reducer";
import { bonusDetailReducer } from "./store/bonusDetail/bonusDetail.reducer";
import { contactsReducer } from "./store/contacts/contacts.reducer";
import { ocrReducer } from "./store/ocr/ocr.reducer";

export const appReducer = combineReducers({
  formStatusReducer: formStatusReducer,
  formDataReducer: formDataReducer,
  wizard: wizardReducer,
  localize: localizeReducer,
  documentTypeReducer: documentTypeReducer,
  validateUsernameReducer: validateUsernameReducer,
  allCitiesReducer: allCitiesReducer,
  countriesReducer: countriesReducer,
  signCheckReducer: signCheckReducer,
  questionListReducer: questionListReducer,
  utagReducer: utagReducer,
  followUpReducer: followUpReducer,
  vivochaProactiveChatReducer: vivochaProactiveChatReducer,
  spidStatusReducer: spidStatusReducer,
  servicesReducer: servicesReducer,
  rechargeLimitReducer: rechargeLimitReducer,
  bonusDetailReducer: bonusDetailReducer,
  contactsReducer: contactsReducer,
  ocrReducer: ocrReducer,
});

// To clear all the state on app reset
export const rootReducer = (state, action) => {
  if (action.type === "APP_DATA_RESET") {
    state = undefined;
  }

  return appReducer(state, action);
};
