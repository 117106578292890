import { useRef } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { usePdvFlowDetection } from "../../../../hooks/useFlow";
import { addFormData } from "../../../../store/formData/formData.action";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { checkingForm } from "../../../../utility/checkingForm/checkingForm.container";
import BirthPlace from "../../../birthplace/birthplace.component";
import BirthDateInputField from "../../../inputFields/birthDateInputField/birthDateInputField.component";
import PopupModal from "../../../popupModal/popupModal";
import Gender from "../gender/gender.component";

export const confirmFiscalCodeQueryParamName = "conferma-codice-fiscale";

export default function CalculateFiscalCodePopup({ onConfirm, onClose, show }) {
  const bodyElRef = useRef(null);

  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);

  const gender = formData.gender || null; // "M" | "F"

  const handleSelectMale = () => dispatch(addFormData({ gender: "M" }));
  const handleSelectFemale = () => dispatch(addFormData({ gender: "F" }));

  return (
    <div className="calculate-fiscal-code-popup">
      <PopupModal show={show}>
        <Header onClose={onClose} />
        <div ref={bodyElRef} className="calculate-fiscal-code-popup-body">
          <PopupModal.Body>
            <div style={{ paddingTop: "0.5rem" }}>
              <BirthDateInputField />

              <div style={{ paddingBottom: "1.5rem" }}>
                <Gender
                  selected={gender}
                  onSelectMale={handleSelectMale}
                  onSelectFemale={handleSelectFemale}
                />
              </div>

              <BirthPlace scrollIntoViewOnOpenTown />
            </div>
          </PopupModal.Body>
        </div>

        <PopupModal.Footer>
          <button
            onClick={onConfirm}
            disabled={checkingForm("gender&birthplace")}
          >
            <Translate id="lbl.calculate" />
          </button>
        </PopupModal.Footer>
      </PopupModal>
    </div>
  );
}

function Header({ onClose }) {
  const isPdvFlow = usePdvFlowDetection();

  const translateId = isPdvFlow
    ? "text.calculateFiscalCode"
    : "text.notRememberFiscalCode";

  return (
    <PopupModal.Header closeMe={onClose}>
      <Translate id={translateId} />
    </PopupModal.Header>
  );
}
