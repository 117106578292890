export const REDIRECT_APP_URL = window.REG_REDIRECT_APP_URL;
export const MAIN_SITE_HOMEPAGE = window.REG_MAIN_SITE_HOMEPAGE;
export const THX_URL_REG = window.REG_THX_URL_REG;
export const KEY_RE_CAPTCHA = window.REG_KEY_RE_CAPTCHA;
export const REACT_APP_URL = window.REG_REACT_APP_URL;
export const LOGIN_APP_URL = window.REG_LOGIN_APP_URL;
export const URL_API_BONUS = window.REG_URL_API_BONUS;
export const DEFAULT_ENDCALLBACKURL = window.REG_DEFAULT_ENDCALLBACKURL;
export const LOGIN_REDIRECT = window.REG_LOGIN_REDIRECT;
export const PORT = window.REG_PORT;
export const APP_SUPERLOTTERIE_ANDROID = window.REG_APP_SUPERLOTTERIE_ANDROID;
export const APP_SUPERLOTTERIE_IOS = window.REG_APP_SUPERLOTTERIE_IOS;
export const APP_SUPERLOTTERIE_WEB = window.REG_APP_SUPERLOTTERIE_WEB;
export const FABRICK_SPID_ON_SUCCESS_REDIRECT_URL =
  window.REG_FABRICK_SPID_ON_SUCCESS_REDIRECT_URL;
export const FABRICK_SPID_ON_FAILURE_REDIRECT_URL =
  window.REG_FABRICK_SPID_ON_FAILURE_REDIRECT_URL;
export const FABRICK_SPID_BUTTON_FE_API_KEY =
  window.REG_FABRICK_SPID_BUTTON_FE_API_KEY;
export const FABRICK_SPID_ENVIRONMENT = window.REG_FABRICK_SPID_ENVIRONMENT;
