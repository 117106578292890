import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export const getLabels = async (language) => {
  const DefaultRequestId = "241f294b-5f29-4c0e-a943-4a00508ec9c3";
  let url = `${REACT_APP_URL}${PORT}/gestisci-lingua-ms/ms/language/getRegistrationLabels`;
  let data = {
    params: {
      requestId: DefaultRequestId,
      language: language,
      channel: 0,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};
