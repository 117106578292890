import ValidationCheck from "../validationCheck/validationCheck.component";
import ValidationCheckRow from "../validationCheckRow/validationCheckRow.component";

export default function ValidationCheckUsername({ username }) {
  const getRowClassName = (passesValidation) => {
    if (username == null || username === "") {
      return "";
    }
    return passesValidation ? "success" : "not-success";
  };

  return (
    <ValidationCheck>
      <ValidationCheckRow
        label="text.usernameCharBounds"
        rowClassName={getRowClassName(
          username.length >= 6 && username.length <= 16,
        )}
      />
      <ValidationCheckRow
        label="text.atLeastAZ"
        rowClassName={getRowClassName(/[A-Za-z]/.test(username))}
      />
    </ValidationCheck>
  );
}
