import { Translate } from "react-localize-redux";
import ErrorSisalLogo from "../../images/svg/errorCard/errorSisalLogo.svg";
import ErrorImg from "../../images/svg/errorCard/errorCircle.svg";
import PosImg from "../../images/svg/errorCard/pos.svg";

import { LOGIN_REDIRECT } from "../../config";
import { disableBeforeUnloadPopup } from "../../hooks/useBeforeUnloadPopup";

export default function ErrorCard({
  variant,
  hideButton,
  titleId,
  textId,
  buttonTextId,
  handleClick,
  className,
}) {
  const imgSrc =
    variant === "login"
      ? ErrorSisalLogo
      : variant === "info"
        ? PosImg
        : ErrorImg;

  const onClickRecoveryLink = () => {
    disableBeforeUnloadPopup();
  };

  const otherClassName = !!className ? ` ${className}` : "";

  return (
    <div className={`errorCardContainer${otherClassName}`}>
      <img className="logo" src={imgSrc} alt="logo" />

      <h2 className={`title ${!textId.length ? "title-large-mb" : ""}`}>
        <Translate id={titleId} />
      </h2>

      {textId.length > 0 && (
        <p className={`text ${hideButton ? "text_dnc" : ""}`}>
          <Translate id={textId} />
        </p>
      )}

      {!hideButton && (
        <button className={`button`} onClick={handleClick}>
          <Translate id={buttonTextId} />
        </button>
      )}

      {variant === "login" && (
        <a
          href={`${LOGIN_REDIRECT}/loginJwt/?recuperaPassword=true`}
          className="recovery_link"
          onClick={onClickRecoveryLink}
        >
          <Translate id="text.recoverypassword" />
        </a>
      )}
    </div>
  );
}
