export const INCREMENT_PDV_KO_CHECK_CRENDENTIALS_COUNTER =
  "INCREMENT_PDV_KO_CHECK_CRENDENTIALS_COUNTER";
export const INCREMENT_PHONE_NUMBER_ALREADY_PRESENT_COUNTER =
  "INCREMENT_PHONE_NUMBER_ALREADY_PRESENT_COUNTER";

export const incrementPdvKoCheckCrendentialsCounter = () => ({
  type: INCREMENT_PDV_KO_CHECK_CRENDENTIALS_COUNTER,
});

export const incrementPhoneNumberAlreadyPresentCounter = () => ({
  type: INCREMENT_PHONE_NUMBER_ALREADY_PRESENT_COUNTER,
});
