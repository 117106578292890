import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useRegBonus, useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import filter from "../../utility/filter";
import { moveCursorAtTheEnd } from "../../utility/input/input";
import SingleInputDate from "../commons/singleInputDate/singleInputDate.component";
import MessageError from "../elementForm/messageError/messageError.component";
import MessageYellow from "../elementForm/messageYellow/messageYellow";
import Town from "../elementForm/town/town.component";
import { spidErrTitleAndDesc } from "../identification/identification.config";
import { useSummaryFormErrorsTracking } from "../summary/summary.hooks";
import DocumentAlertBox from "./documentAlertBox/documentAlertBox.component";
import Tooltip from "./tooltip/tooltip.component";

export default function OcrForm({
  formData,
  formStatus,
  component,
  flusso,
  fromSpidFirstLandingDocument,
  addSpidStatus,
  disabledBtn,
  addFormData,
  addFormStatus,
  utagLink,
  utagView,
  hideUtag,
  documentType,
}) {
  const { goToNextStep } = useFlow();

  let [active, setActive] = useState(false);
  let [ocr, setOcr] = useState({
    number: false,
    expirationDate: false,
    releaseDate: false,
    documentTown: false,
  });
  const { getTranslatedString } = useTranslate();
  const [statusForm, setStatusForm] = useState(true);
  let [flag, setFlag] = useState({ number: false, documentTown: false });
  let [previousValue, setPreviousValue] = useState({
    number: formData.number,
    documentTown: formData.documentTown,
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const [docStateFromSpid, setDocStateFromSpid] = useState({
    isDocTownEmpty: false,
    isDocReleaseDateEmpty: false,
    isDocExpiredDateEmpty: false,
  });
  const documentInput = useRef();
  const expirationDateRef = useRef();
  const releaseDateRef = useRef();
  const documentTownRef = useRef();

  const reg_flusso = useRegFlusso();
  const reg_bonus = useRegBonus();

  const isAtLeastTabletBreakpoint = useMediaQuery("TabletMedium");

  useSummaryFormErrorsTracking("number", flag.number);
  useSummaryFormErrorsTracking("documentTown", flag.documentTown);

  const isValid = (name, value) => {
    setFlag({ ...flag, [name]: false });
    let limitedValue = value.toUpperCase();
    setOcr({ ...ocr, number: false });
    if (limitedValue.length > 20) {
      limitedValue = limitedValue.substring(0, 20);
    }
    if (/^[0-9A-Z]*$/.test(limitedValue)) {
      setFlag({ ...flag, [name]: false });
      addFormStatus({ [name]: "" });
      addFormData({ [name]: limitedValue });
      setStatusForm(checkingForm("ocr"));
    }
  };

  const isValidFreeSpace = (name, value) => {
    let filterData = filter("documentTown", value, "regex");
    if (filterData.boolean) {
      if (filterData.newValue.length > 50) {
        filterData.newValue = filterData.newValue.substring(0, 50);
      }
      if (/^\s+/.test(filterData.newValue)) {
        inputTrimmer(name, filterData.newValue);
      }
      setFlag({ ...flag, [name]: false });
      addFormData({ [name]: filterData.newValue });
      setOcr({ ...ocr, [name]: false });
    }
    setStatusForm(checkingForm("ocr"));
  };

  const inputTrimmer = (name, value) => {
    if (
      !!formData.releasedByEntityObj &&
      formData.releasedByEntityObj.description === "Altro emittente"
    )
      if (/\s+$/.test(value)) {
        addFormData({ [name]: value.trim() });
        setStatusForm(checkingForm("ocr"));
      }
  };

  const addReleaseEntityType = (index, id) => {
    if (id !== formData.releasedByEntity && component.name === "summary") {
      utagLink({
        reg_event: "riepilogo_modifica",
        reg_campo: "releasedByEntity",
      });
    }

    addFormData({
      documentTown: "",
      documentCityFiscalCode: "",
      documentProvinceCode: "",
    });
    addFormData({ releasedByEntity: id });
    addFormData({
      releasedByEntityObj:
        formData.documentTypeObj != null
          ? formData.documentTypeObj.availableIssuers[index]
          : undefined,
    });
    setActive(false);
    setStatusForm(checkingForm("ocr"));
  };

  const checkErrorReleaseDate = () => {
    if (
      ["12", "13"].indexOf(formData.documentType) === 0 &&
      moment(formData.releaseDate).format("YYYY") < 1960
    ) {
      addFormStatus({ releaseDate: "err.releaseDate.less1960" });
    } else if (
      moment(formData.releaseDate).isBefore(moment(formData.birthDate))
    ) {
      addFormStatus({
        releaseDate: "err.releaseDate.dateMajorBirthDate",
      });
    } else if (
      moment(formData.releaseDate).isBefore(
        moment().subtract(11, "year"),
        "day",
      )
    ) {
      addFormStatus({ releaseDate: "err.releaseDate.less11" });
    }
  };

  const handleFocus = (name, value) => {
    let check = filter(name, value.trim(), "formIsValid");
    addFormStatus({ number: "" + check.error.number });
    setOcr({ ...ocr, number: false });
    if (name === "number" && isAtLeastTabletBreakpoint) {
      setShowTooltip(false);
    }
  };

  const handleKeyDownRelease = (event) => {
    if (event.key === "Enter") {
      expirationDateRef.current.focus();
    }
  };

  const handleKeyDownExpiration = (event) => {
    if (event.key === "Enter") {
      expirationDateRef.current.blur();
    }
  };

  const handleKeyDownDocumentTown = (event) => {
    if (event.key === "Enter") {
      documentTownRef.current.blur();
    }
  };

  const handleBlur = (name, value) => {
    if (name === "documentTown") {
      inputTrimmer(name, value);
      let check = filter(name, value.trim(), "formIsValid");
      addFormStatus({ documentTown: "" + check.error.documentTown });
    }
    if (name === "number") {
      let check = filter(name, value.trim(), "formIsValid");
      addFormStatus({ number: "" + check.error.number });
      setOcr({ ...ocr, number: false });
    }

    if (
      previousValue[name] !== formData[name] &&
      component.name === "summary"
    ) {
      utagLink({
        reg_event: "riepilogo_modifica",
        reg_campo: name,
      });
    }
    setPreviousValue({ ...previousValue, [name]: formData[name] });

    setFlag({ ...flag, [name]: true });
    setStatusForm(checkingForm("ocr"));
  };

  const onKeyPressed = (key) => {
    if (key === "Enter" && component.name !== "summary") {
      inputTrimmer("documentTown", formData.documentTown.trim());
      setStatusForm(checkingForm("ocr"));
      if (!checkingForm("ocr")) {
        goToNextStep();
      }
    }
    if (key === "Enter") {
      releaseDateRef.current.focus();
    }
  };

  useEffect(() => {
    if (component.name === "ocrForm" && window.screen.width >= 899) {
      documentInput.current?.focus();
    }
  }, [component.name]);

  useEffect(() => {
    setOcr({ ...ocr, releaseDate: false });
    checkErrorReleaseDate();
    setStatusForm(checkingForm("ocr"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStatus.releaseDateisChanging]);

  useEffect(() => {
    setOcr({ ...ocr, expirationDate: false });
    setStatusForm(checkingForm("ocr"));
  }, [formStatus.expirationDateisChanging]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOcr({ ...ocr, documentTown: false });
    setStatusForm(checkingForm("ocr"));
  }, [formStatus.documentTownisChanging]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setOcr({
      number: formData.ocrData,
      expirationDate: formData.ocrData,
      releaseDate: formData.ocrData,
      documentTown: formData.ocrData,
    });
    if (formData.documentTown === "" && formData.ocrData) {
      addFormStatus({ documentTown: "err.empty.value" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.ocrData]);

  useEffect(() => {
    if (
      !formStatus.releaseDateisChanging &&
      !formStatus.expirationDateisChanging
    )
      disabledBtn(statusForm);
    else disabledBtn(true);
  }, [
    disabledBtn,
    formStatus.expirationDateisChanging,
    formStatus.releaseDateisChanging,
    statusForm,
  ]);

  useEffect(() => {
    if (active) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [active]);

  useEffect(() => {
    if (
      formData.releasedByEntityObj === null &&
      formData.documentTypeObj &&
      formData.documentTypeObj.availableIssuers
    ) {
      addFormData({
        releasedByEntityObj: formData.documentTypeObj.availableIssuers[0],
      });
      addFormData({
        releasedByEntity: formData.documentTypeObj.availableIssuers[0].id,
      });
    }
  }, [formData.documentTypeObj]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hideUtag) return;

    if (component.name === "ocrForm") {
      if (!fromSpidFirstLandingDocument) {
        const ocrReg =
          !formData.hasDocFile || !formData.ocrData
            ? "non utilizzato"
            : formData.ocrData &&
                (formData.documentTown !== "" ||
                  formData.expirationDate !== "" ||
                  formData.number !== "" ||
                  formData.releaseDate !== "")
              ? "completo"
              : "parziale";
        const docReg = formData.documentTypeObj
          ? formData.documentTypeObj.description
          : "";

        utagView({
          reg_step: "documento-dati",
          ...(reg_bonus ? { reg_bonus } : {}),
          reg_flusso,
          reg_doc: docReg,
          reg_ocr: ocrReg,
        });
      } else if (fromSpidFirstLandingDocument) {
        utagView({
          reg_section: "registrazione",
          reg_step: "upload-documento",
          reg_flusso,
        });
      }
    }

    return () => {
      if (fromSpidFirstLandingDocument) {
        addSpidStatus({
          fromSpidFirstLandingDocument: false,
        });
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const trackDocumentSpidAnalytics = () => {
    const { title } = spidErrTitleAndDesc(formStatus.documentSpidError);
    const error_message = getTranslatedString(title);
    utagLink(
      {
        reg_event: "errore-documento-identità",
        conversion_pagename: "documento-selezione",
        error_message: error_message,
        reg_doc: formData.documentTypeObj.description,
        reg_flusso: "spid",
      },
      { delay: 1000 },
    );
  };

  useEffect(() => {
    if (flusso === "SPID" && !!formStatus.documentSpidError) {
      if (
        formStatus.documentSpidError === "err.document.expired" ||
        formStatus.documentSpidError === "err.document.notAccepted" ||
        formStatus.documentSpidError === "err.document.genericErr"
      ) {
        addFormData({
          releaseDate: "",
          expirationDate: "",
          number: "",
          documentTypeObj: documentType.documentType[0],
          documentType: documentType.documentType[0].id,
          releasedByEntity: documentType.documentType[0].availableIssuers[0].id,
          releasedByEntityObj: {
            description:
              documentType.documentType[0].availableIssuers[0].description,
            id: documentType.documentType[0].availableIssuers[0].id,
          },
        });
        addFormStatus({ expirationDate: "" });
      } else if (formStatus.documentSpidError === "err.document.missingData") {
        if (formData.number === "") {
          setFlag({ number: true });
        }
        if (formData.documentTown === "") {
          !!formData.releasedByEntityObj &&
          formData.releasedByEntityObj.description === "Altro emittente"
            ? setFlag({ documentTown: true })
            : setDocStateFromSpid((prevState) => ({
                ...prevState,
                isDocTownEmpty: true,
              }));
        }
        if (formData.releaseDate === "") {
          addFormStatus({ releaseDate: "err.required" });
          setDocStateFromSpid((prevState) => ({
            ...prevState,
            isDocReleaseDateEmpty: true,
          }));
        }
        if (formData.expirationDate === "") {
          addFormStatus({ expirationDate: "err.required" });
          setDocStateFromSpid((prevState) => ({
            ...prevState,
            isDocExpiredDateEmpty: true,
          }));
        }
      }

      trackDocumentSpidAnalytics();
    }
  }, [formStatus.documentSpidError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="container step-container ocrForm"
      id="it-ar-ocrform-container"
    >
      <div className={"row"}>
        <div
          className={
            (component.name === "ocrForm" || component.name === "summary"
              ? "col-12"
              : "") +
            (component.name === "ocrForm"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : "")
          }
        >
          <form>
            <div className={"form-group-relative"}>
              <div className={"form-group md-5"} id="it-ar-ocrform-form-group1">
                <input type="text" name="address" style={{ display: "none" }} />
                <input
                  ref={documentInput}
                  autoComplete="new-password"
                  className={
                    "form-control" +
                    (formStatus.number.length > 0 && flag.number
                      ? formStatus.number === "err.required" &&
                        component.name !== "summary"
                        ? " is-present"
                        : "  is-invalid"
                      : formData.number === "" && ocr.number
                        ? " is-present"
                        : "")
                  }
                  type="text"
                  maxLength="20"
                  spellCheck="false"
                  autoCorrect="off"
                  name="documentNumber"
                  data-id="it-ar-ocrform-documentNumber"
                  id="input_numero_documento"
                  required
                  value={formData.number}
                  onChange={(e) => isValid("number", e.target.value)}
                  onKeyDown={(e) => onKeyPressed(e.key)}
                  onFocus={(e) => {
                    moveCursorAtTheEnd(e.target);
                    handleFocus("number", e.target.value);
                  }}
                  onBlur={(e) => handleBlur("number", e.target.value)}
                />

                <span
                  onClick={() => setShowTooltip(true)}
                  className="question-mark"
                />

                {showTooltip && (
                  <Tooltip handleCloseTooltip={() => setShowTooltip(false)} />
                )}

                <label
                  className="form-control-label"
                  id="it-ar-ocrform-lblDocumentNumber"
                  htmlFor="input_numero_documento"
                >
                  <Translate id="lbl.documentNumber" />
                </label>
                {flag.number ? (
                  <MessageError
                    currentValue={formData.number}
                    currentName={"number"}
                    customAlert={"err.insertDocumentNumber"}
                  />
                ) : (
                  ""
                )}
              </div>
              {formData.number === "" && ocr.number ? <MessageYellow /> : ""}
            </div>

            <div className="document-dates-container">
              <SingleInputDate
                ref={releaseDateRef}
                currentName="releaseDate"
                label="lbl.releaseDate"
                canShowErrorBeforeFirstFocus={fromSpidFirstLandingDocument}
                onKeyDown={handleKeyDownRelease}
                afterBlur={checkErrorReleaseDate}
                hasErrFromSpid={docStateFromSpid.isDocReleaseDateEmpty}
              />
              <SingleInputDate
                ref={expirationDateRef}
                currentName="expirationDate"
                label="lbl.expirationDate"
                canShowErrorBeforeFirstFocus={fromSpidFirstLandingDocument}
                onKeyDown={handleKeyDownExpiration}
                hasErrFromSpid={docStateFromSpid.isDocExpiredDateEmpty}
              />
            </div>

            <div
              id="it-ar-ocrform-form-group4"
              className={
                "form-group " +
                (component.name === "summary" ? "mb-1 mt-2" : "mb-1 mt-2")
              }
              tabIndex="0"
              onBlur={() => setActive(false)}
            >
              <label
                className="form-control-label isSpecial"
                id="it-ar-ocrform-lblReleaseFrom"
              >
                <Translate id="lbl.releaseFrom" />
              </label>
              <div
                id="it-ar-ocrform-select-customer"
                className={
                  component.name === "summary"
                    ? " form-control accordion-ocr"
                    : "select-customer selectType"
                }
                onMouseDown={() => setActive(true)}
              >
                <label
                  data-id="it-ar-ocrform-releasedbyentityobj"
                  id="list_rilascio_autorita"
                >
                  {formData.releasedByEntityObj != null
                    ? formData.releasedByEntityObj.description
                    : undefined}
                </label>
              </div>
              <ul
                className={
                  "list-group opened-list issuers-list " +
                  (active ? "" : "d-none")
                }
              >
                {formData.documentTypeObj &&
                formData.documentTypeObj.availableIssuers
                  ? formData.documentTypeObj.availableIssuers.map(
                      (option, index) => (
                        <li
                          className="list-group-item col-12"
                          onMouseDown={(e) =>
                            addReleaseEntityType(index, e.target.id)
                          }
                          key={index}
                          id={option.id}
                        >
                          {option.description}
                        </li>
                      ),
                    )
                  : null}
              </ul>
            </div>

            <div className={"form-group mb-1 form-group-relative"}>
              {formData.releasedByEntityObj?.description ===
              "Altro emittente" ? (
                <>
                  <input
                    ref={documentTownRef}
                    autoComplete="off"
                    className={
                      "form-control " +
                      (flag.documentTown && formStatus.documentTown !== ""
                        ? formStatus.documentTown === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    type="text"
                    maxLength="50"
                    name="documentTown"
                    id="input_rilascio_paese"
                    required
                    value={formData.documentTown}
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onKeyDown={handleKeyDownDocumentTown}
                    onChange={(e) =>
                      isValidFreeSpace(e.target.name, e.target.value)
                    }
                    onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                  />
                  <label
                    className="form-control-label"
                    htmlFor="input_rilascio_paese"
                  >
                    <Translate id="lbl.docReleaseLocation" />
                  </label>
                  {flag.documentTown ? (
                    <MessageError
                      currentValue={formData.documentTown}
                      currentName={"documentTown"}
                    />
                  ) : null}
                </>
              ) : (
                <div className="form-group-relative">
                  <Town
                    inputId="docCity"
                    nameForm="ocr"
                    trackingId="input_rilascio_paese"
                    paramTown="documentTown"
                    paramProvinceCode="documentProvinceCode"
                    paramCityFiscalCode="documentCityFiscalCode"
                    paramId="documentId"
                    labelId="lbl.docReleaseLocation"
                    townEmptyFromSpid={docStateFromSpid.isDocTownEmpty}
                  />

                  {formData.documentTown === "" && ocr.documentTown && (
                    <MessageYellow />
                  )}

                  <DocumentAlertBox />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <div
        className="overlay d-none"
        onClick={() => setActive(false)}
        onTouchMove={() => setActive(false)}
        onTouchStart={() => setActive(false)}
      />
    </div>
  );
}
