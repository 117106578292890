import { pick } from "lodash";
import { useRef } from "react";
import { useSelector, useStore } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import SignButton from "../../signButton/signButton.component";

export default function UnmatchingPersonalDataSignupButton() {
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const { getState } = useStore();

  const initialDataRef = useRef(
    pick(formDataSelectors.getFormData(getState()), [
      "fiscalCode",
      "name",
      "surname",
      "gender",
      "bithDate",
      "birthState",
      "birthProvince",
      "birthTown",
    ]),
  );

  const untouchedData =
    initialDataRef.current.fiscalCode === formData.fiscalCode &&
    initialDataRef.current.name === formData.name &&
    initialDataRef.current.surname === formData.surname &&
    initialDataRef.current.birthDate === formData.birthDate &&
    initialDataRef.current.birthState?.description ===
      formData.birthState?.description &&
    initialDataRef.current.birthProvince === formData.birthProvince &&
    initialDataRef.current.birthTown === formData.birthTown;

  const formHasErrors =
    !!formStatus.fiscalCode ||
    !!formStatus.name ||
    !!formStatus.surname ||
    !!formStatus.birthDate ||
    !!formStatus.birthTown ||
    !!formStatus.cityEsteroBirth;

  const disabled = untouchedData || formHasErrors;

  return (
    <div className="error-page-sign-up-button-container">
      <SignButton disabled={disabled} />
    </div>
  );
}
