import { useCallback, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { getBonus } from "../../services/bonusController";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { utagView } from "../../store/utagStore/utagStore.action";
import { wizardSelectorsBonus } from "../../store/wizard/wizard.selectors";

export function useWelcomeBonusFetch() {
  const dispatch = useDispatch();

  const { channel, fromPdv, promoCode } = useSelector(
    formDataSelectors.getFormData,
  );
  const bonus = useSelector(wizardSelectorsBonus.getBonus);

  const bonusCode = bonus?.bonusCode;

  return useCallback(async () => {
    try {
      const response = await getBonus(channel, fromPdv);

      if (!Array.isArray(response.bonus)) {
        return null;
      }

      const sortedBonus = response.bonus.slice().sort((a, b) => {
        if (a.canali?.[0] && b.canali?.[0]) {
          return a.canali[0].orderNumber - b.canali[0].orderNumber;
        } else {
          return 0;
        }
      });

      dispatch(
        addFormData({ bonusList: sortedBonus, bonusListAll: sortedBonus }),
      );

      const foundBonus = sortedBonus.some((el) => el.bonusCode === promoCode);
      if (foundBonus) {
        dispatch(addFormData({ findBonus: true }));
      }

      if (bonusCode != null) {
        const bonusList = sortedBonus.filter(
          (el) => el.bonusCode !== bonusCode,
        );
        dispatch(addFormData({ bonusList }));
      }

      return response.bonus;
    } catch (err) {
      console.error(err);

      if (err.response?.status === 422) {
        const nameError = Object.keys(err.response.data.error.errorInfos)[0];
        const errorMessage = err.response.data.error.errorInfos[nameError][0];
        dispatch(addFormStatus({ bonusCode: errorMessage }));
      }

      return null;
    }
  }, [bonusCode, channel, dispatch, fromPdv, promoCode]);
}

export function useSelectedBonusDetection() {
  const { bonusCode, promoCode } = useSelector(formDataSelectors.getFormData);

  return useCallback(
    (bonusItem) =>
      bonusCode === bonusItem?.bonusCode?.toUpperCase() ||
      promoCode === bonusItem?.bonusCode?.toUpperCase(),
    [bonusCode, promoCode],
  );
}

export function useAnalyticsUtagView() {
  const dispatch = useDispatch();

  const { getState } = useStore();

  const reg_flusso = useRegFlusso();

  const reg_doc = useRegDoc();

  useEffect(() => {
    const formData = formDataSelectors.getFormData(getState());

    const getPromoCode = () => {
      if (formData.bonusCode && formData.bonusCode !== "") {
        return formData.bonusCode;
      } else if (formData.promoCode && formData.promoCode !== "") {
        return formData.promoCode;
      } else {
        return "nessun codice";
      }
    };

    dispatch(
      utagView({
        conversion_pagename: "scelta-bonus",
        reg_step: "scelta-bonus",
        promo_code: getPromoCode(),
        reg_flusso,
        ...(reg_flusso === "ocr" || reg_flusso === "ocr rivenditori"
          ? { country: "italia", reg_doc }
          : {}),
      }),
    );
  }, [dispatch, getState, reg_doc, reg_flusso]);
}
