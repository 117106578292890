import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { useRegFlusso } from "../../../hooks/useAnalytics";
import success from "../../../images/svg/success.svg";
import { utagLink, utagView } from "../../../store/utagStore/utagStore.action";
import PopupModal from "../../popupModal/popupModal";

export default function SuccessPopup({ closeModal, sisalPoints }) {
  const dispatch = useDispatch();

  const reg_flusso = useRegFlusso();

  useEffect(() => {
    dispatch(
      utagView({
        reg_step: "registrazione:completamento codice giocata:loyalty",
        conversion_pagename:
          "registrazione:completamento codice giocata:loyalty",
        reg_flusso,
      }),
    );
  }, [dispatch, reg_flusso]);

  const trackPopupClose = (reg_cta) => {
    dispatch(
      utagLink({
        reg_event: "completamento-codice-giocata-cta-click",
        reg_cta,
        conversion_pagename:
          "registrazione:completamento codice giocata:loyalty",
        reg_flusso,
      }),
    );
  };

  const handleClose = () => {
    trackPopupClose("chiudi");
    closeModal();
  };

  const handleClickContinue = () => {
    trackPopupClose("continua la registrazione");
    closeModal();
  };

  return (
    <div id="success-modal-loyalty">
      <PopupModal>
        <PopupModal.Header closeMe={handleClose}>
          <Translate id="text.insert.gameCode" />
        </PopupModal.Header>

        <PopupModal.Body>
          <div className="popupmodal-img">
            <img alt="success" src={success} />
          </div>

          <h6>
            <Translate id="text.insertedCodePlayed.successfully" />
          </h6>

          <p>
            {sisalPoints ? (
              <b>
                <Translate
                  id="text.withSuperEnalotto.youHadWon"
                  data={{ points: sisalPoints }}
                />
              </b>
            ) : (
              <b>
                <Translate id="text.youWillGetSisalPoints" />
              </b>
            )}
          </p>

          <p>
            <Translate id="text.completeRegistrationLoyalty" />
          </p>
        </PopupModal.Body>

        <PopupModal.Footer>
          <button id="close-success-loyalty-btn" onClick={handleClickContinue}>
            <Translate id="lbl.promocode.continue" />
          </button>
        </PopupModal.Footer>
      </PopupModal>
    </div>
  );
}
