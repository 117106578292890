import CodiceFiscale from "codice-fiscale-js";
import moment from "moment";
import store from "../../store.config";
import { documentTypeSelectors } from "../../store/documentType/documentType.selectors";

export function ocrDataToFormData(ocrData, cities) {
  const documentTypeObj = getDocumentTypeObj(ocrData.documentType);

  return {
    name: ocrData.name,
    surname: ocrData.familyName,
    birthId: ocrData.birthId ?? "",
    birthTown: ocrData.birthTown ?? "",
    birthProvince: ocrData.birthProvince ?? "",
    birthCityFiscalCode: ocrData.birthCityFiscalCode ?? "",
    birthDate: ocrData.birthDate ? convertToFormDate(ocrData.birthDate) : "",
    ...(ocrData.birthState && { birthState: ocrData.birthState }),
    gender: ocrData.gender ?? "",
    address: ocrData.address != null ? ocrData.address.substring(0, 35) : "",
    ...(ocrData.state && { state: ocrData.state }),
    province: ocrData.province ?? "",
    city: ocrData.city ?? "",
    cityFiscalCode: ocrData.cityFiscalCode ?? "",
    cap: ocrData.cap ?? "",
    ...(ocrData.documentType && { documentType: ocrData.documentType }),
    documentId: getCityId(cities, ocrData.documentTown),
    documentTypeObj,
    number: ocrData.number,
    releaseDate: ocrData.releaseDate
      ? convertToFormDate(ocrData.releaseDate)
      : "",
    expirationDate: ocrData.expirationDate
      ? convertToFormDate(ocrData.expirationDate)
      : "",
    releasedByEntity: ocrData.releasedByEntity,
    releasedByEntityObj: getReleasedByEntityObj(
      documentTypeObj,
      ocrData.releasedByEntity,
    ),
    documentTown: ocrData.documentTown ?? "",
    documentProvinceCode: ocrData.documentProvinceCode ?? "",
    documentCityFiscalCode: ocrData.documentCityFiscalCode ?? "",
    fiscalCode: getValidatedFiscalCode(ocrData.fiscalCode),
  };
}

function getCityId(cities, city) {
  if (city == null) return null;

  const foundCity = cities.find(
    ({ description }) =>
      description.toLowerCase().trim() === city.toLowerCase().trim(),
  );
  return foundCity?.id ?? null;
}

function getDocumentTypeObj(documentTypeId) {
  const state = store.getState();

  return documentTypeSelectors
    .getDocumentType(state)
    .documentType.find((value) => {
      const valueInt = parseInt(value.id);
      const documentTypeIdInt = parseInt(documentTypeId);
      return (
        Number.isInteger(valueInt) &&
        Number.isInteger(documentTypeIdInt) &&
        valueInt === documentTypeIdInt
      );
    });
}

function getReleasedByEntityObj(documentTypeObj, releasedByEntity) {
  const releasedByEntityObj = documentTypeObj?.availableIssuers.find(
    (issuer) => issuer.id === releasedByEntity,
  );
  return releasedByEntityObj ?? null;
}

function convertToFormDate(value) {
  return moment(value, "DD/MM/YYYY")
    .add(1, "hour")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}

function getValidatedFiscalCode(fiscalCodeFromOCR) {
  try {
    const sanitizedFiscalCode = sanitizeFiscalCode(fiscalCodeFromOCR);
    new CodiceFiscale(sanitizedFiscalCode);
    return sanitizedFiscalCode;
  } catch {
    console.error(`Invalid fiscal code from OCR ${fiscalCodeFromOCR}`);
    return "";
  }
}

// This function is used because Jumio OCR has proven unable to distinguish reliably
// between letters and digits.
function sanitizeFiscalCode(fiscalCodeFromOCR) {
  const convertDigitToLetter = (digit) => {
    switch (digit) {
      case "1":
        return "I";
      case "0":
        return "O";
      default:
        return digit;
    }
  };

  const convertLetterToDigit = (letter) => {
    switch (letter) {
      case "I":
        return "1";
      case "O":
        return "0";
      default:
        return letter;
    }
  };

  if (fiscalCodeFromOCR.length !== 16) {
    return fiscalCodeFromOCR;
  }

  const sanitizedFiscalCode = fiscalCodeFromOCR
    .split("")
    .map((character, index) => {
      if (index < 6) return convertDigitToLetter(character);
      else if (6 <= index && index < 8) return convertLetterToDigit(character);
      else if (index === 8) return convertDigitToLetter(character);
      else if (9 <= index && index < 11) return convertLetterToDigit(character);
      else if (index === 11) return convertDigitToLetter(character);
      else if (index < 15) return convertLetterToDigit(character);
      else return convertDigitToLetter(character);
    })
    .join("");

  return sanitizedFiscalCode;
}
