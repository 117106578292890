import RegexCheck from "./regexCheck/regexCheck";
import formIsValid from "./formIsValid/formIsValid.container";

const scomposition = (value) => {
  if (/[—‘’]/.test(value)) {
    if (value.indexOf("—") !== -1) {
      value = value.replace("—", "--");
    }
    if (value.indexOf("‘") !== -1) {
      value = value.replace("‘", "'");
    }
    if (value.indexOf("’") !== -1) {
      value = value.replace("’", "'");
    }
  }
  return value;
};

const filter = (name, value, select) => {
  value = scomposition(value);
  switch (select) {
    case "regex":
      return { newValue: value, boolean: RegexCheck(name, value) };
    case "formIsValid":
      return { newValue: value, error: formIsValid(name, value) };
    default:
      return { newValue: value };
  }
};

export default filter;
