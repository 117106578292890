const getValidateUsername = (state) => {
  return state.validateUsernameReducer;
};

export const validateUsernameSelectors = {
  getValidateUsername,
};

export function selectUsernameHasBeenValidated(state) {
  return getValidateUsername(state).usernameHasBeenValidated;
}
