const getWizardStep = (state) => {
  return state.wizard.step;
};

export const wizardSelectorsStep = {
  getWizardStep,
};

const getWizardBtn = (state) => {
  return state.wizard.disabled;
};

export const wizardSelectorsBtn = {
  getWizardBtn,
};

const getWizardIsLoading = (state) => {
  return state.wizard.isLoading;
};

export const wizardSelectorsIsLoading = {
  getWizardIsLoading,
};

const getComponent = (state) => {
  return state.wizard.setComponent;
};

export const wizardSelectorsComponent = {
  getComponent,
};

const getWizardIsVisiblePhonePopup = (state) => {
  return state.wizard.setIsVisiblePhonePopup;
};

const getWizardIsPhonePopupOtpSended = (state) => {
  return state.wizard.isPhonePopupOtpSended;
};

const getWizardIsOtpNotValid = (state) => {
  return state.wizard.isOtpNotValid;
};

const getWizardOtpBlockStatus = (state) => {
  return state.wizard.otpBlockStatus;
};

const getWizardIsVerifyPhone = (state) => {
  return state.wizard.isVerifiedPhone;
};

const getWizardSmsEnabled = (state) => {
  return state.wizard.setSmsEnabled;
};

export const wizardSelectorsIsVisiblePhonePopup = {
  getWizardIsVisiblePhonePopup,
};

export const wizardSelectorsIsPhonePopupOtpSended = {
  getWizardIsPhonePopupOtpSended,
};

export const wizardSelectorsIsOtpNotValid = {
  getWizardIsOtpNotValid,
};

export const wizardSelectorsOtpBlockStatus = {
  getWizardOtpBlockStatus,
};

export const wizardSelectorsSmsEnabled = {
  getWizardSmsEnabled,
};

export const wizardSelectorsVerifyPhone = {
  getWizardIsVerifyPhone,
};

const getBonus = (state) => {
  return state.wizard.setBonus;
};

export const wizardSelectorsBonus = {
  getBonus,
};

const getWizardFlow = (state) => {
  return state.wizard.flow;
};

const getWizardDefaultFlowName = (state) => {
  return state.wizard.defaultFlowName;
};

const getWizardDefaultClassicFlowName = (state) => {
  return state.wizard.defaultClassicFlowName;
};

export const wizardSelectorsFlow = {
  getWizardFlow,
  getWizardDefaultFlowName,
  getWizardDefaultClassicFlowName,
};

const getFlusso = (state) => {
  return state.wizard.flusso;
};

export const wizardSelectorsFlusso = {
  getFlusso,
};

export const getSignUpCompleted = (state) => {
  return state.wizard.signUpCompleted;
};

export const wizardSelectorsSignUpStatus = {
  getSignUpCompleted,
};
