import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { wizardSelectorsBonus } from "../../../store/wizard/wizard.selectors";
import { getActiveBonusList } from "../welcomeBonus.helpers";
import WelcomeBonusOthers from "../welcomeBonusOthers/welcomeBonusOthers.component";
import WelcomeBonusSelectedCard from "../welcomeBonusSelectedCard/welcomeBonusSelectedCard.component";

export default function WelcomeBonusPromoCodeBlock({
  onClickChangeButton,
  onClickInfoLink,
}) {
  const bonus = useSelector(wizardSelectorsBonus.getBonus);

  return (
    <>
      {!!bonus && (
        <WelcomeBonusSelectedCard
          bonusItem={bonus}
          onClickChangeButton={onClickChangeButton}
        />
      )}

      <OtherBonuses
        onClickChangeButton={onClickChangeButton}
        onClickInfoLink={onClickInfoLink}
      />
    </>
  );
}

function OtherBonuses({ onClickChangeButton, onClickInfoLink }) {
  const { channel, bonusList } = useSelector(formDataSelectors.getFormData);
  const bonus = useSelector(wizardSelectorsBonus.getBonus);

  const otherBonusList = getActiveBonusList(bonusList, channel).filter(
    (item) => item.bonusCode !== bonus?.bonusCode,
  );

  return (
    <WelcomeBonusOthers
      otherBonusList={otherBonusList}
      onClickChangeButton={onClickChangeButton}
      onClickInfoLink={onClickInfoLink}
    />
  );
}
