import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import SubNote from "../../../commons/subNote/subNote.component";
import PopupModal from "../../../popupModal/popupModal";
import {
  postCheckOtp,
  postGenerateOtp,
} from "../../../../services/otpController";
import verifiedImg from "../../../../images/svg/popupModal/verified.svg";
import { useHistory } from "react-router-dom";

const hideEmail = (email) => {
  if (email) {
    const [firstpart, endpart] = email.split("@");
    return firstpart.replaceAll(/./g, "*") + "@" + endpart;
  }
};

const TYPCModalOtpStep = ({
  email,
  formData,
  isOtpNotValid,
  isOtpSended,
  nextStep,
  otpBlockStatus,
  setIsEmailVerified,
  setIsLoading,
  setIsOtpNotValid,
  setIsOtpSended,
  setOtpBlockStatus,
  utagView,
}) => {
  const history = useHistory();
  const [otpCode, setOTPCode] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [timerStatus, setTimerStatus] = useState(true);
  const [feedback, setFeedback] = useState(false);

  const clearMessageLabelsFlag = () => {
    setOtpError(false);
    setIsOtpNotValid(false);
    setIsOtpSended(false);
    setOtpBlockStatus({ ...otpBlockStatus, invalided: false });
  };

  const onChange = (value) => {
    if (/[ ]/.test(value)) return;
    if (isNaN(+value) && value !== "") return;
    if (value.length > 4) return;
    clearMessageLabelsFlag();
    setOTPCode(value);
  };

  const checkValidate = () =>
    !otpError && otpCode.length === 4 ? true : false;

  const generateOtpCode = async () => {
    setIsLoading(true);
    setOTPCode("");
    clearMessageLabelsFlag();
    postGenerateOtp(formData, {
      email,
    })
      .then((r) => {
        setIsLoading(false);
        setTimerStatus(true);
        setIsOtpSended(true);
        if (r.counterRefresh > 2) {
          setOtpBlockStatus({ ...otpBlockStatus, blocked: true });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (
          e.response.status === 422 &&
          e.response.data.error?.errorInfos?.generic[0] === "err.otp.block"
        ) {
          setOtpBlockStatus({
            blocked: false,
            invalided: false,
          });
        } else {
          history.push("/errore-registrazione");
        }
      });
  };

  const checkOtpCode = () => {
    const isVerify = formData.email === email;
    setIsLoading(true);

    postCheckOtp(formData, { email }, otpCode, isVerify)
      .then(() => {
        setIsLoading(false);
        setIsEmailVerified(true);
        setFeedback(true);
        setTimeout(() => {
          nextStep();
        }, 1500);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response && e.response.status === 422) {
          setOTPCode("");
          if (
            e.response.data.error?.errorInfos?.generic[0] === "err.generic.otp"
          ) {
            e.response.data.counterCheck && e.response.data.counterCheck < 3
              ? setOtpError(true)
              : setIsOtpNotValid(true);
          } else if (
            e.response.data.error?.errorInfos?.generic[0] ===
            "err.otp.expired.session"
          ) {
            setIsOtpNotValid(true);
          } else if (
            e.response.data.error?.errorInfos?.generic[0] === "err.otp.block"
          ) {
            setOtpBlockStatus({
              blocked: true,
              invalided: true,
            });
          }
        } else {
          history.push("/errore-registrazione");
        }
      });
  };

  useEffect(() => {
    let intervalId = null;
    if (timerStatus) {
      let tl = 10;
      intervalId = setInterval(() => {
        if (tl > 1) {
          --tl;
        } else {
          setTimerStatus(false);
        }
      }, 1050);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [timerStatus]);

  useEffect(() => {
    if (feedback) {
      utagView({
        pagename: `popup:verifica-mail::otp_mail:ok`,
        page_type: "popup-servizio",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback]);

  return (
    <div id="typ-ccm-otp-step">
      <PopupModal.Body>
        <>
          {feedback && (
            <div className="otp-popup-verified-overlay">
              <div className="otp-popup-verified">
                <div className="otp-popup-verified-img">
                  <img alt="verified" src={verifiedImg} />
                </div>
                <div className="otp-popup-verified-title">
                  <span>
                    <Translate id="text.otp.verified" />
                  </span>
                </div>
              </div>
            </div>
          )}
          <p className="modal-text">
            <Translate id="text.emailTYP.otp.body" /> <b>{hideEmail(email)}</b>
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={`form-group`}>
              <input
                autoComplete="off"
                className={`form-control ${
                  otpError || isOtpNotValid || otpBlockStatus.invalided
                    ? "is-invalid"
                    : ""
                }`}
                type="tel"
                name="otp-code"
                maxLength="4"
                required
                spellCheck="false"
                autoCorrect="off"
                value={otpCode}
                onChange={(e) => onChange(e.target.value)}
              />
              <label className="form-control-label">
                <Translate id="text.otp.securityCode" />
              </label>
              {isOtpSended && (
                <div className={`message-label resend`}>
                  <Translate id="text.resendOtpEmail" />
                </div>
              )}
              {otpError ? (
                <div className="message-label invalid">
                  <Translate id="text.otpMsg" />
                </div>
              ) : isOtpNotValid ? (
                <div className="message-label invalid">
                  <Translate id="text.invalidOtpMsg" />
                </div>
              ) : otpBlockStatus.invalided ? (
                <div className="message-label invalid">
                  <Translate id="text.blockOtpMsg" />
                </div>
              ) : null}
            </div>

            {otpBlockStatus.blocked ? (
              <SubNote text="text.waitForResend.long" />
            ) : (
              !timerStatus && (
                <p className="otp-resend">
                  <Translate id="text.nonRiceviSms" />
                  <a
                    href="."
                    className="otp-resend-link"
                    onClick={(e) => {
                      e.preventDefault();
                      generateOtpCode();
                    }}
                  >
                    <Translate id="lbl.resend" />
                  </a>
                </p>
              )
            )}
          </form>
        </>
      </PopupModal.Body>
      <PopupModal.Footer>
        <button
          disabled={!checkValidate()}
          onClick={(e) => {
            e.preventDefault();
            checkOtpCode();
          }}
        >
          <Translate id="lbl.verify" />
        </button>
      </PopupModal.Footer>
    </div>
  );
};

export default TYPCModalOtpStep;
