import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";

export default function SummaryRecaptcha() {
  const { channel } = useSelector(formDataSelectors.getFormData);

  const privacyUrl =
    channel === "62"
      ? "https://policies.google.com/privacy"
      : "https://policies.google.com/privacy?open=https%3A%2F%2Fpolicies.google.com%2Fprivacy";

  const termsUrl =
    channel === "62"
      ? "https://policies.google.com/terms"
      : "https://policies.google.com/terms?open=https%3A%2F%2Fpolicies.google.com%2Fterms";

  return (
    <div className="re-captcha">
      This site is protected by reCAPTCHA and the Google&nbsp;
      <a href={privacyUrl} rel="noopener noreferrer" target="_blank">
        Privacy Policy
      </a>
      &nbsp;and&nbsp;
      <a href={termsUrl} rel="noopener noreferrer" target="_blank">
        Terms of Service
      </a>
      &nbsp;apply.
    </div>
  );
}
