export const SpidErrors = {
  errEmailAlreadyPresent: "err.mail.alreadypresent",
  errPhoneNumberAlreadyPresent: "err.phoneNumber.alreadypresent",
  errPhoneNumberNoItalianPrefix: "err.phoneNumber.noItalianPrefix",
  errFiscalCodeNoEmployersAllowed: "err.fiscalcode.noemployersallowed",
  errFiscalCodeUserSelfExcluded: "err.fiscalcode.userselfexcluted",
  errFiscalCodeRecentlyClosedAccount: "err.fiscalcode.recentlyclosedaccount",
  errFiscalCodeMultipleClosedAccounts: "err.fiscalcode.multipleclosedaccounts",
  errFiscalCodeUserUnderage: "err.fiscalcode.userunderage", // err.8008 PGAD
  errFiscalCodeUserOver100YearsOld: "err.fiscalcode.userover100yearsold",
  errFiscalCodeAlreadyPresent: "err.fiscalcode.alreadypresent", // err 3502
  errFiscalCodeActivationAlreadyStarted:
    "err.fiscalcode.activationalreadystarted", // err 2023
  errFiscalCodeBelfioreCode: "err.fiscalCode.belfiorecode",
  errDocumentExpired: "err.document.expired",
  errDocumentNotAccepted: "err.document.notAccepted",
  errDocumentMissingData: "err.document.missingData",
  errDocumentGeneric: "err.document.genericErr",
  errAddressUnrecognizedFormat: "err.address.unrecognizedformat",
  errAddressUnrecognizedFormatForeign: "err.address.unrecognizedformatforeign",
  errSpidAgid: "err.spid.agid",
  errSpidAuth: "err.spid.auth",
};

export const contactsSpidErrors = [
  SpidErrors.errEmailAlreadyPresent,
  SpidErrors.errPhoneNumberAlreadyPresent,
  SpidErrors.errPhoneNumberNoItalianPrefix,
];

export const fiscalCodeSpidErrors = [
  SpidErrors.errFiscalCodeNoEmployersAllowed,
  // SpidErrors.errFiscalCodeUserSelfExcluded,
  SpidErrors.errFiscalCodeRecentlyClosedAccount,
  SpidErrors.errFiscalCodeMultipleClosedAccounts,
  SpidErrors.errFiscalCodeUserUnderage,
  SpidErrors.errFiscalCodeUserOver100YearsOld,
  SpidErrors.errFiscalCodeAlreadyPresent,
  SpidErrors.errFiscalCodeActivationAlreadyStarted,
  SpidErrors.errFiscalCodeBelfioreCode,
];

export const documentSpidErrors = [
  SpidErrors.errDocumentExpired,
  SpidErrors.errDocumentNotAccepted,
  SpidErrors.errDocumentMissingData,
  SpidErrors.errDocumentGeneric,
];

export const residenceSpidErrors = [
  SpidErrors.errAddressUnrecognizedFormat,
  SpidErrors.errAddressUnrecognizedFormatForeign,
];
