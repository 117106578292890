import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN_REDIRECT } from "../../config";
import { useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import {
  disableBeforeUnloadPopup,
  useBeforeUnloadPopup,
} from "../../hooks/useBeforeUnloadPopup";
import {
  useFlow,
  usePdvFlowDetection,
  useRegistrationSelectionNavigation,
} from "../../hooks/useFlow";
import { useTranslate } from "../../hooks/useTranslate";
import {
  addFormData,
  resetFormData,
} from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { resetFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { cleanSpidStatus } from "../../store/spidStatus/spidStatus.action";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import {
  wizardSelectorsFlow,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { getFlow } from "../../utility/wizardConfig/wizardConfig";
import Background from "../commons/background/background.component";
import PreFooter from "../elementForm/preFooter/preFooter.component";
import ErrorCard from "../errorCard/errorCard.component";
import Header from "../header/header.component";
import {
  defaultCardTitleError,
  errorCardButtonLabels,
  errorCardsPageText,
  spidRetryRegistrationCases,
} from "./errorCardsPage.helpers";
import { useCardErrorKey } from "./errorCardsPage.hooks";

export default function ErrorCardsPage() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { getTranslatedString } = useTranslate();

  const { goToSpecificStep } = useFlow();

  const isMountedWithSpidRef = useRef(false);

  const defaultClassicFlowName = useSelector(
    wizardSelectorsFlow.getWizardDefaultClassicFlowName,
  );
  const flow = useSelector(wizardSelectorsFlow.getWizardFlow);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const { goToRegistrationSelection } = useRegistrationSelectionNavigation();

  useBeforeUnloadPopup();

  const cardError = useCardErrorKey();

  const isPdvFlow = usePdvFlowDetection();

  const redirectToPdv = useCallback(() => {
    dispatch(resetFormData());
    dispatch(resetFormStatus());
    dispatch(addFormData({ fromPdv: true }));

    getFlow(defaultClassicFlowName, null, history);
  }, [defaultClassicFlowName, dispatch, history]);

  const redirect = () => {
    const isFiscalCodeAlreadyPresent =
      cardError === "err.fiscalcode.alreadypresent" ||
      formStatus.fiscalCode === "err.fiscalcode.alreadypresent";

    const isActivationAlreadyStarted =
      cardError === "err.bill.activation.already.submitted" ||
      formStatus.fiscalCode === "err.bill.activation.already.submitted" ||
      cardError === "err.fiscalcode.activationalreadystarted" ||
      formStatus.fiscalCode === "err.fiscalcode.activationalreadystarted";

    if (errorCardsPageText[cardError].utagErrorMessage) {
      dispatch(
        utagLink({
          error_message: errorCardsPageText[cardError].utagErrorMessage,
          reg_event: "form-error",
        }),
      );
    }

    if (
      cardError === "err.fiscalcode.alreadypresentPdv" ||
      cardError === "err.fiscalcode.activationalreadystartedPdv" ||
      (isPdvFlow && cardError === "genericError")
    ) {
      history.push("/registrazione-semplificata-pdv");
    } else if (isFiscalCodeAlreadyPresent) {
      const path = `${LOGIN_REDIRECT}/loginJwt/?siteid=${formData.channel}`;
      window.location.href = path;
    } else if (isActivationAlreadyStarted) {
      redirectToPdv();
    } else if (formData.nameError === "err.e3512") {
      goToSpecificStep("/bonus");
    } else if (formData.nameError === "err.e3510") {
      window.location.reload();
    } else {
      window.location.href = formData.cancelcallbackurl;
    }
  };

  const onRetryRegistrationClick = () => {
    dispatch(
      utagLink({
        reg_cta: "riprova a registrarti",
        reg_flusso,
        reg_event: "registrazione|error-page|riprova-registrazione",
      }),
    );

    goToRegistrationSelection();
  };

  const handleClick = () => {
    disableBeforeUnloadPopup();

    if (spidRetryRegistrationCases.includes(cardError)) {
      onRetryRegistrationClick();
    } else {
      redirect();
    }
  };

  useEffect(() => {
    if (
      isMountedWithSpidRef.current &&
      flusso !== "SPID" &&
      flow.some((component) => component.name === "activeAccount") &&
      (formStatus.fiscalCode === "err.bill.activation.already.submitted" ||
        formStatus.fiscalCode === "err.fiscalcode.activationalreadystarted")
    ) {
      redirectToPdv();
    } else if (flusso === "SPID") {
      isMountedWithSpidRef.current = true;
    }
  }, [flow, flusso, formStatus.fiscalCode, redirectToPdv]);

  useEffect(() => {
    return () => dispatch(cleanSpidStatus());
  }, [dispatch]);

  const textId = errorCardsPageText[cardError]?.cardText ?? cardError;
  const { promoCode, bonusCode } = formData;
  const reg_doc = useRegDoc();
  const reg_flusso = useRegFlusso();
  const reg_bonus = promoCode || bonusCode;
  const error_message = getTranslatedString(textId);

  useEffect(() => {
    dispatch(
      utagView({
        conversion_pagename: "error-page",
        reg_step: "error-page",
        error_message,
        reg_bonus,
        reg_flusso,
        reg_doc,
      }),
    );
  }, [dispatch, error_message, reg_bonus, reg_doc, reg_flusso]);

  return (
    <div className="errorCardsPage">
      <Background />

      <Header />

      <div className="cardsContainer">
        <ErrorCard
          variant={errorCardsPageText[cardError]?.variant ?? ""}
          titleId={
            errorCardsPageText[cardError]?.cardTitle ?? defaultCardTitleError
          }
          textId={textId}
          buttonTextId={
            errorCardsPageText[cardError]?.buttonText ??
            errorCardButtonLabels.close
          }
          handleClick={handleClick}
        />
      </div>

      {!isPdvFlow && <PreFooter />}
    </div>
  );
}
