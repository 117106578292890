import CodiceFiscale from "codice-fiscale-js";
import { useDispatch, useSelector } from "react-redux";
import { allCitiesSelectors } from "../../../store/allCities/allCities.selectors";
import { countriesSelectors } from "../../../store/countries/countries.selectors";
import { addFormData } from "../../../store/formData/formData.action";
import { addFormStatus } from "../../../store/formStatus/formStatus.action";
import { getFiscalCodeError } from "../../../utility/formIsValid/formIsValid.container";
import { useFiscalCode } from "../../fiscalCode/fiscalCode.hooks";
import FiscalCodeInputFieldBare from "../fiscalCodeInputFieldBare/fiscalCodeInputFieldBare.component";

export default function FiscalCodeInputField() {
  const dispatch = useDispatch();

  const { allCitiesFalse } = useSelector(allCitiesSelectors.getAllCities);

  const { countriesFalse } = useSelector(countriesSelectors.getCountries);

  const { getErrorInputClassName } = useFiscalCode();

  const handleFiscalCodeChange = (event) => {
    const valueUpperCase = event.target.value.toUpperCase();

    if (/[^a-zA-Z0-9]/.test(valueUpperCase)) return;

    dispatch(addFormData({ fiscalCode: valueUpperCase }));

    const fiscalCodeError = getFiscalCodeError(valueUpperCase);
    dispatch(addFormStatus({ fiscalCode: fiscalCodeError }));

    if (fiscalCodeError) return;

    const derivedData = deriveDataFromFiscalCode(valueUpperCase);

    const city = allCitiesFalse?.find(
      (item) => item.description === derivedData.birthTown.toUpperCase(),
    );

    if (!!city) {
      dispatch(
        addFormData({
          name: "",
          surname: "",
          birthState: selectItaly(countriesFalse),
          birthCityFiscalCode: city.fiscalCode,
          birthId: city.id,
          ...derivedData,
        }),
      );
    }
  };

  const errorInputClassName = getErrorInputClassName();

  return (
    <FiscalCodeInputFieldBare
      onChange={handleFiscalCodeChange}
      errorInputClassName={errorInputClassName}
    />
  );
}

function deriveDataFromFiscalCode(fiscalCode) {
  const cf = new CodiceFiscale(fiscalCode);

  const birthDate = new Date(cf.year, cf.month - 1, cf.day).toISOString();

  return {
    gender: cf.gender,
    birthTown: cf.birthplace.nome,
    birthProvince: cf.birthplace.prov,
    birthDate,
  };
}

function selectItaly(countries = []) {
  return countries?.find((country) => country.fiscalCode === "Z000");
}
