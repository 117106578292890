import FlagImg from "../../flagImg/flagImg.component";

export default function TextWithNationFlag({ sign, text }) {
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <FlagImg country={sign} />
      <span>{text}</span>
    </div>
  );
}
