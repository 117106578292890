import { Suspense, lazy, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useBeforeUnloadPopup } from "../../hooks/useBeforeUnloadPopup";
import { usePdvFlowDetection } from "../../hooks/useFlow";
import { useFollowUpStrategy } from "../../hooks/useFollowUpStrategy";
import Background from "../commons/background/background.component";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";
import PreFooter from "../elementForm/preFooter/preFooter.component";
import Header from "../header/header.component";
import WizardOcrSummaryDataFooter from "../ocrDataSummary/wizardOcrSummaryDataFooter/wizardOcrSummaryDataFooter.component";
import ProgressBarSteps from "../progressBarSteps/progressBarSteps.component";
import SpidLeavingPopup from "../spidLeavingPopup/spidLeavingPopup.component";
import WizardBtn from "../wizardBtn/wizardBtn.container";

const hideProgressBarComponentNames = ["inizio", "summary", "activeAccount"];

const DebugView = lazy(() =>
  process.env.NODE_ENV === "development"
    ? import("../debugView/debugView.component")
    : null,
);

export default function Wizard({
  isLoading,
  countries,
  allCities,
  component,
  flusso,
  formData,
  getCountriesAPI,
  getAllCitiesAPI,
}) {
  const [spidLeavingPopupIsOpen, setSpidLeavingPopupIsOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!component.name || component.name === "inizio") {
      return;
    }

    if (countries.fetchedTrue === false) {
      getCountriesAPI(true, formData.channel, history);
    }
    if (countries.fetchedFalse === false) {
      getCountriesAPI(false, formData.channel, history);
    }
    if (allCities.fetched === false) {
      getAllCitiesAPI(formData.channel, history);
    }
  }, [
    allCities.fetched,
    component.name,
    countries.fetchedFalse,
    countries.fetchedTrue,
    formData.channel,
    getAllCitiesAPI,
    getCountriesAPI,
    history,
  ]);

  useFollowUpStrategy();

  useBeforeUnloadPopup();

  const isPdvFlow = usePdvFlowDetection();

  const showPrefooter =
    component.name !== "summary" &&
    component.name !== "inizio" &&
    component.name !== "ocrDataSummary" &&
    flusso !== "PDV" &&
    flusso !== "OCRPDV";

  return (
    <div className="wizard-container">
      {process.env.NODE_ENV === "development" && (
        <Suspense>
          <DebugView />
        </Suspense>
      )}

      {(isLoading || allCities.fetching) &&
      component.name !== "document" &&
      component.name !== "summary" ? (
        <FullPageLoader />
      ) : null}

      <Header setSpidLeavingPopupIsOpen={setSpidLeavingPopupIsOpen} />

      {!hideProgressBarComponentNames.includes(component.name) &&
        (flusso === "OCR" || isPdvFlow ? (
          <ProgressBarSteps useBootstrapClasses={false} />
        ) : flusso === "C1" ? (
          <ProgressBarSteps />
        ) : null)}

      <div
        className={
          "wizard " +
          "wizard--R" +
          (component.name === "inizio" ? " wizard-inizio" : "")
        }
      >
        {component.name !== "inizio" && <Background />}

        <div className="wizard__top-padding"></div>

        {component.component}

        {spidLeavingPopupIsOpen && flusso === "SPID" && (
          <SpidLeavingPopup
            spidLeavingPopupIsOpen={spidLeavingPopupIsOpen}
            setSpidLeavingPopupIsOpen={setSpidLeavingPopupIsOpen}
          />
        )}

        {component.name === "ocrDataSummary" ? (
          <WizardOcrSummaryDataFooter />
        ) : component.name !== "summary" && component.name !== "inizio" ? (
          <WizardBtn component={component} />
        ) : null}
      </div>

      {showPrefooter && (
        <div className="prefooterRect-parent-div">
          <div
            className={
              formData.channel !== "62"
                ? component.name === "activeAccount"
                  ? "invisible "
                  : "d-none"
                : ""
            }
          >
            <PreFooter />
          </div>
        </div>
      )}
    </div>
  );
}
