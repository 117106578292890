import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  useRegDoc,
  useOcrRegFlussoFromPathParam,
} from "../../hooks/useAnalytics";
import {
  getFastfillOcrStatus,
  getFastfillRetrieveData,
} from "../../services/jumioOcrController";
import { getAllCitiesAPI } from "../../store/allCities/allCities.action";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { setJumioAccountId } from "../../store/ocr/ocr.actions";
import { utagView } from "../../store/utagStore/utagStore.action";
import { getFlow } from "../../utility/wizardConfig/wizardConfig";
import { ocrDataToFormData } from "./ocrCallback.helpers";

export function useOcrDataRetrieval() {
  const history = useHistory();

  const dispatch = useDispatch();

  const { regType, accountId, workflowExecutionId } = useParams();

  const { channel } = useSelector(formDataSelectors.getFormData);

  useEffect(() => {
    if (!accountId || !channel || !workflowExecutionId || !regType) return;

    const waitForOcrStatus = async ({
      channel,
      accountId,
      workflowExecutionId,
    }) => {
      const MAX_ITERATIONS = 3;
      const INTERVAL = 5000;

      // wait for INTERVAL seconds before checking the status
      await new Promise((resolve) => setTimeout(resolve, INTERVAL));

      // then check for MAX_ITERATIONS times
      return new Promise((resolve, reject) => {
        let iterations = 0;

        const interval = setInterval(async () => {
          iterations++;

          if (iterations > MAX_ITERATIONS) {
            clearInterval(interval);
            reject();
          }

          try {
            const data = await getFastfillOcrStatus({
              channel,
              accountId,
              workflowExecutionId,
            });

            if (data.workflowStatus === "PROCESSED") {
              clearInterval(interval);
              resolve();
            }
          } catch (error) {
            clearInterval(interval);
            reject(error);
          }
        }, INTERVAL);
      });
    };

    const fillUserDataFromOcr = async () => {
      try {
        await waitForOcrStatus({
          channel,
          accountId,
          workflowExecutionId,
        });

        const [cities, ocrResponse] = await Promise.all([
          dispatch(getAllCitiesAPI(channel, history)),
          getFastfillRetrieveData({
            channel,
            accountId,
            workflowExecutionId,
          }),
        ]);

        if (isUnsupportedDocumentType(ocrResponse)) {
          history.push("/errore-ocr?type=documento-non-supportato");
          return;
        }

        const formData = ocrDataToFormData(ocrResponse.payload, cities);
        dispatch(addFormData(formData));

        dispatch(setJumioAccountId(accountId));

        const flowName = regType === "pdv" ? "OCRPDV" : "OCR";
        await getFlow(flowName, null, history, true);
      } catch (error) {
        console.error(error);
        history.push("/errore-ocr");
      }
    };

    fillUserDataFromOcr();
  }, [accountId, channel, dispatch, history, regType, workflowExecutionId]);
}

export function useAnalyticsUtagView() {
  const dispatch = useDispatch();

  const reg_doc = useRegDoc();

  const reg_flusso = useOcrRegFlussoFromPathParam();

  useEffect(() => {
    if (!reg_doc) return;

    const reg_step =
      reg_flusso === "ocr rivenditori"
        ? "registrazione:pdv ocr:elaborazione dati"
        : "ocr:elaborazione dati";

    dispatch(
      utagView({
        reg_step,
        conversion_pagename: reg_step,
        reg_doc,
        country: "italia",
        reg_flusso,
      }),
    );
  }, [dispatch, reg_doc, reg_flusso]);
}

function isUnsupportedDocumentType(ocrResponse) {
  return (
    ocrResponse.errors?.some(
      (error) => error.code === "err.docType.notSupported",
    ) ?? false
  );
}
