import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addFormData } from "../../../../store/formData/formData.action";
import { addFormStatus } from "../../../../store/formStatus/formStatus.action";
import PopupModal from "../../../popupModal/popupModal";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../../summary.hooks";
import { documentLinkTranslateId } from "../summaryDocument.config";
import SummaryDocumentPopupDetailContent from "./summaryDocumentPopupDetailContent.component";
import SummaryDocumentPopupEditContent from "./summaryDocumentPopupEditContent.component";

export default function SummaryDocumentPopup({ show, onClose }) {
  const dispatch = useDispatch();

  const { getCurrentDocumentFormData } = useCurrentData();

  const documentFormDataBeforeEditRef = useRef(getCurrentDocumentFormData());

  const [editMode, setEditMode] = useState(false);

  const { trackEditData } = useSummaryAnalyticsTrackLinks();

  const handlePressEdit = () => {
    trackEditData(documentLinkTranslateId);
    documentFormDataBeforeEditRef.current = getCurrentDocumentFormData();
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);

    dispatch(
      addFormStatus({
        number: "",
        releaseDate: "",
        expirationDate: "",
        documentTown: "",
      }),
    );

    if (!!documentFormDataBeforeEditRef.current) {
      dispatch(addFormData(documentFormDataBeforeEditRef.current));
    }
  };

  const handleCloseEdit = () => {
    setEditMode(false);
    onClose();
  };

  return (
    <PopupModal show={show}>
      {editMode ? (
        <SummaryDocumentPopupEditContent
          onClose={handleCloseEdit}
          onCancelEdit={handleCancelEdit}
          previousData={documentFormDataBeforeEditRef.current}
        />
      ) : (
        <SummaryDocumentPopupDetailContent
          onClose={onClose}
          onPressEdit={handlePressEdit}
        />
      )}
    </PopupModal>
  );
}
