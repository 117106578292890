import { isEqual } from "lodash";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../../store/formStatus/formStatus.selectors";
import { contactsContainsError } from "../../../../utility/checkingForm/checkingForm.container";
import Contacts from "../../../contacts/contacts.component";
import PopupModal from "../../../popupModal/popupModal";
import { extractContactsFromFormData } from "../../summary.helpers";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupEditFooter from "../../summaryPopupFooter/summaryPopupEditFooter.component";
import { contactsLinkTranslateId } from "../summaryContacts.config";

export default function SummaryContactsPopupEditContent({
  onClose,
  onCancelEdit,
  previousData,
}) {
  const handleCloseIcon = () => {
    onCancelEdit();
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleCloseIcon}>
        <Translate id="lbl.contacts" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body popup-contacts-body">
          <Contacts />
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryContactsPopupEditFooter
          onConfirm={onClose}
          onCancel={onCancelEdit}
          previousData={previousData}
        />
      </PopupModal.Footer>
    </>
  );
}

function SummaryContactsPopupEditFooter({ onCancel, onConfirm, previousData }) {
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const { trackCancelEditData, trackConfirmEditData } =
    useSummaryAnalyticsTrackLinks();

  const dirty = isContactsFormDirty(formData, previousData);

  const confirmDisabled = !dirty || contactsContainsError(formData, formStatus);

  const handleConfirm = () => {
    trackConfirmEditData(contactsLinkTranslateId);
    onConfirm();
  };

  const handleCancel = () => {
    trackCancelEditData(contactsLinkTranslateId);
    onCancel();
  };

  return (
    <SummaryPopupEditFooter
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmDisabled={confirmDisabled}
    />
  );
}

function isContactsFormDirty(formData, previousData) {
  const current = extractContactsFromFormData(formData);
  const previous = extractContactsFromFormData(previousData);
  return !isEqual(current, previous);
}
