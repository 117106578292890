import React from "react";
import { Translate } from "react-localize-redux";
import { usePdvFlowDetection } from "../../../../hooks/useFlow";

export default function NoteContactPage() {
  const isPdvFlow = usePdvFlowDetection();
  return (
    <div id="it-ar-contacts-pragraph-message" className="verify-num">
      <p className="verifyNum">
        <Translate
          id={isPdvFlow ? "lbl.verificaNumTextPdv" : "lbl.verificaNumText"}
        />
      </p>
    </div>
  );
}
