import { SET_JUMIO_ACCOUNT_ID } from "./ocr.actions";
import { SET_JUMIO_SDK_TOKEN } from "./ocr.actions";

const initialState = {
  jumioAccountId: null,
  jumioSdkToken: null,
};

export function ocrReducer(state = initialState, action) {
  switch (action.type) {
    case SET_JUMIO_ACCOUNT_ID:
      return { ...state, jumioAccountId: action.payload };
    case SET_JUMIO_SDK_TOKEN:
      return { ...state, jumioSdkToken: action.payload };
    default:
      return state;
  }
}
