import { useState } from "react";
import rightArrow from "../../../images/svg/signButton/rightArrow.svg";
import loyaltyLottery from "../../../images/svg/loyalty-lottery.svg";
import LoyaltyPopup from "../../loyaltyPopup/loyaltyPopup.component";

export default function LoyaltyCardInsertCode({ title, description, onClick }) {
  const [showLoyaltyModal, setShowLoyaltyModal] = useState(false);

  const handleClick = () => {
    if (showLoyaltyModal) return;
    onClick?.();
    setShowLoyaltyModal(true);
  };

  return (
    <div
      id="newLoyalty-lottery"
      className="banner-loyalty-lottery"
      onClick={handleClick}
    >
      <img alt="loyalty" src={loyaltyLottery} />

      <div className="banner-text-bonus">
        <p className="question-title">{title}</p>

        <p className="body">{description}</p>
      </div>

      <div className="banner-btn-bonus">
        <img alt="arrowBtn" src={rightArrow} />
      </div>

      {showLoyaltyModal && (
        <LoyaltyPopup closeLoyaltyModal={() => setShowLoyaltyModal(false)} />
      )}
    </div>
  );
}
