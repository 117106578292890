const emailReg = new RegExp(
  /^[a-zA-Z0-9_-]+(?:\.[a-z0-9_-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z](?:[a-zA-Z]{0,61}[a-zA-Z]){1,}){1,}$/,
);

export const getClassFromError = (error, focus = false) => {
  if (error === "err.required") return "is-required";
  if (error.length && error !== "err.required" && !focus) return "is-invalid";
  return "";
};

export const validateContact = (email) => {
  if (!email.length) return "err.required";
  if (!emailReg.test(email)) return "err.emailTYP.bad";
  return "";
};
