import React from "react";

export default function EmailPredictions({
  emailPredictions,
  selectedEmailPrediction,
  setEmailPrediction,
  predictionButtons,
}) {
  if (!emailPredictions) return null;

  return (
    <div className="emailPrediction">
      <div className="predictions">
        {Object.keys(emailPredictions).map((i) => (
          <button
            key={i}
            type="button"
            ref={predictionButtons[i]}
            className={
              "email-btn " +
              (selectedEmailPrediction.toString() === i
                ? "btn-selected"
                : "not-selected")
            }
            onMouseDown={() => setEmailPrediction(i, emailPredictions[i])}
            onClick={() => setEmailPrediction(i, emailPredictions[i])}
          >
            {emailPredictions[i]}
          </button>
        ))}
      </div>
      <span className="gradient"></span>
    </div>
  );
}
