import { Translate } from "react-localize-redux";

export default function PreFooterMobileText({ openPopUp }) {
  return (
    <div id="preFooter" className="preFooter preFooter-mobile-text-container">
      <Translate id="text.needHelp" />{" "}
      <button className="contactUs" onClick={openPopUp}>
        <Translate id="text.contactUs" />
      </button>
    </div>
  );
}
