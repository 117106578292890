import LoyaltyCard from "../../loyaltyCard/loyaltyCard.component";

export default function TypLoyaltyCardPdv() {
  return (
    <LoyaltyCard
      insertCodeTitleTranslationId="text.typPdv.doesTheCustomerHaveCodeSuperEnalotto"
      insertCodeDescriptionTranslationId="text.typPdv.insertYourPlayedCode"
      confirmWithPointsTitleTranslationId="text.typPdv.withSuperEnalotto.theCustomerWonPoints"
      confirmWithPointsDescriptionTranslationId="text.typPdv.theCustomerCanGetLoyaltyBonus"
      confirmWithoutPointsDescriptionTranslationId="text.typPdv.thanksForRegistrationCustomer"
    />
  );
}
