import { v4 as uuidv4 } from "uuid";
import { instancePost } from "../axios.config";
import { REACT_APP_URL } from "../config";

export async function generateOtp(phoneNumber, phonePrefix, token, channel) {
  const url = `${REACT_APP_URL}/otp-security-ms/ms/sms/phone/generate`;
  const phoneParams = {
    requestId: uuidv4(),
    channel: channel,
    phoneNumber: phoneNumber,
    phonePrefix: phonePrefix,
    recaptchaToken: token,
  };
  const res = await instancePost.post(url, phoneParams);
  return res.data;
}

export async function postGenerateOtp(formData, contacts) {
  const url = `${REACT_APP_URL}/otp-security-ms/ms/contact/generate`;
  const payload = {
    requestId: uuidv4(),
    channel: formData.channel,
    ipAddress: formData.ipAddress,
    infoChannel: formData.userAgent,
    ...(contacts.email && {
      name: formData.name,
      surname: formData.surname,
    }),
    ...contacts,
  };
  const config = {
    headers: {
      authorization: `Bearer\u0020${formData.token}`,
    },
  };
  const res = await instancePost.post(url, payload, config);
  return res.data;
}

export async function checkOtp(phoneNumber, phonePrefix, otp, token, channel) {
  const url = `${REACT_APP_URL}/otp-security-ms/ms/sms/phone/check`;
  const phoneParams = {
    requestId: uuidv4(),
    channel: channel,
    phoneNumber: phoneNumber,
    phonePrefix: phonePrefix,
    recaptchaToken: token,
  };
  if (otp !== "") phoneParams.otp = otp;
  const res = await instancePost.post(url, phoneParams);
  return res.data;
}

export async function postCheckOtp(formData, contacts, otp, isVerify) {
  const url = `${REACT_APP_URL}/otp-security-ms/ms/contact/check`;
  const payload = {
    requestId: uuidv4(),
    channel: formData.channel,
    ipAddress: formData.ipAddress,
    infoChannel: formData.userAgent,
    isVerify,
    ...contacts,
    otp: otp,
  };
  const config = {
    headers: {
      authorization: `Bearer\u0020${formData.token}`,
    },
  };
  const res = await instancePost.post(url, payload, config);
  return res.data;
}
