import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import LoyaltyCardConfirm from "./loyaltyCardConfirm/loyaltyCardConfirm.component";
import LoyaltyCardInsertCode from "./loyaltyCardInsertCode/loyaltyCardInsertCode.component";

export default function LoyaltyCard({
  insertCodeTitleTranslationId,
  insertCodeDescriptionTranslationId,
  confirmWithPointsTitleTranslationId,
  confirmWithPointsDescriptionTranslationId,
  confirmWithoutPointsDescriptionTranslationId,
  onClick,
}) {
  const formData = useSelector(formDataSelectors.getFormData);

  const gameCodeExist = formData.loyalty.gameCodeExist;
  const sisalPointsAvailable = formData.loyalty.sisalBonusPoints;

  if (!gameCodeExist) {
    return (
      <LoyaltyCardInsertCode
        title={<Translate id={insertCodeTitleTranslationId} />}
        description={<Translate id={insertCodeDescriptionTranslationId} />}
        onClick={onClick}
      />
    );
  }

  if (sisalPointsAvailable) {
    return (
      <LoyaltyCardConfirm
        title={
          <Translate
            id={confirmWithPointsTitleTranslationId}
            data={{ points: sisalPointsAvailable }}
          />
        }
        description={
          <Translate id={confirmWithPointsDescriptionTranslationId} />
        }
      />
    );
  }

  return (
    <LoyaltyCardConfirm
      description={
        <Translate id={confirmWithoutPointsDescriptionTranslationId} />
      }
    />
  );
}
