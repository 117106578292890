import { useSelector } from "react-redux";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../../store/wizard/wizard.selectors";
import AlertBox from "../../commons/alertBox/alertBox";

export default function DocumentAlertBox() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const component = useSelector(wizardSelectorsComponent.getComponent);

  if (component.name !== "ocrForm") return null;

  switch (flusso) {
    case "C1":
      return <AlertBox textId="text.alertbox.document.body" />;
    case "PDV":
      return <AlertBox textId="text.alertbox.document.bodyPdv" />;
    case "SPID":
      return <AlertBox textId="text.spid.document.info" />;
    default:
      return null;
  }
}
