import { STATUS_SECTION } from "./signCheck.action";
const initialState = {
  status: {
    personaData: true,
    fiscalCode: true,
    gender: true,
    ocr: true,
    document: false,
    contacts: true,
    userData: true,
    birthPlace: true,
    residence: true,
    domicile: false,
    rechargeLimit: true,
    termsAndConditions: true,
    welcomeBonus: true,
    securityQuestion: true,
  },
};

export const signCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_SECTION:
      const newObj = Object.assign(state.status, action.status);
      return { ...state, status: { ...newObj } };
    default:
      return state;
  }
};
