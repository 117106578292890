import moment from "moment";
import store from "../../store.config";
import { shouldShowInvalidDateFormatError } from "./dateIsValid";
import CodiceFiscale from "codice-fiscale-js";

export default function formIsValid(name, value) {
  let errors = {};
  if (value !== "") {
    switch (name) {
      case "name":
        if (!/(.*[a-zA-Zàáèéìíòóùú]){2}/i.test(value) && value.length !== 0) {
          errors.name = "err.name.length";
        } else {
          errors.name = "";
        }
        break;
      case "surname":
        if (!/(.*[a-zA-Zàáèéìíòóùú]){2}/i.test(value) && value.length !== 0) {
          errors.surname = "err.surname.length";
        } else {
          errors.surname = "";
        }
        break;
      case "email":
        const reg =
          /^[a-zA-Zàáèéìíòóùú@0-9./'_\-!#$%&'*+-/=?^_`{|}~.@]+(?:\.[a-zA-Zàáèéìíòóùú@0-9./'_\-!#$%&'*+-/=?^_`{|}~.@]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z](?:[a-zA-Z]{0,61}[a-zA-Z]){1,}){1,}$/;
        if (!reg.test(value) && value !== "") {
          errors.email = "err.mail.bad";
        } else {
          if (
            store.getState().formStatusReducer.formStatus.email ===
            "err.mail.alreadypresent"
          ) {
            errors.email = "err.mail.alreadypresent";
          } else {
            if (value.length > 50) errors.email = "err.mail.length";
            else errors.email = "";
          }
        }
        break;
      case "birthDate":
        if (shouldShowInvalidDateFormatError(value)) {
          errors.birthDate = "err.enterAValidDate";
          break;
        } else if (value.length > 0 && value.length < 10) {
          errors.birthDate = "err.wrongFormatDate";
          break;
        }
        const birthDate = moment(value, "DD/MM/YYYY");
        const age = moment.duration(moment().diff(birthDate))["_data"].years;
        if (age < 18) {
          errors.birthDate = "err.date.notadult";
        } else if (age >= 100) {
          errors.birthDate = "err.date.lessThen";
        } else {
          errors.birthDate = "";
        }
        break;
      case "phoneNumber":
        let prefix =
          store.getState().formDataReducer.formData.phonePrefix.callingCodes;
        if (prefix === "39" && value.length < 9 && value !== "") {
          errors.phoneNumber = "err.telephone.min";
        } else if (prefix !== "39" && value.length < 7 && value !== "") {
          errors.phoneNumber = "err.foreignTelephone.min";
        } else if (prefix === "39" && value.length > 10) {
          errors.phoneNumber = "err.telephone.max";
        } else if (prefix !== "39" && value.length > 15) {
          errors.phoneNumber = "err.foreignTelephone.max";
        } else {
          /*else if (value.length > 12) {
        errors.phoneNumber = 'err.telephone.max'
      } */
          errors.phoneNumber = "";
        }
        break;
      case "fiscalCode":
        errors.fiscalCode = getFiscalCodeError(value);
        break;
      case "cityEsteroBirth":
        if (value.length < 2 && value.length !== 0) {
          errors.cityEsteroBirth = "err.cityEsteroBirth.bad";
        } else errors.cityEsteroBirth = "";

        break;
      case "cap":
        if (value.length !== 5 && value.length !== 0) {
          errors.cap = "err.cap.length";
        } else {
          errors.cap = "";
        }
        break;
      case "domicileCap":
        if (value.length !== 5 && value.length !== 0) {
          errors.domicileCap = "err.cap.length";
        } else {
          errors.domicileCap = "";
        }
        break;
      case "capEstero":
        errors.capEstero = "";
        break;
      case "capEsteroDomi":
        errors.capEsteroDomi = "";
        break;
      case "username":
        if (value.length < 6) {
          errors.username = "err.username.min";
        } else if (value.length > 16) {
          errors.username = "err.username.max";
        } else if (!/[A-Za-z]/.test(value)) {
          errors.username = "err.username.capLetter";
        } else if (/[^\w.-]/.test(value)) {
          errors.username = "err.username.errorChar";
        } else {
          errors.username = "";
        }
        break;
      case "password":
        if (!/^[0-9a-zA-Z!#$/&*()=:.;,?+\-<>[%\]_\]\\]*$/.test(value)) {
          errors.password = "err.password.specialCharacter";
        }
        //  else if (!/^(?!.*(.)\1{2}).+$/.test(value.toLowerCase())) {
        //   errors.password = "err.password.consecutiveChar";
        // } else if (/(..)(.*?\1){2,}/.test(value.toLowerCase())) {
        //   errors.password = "err.password.consecutiveSequence";
        // }
        else if (value.length < 8 && value.length !== 0) {
          errors.password = "err.password.noVisualError";
        } else if (value.length > 20) {
          errors.password = "err.password.noVisualError";
        } else if (!/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(value)) {
          errors.password = "err.password.noVisualError";
        } else if (!/[!#$/^&*()=:.;,?+\-<>[%\]_\]\\]/.test(value)) {
          errors.password = "err.password.noVisualError";
        } else {
          errors.password = "";
        }
        break;
      case "documentNumber":
        if (value.length < 6) {
          errors.documentNumber = "err.documentNumber.min";
        } else if (/[^a-zA-Z0-9]/.test(value)) {
          errors.documentNumber = "err.documentNumber.errorChar";
        } else {
          errors.documentNumber = "";
        }
        break;
      case "birthState":
        if (
          Object.entries(value).length === 0 &&
          value.constructor === Object
        ) {
          errors.birthState = "err.birthNation.bad";
        } else {
          errors.birthState = "";
        }
        break;
      case "domicileState":
        if (
          Object.entries(value).length === 0 &&
          value.constructor === Object
        ) {
          errors.domicileState = "err.domicileState.bad";
        } else {
          errors.domicileState = "";
        }
        break;
      case "state":
        if (
          Object.entries(value).length === 0 &&
          value.constructor === Object
        ) {
          errors.state = "err.state.bad";
        } else {
          errors.state = "";
        }
        break;
      case "number":
        var countSpace = value.split(" ").length - 1;
        if (value !== "" && value.length < 6 + countSpace) {
          errors.number = "err.number.tooshort";
        } else {
          errors.number = "";
        }
        break;
      case "releaseDate":
        if (shouldShowInvalidDateFormatError(value)) {
          errors.releaseDate = "err.enterAValidDate";
        } else if (value.length > 0 && value.length < 10) {
          errors.releaseDate = "err.wrongFormatDate";
        } else if (moment(value, "DD/MM/YYYY").isAfter(moment())) {
          errors.releaseDate = "err.releaseDate.futureDate";
        } else {
          errors.releaseDate = "";
        }

        break;
      case "expirationDate":
        if (shouldShowInvalidDateFormatError(value)) {
          errors.expirationDate = "err.enterAValidDate";
        } else if (value.length > 0 && value.length < 10) {
          errors.expirationDate = "err.wrongFormatDate";
        } else if (moment(value, "DD/MM/YYYY").isBefore(moment())) {
          errors.expirationDate = "err.expirationDate.expired";
        } else if (moment(value, "DD/MM/YYYY").isAfter(moment().add(11, "y"))) {
          errors.expirationDate = "err.wrongFormatDate";
        } else {
          errors.expirationDate = "";
        }
        break;
      case "documentTown":
        if (value.length < 2 && value.length !== 0) {
          errors.documentTown = "err.documentTown.length";
        } else {
          errors.documentTown = "";
        }
        break;
      default:
        break;
    }
    return errors;
  } else {
    errors[name] = "err.required";
    return errors;
  }
}

export function getFiscalCodeError(value) {
  if (value.length < 16 && value !== "") {
    return "err.fiscalcode.length";
  } else {
    const re = new RegExp(
      "^[A-Z]{6}[LMNPQRSTUV0-9]{2}[A-Z][LMNPQRSTUV0-9]{2}[A-Z][LMNPQRSTUV0-9]{3}[A-Z]",
    );
    if (!re.test(value) && value.length !== 0) {
      return "err.fiscalcode.bad";
    } else {
      const valid = CodiceFiscale.check(value);
      if (valid) {
        const cfData = new CodiceFiscale(value).toJSON();
        const age = moment
          .duration(moment().diff(new Date(cfData.birthday)))
          .asYears();

        return age < 18 || 100 <= age ? "err.date.forbiddenAge" : "";
      } else {
        return "err.fiscalcode.bad";
      }
    }
  }
}
