import { useSelector } from "react-redux";
import { MAIN_SITE_HOMEPAGE } from "../../config";
import logo from "../../images/svg/logo-sisal.svg";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";
import CloseButton from "./partials/closeButton.component";

export default function Header() {
  const { channel } = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const showCloseButton = !["06", "6", "49"].includes(channel);

  return (
    <header
      className={"sisal-header sisal-header--" + flusso}
      id={flusso !== "SPID" ? "it-ar-header-sisal-header" : undefined}
    >
      <div className="container-fluid" id="it-ar-header-container-fluid">
        {channel === "62" ? (
          <a href={MAIN_SITE_HOMEPAGE} title="Sisal" id="headerLogo">
            <img className="logo" alt="logo" src={logo} />
          </a>
        ) : (
          <img className="logo" alt="logo" src={logo} />
        )}

        {showCloseButton && <CloseButton />}
      </div>
    </header>
  );
}
