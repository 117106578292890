import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { utagLink } from "../../store/utagStore/utagStore.action";
import { getSpidButtonConfig } from "../../utility/spid/spidButtonConfig";

export default function SpidButton() {
  const dispatch = useDispatch();

  const btnRef = useRef(null);

  useEffect(() => {
    if (!btnRef.current) return;

    const SPID_PROVIDER_SELECTOR = ".spid-idp-button-link";

    const trackProviderSelect = () => {
      dispatch(
        utagLink({
          reg_cta: "entra con spid",
          reg_event: "registrazione|registrazione_con_spid|entra_spid",
        }),
      );
    };

    const observer = new MutationObserver((mutations) => {
      const mutation = mutations.find((m) => m.type === "childList");
      if (mutation.addedNodes.length) {
        for (const node of document.querySelectorAll(SPID_PROVIDER_SELECTOR)) {
          node.addEventListener("click", trackProviderSelect);
        }
      }
    });

    observer.observe(btnRef.current, { childList: true });

    return () => {
      observer.disconnect();
      for (const node of document.querySelectorAll(SPID_PROVIDER_SELECTOR)) {
        node.removeEventListener("click", trackProviderSelect);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const initBtn = () => {
      window.FabrickSpid.init("#spid-button", getSpidButtonConfig());
    };

    if (window.FabrickSpid != null) {
      initBtn();
    } else {
      try {
        document
          .getElementById("fabrick-spid-button-script")
          .addEventListener("load", initBtn);
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  return <div id="spid-button" ref={btnRef}></div>;
}
