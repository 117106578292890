import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import {
  markUsernameAsNotValidated,
  markUsernameAsValidated,
  setUsername,
  validateUsernameAPI,
} from "../../store/validateUsername/validateUsername.action";
import {
  selectUsernameHasBeenValidated,
  validateUsernameSelectors,
} from "../../store/validateUsername/validateUsername.selectors";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import formIsValid from "../../utility/formIsValid/formIsValid.container";
import { moveCursorAtTheEnd } from "../../utility/input/input";
import { getRecaptchaToken } from "../../utility/recaptcha";
import MessageAlert from "../elementForm/messageAlert/messageAlert.container";
import MessageError from "../elementForm/messageError/messageError.component";
import { useSummaryFormErrorsTracking } from "../summary/summary.hooks";
import SuggestList from "./suggestList/suggestList.component";
import ValidationCheckPassword from "./validationCheckPassword/validationCheckPassword.component";
import ValidationCheckUsername from "./validationCheckUsername/validationCheckUsername.component";

export default function UserData({ hideUtag }) {
  /** @type {import("redux-thunk").ThunkDispatch<RootState, any, AnyAction>} */
  const dispatch = useDispatch();

  const { goToNextStep } = useFlow();

  const { getState } = useStore();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const validateUsername = useSelector(
    validateUsernameSelectors.getValidateUsername,
  );
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const [typePassword, setTypePassword] = useState(true);
  const [statusForm, setStatusForm] = useState(true);
  let [active, setActive] = useState(false);
  let [flag, setFlag] = useState(false);
  let [previousValue, setPreviousValue] = useState({
    username: formData.username,
    password: formData.password,
  });

  const [isUsernameFocus, setIsUsernameFocus] = useState(false);
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);

  const usernameRef = useRef();
  const passwordRef = useRef();

  const history = useHistory();

  const reg_doc = useRegDoc();
  const reg_flusso = useRegFlusso();

  useSummaryFormErrorsTracking("username", flag);
  useSummaryFormErrorsTracking("password");

  const isValidUsername = (value) => {
    setFlag(false);
    let limitedValue = value;
    if (value.length > 16) {
      limitedValue = value.substring(0, 16);
    }
    if (/^[a-zA-Z0-9]*$/.test(limitedValue) || limitedValue === "") {
      setActive(false);
      const error = formIsValid("username", limitedValue);
      dispatch(addFormStatus(error));
      dispatch(addFormData({ username: limitedValue }));
    }
    handleFormAndButtonState();
  };

  const isValidPassword = (value) => {
    dispatch(addFormStatus({ password: "" }));
    let limitedValue = value.trim();
    if (value.length > 50) {
      limitedValue = value.substring(0, 50);
    }
    if (!/[\^]/.test(limitedValue) || limitedValue === "") {
      dispatch(addFormData({ password: limitedValue }));
      if (limitedValue.length) {
        if (
          (formData.name.length &&
            limitedValue.toUpperCase().includes(formData.name.toUpperCase())) ||
          (formData.surname.length &&
            limitedValue
              .toUpperCase()
              .includes(formData.surname.toUpperCase())) ||
          (formData.birthDate.length &&
            (limitedValue.includes(moment(formData.birthDate).format("YYYY")) ||
              limitedValue.includes(
                moment(formData.birthDate).format("DDMMYYYY"),
              )))
        ) {
          dispatch(addFormStatus({ password: "err.password.personalData" }));
        } else if (
          formData.username.length &&
          limitedValue.toUpperCase().includes(formData.username.toUpperCase())
        ) {
          dispatch(addFormStatus({ password: "err.password.username" }));
        } else if (
          formData.phoneNumber != null &&
          formData !== "" &&
          limitedValue.includes(formData.phoneNumber)
        ) {
          dispatch(addFormStatus({ password: "err.password.phoneNumber" }));
        } else {
          const error = formIsValid("password", value);
          dispatch(addFormStatus(error));
        }
      }
    }
    handleFormAndButtonState();
  };

  const handleKeyDownUsername = (event) => {
    if (event.key === "Enter") {
      passwordRef.current.focus();
    }
  };

  const handlePasswordKeyDown = (event) => {
    const key = event.key;

    if (key === "Enter" && component.name === "userData") {
      if (!statusForm) {
        goToNextStep();
      }
    }
  };

  const checkUsername = async (value) => {
    setActive(false);

    if (
      formData.usernamePdv &&
      formData.usernamePdv.toLowerCase() === value.toLowerCase()
    ) {
      dispatch(markUsernameAsValidated());
      return;
    }

    if (formIsValid("username", value).username === "") {
      const recaptchaToken = await getRecaptchaToken("VERIFY_USERNAME");

      await dispatch(
        validateUsernameAPI(
          formData.channel,
          value,
          getBirthDateOrDefault(),
          history,
          recaptchaToken,
        ),
      );
      const error = formIsValid("username", value);
      dispatch(addFormStatus(error));
      handleFormAndButtonState();
    } else {
      dispatch(
        addFormStatus({
          username: formIsValid("username", value).username,
        }),
      );
    }
  };

  // checkUsername endpoint requires a mandatory birthDate to give suggestions.
  // Instead of failing the request and block the user, we provide a default birthDate
  const getBirthDateOrDefault = () => {
    if (!!formData.birthDate && formData.birthDate !== "Invalid date") {
      return formData.birthDate;
    } else {
      return moment("01/01/2000", "DD/MM/YYYY")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }
  };

  const handleChangeUsername = (e) => {
    dispatch(markUsernameAsNotValidated());
    isValidUsername(e.target.value.trim());
  };

  const handleBlurUsername = (e) => {
    const value = e.target.value.trim();

    setIsUsernameFocus(false);
    setFlag(true);
    checkUsername(value);

    if (
      previousValue.username !== formData.username &&
      component.name === "summary"
    ) {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "username",
        }),
      );
    }
    setPreviousValue({ ...previousValue, username: formData.username });
  };

  useEffect(() => {
    if (usernameRef.current && window.screen.width >= 899) {
      usernameRef.current.focus();
    }
  }, []);

  useEffect(() => {
    isValidPassword(formData.password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.name,
    formData.surname,
    formData.birthDate,
    formData.username,
    formData.phoneNumber,
  ]);

  useEffect(() => {
    if (
      validateUsername.errorListUsername?.length ||
      (!validateUsername.errorListUsername && validateUsername.alreadyExist)
    ) {
      dispatch(addFormStatus({ username: "err.username.alreadypresent" }));
      handleFormAndButtonState();
      setActive(true);
    } else {
      setActive(false);
    }
  }, [validateUsername.errorListUsername]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePasswordBlur = (e) => {
    setIsPasswordFocus(false);

    const value = e.target.value;

    if (value.length === 0) {
      dispatch(addFormStatus({ password: "err.required" }));
    }

    if (
      previousValue.password !== formData.password &&
      component.name === "summary"
    ) {
      dispatch(
        utagLink({
          reg_event: "riepilogo_modifica",
          reg_campo: "password",
        }),
      );
    }

    setPreviousValue({ ...previousValue, password: formData.password });
  };

  const handleFormAndButtonState = () => {
    const usernameHasBeenValidated = selectUsernameHasBeenValidated(getState());
    const invalidForm = checkingForm("userData") || !usernameHasBeenValidated;

    setStatusForm(invalidForm);
    dispatch(disabledBtn(invalidForm));
  };

  useEffect(() => {
    if (component.name !== "summary") {
      if (formData.username.length) {
        checkUsername(formData.username);
      }
      dispatch(addFormData({ password: "" }));
      dispatch(addFormStatus({ password: "" }));
    }

    return () => {
      if (formStatus.password === "err.required") {
        dispatch(addFormStatus({ username: "" }));
      }
      dispatch(
        setUsername({
          errorListUsername: [],
          fetching: false,
          error: null,
        }),
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hideUtag) return;
    if (component.name === "userData") {
      dispatch(
        utagView({
          conversion_pagename: "dati-accesso",
          reg_step: "dati-accesso",
          reg_flusso,
          ...(reg_flusso === "ocr" || reg_flusso === "ocr rivenditori"
            ? { country: "italia", reg_doc }
            : {}),
        }),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="container step-container userData"
      id="it-ar-userdata-container"
    >
      <div className="row">
        <div
          className={
            "col-12" +
            (component.name === "userData"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : "")
          }
        >
          <form autoComplete="off">
            <div className="header-form" id="it-ar-userdata-header-form">
              <h2 className="sub-title-form" id="it-ar-userdata-sub-title-form">
                <Translate
                  id={
                    flusso !== "SPID"
                      ? "text.yourAccessData"
                      : "text.yourAccessData.spid"
                  }
                />
              </h2>
              <p
                className="label-form margin-bot-standard"
                id="it-ar-userdata-label-form"
              >
                <Translate
                  id={
                    flusso !== "SPID"
                      ? "text.toAccessPersonalArea"
                      : "text.toAccessPersonalArea.spid"
                  }
                />
              </p>
            </div>
            <div
              id="it-ar-userdata-form-group"
              className={
                "form-group username-input-container" +
                (component.name === "summary" ? "accordion-form " : "")
              }
            >
              <input
                ref={usernameRef}
                autoComplete="off"
                className={
                  "form-control " +
                  (formStatus.username.length > 0
                    ? formStatus.username === "err.required" &&
                      component.name !== "summary" &&
                      flag
                      ? "is-present"
                      : "is-invalid"
                    : "")
                }
                type="text"
                name="username"
                maxLength="16"
                spellCheck="false"
                autoCorrect="off"
                id="it-ar-userdata-username"
                required
                value={formData.username}
                onChange={handleChangeUsername}
                onKeyDown={handleKeyDownUsername}
                onFocus={(e) => {
                  moveCursorAtTheEnd(e.target);
                  setIsUsernameFocus(true);
                }}
                onBlur={handleBlurUsername}
              />
              <label
                className="form-control-label"
                id="it-ar-userdata-lblUsername"
                htmlFor="it-ar-userdata-username"
              >
                <Translate id="lbl.username" />
              </label>
              {formData.username === "" && flag && !isUsernameFocus && (
                <MessageAlert />
              )}
              {isUsernameFocus && (
                <>
                  <div
                    className={
                      "validationCheckContainer " + (active ? "d-none" : "")
                    }
                  >
                    <ValidationCheckUsername username={formData.username} />
                  </div>
                </>
              )}
              {active && <SuggestList checkUsername={checkUsername} />}
            </div>

            <div
              id="passPopover"
              className={
                component.name === "summary" ? " mb-4 mb-sm-3 mb-md-3" : ""
              }
            >
              <div
                id="it-ar-userdata-form-group2"
                className="form-group password-input-container"
              >
                <input
                  ref={passwordRef}
                  autoComplete="off"
                  className={
                    "form-control" +
                    (isPasswordFocus && formStatus.password.length
                      ? formStatus.password !== "err.required"
                        ? " is-invalid"
                        : ""
                      : formStatus.password === "err.required"
                        ? " is-present"
                        : formStatus.password.length
                          ? " is-invalid"
                          : "") +
                    (typePassword ? " key" : "")
                  }
                  type="text"
                  name="password"
                  id="it-ar-userdata-password"
                  spellCheck="false"
                  autoCorrect="off"
                  required
                  value={formData.password}
                  onChange={(e) => isValidPassword(e.target.value)}
                  onKeyDown={handlePasswordKeyDown}
                  onFocus={(e) => {
                    moveCursorAtTheEnd(e.target);
                    setIsPasswordFocus(true);
                  }}
                  onBlur={handlePasswordBlur}
                />
                <span
                  className="rightEyeButton"
                  onClick={() => setTypePassword(!typePassword)}
                >
                  <i className={typePassword ? "btn eyeClose" : "btn eye"}></i>
                </span>
                <label
                  className="form-control-label"
                  id="it-ar-userdata-lblPassword"
                  htmlFor="it-ar-userdata-password"
                >
                  <Translate id="lbl.password" />
                </label>
                <div
                  className={
                    "error-box" +
                    (formStatus.password === "err.required"
                      ? " is-required"
                      : "") +
                    (isPasswordFocus ? " d-none" : "")
                  }
                >
                  <MessageError
                    currentValue={formData.password}
                    currentName={"password"}
                  />
                </div>
                {isPasswordFocus && (
                  <div className="validationCheckContainer">
                    <ValidationCheckPassword password={formData.password} />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
