import { applyMiddleware, createStore } from "redux";
import { rootReducer } from "./redux.config";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

const store = createStore(
  rootReducer,
  composeWithDevTools({ trace: true, traceLimit: 50 })(applyMiddleware(thunk)),
);

export default store;
