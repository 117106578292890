import { connect } from "react-redux";
import * as formStatusAction from "../../../store/formStatus/formStatus.action";
import * as formDataAction from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";

import CheckSex from "./checkSex.component";
import {
  disabledBtn,
  stepBack,
  stepNext,
} from "../../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../../store/wizard/wizard.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";

const mapStateToProps = (state, ownProps) => {
  return {
    formStatus: formStatusSelectors.getFormStatus(state),
    formData: formDataSelectors.getFormData(state),
    component: wizardSelectorsComponent.getComponent(state),
    flusso: wizardSelectorsFlusso.getFlusso(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    stepNext: (step) => dispatch(stepNext(step)),
    stepBack: (step) => dispatch(stepBack(step)),
    disabledBtn: (status) => dispatch(disabledBtn(status)),
    utagLink: (data) => dispatch(utagLink(data)),
  };
};

const DocumentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckSex);
export default DocumentContainer;
