import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { utagView } from "../../../store/utagStore/utagStore.action";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";
import { getRegFlusso } from "../../../utility/analytics";
import PreFooterDesktop from "./preFooterDeskop/preFooterDesktop.component";
import PreFooterMobilePopup from "./preFooterMobilePopup/preFooterMobilePopup.component";
import PreFooterMobileText from "./preFooterMobileText/preFooterMobileText.component";

export default function PreFooter() {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const [popUpActive, setPopUpActive] = useState(false);

  const openPopUp = () => {
    const bonus = !formData.promoCode ? formData.bonusCode : formData.promoCode;

    dispatch(
      utagView({
        reg_step: "contattaci",
        reg_bonus: bonus,
        reg_flusso: getRegFlusso(flusso, formData.fromPdv),
      }),
    );

    setPopUpActive(true);
  };

  if (popUpActive) {
    return <PreFooterMobilePopup closePopUp={() => setPopUpActive(false)} />;
  }

  return (
    <>
      <PreFooterMobileText openPopUp={openPopUp} />
      <PreFooterDesktop />
    </>
  );
}
