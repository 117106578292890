import { MARK_VISITED_FOLLOUP_LINK } from "./followUp.action";

const initialState = {
  visitedFollowUpLink: false,
};

export const followUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case MARK_VISITED_FOLLOUP_LINK:
      return { ...state, visitedFollowUpLink: true };
    default:
      return state;
  }
};
