import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFlow } from "../../../hooks/useFlow";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { getFlow } from "../../../utility/wizardConfig/wizardConfig";
import RegistrationOCRSelectionCard from "../registrationOCRSelectionCard/registrationOCRSelectionCard.component";

export default function ManualRegistrationCardPdv() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { goToSpecificStep } = useFlow();

  const handleClick = async () => {
    dispatch(
      utagLink({
        reg_event: "inserimento-dati",
        conversion_pagename:
          "registrazione:pdv:step_0:apri un conto rivenditore",
        reg_cta: "scansiona documento",
        reg_flusso: "pdv rivenditori",
      }),
    );

    await getFlow("PDV", null, history);

    // skip page "inizio"
    goToSpecificStep("/nome-cognome");
  };

  return (
    <RegistrationOCRSelectionCard
      title={<Translate id="text.registrationOCR.insertDataManually" />}
      description={
        <Translate id="text.registrationOCR.insertDataManuallyDescriptionPdv" />
      }
      onClick={handleClick}
    />
  );
}
