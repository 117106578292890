import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import PopupModal from "../../../popupModal/popupModal";
import { isForeign } from "../../summary.helpers";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupDetailFooter from "../../summaryPopupFooter/summaryPopupDetailFooter.component";
import SummaryPopupInfoBlock from "../../summaryPopupInfoBlock/summaryPopupInfoBlock.component";
import TextWithNationFlag from "../../textWithNationFlag/textWithNationFlag.component";
import { addressLinkTranslateId } from "../summaryAddress.config";

export default function SummaryAddressPopupDetailContent({
  onClose,
  onPressEdit,
}) {
  const { trackClosePopup } = useSummaryAnalyticsTrackLinks();

  const handleClose = () => {
    trackClosePopup(addressLinkTranslateId);
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleClose}>
        <Translate id="lbl.residenceDomicile" />
      </PopupModal.Header>

      <PopupModal.Body>
        <SummaryAddressPopupDetailBody />
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryPopupDetailFooter onClose={onClose} onPressEdit={onPressEdit} />
      </PopupModal.Footer>
    </>
  );
}

function SummaryAddressPopupDetailBody() {
  const { sameResidenceAndDomicile } = useSelector(
    formDataSelectors.getFormData,
  );

  return (
    <div className="popup-body">
      {!sameResidenceAndDomicile && (
        <div className="popup-label">
          <Translate id="lbl.residence" />
        </div>
      )}

      <ResidenceInfo />

      {!sameResidenceAndDomicile && (
        <>
          <div className="popup-label" style={{ paddingTop: "8px" }}>
            <Translate id="lbl.domicile" />
          </div>
          <DomicileInfo />
        </>
      )}
    </div>
  );
}

function ResidenceInfo() {
  const {
    state,
    city,
    province,
    address,
    cap,
    addressEstero,
    capEstero,
    cityEstero,
  } = useSelector(formDataSelectors.getFormData);

  const foreign = isForeign(state);

  return (
    <>
      <SummaryPopupInfoBlock
        title={<Translate id="lbl.country" />}
        data={
          <TextWithNationFlag sign={state?.sign} text={state?.description} />
        }
      />

      <SummaryPopupInfoBlock
        title={<Translate id="lbl.residenceTown" />}
        data={foreign ? cityEstero : `${city} (${province})`}
      />

      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr" }}>
        <SummaryPopupInfoBlock
          title={<Translate id="lbl.residenceAddress" />}
          data={foreign ? addressEstero : address}
        />
        <SummaryPopupInfoBlock
          title={<Translate id="lbl.residenceCap" />}
          data={foreign ? capEstero : cap}
        />
      </div>
    </>
  );
}

function DomicileInfo() {
  const {
    domicileState,
    domicileCity,
    domicileProvince,
    domicileAddress,
    domicileCap,
    addressEsteroDomi,
    capEsteroDomi,
    cityEsteroDomi,
  } = useSelector(formDataSelectors.getFormData);

  const foreign = isForeign(domicileState);

  return (
    <>
      <SummaryPopupInfoBlock
        title={<Translate id="lbl.country" />}
        data={
          <TextWithNationFlag
            sign={domicileState?.sign}
            text={domicileState?.description}
          />
        }
      />

      <SummaryPopupInfoBlock
        title={<Translate id="lbl.residenceTown" />}
        data={
          foreign ? cityEsteroDomi : `${domicileCity} (${domicileProvince})`
        }
      />

      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr" }}>
        <SummaryPopupInfoBlock
          title={<Translate id="lbl.residenceAddress" />}
          data={foreign ? addressEsteroDomi : domicileAddress}
        />
        <SummaryPopupInfoBlock
          title={<Translate id="lbl.residenceCap" />}
          data={foreign ? capEsteroDomi : domicileCap}
        />
      </div>
    </>
  );
}
