import { v4 as uuidv4 } from "uuid";
import { instanceGet, instancePost } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export const getDisponibilita = async (channel) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/adv/getDisponibilita`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const getOrigineFondi = async (channel) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/adv/getOrigineFondi`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const getProfessioni = async (channel) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/adv/getProfessioni`;
  let data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const richiestaAdvPlus = async (formData, data, esitoAdv) => {
  let url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/adv/richiestaAdvPlus`;

  let formParams = {
    requestId: uuidv4(),
    channel: formData.channel,
    ipAddress: formData.ipAddress,
    infoChannel: formData.userAgent,
    esitoAdv: esitoAdv,
  };
  if (esitoAdv) {
    formParams = Object.assign(formParams, {
      origineFondi: data.origineFondi,
      professione: data.professione,
      disponibilita: data.disponibilita,
    });
  }

  const config = {
    headers: {
      Authorization: `Bearer\u0020${formData.token}`,
    },
  };

  const res = await instancePost.post(url, formParams, config);
  return res.data;
};
