import { useDispatch } from "react-redux";
import { useRegFlusso } from "../../../hooks/useAnalytics";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import LoyaltyCard from "../../loyaltyCard/loyaltyCard.component";

export default function TypLoyaltyCard({ completionPercentage }) {
  const dispatch = useDispatch();

  const reg_flusso = useRegFlusso();

  const handleClick = () => {
    dispatch(
      utagLink({
        reg_event: "loyalty-banner-typ-click",
        reg_cta: "inserisci",
        conversion_pagename: `reg-typ:${completionPercentage}`,
        reg_flusso,
      }),
    );
  };

  return (
    <div style={{ maxWidth: "500px" }}>
      <LoyaltyCard
        insertCodeTitleTranslationId="text.doYouHaveCodeSuperEnalotto"
        insertCodeDescriptionTranslationId="text.insertYourPlayedCode"
        confirmWithPointsTitleTranslationId="text.withSuperEnalotto.youHadWonPoints"
        confirmWithPointsDescriptionTranslationId="text.youCanGetYourBonus"
        confirmWithoutPointsDescriptionTranslationId="text.loyalty.thankYouForRegistration"
        onClick={handleClick}
      />
    </div>
  );
}
