import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import {
  getLotteryPdvPrize,
  postGeneratePrize,
  validateLotteryReceipt,
  postLotteryReceipt,
} from "../../services/loyaltyLottery";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { SignCheckSelectors } from "../../store/signCheck/signCheck.selectors";
import { utagView } from "../../store/utagStore/utagStore.action";
import { getErrorTranslationId } from "./loyaltyError";
import { useLoyaltyPopupFormError } from "./loyaltyPopup.hooks";
import SuccessPopup from "./successPopup/successPopup.component";
import ValidateReceiptPopup from "./validateReceiptPopup/validateReceiptPopup.component";

export default function LoyaltyPopup({ closeLoyaltyModal }) {
  const dispatch = useDispatch();

  const history = useHistory();

  const reg_flusso = useRegFlusso();

  const generateLoyaltyPrizeIfEnabled = useLoyaltyPrizeGeneration();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showValidationPopup, setShowValidationPopup] = useState(true);

  const formData = useSelector(formDataSelectors.getFormData);
  const signStatus = useSelector(SignCheckSelectors.getSignStatus);

  const { goToNextStep } = useFlow();

  const { addAndTrackLoyaltyFormError, removeLoyaltyFormError } =
    useLoyaltyPopupFormError();

  useEffect(() => {
    dispatch(
      utagView(
        {
          reg_step: "registrazione:inserisci codice giocata:loyalty",
          conversion_pagename: "registrazione:inserisci codice giocata:loyalty",
          reg_flusso,
        },
        { force: true, delay: 700 },
      ),
    );
  }, [dispatch, reg_flusso]);

  const handleSubmit = async () => {
    try {
      await validateLotteryReceipt(
        formData.channel,
        formData.ipAddress,
        formData.loyalty.lotteryReceipt.playCode,
      );

      setShowValidationPopup(false);

      sisalBonusPoints();

      if (!!formData.billCode) {
        savePlayCodeValue();
      }

      removeLoyaltyFormError();
    } catch (error) {
      const serverResponse = error?.response?.data;

      if (!serverResponse) {
        console.error(error);
        history.push("/errore-registrazione");
        return;
      }

      const errCodeMapped = getErrorTranslationId(
        serverResponse?.error?.errorInfos?.playCode?.[0],
      );

      addAndTrackLoyaltyFormError(errCodeMapped);

      dispatch(
        addFormData({
          loyalty: {
            ...formData.loyalty,
            lotteryReceipt: {
              playCode: "",
              flagPdv: "0",
            },
          },
        }),
      );
    }
  };

  const savePlayCodeValue = async () => {
    try {
      await postLotteryReceipt(
        formData.channel,
        formData.ipAddress,
        formData.loyalty.lotteryReceipt.playCode,
        formData.billCode,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const sisalBonusPoints = async () => {
    try {
      const data = await getLotteryPdvPrize(
        formData.channel,
        formData.ipAddress,
        formData.loyalty.lotteryReceipt.playCode,
      );

      dispatch(
        addFormData({
          loyalty: {
            ...formData.loyalty,
            sisalBonusPoints: data.prizeAmount,
            sgadId: data.sgadId,
          },
        }),
      );

      setShowSuccessModal(true);

      // this gets called only in thank you page in this context. If the billCode from the
      // signUp is not set in Redux, the API call is skipped
      generateLoyaltyPrizeIfEnabled(data.prizeAmount, data.sgadId);
    } catch (error) {
      setShowSuccessModal(true);
    }
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    closeLoyaltyModal();

    dispatch(
      addFormData({ loyalty: { ...formData.loyalty, gameCodeExist: true } }),
    );

    if (signStatus.personaData === false && formData.fromPdv) {
      goToNextStep();
    }
  };

  const closeReceiptPupup = () => {
    setShowValidationPopup(false);

    closeLoyaltyModal();

    dispatch(
      addFormData({
        loyalty: {
          ...formData.loyalty,
          lotteryReceipt: { ...formData.loyalty.lotteryReceipt, playCode: "" },
        },
      }),
    );

    dispatch(addFormStatus({ playCode: "" }));
  };

  return (
    <div>
      {showValidationPopup && (
        <ValidateReceiptPopup
          onSubmit={handleSubmit}
          closeModal={closeReceiptPupup}
        />
      )}

      {showSuccessModal && (
        <SuccessPopup
          closeModal={closeSuccessModal}
          sisalPoints={formData.loyalty.sisalBonusPoints}
        />
      )}
    </div>
  );
}

function useLoyaltyPrizeGeneration() {
  const { billCode } = useSelector(formDataSelectors.getFormData);

  return async (sisalBonusPoints, sgadId) => {
    const shouldCallGeneratePrizeEndpoint = !!billCode;

    if (!shouldCallGeneratePrizeEndpoint) {
      return;
    }

    try {
      await postGeneratePrize(billCode, sisalBonusPoints, sgadId);
    } catch (error) {
      console.error(error);
    }
  };
}
