export default function DocumentSelectionCTA({
  text,
  selected,
  onChange,
  value,
}) {
  return (
    <label
      className={`document-selection-cta ${selected ? "document-selection-cta--selected" : ""}`}
    >
      <input
        name="document-selection"
        type="radio"
        onChange={onChange}
        checked={selected}
        value={value}
      />
      {text}
    </label>
  );
}
