import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFlow } from "../../../hooks/useFlow";
import { checkBonusByCode } from "../../../services/bonusController";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import { disabledBtn, setBonus } from "../../../store/wizard/wizard.action";
import {
  wizardSelectorsBonus,
  wizardSelectorsComponent,
} from "../../../store/wizard/wizard.selectors";
import { checkingForm } from "../../../utility/checkingForm/checkingForm.container";
import PromoCodePopup from "./promoCodePopup/promoCodePopup.component";

export default function PromoCode({ onClose, onCancel }) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { goToNextStep } = useFlow();

  const inputRef = useRef();

  const bonus = useSelector(wizardSelectorsBonus.getBonus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const formData = useSelector(formDataSelectors.getFormData);

  const [promoCode, setPromoCode] = useState(
    formData.promoCode || formData.promoCodeUrl,
  );
  const [active, setActive] = useState(formData.promoCode.length > 0);
  const [bonusDesc, setBonusDesc] = useState("");
  const [bonusValidityErr, setBonusValidityErr] = useState(false);
  const [
    inputValueIsChangedFromLastCheck,
    setInputValueIsChangedFromLastCheck,
  ] = useState(false);

  const checkBonusByCodeAPI = async (popupSuccessCallback) => {
    if (inputRef.current.value.trim() === "") return;

    try {
      const data = await checkBonusByCode(
        inputRef.current.value.trim(),
        formData.channel,
        formData.codiceRice,
      );

      if (data.isValid) {
        dispatch(
          addFormData({
            promoCode: promoCode.toUpperCase().trim(),
            customizesBonus: true,
            bonusCode: "",
          }),
        );

        dispatch(
          utagLink({
            promo_code: inputRef.current.value.trim(),
            reg_event: "promo-check",
            promo_outcome: "ok",
          }),
        );

        setInputValueIsChangedFromLastCheck(false);

        popupSuccessCallback(
          promoCode.toUpperCase().trim(),
          formData.promoCode,
          formData.promoCodeUrl,
          setBonusDesc,
        );
      } else {
        setBonusDesc("");
        setBonusValidityErr(true);
        setInputValueIsChangedFromLastCheck(false);

        dispatch(
          utagLink({
            promo_code: inputRef.current.value.trim(),
            reg_event: "promo-check",
            promo_outcome: "ko",
          }),
        );
      }
    } catch {
      history.push("/errore-registrazione");
    }
  };

  const isValid = (value) => {
    setBonusValidityErr(false);

    if (value.length <= 20) {
      dispatch(
        addFormData(
          component.name !== "summary"
            ? { customizesBonus: false, bonusCode: "" }
            : { customizesBonus: false, bonusCode: "", promoCode: "" },
        ),
      );
      setPromoCode(value);
    }
  };

  const clearBonusInput = (e, isValidFunction) => {
    e.preventDefault();
    isValidFunction("");
    inputRef.current.focus();
  };

  const addBlankBonus = () => {
    let description =
      "Il codice promozioni inserito è corretto, completa la registrazione per riscattare il tuo bonus";

    setBonusDesc(description);

    dispatch(
      setBonus({
        bonusCode: formData.promoCode,
        title: formData.promoCode,
        bonusTitleLightBox: formData.promoCode,
        description: description,
        style: "bonus",
      }),
    );

    dispatch(
      addFormData({
        promoCode: promoCode.toUpperCase().trim(),
        customizesBonus: true,
        bonusCode: "",
        findBonus: false,
      }),
    );
  };

  const bonusSelect = () => {
    if (formData.findBonus) {
      dispatch(addFormData({ findBonus: false }));
      setActive(false);
    }

    if (
      formData.promoCode !== "" &&
      formData.bonusCode === "" &&
      !bonusValidityErr
    ) {
      if (formData.bonusList && formData.bonusList.length > 0) {
        let index = formData.bonusList.findIndex(
          (obj) => obj.bonusCode === formData.promoCode,
        );
        if (index !== -1) {
          let item = formData.bonusList[index];

          if (!!item.description) {
            setBonusDesc(item.description);
          }
          dispatch(setBonus(item));

          dispatch(
            addFormData({
              promoCode: promoCode.toUpperCase().trim(),
              customizesBonus: true,
              bonusCode: "",
              findBonus: false,
            }),
          );
        } else {
          const item = formData.bonusListAll.find(
            (obj) => obj.bonusCode === formData.promoCode,
          );

          if (!!item) {
            if (!!item.description) {
              setBonusDesc(item.description);
            }
            dispatch(setBonus(item));

            dispatch(
              addFormData({
                promoCode: promoCode.toUpperCase().trim(),
                customizesBonus: true,
                bonusCode: "",
                findBonus: false,
              }),
            );
          } else {
            addBlankBonus();
          }
        }
      } else {
        if (formData.promoCodeUrl === "" || formData.promoCodeUrl == null) {
          addBlankBonus();
        }
      }
    } else {
      setBonusDesc("");
    }
  };

  const confirmPromoCode = () => {
    if (!!bonus) {
      goToNextStep();
    }

    dispatch(addFormData({ promoCodeUrl: "" }));
    setActive(!active);
    setBonusValidityErr(false);
    window.scrollTo(0, 0);
    dispatch(disabledBtn(checkingForm("welcomeBonus")));
    onClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => bonusSelect(), [formData.promoCode, formData.bonusList]);

  return (
    <PromoCodePopup
      bonusDesc={bonusDesc}
      bonusValidityErr={bonusValidityErr}
      checkBonusByCodeAPI={checkBonusByCodeAPI}
      clearBonusInput={clearBonusInput}
      onClose={onCancel ?? onClose}
      confirmPromoCode={confirmPromoCode}
      inputRef={inputRef}
      inputValueIsChangedFromLastCheck={inputValueIsChangedFromLastCheck}
      promoCode={promoCode}
      setInputValueIsChangedFromLastCheck={setInputValueIsChangedFromLastCheck}
      validationFunction={isValid}
    />
  );
}
