import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { MAIN_SITE_HOMEPAGE } from "../../config";
import useQueryParams from "../../hooks/useQueryParams";
import confermaImg from "../../images/svg/conferma-email.svg";
import logo from "../../images/svg/logo-sisal.svg";
import { confirmEmail } from "../../services/validatorController";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { utagView } from "../../store/utagStore/utagStore.action";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";
import PreFooter from "../elementForm/preFooter/preFooter.component";

const fetchStateValue = {
  loading: "loading",
  success: "success",
  error: "error",
};

export default function VerificationEmail() {
  const dispatch = useDispatch();
  const { searchParams } = useQueryParams();
  const { channel } = useSelector(formDataSelectors.getFormData);

  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [fetchState, setFetchState] = useState(fetchStateValue.loading);
  const [confirmedBefore, setConfirmedBefore] = useState(false);

  const goToHomePage = () => {
    const url = MAIN_SITE_HOMEPAGE;
    window.location.href = url;
  };

  useEffect(() => {
    const execConfirmEmail = async () => {
      try {
        await confirmEmail({
          channel,
          srv: searchParams.get("srv"),
          req: searchParams.get("req"),
          cod: searchParams.get("cod"),
          ctr_req: searchParams.get("ctr_req"),
        });
        setFetchState(fetchStateValue.success);
      } catch (err) {
        setFetchState(fetchStateValue.error);
        if (
          err.response?.data?.error?.errorInfos?.generic?.[0]?.includes("2008")
        ) {
          setTitle("text.confermaMail");
          setConfirmedBefore(true);
        } else {
          setTitle("text.somethingWentWrong");
          setMessage("text.contactForHelp");
        }
      }
    };

    dispatch(utagView({ reg_step: "verification-email" }));

    execConfirmEmail();
  }, [channel, dispatch, searchParams]);

  if (fetchState === fetchStateValue.loading) return <FullPageLoader />;

  return (
    <>
      <header className="conferma-header">
        <a
          href={MAIN_SITE_HOMEPAGE}
          title="Sisal"
          id="headerLogo"
          className="verificationEmailHeaderLogo"
        >
          <img className="logo" alt="logo" src={logo} />
        </a>
      </header>
      <div className="container">
        <div className="row conferma-email">
          <div className="col-12 col-md-6">
            <img src={confermaImg} alt="conferma-email" />
          </div>
          {fetchState === fetchStateValue.error ? (
            <VerificationEmailError
              title={title}
              confirmedBefore={confirmedBefore}
              message={message}
              goToHomePage={goToHomePage}
            />
          ) : (
            <VerificationEmailSuccess goToHomePage={goToHomePage} />
          )}
        </div>
      </div>
    </>
  );
}

function VerificationEmailError({
  title,
  confirmedBefore,
  message,
  goToHomePage,
}) {
  return (
    <div className="col-12 col-md-6">
      <h1>
        <Translate id={title} />
      </h1>
      <div className="conferma-text">
        {confirmedBefore ? (
          <>
            <p>
              <Translate id="text.troviDatiPersonali" />
            </p>
            <p>
              <Translate id="text.customerText" />
            </p>
          </>
        ) : (
          <p>
            <Translate id={message} />
          </p>
        )}
      </div>
      {confirmedBefore ? (
        <button className="vai-homepage" onClick={goToHomePage}>
          <Translate id="text.goHome" />
        </button>
      ) : (
        <PreFooter />
      )}
    </div>
  );
}

function VerificationEmailSuccess({ goToHomePage }) {
  return (
    <div className="col-12 col-md-6">
      <h1>
        <Translate id="text.confermaMail" />
      </h1>
      <div className="conferma-text">
        <p>
          <Translate id="text.troviDatiPersonali" />
        </p>
        <p>
          <Translate id="text.customerText" />
        </p>
      </div>
      <button className="vai-homepage" onClick={goToHomePage}>
        <Translate id="text.goHome" />
      </button>
    </div>
  );
}
