import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./_phoneNumberPopup.scss";
import { checkOtp, generateOtp } from "../../../services/otpController";
import { Translate } from "react-localize-redux";
import { addFormData } from "../../../store/formData/formData.action";
import { KEY_RE_CAPTCHA } from "../../../config";
import { compileOtpFromSms } from "../../../utility/otp/otp";
import PopupModal from "../../popupModal/popupModal";
import verifiedImg from "../../../images/svg/popupModal/verified.svg";

const PhonePopup = ({
  isVerifiedPhone,
  isPhonePopupOtpSended,
  setIsPhoneOtpSended,
  isOtpNotValid,
  setIsOtpNotValid,
  otpBlockStatus,
  setOtpBlockStatus,
  phonePrefix,
  utagLink,
  utagView,
  closeMe,
  number,
  formData,
  activate,
}) => {
  const dispatch = useDispatch();

  const prefix = "+" + phonePrefix;

  let [error, setError] = useState(false);
  const [timerStatus, setTimerStatus] = useState(true);
  // const [timeLeft, setTimeLeft] = useState(10);
  const [otpCode, setOTPCode] = useState("");

  const clearInputFields = () => {
    setOTPCode("");
  };

  const handleChangeData = (value, name) => {
    if (!/[ ]/.test(value)) {
      let valueNumber = +value;
      if (!isNaN(valueNumber) || value === "") {
        setError(false);
        setIsOtpNotValid(false);
        setIsPhoneOtpSended(false);
        setOtpBlockStatus({ ...otpBlockStatus, invalided: false });
        if (value.length <= 4) setOTPCode(value);
      }
    }
  };

  const utagLinkEvent = (link) =>
    utagLink({
      reg_event: link,
    });

  const resendCode = async () => {
    clearInputFields();
    setIsPhoneOtpSended(false);
    setError(false);
    setIsOtpNotValid(false);

    const token = await window.grecaptcha.enterprise.execute(KEY_RE_CAPTCHA, {
      action: "GENERATE_REG_OTP",
    });

    try {
      const r = await generateOtp(number, prefix, token, formData.channel);
      setTimerStatus(true);
      setIsPhoneOtpSended(true);
      if (r.counterRefresh > 2) {
        setOtpBlockStatus({ ...otpBlockStatus, blocked: true });
      }

      compileOtpFromSms(setOTPCode);
    } catch (e) {
      const error = e.response.data.error.errorInfos.generic[0];
      if (e.response.status === 422 && error === "err.otp.block") {
        setOtpBlockStatus({ blocked: false, invalided: false });
      } else {
        closeMe();
      }
    }
  };

  const checkValidate = () => !error && otpCode.length === 4;

  const validateOtp = (e) => {
    window.grecaptcha.enterprise
      .execute(KEY_RE_CAPTCHA, { action: "CHECK_REG_OTP" })
      .then(async (token) => {
        await checkOtp(number, prefix, otpCode, token, formData.channel)
          .then(({ jwt, status }) => {
            if (status !== "SUCCESS") {
              console.error("Error in otp validation");
              setError(true);
              return;
            }

            dispatch(
              addFormData({
                phoneNumberCertified: true,
                jwt: jwt,
              }),
            );
            activate();
            utagLinkEvent("otp_tel_ok");
          })
          .catch((e) => {
            utagLinkEvent("otp_tel_ko");
            if (e.response && e.response.status === 422) {
              clearInputFields();

              const error =
                e.response.data.error.errorInfos?.generic[0] ??
                e.response.data.error.errorInfos?.void[0];

              switch (error) {
                case "err.generic.otp":
                  const counterCheck = e.response.data.counterCheck;
                  if (counterCheck && counterCheck < 3) {
                    setError(true);
                  } else {
                    setIsOtpNotValid(true);
                  }
                  break;
                case "err.otp.expired.session":
                  setIsOtpNotValid(true);
                  break;
                case "err.otp.block":
                  setOtpBlockStatus({ blocked: true, invalided: true });
                  break;
                case "err.void":
                  setError(true);
                  break;
                default:
                  break;
              }
            } else {
              closeMe();
            }
          });
      });
  };

  useEffect(() => {
    utagView({ reg_step: "verifica_cel_otp" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    compileOtpFromSms(setOTPCode, abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let intervalId = null;

    if (timerStatus) {
      let tl = 10;
      intervalId = setInterval(() => {
        if (tl > 1) {
          --tl;
        } else {
          setTimerStatus(false);
        }
      }, 1050);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [timerStatus]);

  return (
    <PopupModal>
      <PopupModal.Header
        closeMe={() => {
          if (!isVerifiedPhone) closeMe();
        }}
      >
        <Translate id="text.verificaNumero" />
      </PopupModal.Header>

      <PopupModal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (checkValidate()) validateOtp();
          }}
        >
          <div className="otp-popup-main" id="it-ar-phone-popup-main">
            {isVerifiedPhone && (
              <div className="otp-popup-verified-overlay">
                <div className="otp-popup-verified">
                  <div className="otp-popup-verified-img">
                    <img alt="verified" src={verifiedImg} />
                  </div>
                  <div className="otp-popup-verified-title">
                    <span>
                      <Translate id="text.otp.verified" />
                    </span>
                  </div>
                </div>
              </div>
            )}
            <p className="otp-popup-main-text" id="it-ar-phone-popup-inserisci">
              <Translate id="text.inserisciPartOne" />{" "}
              <span className="bold">
                {number.slice(0, 2)} *** ** {number.slice(-2)}
              </span>
            </p>

            <div
              className={`form-group otp-popup-form-group ${
                otpBlockStatus.blocked || (!timerStatus && !isVerifiedPhone)
                  ? " timer-slot-notEmpty"
                  : ""
              }`}
            >
              <input
                autoComplete="one-time-code"
                className={`form-control ${
                  error || isOtpNotValid || otpBlockStatus.invalided
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                inputMode="numeric"
                name="otp-code"
                maxLength="4"
                required
                spellCheck="false"
                autoCorrect="off"
                value={otpCode}
                onChange={(e) =>
                  handleChangeData(e.target.value, e.target.name)
                }
              />
              <label className="form-control-label">
                <Translate id="text.otp.securityCode" />
              </label>
              {isPhonePopupOtpSended && (
                <div className={`resended-otp`}>
                  <Translate id="text.resendOtpMsg" />
                </div>
              )}
              {error ? (
                <div className="invalid-new">
                  <Translate id="text.otpMsg" />
                </div>
              ) : isOtpNotValid ? (
                <div className="invalid-new">
                  <Translate id="text.invalidOtpMsg" />
                </div>
              ) : otpBlockStatus.invalided ? (
                <div className="invalid-new">
                  <Translate id="text.blockOtpMsg" />
                </div>
              ) : null}
            </div>

            {otpBlockStatus.blocked ? (
              <div className="otp-popup-resend-wait">
                <p className="otp-popup-resend-wait-text">
                  {" "}
                  <Translate id="text.waitForResend.long" />
                </p>
              </div>
            ) : (
              !timerStatus &&
              !isVerifiedPhone && (
                <div className="otp-popup-main-resend">
                  <p className="otp-popup-main-resend-text">
                    {" "}
                    <span>
                      <Translate id="text.nonRiceviSms" />
                    </span>{" "}
                    <a
                      className="otp-popup-main-resend-link"
                      onClick={() => resendCode()}
                    >
                      {" "}
                      <Translate id="lbl.resend" />
                    </a>
                  </p>
                </div>
              )
            )}
          </div>
        </form>
      </PopupModal.Body>

      <PopupModal.Footer>
        <button
          disabled={!checkValidate() || isVerifiedPhone}
          onClick={(e) => {
            e.preventDefault();
            validateOtp();
          }}
        >
          <Translate id="lbl.verify" />
        </button>
      </PopupModal.Footer>
    </PopupModal>
  );
};

export default PhonePopup;
