import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeRechargeLimitApprovalPopup } from "../store/rechargeLimit/rechargeLimit.action";

export function useRechargeLimitModalCleanup() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(closeRechargeLimitApprovalPopup());
  }, [dispatch]);
}
