export const vivochaErrorCodes = {
  PDV_KO_CHECK_CRENDENTIALS: "pdvKoCheckCrendentials",
  PDV_EXPIRED_TICKET: "pdvExpiredTicket",
  FISCAL_CODE_AND_DATA_NOT_MATCHING: "fiscalCodeAndDataNotMatching",
  ACCOUNT_ALREADY_EXISTS: "accountAlreadyExists",
  FISCAL_CODE_ALREADY_PRESENT: "fiscalCodeAlreadyPresent",
  PHONE_NUMBER_ALREADY_PRESENT: "phoneNumberAlreadyPresent",
};

export const vivochaWatchedErrors = {
  "err.generic.pdv.mismatch": vivochaErrorCodes.PDV_KO_CHECK_CRENDENTIALS,
  "err.pdv.ticket": vivochaErrorCodes.PDV_EXPIRED_TICKET,
  "err.6055": vivochaErrorCodes.PDV_EXPIRED_TICKET,
  "err.generic.header.title.e80XX":
    vivochaErrorCodes.FISCAL_CODE_AND_DATA_NOT_MATCHING,
  "err.fiscalcode.bad": vivochaErrorCodes.FISCAL_CODE_AND_DATA_NOT_MATCHING,
  "err.fiscalcode.badcontrolchar":
    vivochaErrorCodes.FISCAL_CODE_AND_DATA_NOT_MATCHING,
  "err.e8003": vivochaErrorCodes.ACCOUNT_ALREADY_EXISTS,
  "err.fiscalcode.alreadypresent":
    vivochaErrorCodes.FISCAL_CODE_ALREADY_PRESENT,
  "err.phoneNumber.alreadypresent":
    vivochaErrorCodes.PHONE_NUMBER_ALREADY_PRESENT,
};

export function getCurrentVivochaErrors(formStatus) {
  return Object.entries(vivochaWatchedErrors)
    .filter(([watchedErrorKey, watchedErrorValue]) => {
      const currentError = Object.entries(formStatus).find(
        ([formStatusKey, formStatusValue]) =>
          watchedErrorKey === formStatusValue,
      );

      return currentError != null;
    })
    .map(([watchedErrorKey, watchedErrorValue]) => watchedErrorValue);
}

export const isVivochaError = (messageError) => {
  return Object.keys(vivochaWatchedErrors).includes(messageError);
};
