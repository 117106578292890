export function spidErrTitleAndDesc(errorSpidDoc) {
  switch (errorSpidDoc) {
    case "err.document.expired":
      return {
        title: "text.document.expiredTitle",
        desc: "text.document.expiredDesc",
      };
    case "err.document.missingData":
      return {
        title: "text.document.missingDataTitle",
        desc: "text.document.missingDataDesc",
      };
    case "err.document.notAccepted":
      return {
        title: "text.document.notAcceptedTitle",
        desc: "text.document.notAcceptedDesc",
      };
    case "err.document.genericErr":
      return {
        title: "text.document.genericErrTitle",
        desc: "text.document.genericErrDesc",
      };
    default:
      return {
        title: null,
        desc: null,
      };
  }
}
