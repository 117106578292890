import { forwardRef } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { moveCursorAtTheEnd } from "../../../utility/input/input";
import MessageError from "../../elementForm/messageError/messageError.component";

function FiscalCodeInputFieldBareUi(
  { errorInputClassName, bottomComponent, ...rest },
  ref,
) {
  const { fiscalCode } = useSelector(formDataSelectors.getFormData);

  return (
    <div id="it-ar-fiscalCode-form-group1" className="form-group form-group-fc">
      <input
        ref={ref}
        autoComplete="on"
        className={`form-control ${
          errorInputClassName != null ? errorInputClassName : ""
        }`}
        type="text"
        required
        name="fiscalCode"
        id="it-ar-fiscalCode-fiscalCode"
        value={fiscalCode}
        onFocus={(e) => moveCursorAtTheEnd(e.target)}
        {...rest}
      />

      <label
        className="form-control-label"
        id="it-ar-fiscalCode-lblfiscalcode"
        htmlFor="it-ar-fiscalCode-fiscalCode"
      >
        <Translate id="lbl.fiscalcode" />
      </label>

      {!!errorInputClassName && (
        <MessageError currentValue={fiscalCode} currentName="fiscalCode" />
      )}

      {bottomComponent}
    </div>
  );
}

const FiscalCodeInputFieldBare = forwardRef(FiscalCodeInputFieldBareUi);

export default FiscalCodeInputFieldBare;
