import { connect } from "react-redux";
import LoadingGif from "./loadingGif.component";
import * as formStatusAction from "../../../store/formStatus/formStatus.action";
import { setComponent } from "../../../store/wizard/wizard.action";
import { wizardSelectorsFlow } from "../../../store/wizard/wizard.selectors";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { utagView } from "../../../store/utagStore/utagStore.action";

const mapStateToProps = (state) => {
  return {
    formData: formDataSelectors.getFormData(state),
    flow: wizardSelectorsFlow.getWizardFlow(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    setComponent: (component) => dispatch(setComponent(component)),
    utagView: (data) => dispatch(utagView(data)),
  };
};

const LoadingGifContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoadingGif);
export default LoadingGifContainer;
