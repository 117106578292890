import { v4 as uuidv4 } from "uuid";
import { instanceGet, instancePost } from "../axios.config";
import { REACT_APP_URL } from "../config";

export async function postFastfillOcr({
  billCodeId,
  channel,
  ipAddress,
  documentType,
}) {
  const response = await instancePost.post(
    `${REACT_APP_URL}/ocr-ms/ms/jumio/fastfill-ocr`,
    {
      requestId: uuidv4(),
      channel,
      ipAddress,
      billCodeId,
      documentType,
      callbackUrl: `${REACT_APP_URL}/ocr-ms/ms/jumio/callback`,
    },
  );

  return response.data;
}

export async function getFastfillOcrStatus({
  channel,
  ipAddress,
  accountId,
  workflowExecutionId,
}) {
  const response = await instanceGet.get(
    `${REACT_APP_URL}/ocr-ms/ms/jumio/fastfill-ocr-status`,
    {
      params: {
        requestId: uuidv4(),
        channel,
        ipAddress,
        accountId,
        workflowExecutionId,
      },
    },
  );

  return response.data;
}

export async function getFastfillRetrieveData({
  channel,
  ipAddress,
  accountId,
  workflowExecutionId,
}) {
  const response = await instanceGet.get(
    `${REACT_APP_URL}/ocr-ms/ms/jumio/fastfill-retrieve-data`,
    {
      params: {
        requestId: uuidv4(),
        channel,
        ipAddress,
        accountId,
        workflowExecutionId,
      },
    },
  );

  return response.data;
}
