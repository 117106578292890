import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";

export const SERVICES = {
  spid: "SPID",
  jumio: "jumio",
};

export async function getServiceStatus(service) {
  const url = `${REACT_APP_URL}${PORT}/config-reg-ms/ms/flowflag/serviceEnabled/${service}`;
  const response = await instanceGet.get(url);
  return response.data;
}

export async function getAllServiceStatus() {
  // const url = `${REACT_APP_URL}${PORT}/config-reg-ms/ms/flowflag/serviceEnabled/`;
  // const response = await instanceGet.get(url);
  // return response.data;
  return {
    status: "SUCCESS",
    allServicesEnabled: {
      SPID: true,
      recaptchaLoginIGT: false,
    },
  };
}
