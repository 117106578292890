import { VIEW, LINK } from "./utagStore.action";

const initialState = {
  view: {
    reg_step: "",
  },
};

export const utagReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW:
      return { ...state, view: action.view };
    case LINK:
      return { ...state, link: action.link };
    default:
      return state;
  }
};
