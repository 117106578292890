import { connect } from "react-redux";
import { utagView, utagLink } from "../../../store/utagStore/utagStore.action";
import { utagSelectors } from "../../../store/utagStore/utagStore.selectors";
import {
  setIsOptNotValid,
  setOtpBlockStatus,
  setIsPhonePopupOtpSended,
} from "../../../store/wizard/wizard.action";
import {
  wizardSelectorsIsOtpNotValid,
  wizardSelectorsIsPhonePopupOtpSended,
  wizardSelectorsOtpBlockStatus,
  wizardSelectorsVerifyPhone,
} from "../../../store/wizard/wizard.selectors";
import PhonePopup from "./phoneNumberPopup";
import { formDataSelectors } from "../../../store/formData/formData.selectors";

const mapStateToProps = (state, ownProps) => {
  return {
    utag: utagSelectors.getUtag(state),
    formData: formDataSelectors.getFormData(state),
    isPhonePopupOtpSended:
      wizardSelectorsIsPhonePopupOtpSended.getWizardIsPhonePopupOtpSended(
        state,
      ),
    isOtpNotValid: wizardSelectorsIsOtpNotValid.getWizardIsOtpNotValid(state),
    otpBlockStatus:
      wizardSelectorsOtpBlockStatus.getWizardOtpBlockStatus(state),
    isVerifiedPhone: wizardSelectorsVerifyPhone.getWizardIsVerifyPhone(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    utagView: (data) => dispatch(utagView(data)),
    utagLink: (data) => dispatch(utagLink(data)),
    setIsPhoneOtpSended: (isPhoneOtpSended) =>
      dispatch(setIsPhonePopupOtpSended(isPhoneOtpSended)),
    setIsOtpNotValid: (isOtpNotValid) =>
      dispatch(setIsOptNotValid(isOtpNotValid)),
    setOtpBlockStatus: (otpBlockStatus) =>
      dispatch(setOtpBlockStatus(otpBlockStatus)),
  };
};

const PhonePopupContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhonePopup);
export default PhonePopupContainer;
