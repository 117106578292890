import React, { useEffect } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import Betting from "../../../images/svg/icon/loader-ocr/betting.svg";
import Bingo from "../../../images/svg/icon/loader-ocr/bingo.svg";
import Lotterie from "../../../images/svg/icon/loader-ocr/lotterie.svg";
import Poker from "../../../images/svg/icon/loader-ocr/poker.svg";
import Roulette from "../../../images/svg/icon/loader-ocr/roulette.svg";
import Slot from "../../../images/svg/icon/loader-ocr/slot.svg";
import Virtual from "../../../images/svg/icon/loader-ocr/virtual.svg";
import { useHistory } from "react-router-dom";
import Regolamenti from "../../../images/svg/timeout/Regolamenti.svg";

const LoadingGif = (props) => {
  const {
    translate,
    formData,
    hasTimeout,
    utagView,
    utagLink,
    addFormData,
    addFormStatus,
    component,
    flow,
    setComponent,
  } = props;
  const history = useHistory();

  useEffect(() => {
    let flow = formData.fromPdv ? "pdv" : "standard";
    let bonus =
      formData.promoCode === "" ? formData.bonusCode : formData.promoCode;
    let docReg = formData.documentTypeObj
      ? formData.documentTypeObj.description
      : "";
    let errorMessage =
      formData.errorPageText === ""
        ? translate("text.somethingWentWrong")
        : formData.errorPageText;
    if (hasTimeout) {
      utagView({
        reg_step: "error-page-timeoutcaricamento",
        error_message: errorMessage,
        reg_bonus: bonus,
        reg_flusso: flow,
        reg_doc: docReg,
      });
    }
  }, [hasTimeout]);

  const goBack = () => {
    addFormStatus({ visualError: false });
    addFormData({ isLoadingOcr: false });
    uploadLater();
    if (component.name !== "summary") {
      if (component.previousComponentName) {
        const val = flow.find((v) => v.path === component.nextComponentName);
        if (val) {
          setComponent(val);
        }
        history.push(component.nextComponentName);
      }
    } else {
      const val = flow.find((v) => v.path === "/riepilogo");
      if (val) {
        setComponent(val);
      }
      history.push("/riepilogo");
    }
  };

  const uploadLater = () => {
    addFormData({
      hasDocFile: false,
      frontFileName: "",
      backFileName: "",
    });
    addFormStatus({
      frontFileName: "",
      backFileName: "",
    });

    linkUtagEvent("upload_no");
  };

  const linkUtagEvent = (name, value) => {
    utagLink({
      reg_event: name,
      reg_doc: value,
    });
  };

  return (
    <div className="loadingGif">
      <div className="all-black-gif">
        {hasTimeout ? (
          <>
            <div className="main">
              <div className="document">
                <img src={Regolamenti} alt="regolamenti" />
              </div>
              <div className="description">
                <h2 className="ops-si-sono-verifi">
                  Ops… Si sono verificati dei problemi durante il caricamento
                </h2>
                <p className="non-preoccuparti-pu">
                  Non preoccuparti, puoi aprire il Conto Gioco e<br />
                  inviarci il tuo documento d’identità dopo la registrazione.
                </p>
                <div className="last-line">
                  Hai fino a 30 giorni di tempo per farlo
                </div>
              </div>
              <div className="accedi">
                <button
                  className="component-button-primary-activ"
                  onClick={goBack}
                >
                  <span>Continua la registrazione</span>
                  <svg
                    className="icon-arrow-down-icon-arrow-for"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="loading-block">
              <div className="loading-gif ">
                <img className="gif-frame" src={Betting} alt="" />
              </div>
              <div className="loading-gif ">
                <img className="gif-frame" src={Bingo} alt="" />
              </div>
              <div className="loading-gif ">
                <img className="gif-frame" src={Lotterie} alt="" />
              </div>
              <div className="loading-gif ">
                <img className="gif-frame" src={Poker} alt="" />
              </div>
              <div className="loading-gif ">
                <img className="gif-frame" src={Roulette} alt="" />
              </div>
              <div className="loading-gif ">
                <img className="gif-frame" src={Slot} alt="" />
              </div>
              <div className="loading-gif ">
                <img className="gif-frame" src={Virtual} alt="" />
              </div>
            </div>
            <div className="loading-text">
              <p>
                <Translate id="text.processing" /> <br />{" "}
                <Translate id="text.yourDocument" />{" "}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withLocalize(LoadingGif);
