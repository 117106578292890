import { Translate } from "react-localize-redux";
import Header from "../header/header.component";
import Loader from "../loader/loader.component";
import { useAnalyticsUtagView, useOcrDataRetrieval } from "./ocrCallback.hooks";

export default function OcrCallback() {
  useOcrDataRetrieval();

  useAnalyticsUtagView();

  return (
    <>
      <Header />

      <main className="ocr-loader-container">
        <section className="ocr-loader-section">
          <h2 className="title">
            <Translate id="text.registrationOCR.processingAcquiredData" />
          </h2>

          <Loader />

          <p className="description">
            <Translate id="text.registrationOCR.itMayTakeSomeSeconds" />
          </p>
        </section>
      </main>
    </>
  );
}
