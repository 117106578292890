import { Translate } from "react-localize-redux";
import { useParams } from "react-router-dom";
import { useBeforeUnloadPopup } from "../../hooks/useBeforeUnloadPopup";
import { ReactComponent as Success } from "../../images/svg/success.svg";
import Header from "../header/header.component";
import TypLoyaltyCardPdv from "./typLoyaltyCardPdv/typLoyaltyCardPdv.component";
import UploadDocumentCard from "./uploadDocumentCard/uploadDocumentCard.component";

export default function ThankYouPagePdv() {
  const { slug } = useParams();

  useBeforeUnloadPopup();

  const showUploadDocumentCard = slug === "inserimento-manuale";

  return (
    <div className="thank-you-page-pdv">
      <Header />

      <main className="thank-you-page-pdv-body">
        <div className="text-section">
          <div className="success-icon-wrapper">
            <Success />
          </div>

          <h1 className="thank-you-page-pdv-title">
            <Translate id="text.typPdv.gameAccountActivated" />
          </h1>

          <p className="thank-you-page-pdv-description">
            <Translate id="text.typPdv.description" />
          </p>

          {showUploadDocumentCard && (
            <div className="card-wrapper">
              <UploadDocumentCard />

              <div className="upload-document-note">
                <Translate id="text.typPdv.uploadDocumentNote" />
              </div>
            </div>
          )}
        </div>

        <div className="bottom-section">
          <TypLoyaltyCardPdv />
        </div>
      </main>
    </div>
  );
}
