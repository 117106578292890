import { useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { disabledBtn } from "../../store/wizard/wizard.action";
import {
  wizardSelectorsComponent,
  wizardSelectorsFlusso,
} from "../../store/wizard/wizard.selectors";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import formIsValid from "../../utility/formIsValid/formIsValid.container";
import { moveCursorAtTheEnd } from "../../utility/input/input";
import MessageError from "../elementForm/messageError/messageError.component";
import Nation from "../elementForm/nation/nation.component";
import Town from "../elementForm/town/town.component";

export default function BirthPlace({
  onSelectTown,
  keyNation,
  scrollIntoViewOnOpenTown = false,
}) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const component = useSelector(wizardSelectorsComponent.getComponent);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const spidFiscalCodeFlowStatus = useSelector(
    spidStatusSelectors.getSpidFiscalCodeFlowStatus,
  );

  const [statusForm, setStatusForm] = useState(true);
  const cityEsteroBirthRef = useRef();
  let [flagEstero, setFlagEstero] = useState(false);
  let [previousValue, setPreviousValue] = useState({
    cityEsteroBirth: formData.cityEsteroBirth,
  });

  const onChange = (value) => {
    let newValue;

    setFlagEstero(false);
    if (!/[^a-zA-Z-' àáèéìíòóùú.'-]/.test(value)) {
      newValue = /^\s+/.test(value)
        ? value.substring(0, 50).trim()
        : value.substring(0, 50);
      dispatch(addFormData({ cityEsteroBirth: newValue }));
      if (newValue.length >= 2) {
        const error = formIsValid("cityEsteroBirth", newValue);
        dispatch(addFormStatus(error));
      }
    }
  };

  const onBlur = async (value, name) => {
    let newValue = value.trim();
    if (name === "cityEsteroBirth") {
      dispatch(addFormData({ [name]: newValue }));
      setFlagEstero(true);
      let error = formIsValid("cityEsteroBirth", newValue);
      dispatch(addFormStatus(error));
    }

    setPreviousValue({ ...previousValue, [name]: newValue });
  };

  useEffect(() => {
    if (spidFiscalCodeFlowStatus.showGenerationStep) {
      return;
    }
    if (
      (flusso === "C1" || flusso === "SPID") &&
      formData.birthDate !== "" &&
      formData.gender !== ""
    ) {
      if (
        ((formData.birthState.fiscalCode === "Z000" &&
          formData.birthTown !== "") ||
          (formData.birthState.fiscalCode !== "Z000" &&
            formData.cityEsteroBirth !== "")) &&
        !formStatus.birthDateisChanging
      ) {
        setStatusForm(checkingForm("gender&birthplace"));
        dispatch(disabledBtn(statusForm || formStatus.birthStateIsChanging));
      } else {
        dispatch(disabledBtn(true));
      }
    } else if (flusso !== "C1" && flusso !== "SPID") {
      setStatusForm(checkingForm("birthPlace"));
      dispatch(disabledBtn(statusForm || formStatus.birthStateIsChanging));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusForm,
    formData.name,
    formData.surname,
    formData.birthDate,
    formData.genders,
    formData.birthState.fiscalCode,
    formData.birthTown,
    formData.cityEsteroBirth,
    formStatus.birthStateIsChanging,
    formStatus.birthDateisChanging,
  ]);

  return (
    <div
      className="container step-container birthplace"
      id="it-ar-birthplace-container"
    >
      <div className="row">
        <div className="col-12 col-lg-6 offset-0 offset-lg-3 px-sm-0 birthplace-subcontainer">
          <div>
            <div className="row" id="it-ar-birthplace-row">
              <div id="it-ar-birthplace-col" className="col-12">
                <Nation
                  paramNation="birthState"
                  nameForm="gender&birthplace"
                  existentState="false"
                  key={keyNation}
                />
              </div>
              <div
                id="it-ar-birthplace-col2"
                className={
                  "col-12 " +
                  (formData.birthState.fiscalCode === "Z000" ? "" : "d-none")
                }
              >
                <Town
                  nameForm="gender&birthplace"
                  inputId="birthCity"
                  paramTown="birthTown"
                  paramProvinceCode="birthProvince"
                  paramCityFiscalCode="birthCityFiscalCode"
                  paramId="birthId"
                  labelId="lbl.birthTown"
                  scrollIntoViewOnOpen={scrollIntoViewOnOpenTown}
                  onSelectTown={onSelectTown}
                />
              </div>
              <div
                id="it-ar-birthplace-foreign"
                className={
                  "col-12 " +
                  (formData.birthState.fiscalCode !== "Z000" ? "" : "d-none")
                }
              >
                <div className="form-group">
                  <input
                    ref={cityEsteroBirthRef}
                    autoComplete="off"
                    className={
                      "form-control " +
                      (formStatus.cityEsteroBirth.length > 0 && flagEstero
                        ? formStatus.cityEsteroBirth === "err.required" &&
                          component.name !== "summary"
                          ? " is-present"
                          : " is-invalid"
                        : "")
                    }
                    required
                    type="text"
                    name="cityEsteroBirth"
                    value={formData.cityEsteroBirth}
                    maxLength="50"
                    spellCheck="false"
                    autoCorrect="off"
                    onFocus={(e) => moveCursorAtTheEnd(e.target)}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={(e) => onBlur(e.target.value.trim(), e.target.name)}
                  />
                  <label className="form-control-label">
                    <Translate id="lbl.birthTown" />
                  </label>
                  {flagEstero ? (
                    <MessageError
                      currentValue={formData.cityEsteroBirth}
                      currentName={"cityEsteroBirth"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
