import { instanceGet } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";
import { v4 as uuidv4 } from "uuid";

export const getCountry = async (current, channel) => {
  const url = `${REACT_APP_URL}${PORT}/residenza-ms/ms/geo/countries`;
  const data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      current: current,
      enableItaly: true,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const getPrefixes = async (channel) => {
  let url = `${REACT_APP_URL}${PORT}/config-reg-ms/ms/prefix`;
  let data = {
    params: {
      channel: channel,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};

export const getAllCities = async (current, channel) => {
  const url = `${REACT_APP_URL}${PORT}/residenza-ms/ms/geo/allCities`;
  const data = {
    params: {
      requestId: uuidv4(),
      channel: channel,
      current: current,
    },
  };
  const res = await instanceGet.get(url, data);
  return res.data;
};
