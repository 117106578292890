import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { utagView } from "../../store/utagStore/utagStore.action";
import Header from "../header/header.component";
import PrivacyDisclaimer from "../privacyDisclaimer/privacyDisclaimer.component";
import ManualRegistrationCardPdv from "./manualRegistrationCardPdv/manualRegistrationCardPdv.component";
import RegistrationOCRSelectionBottomBanner from "./registrationOCRSelectionBottomBanner/registrationOCRSelectionBottomBanner.component";
import ScanDocumentCardPdv from "./scanDocumentCardPdv/scanDocumentCardPdv.component";

export default function RegistrationOCRSelectionPdv() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      utagView({
        reg_step: "registrazione:pdv:step_0:apri un conto rivenditore",
        conversion_pagename:
          "registrazione:pdv:step_0:apri un conto rivenditore",
        reg_flusso: "pdv rivenditori",
      }),
    );
  }, [dispatch]);

  return (
    <>
      <Header />

      <main className="ocr-selection-container">
        <div className="ocr-selection-subcontainer">
          <h3 className="title">
            <Translate id="text.registrationOCR.openGameAccountSisal" />
          </h3>

          <h4 className="subtitle">
            <Translate id="text.registrationOCR.canOpenOnlyOneAccountPerFiscalCode" />
          </h4>

          <ScanDocumentCardPdv />

          <ManualRegistrationCardPdv />
        </div>

        <PrivacyDisclaimer
          className="ocr-selection-privacy-disclaimer"
          translateId="text.personalDataPrivacyPdv"
        />

        <RegistrationOCRSelectionBottomBanner />
      </main>
    </>
  );
}
