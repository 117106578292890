import React, { useEffect } from "react";
import Chat from "../../images/svg/icon/chat.svg";
import Phone from "../../images/svg/icon/phone.svg";
import { Translate, withLocalize } from "react-localize-redux";
import store from "../../store.config";
import Header from "../header/header.component";
import { getRegFlusso } from "../../utility/analytics";
import { useDispatch, useSelector } from "react-redux";
import { utagView } from "../../store/utagStore/utagStore.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";

const ErrorPage = ({ translate }) => {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  useEffect(() => {
    let bonus =
      formData.promoCode === "" ? formData.bonusCode : formData.promoCode;
    let docReg = formData.documentTypeObj
      ? formData.documentTypeObj.description
      : "";
    let errorMessage =
      formData.errorPageText === ""
        ? translate("text.somethingWentWrong")
        : translate(formData.errorPageText);
    if (formData.errorPageServices && formData.errorPageServices !== "")
      errorMessage = formData.errorPageServices;

    let options = {
      reg_step: "error-page",
      error_message: errorMessage,
      reg_bonus: bonus,
      reg_flusso: getRegFlusso(flusso, formData.fromPdv),
      reg_doc: docReg,
    };

    dispatch(utagView(options));
  }, [store.getState().localize.translations]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />
      <div className="errorPage">
        <div className="error-bg">
          <div className="lifebelt"></div>
        </div>

        <div className="container">
          <div className="header-form">
            <h5 className="title-form">
              <Translate id="text.assistance" />
            </h5>
            <h2 className="sub-title-form">
              <Translate id="text.somethingWentWrong" />
            </h2>
            <p className="label-form">
              <Translate id="text.contactForHelp" />
            </p>
          </div>
          <div className="px-md-4">
            <p>
              <Translate
                id={
                  formData.errorPageText !== ""
                    ? formData.errorPageText
                    : "text.baseText"
                }
              />{" "}
            </p>
            <div className="row">
              <div className="col-md-6 ">
                <p>
                  <span className="contactIcon">
                    <img src={Phone} width="40" alt="" />
                  </span>
                </p>
                <p className="contact-caption callInfo">
                  <strong className="contactTitle">
                    <Translate id="text.callUs" />
                  </strong>
                  <br />
                  <strong>
                    <u>
                      <a rel="noopener noreferrer" href="tel:800.999.445">
                        800.999.445
                      </a>
                    </u>
                  </strong>
                </p>
              </div>
              <div
                className="col-md-6 chatCol"
                onMouseDown={() => window.vivochaEngageChat()}
              >
                <p>
                  <span className="contactIcon">
                    <img src={Chat} width="40" alt="" />
                  </span>
                </p>
                <p className="contact-caption">
                  <strong className="contactTitle">
                    <Translate id="text.writeIn" />
                  </strong>
                  <br />
                  <u>
                    <strong>
                      <Translate id="text.liveChat" />
                    </strong>
                  </u>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withLocalize(ErrorPage);
