import { connect } from "react-redux";
import Document from "./document.component";
import * as formStatusAction from "../../store/formStatus/formStatus.action";
import * as formDataAction from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import {
  wizardSelectorsStep,
  wizardSelectorsBtn,
  wizardSelectorsComponent,
} from "../../store/wizard/wizard.selectors";
import {
  stepNext,
  stepBack,
  disabledBtn,
} from "../../store/wizard/wizard.action";
import { utagSelectors } from "../../store/utagStore/utagStore.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";

const mapStateToProps = (state) => {
  return {
    formStatus: formStatusSelectors.getFormStatus(state),
    formData: formDataSelectors.getFormData(state),
    step: wizardSelectorsStep.getWizardStep(state),
    btnDisabled: wizardSelectorsBtn.getWizardBtn(state),
    utag: utagSelectors.getUtag(state),
    component: wizardSelectorsComponent.getComponent(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFormStatus: (status) => dispatch(formStatusAction.addFormStatus(status)),
    addFormData: (data) => dispatch(formDataAction.addFormData(data)),
    stepNext: (step) => dispatch(stepNext(step)),
    stepBack: (step) => dispatch(stepBack(step)),
    disabledBtn: (status) => dispatch(disabledBtn(status)),
    utagView: (data) => dispatch(utagView(data)),
    utagLink: (data) => dispatch(utagLink(data)),
  };
};

const DocumentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Document);
export default DocumentContainer;
