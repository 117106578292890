export const isMobile = () => {
  return isAndroid() || isIOS();
};

export const isAndroid = () => {
  const regexp = /android/i;
  return regexp.test(navigator.userAgent);
};

export const isIOS = () => {
  const regexp = /iphone|kindle|ipad/i;
  return regexp.test(navigator.userAgent);
};
