import { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import popupClose from "../../images/svg/popupModal/close.svg";

export default function PopupModal({ children, show = true, className }) {
  const overlayRef = useRef(null);
  const otherClassName = !!className ? ` ${className}` : "";

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }

    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [show]);

  return (
    <CSSTransition
      nodeRef={overlayRef}
      in={show}
      timeout={400}
      classNames="popup-modal-overlay-transition"
      mountOnEnter
      unmountOnExit
    >
      <div
        ref={overlayRef}
        className={`overlay popup-modal-overlay${otherClassName}`}
      >
        <div className="popup-modal">{children}</div>
      </div>
    </CSSTransition>
  );
}

PopupModal.Header = ({ closeMe, children }) => {
  return (
    <div className="popup-modal-header">
      <h2 className="popup-modal-header-title">{children}</h2>
      <div className="popup-modal-close" onClick={closeMe}>
        <img src={popupClose} alt="close popup" />
      </div>
    </div>
  );
};

PopupModal.Body = ({ children, hideScrollbar }) => {
  return (
    <div
      className={`popup-modal-body ${hideScrollbar ? "hide-scrollbar" : ""}`}
    >
      {children}
    </div>
  );
};

PopupModal.Footer = ({ children, className, ...rest }) => {
  const otherClassName = !!className ? ` ${className}` : "";
  return (
    <div className={`popup-modal-footer${otherClassName}`} {...rest}>
      {children}
    </div>
  );
};
