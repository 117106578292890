// this reducer is needed because it manages the error in the contacts component
// but it gets enabled also on the wizard component. At this moment only phone
// error is managed here. The other one, the email error, is managed inside its
// own component.

import {
  DISABLE_PHONE_ERROR_VIEW,
  ENABLE_PHONE_ERROR_VIEW,
} from "./contacts.action";

const initialState = {
  canShowPhoneError: false,
};

export function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case ENABLE_PHONE_ERROR_VIEW:
      return { canShowPhoneError: true };
    case DISABLE_PHONE_ERROR_VIEW:
      return { canShowPhoneError: false };
    default:
      return state;
  }
}
