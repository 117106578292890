import { Translate } from "react-localize-redux";

export default function WelcomeBonusHeadForm({ promoCode, flusso }) {
  return (
    <div className="header-form" id="headerForm">
      <h2 className="sub-title-form" id="it-ar-welcomeBonus-sub-title-form">
        <Translate id={getSubTitleLbl(promoCode, flusso)} />
      </h2>

      <p
        className="label-form margin-bot-standard"
        id="it-ar-welcomeBonus-label-form"
      >
        <Translate id={getTextLbl(promoCode, flusso)} />
      </p>
    </div>
  );
}

function getSubTitleLbl(promoCode, flusso) {
  switch (flusso) {
    case "C1":
    case "SPID":
      return promoCode
        ? "text.bonusSubTitleWithPromo_C1"
        : "text.bonusSbuTitle";
    case "PDV":
    case "OCRPDV":
      return "text.typ.spid.welcomeBonus";
    default:
      return promoCode ? "text.bonusSubTitleWithPromo" : "text.bonusSbuTitle";
  }
}

function getTextLbl(promoCode, flusso) {
  switch (flusso) {
    case "C1":
      return promoCode ? "text.bonusWithPromo_C1" : "text.bonus_C1";
    case "OCRPDV":
      return "text.bonusSubTitleWithPromoPdv";
    case "PDV":
      return "text.bonusSubTitleWithPromoPdv2";
    case "SPID":
      return promoCode ? "text.bonusWithPromo_C1" : "text.bonus";
    default:
      return promoCode ? "text.bonusWithPromo" : "text.bonus";
  }
}
