import { useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as EyeClosed } from "../../../../images/svg/icon/eye_close.svg";
import { ReactComponent as EyeOpen } from "../../../../images/svg/icon/eye_open.svg";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import PopupModal from "../../../popupModal/popupModal";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupDetailFooter from "../../summaryPopupFooter/summaryPopupDetailFooter.component";
import SummaryPopupInfoBlock from "../../summaryPopupInfoBlock/summaryPopupInfoBlock.component";
import { accessDataLinkTranslateId } from "../summaryAccessData.config";

export default function SummaryAccessDataPopupDetailContent({
  onClose,
  onPressEdit,
}) {
  const { username } = useSelector(formDataSelectors.getFormData);

  const { trackClosePopup } = useSummaryAnalyticsTrackLinks();

  const handleClose = () => {
    trackClosePopup(accessDataLinkTranslateId);
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleClose}>
        <Translate id="lbl.accessData" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body">
          <SummaryPopupInfoBlock
            title={<Translate id="lbl.username" />}
            data={username}
          />

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.password" />}
            data={<PasswordRow />}
          />
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryPopupDetailFooter onClose={onClose} onPressEdit={onPressEdit} />
      </PopupModal.Footer>
    </>
  );
}

function PasswordRow() {
  const { password } = useSelector(formDataSelectors.getFormData);

  const [showPassword, setShowPassword] = useState(false);

  const shownPassword = showPassword ? password : password.replace(/./g, "*");

  const togglePswVisibility = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <div className="password-row">
      <span>{shownPassword}</span>
      <span className="password-row__eye-icon" onClick={togglePswVisibility}>
        {showPassword ? <EyeClosed /> : <EyeOpen />}
      </span>
    </div>
  );
}
