import React from "react";
import { Translate } from "react-localize-redux";
import InfoWithPdfComponent from "../../../infoWithPdf/infoWithPdf.component";
import { modalText } from "../privacyInfoModal.helpers";

const PrivacyInfoModalBody = ({ isMobile, onClose, setPdfActive, type }) => {
  return (
    <>
      <h3 className="privacy-info-modal-title">
        <Translate id={modalText[type].title} />
      </h3>
      <p className="privacy-info-modal-text">
        <Translate id={modalText[type].text} />
      </p>
      <div
        onClick={() => {
          if (isMobile) {
            onClose();
          }
        }}
      >
        <InfoWithPdfComponent
          className="privacy-info-modal-link"
          isMobile={isMobile}
          link="link.informationPrivacy"
          text="text.privacy.info.modal.link"
          setPdfActive={setPdfActive}
        />
      </div>
    </>
  );
};

export default PrivacyInfoModalBody;
