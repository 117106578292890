import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import PopupModal from "../../../popupModal/popupModal";
import { dateFormatter } from "../../summary.helpers";
import { useSummaryAnalyticsTrackLinks } from "../../summary.hooks";
import SummaryPopupDetailFooter from "../../summaryPopupFooter/summaryPopupDetailFooter.component";
import SummaryPopupInfoBlock from "../../summaryPopupInfoBlock/summaryPopupInfoBlock.component";
import { documentLinkTranslateId } from "../summaryDocument.config";

export default function SummaryDocumentPopupDetailContent({
  onClose,
  onPressEdit,
}) {
  const {
    documentType,
    number,
    releaseDate,
    expirationDate,
    releasedByEntityObj,
    documentTown,
    documentProvinceCode,
  } = useSelector(formDataSelectors.getFormData);

  const { trackClosePopup } = useSummaryAnalyticsTrackLinks();

  const handleClose = () => {
    trackClosePopup(documentLinkTranslateId);
    onClose();
  };

  return (
    <>
      <PopupModal.Header closeMe={handleClose}>
        <Translate id="lbl.document" />
      </PopupModal.Header>

      <PopupModal.Body>
        <div className="popup-body">
          <div className="popup-label">
            {getDocumentTypeString(documentType)}
          </div>

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.documentNumber" />}
            data={number}
          />

          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <SummaryPopupInfoBlock
              title={<Translate id="lbl.releaseDate" />}
              data={getDate(releaseDate)}
            />

            <SummaryPopupInfoBlock
              title={<Translate id="lbl.expirationDate" />}
              data={getDate(expirationDate)}
            />
          </div>

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.releaseFrom" />}
            data={releasedByEntityObj?.description}
          />

          <SummaryPopupInfoBlock
            title={<Translate id="lbl.docReleaseLocation" />}
            data={`${documentTown} (${documentProvinceCode})`}
          />
        </div>
      </PopupModal.Body>

      <PopupModal.Footer>
        <SummaryPopupDetailFooter onClose={onClose} onPressEdit={onPressEdit} />
      </PopupModal.Footer>
    </>
  );
}

function getDocumentTypeString(docTypeId) {
  switch (docTypeId.toString()) {
    case "10":
      return "Carta d'identità";
    case "13":
      return "Patente italiana";
    case "14":
      return "Passaporto italiano";
    default:
      return "Altro documento";
  }
}

function getDate(dateStr) {
  return !!dateStr ? dateFormatter.format(new Date(dateStr)) : "-";
}
