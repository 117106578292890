import { useAnalyticsUtagView } from "./ocrDataSummary.hooks";
import OcrSummaryAddress from "./ocrSummaryAddress/ocrSummaryAddress.component";
import OcrSummaryDocument from "./ocrSummaryDocument/ocrSummaryDocument.component";
import OcrSummaryPersonalData from "./ocrSummaryPersonalData/ocrSummaryPersonalData.component";
import OcrSummaryTextBlock from "./ocrSummaryTextBlock/ocrSummaryTextBlock.component";

export default function OcrDataSummary() {
  useAnalyticsUtagView();

  return (
    <div className="ocr-data-summary-container">
      <OcrSummaryTextBlock />

      <OcrSummaryPersonalData />

      <hr className="ocr-data-summary-separator" />

      <OcrSummaryAddress />

      <hr className="ocr-data-summary-separator" />

      <OcrSummaryDocument />
    </div>
  );
}
