import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { wizardSelectorsFlusso } from "../../../store/wizard/wizard.selectors";
import ErrorCard from "../errorCard/errorCard.component";
import { useInsertManuallyAction } from "../errorCardOcrPage.hooks";

export default function ErrorCardUnsupportedDocuments() {
  return (
    <ErrorCard
      title="Documento non supportato"
      text={
        <>
          Non è possibile caricare un documento con questo formato.
          <br />
          <br />
          Ti invitiamo a{" "}
          <strong>inserire manualmente i tuoi dati personali</strong> o
          riprovare con un altro documento.
        </>
      }
      actions={<ErrorCardUnsupportedDocumentsActions />}
      note={<ErrorCardUnsupportedDocumentsNote />}
    />
  );
}

function ErrorCardUnsupportedDocumentsActions() {
  const history = useHistory();

  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const handleClickInsertManually = useInsertManuallyAction();

  const handleClickRetry = () => {
    const regType = flusso === "OCRPDV" ? "pdv" : "cliente";
    const nextRoute = `/seleziona-documento/${regType}`;
    history.push(nextRoute);
  };

  return (
    <>
      <button
        className="error-card__btn-primary"
        onClick={handleClickInsertManually}
      >
        Inserisci manualmente i dati
      </button>
      <button className="error-card__btn-secondary" onClick={handleClickRetry}>
        Riprova con un altro documento
      </button>
    </>
  );
}

function ErrorCardUnsupportedDocumentsNote() {
  return (
    <p className="error-card__note">
      Sono accettati solo i seguenti documenti{" "}
      <strong>rilasciati in Italia</strong>, con i seguenti formati:
      <br />
      <br />
      <ul>
        <li>Carta d'identità elettronica</li>
        <li>Passaporto</li>
        <li>Patente di guida</li>
      </ul>
    </p>
  );
}
