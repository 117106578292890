export const SET_JUMIO_ACCOUNT_ID = "SET_JUMIO_ACCOUNT_ID";
export const SET_JUMIO_SDK_TOKEN = "SET_JUMIO_SDK_TOKEN";

export function setJumioAccountId(jumioAccountId) {
  return { type: SET_JUMIO_ACCOUNT_ID, payload: jumioAccountId };
}

export function setJumioSdkToken(jumioSdkToken) {
  return { type: SET_JUMIO_SDK_TOKEN, payload: jumioSdkToken };
}
