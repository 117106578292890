import { getQuestionList } from "../../services/questionController";

export const FETCH_QUESTION_LIST_START = "FETCH_QUESTION_LIST_START";
export const RECEIVE_QUESTIONLIST = "RECEIVE_QUESTIONLIST";
export const FETCH_QUESTION_LIST_ERROR = "FETCH_QUESTION_LIST_ERROR";

export const getQuestionListAPI = (channel, history) => {
  return (dispatch) => {
    dispatch(fetchQuestionListStart());
    getQuestionList(channel)
      .then((response) => {
        dispatch(receiveQuestionList(response.questions)); //TODO
      })
      .catch((err) => {
        if (err.response && err.response.error === 422) {
          dispatch(fetchQuestionListError(err));
        } else {
          history.push("/errore-registrazione");
        }
      });
  };
};

export const fetchQuestionListStart = () => {
  return {
    type: FETCH_QUESTION_LIST_START,
  };
};

export const receiveQuestionList = (state) => {
  return {
    type: RECEIVE_QUESTIONLIST,
    payload: state,
  };
};

export const fetchQuestionListError = (state) => {
  return {
    type: FETCH_QUESTION_LIST_ERROR,
    formData: state,
  };
};
