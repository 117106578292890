import { useSelector } from "react-redux";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import bonusImg from "../../images/png/bonus/bonusDetails/bonus-promo.png";
import { ReactComponent as Check } from "../../images/svg/check.svg";
import { wizardSelectorsComponent } from "../../store/wizard/wizard.selectors";
import { useProgressBarData } from "./progressBarSteps.hooks";

export default function ProgressBarSteps({ useBootstrapClasses = true }) {
  return (
    <div className="container">
      {useBootstrapClasses ? (
        <div className="row">
          <div className="col-12 col-lg-6 offset-0 offset-lg-3 px-sm-0">
            <StepsbarContainer />
          </div>
        </div>
      ) : (
        <StepsbarContainer />
      )}
    </div>
  );
}

function StepsbarContainer() {
  const progressBarData = useProgressBarData();

  return (
    <div className="stepsbar-container">
      {progressBarData.map(({ name, steps, stepContent }, index) => (
        <Step
          key={name}
          name={name}
          stepContent={stepContent}
          steps={steps}
          stepPositionIndex={index + 1}
        />
      ))}
    </div>
  );
}

function Step({ name, stepContent, steps, stepPositionIndex }) {
  const component = useSelector(wizardSelectorsComponent.getComponent);

  if (!component) return null;

  const isCompletedGroup = Math.max(...steps) < component.step;

  const isCurrentStep = steps.includes(component.step);

  return (
    <div
      className={`step-holder ${isCurrentStep ? "active-step" : ""} ${
        isCompletedGroup ? "completed-step" : ""
      }`}
    >
      <div className="step">
        {isCompletedGroup ? (
          <Check />
        ) : (
          <UncompletedStep
            steps={steps}
            stepContent={stepContent}
            name={name}
            stepPositionIndex={stepPositionIndex}
            isCurrentStep={isCurrentStep}
          />
        )}
      </div>
    </div>
  );
}

function UncompletedStep({
  steps,
  stepContent,
  name,
  stepPositionIndex,
  isCurrentStep,
}) {
  return (
    <>
      <StepFill steps={steps} />

      <div className="label-holder">
        <ComputedStepContent
          label={stepContent}
          name={name}
          stepPositionIndex={stepPositionIndex}
          isCurrentStep={isCurrentStep}
        />
      </div>
    </>
  );
}

function StepFill({ steps }) {
  const component = useSelector(wizardSelectorsComponent.getComponent);

  const fillWidth = getFillWidth(steps, component.step);

  return <div className="step-fill" style={{ width: `${fillWidth}%` }} />;
}

// it changes based on screen size and the current step
function ComputedStepContent({
  label,
  name,
  isCurrentStep,
  stepPositionIndex,
}) {
  const isAtLeastTabletBreakpoint = useMediaQuery("TabletMedium");

  if (isAtLeastTabletBreakpoint) return label;

  if (name === "bonus") {
    return isCurrentStep ? label : <img src={bonusImg} alt="bonus-img" />;
  }

  return isCurrentStep ? label : stepPositionIndex;
}

function getFillWidth(steps, currentPosition) {
  if (currentPosition < Math.min(...steps)) {
    return 0;
  } else if (Math.max(...steps) < currentPosition) {
    return 100;
  }

  const currentStepIndex = steps.indexOf(currentPosition) + 1;

  return (currentStepIndex / steps.length) * 100;
}
