import { instancePost } from "../axios.config";
import { PORT, REACT_APP_URL } from "../config";
import {
  prepareSignUpParams,
  prepareSignUpPdvParams,
} from "../utility/signUp/signUp";

export async function postSignUp(
  formData,
  fromSpid,
  emailCertified,
  recaptchaToken,
  jumioAccountId,
) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/v4/signup`;

  const formParams = prepareSignUpParams(
    formData,
    fromSpid,
    emailCertified,
    recaptchaToken,
    jumioAccountId,
  );

  const res = await instancePost.post(url, formParams);
  return res.data;
}

export async function postSignUpPdv(formData, recaptchaToken, jumioAccountId) {
  const url = `${REACT_APP_URL}${PORT}/registrazione-ms/ms/affiliation/signup`;

  const formParams = prepareSignUpPdvParams(
    formData,
    recaptchaToken,
    jumioAccountId,
  );

  const res = await instancePost.post(url, formParams);
  return res.data;
}
