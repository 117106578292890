import moment from "moment";

export const promoCodeOptionValue = "PROMOCODE";

export const getToday = () => moment();

export const bonusTitle = (title) => {
  switch (title) {
    case "tuttiigiochi":
      return "Tutti i giochi";
    case "scommesse":
      return "Scommesse";
    case "ippica":
      return "Ippica";
    case "totocalcio":
      return "Totocalcio";
    case "poker":
      return "Poker";
    case "casino":
      return "Casinò";
    case "skillgames":
      return "Giochi di carte";
    case "lotterie":
      return "Lotterie";
    case "bingo":
      return "Bingo";
    case "quickGames":
      return "Quick games";
    case "bonus":
      return "Codice promo inserito";
    default:
      return "";
  }
};

export function getActiveBonusList(bonusList, channel) {
  return bonusList
    .filter((item) => item.bonusCode != null && isActive(item, channel))
    .filter(filterByBonusDateValidity);
}

function filterByBonusDateValidity(item) {
  const today = getToday();
  const dateFormat = "DD/MM/YYYY HH:mm";

  const datePublication = moment(item.datePublication, dateFormat);
  const dateExpiration = moment(item.dateExpiration, dateFormat);

  const diffPub = today.diff(datePublication, "days");
  const diffExp = today.diff(dateExpiration, "days");

  return diffPub >= 0 && diffExp <= 0;
}

function isActive(bonusItem, channel) {
  return (
    bonusItem.canali.find((canale) => canale.idSite.toString() === channel)
      ?.isActive ?? false
  );
}
