import { useSelector } from "react-redux";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import Background from "../commons/background/background.component";
import PreFooter from "../elementForm/preFooter/preFooter.component";
import ErrorCard from "../errorCard/errorCard.component";
import Header from "../header/header.component";

export default function ErrorCardBonusPage() {
  const { cancelcallbackurl } = useSelector(formDataSelectors.getFormData);

  const handleClick = () => {
    window.location.href = cancelcallbackurl;
  };

  return (
    <div className="errorCardsPage error-card-bonus-page">
      <Background />

      <Header />

      <div className="cardsContainer">
        <ErrorCard
          titleId="err.header.title.bonusUnavailable"
          textId="err.bonusUnavailable.card.body"
          buttonTextId="lbl.errorcard.button.close"
          handleClick={handleClick}
        />
      </div>

      <PreFooter />
    </div>
  );
}
