import { useEffect, useState } from "react";

export const Breakpoint = {
  Phone: 0,
  TabletSmall: 576,
  TabletMedium: 768,
  Laptop: 992,
  Desktop: 1200,
};

/**
 * @typedef {keyof typeof Breakpoint} WindowDimension
 */

/**
 * Custom hook to listen for media query changes based on given window dimension.
 * @param {WindowDimension} windowDimension - The breakpoint key.
 * @returns {boolean} Whether the media query matches the current viewport.
 */
export function useMediaQuery(windowDimension) {
  const [matches, setMatches] = useState(
    getMediaQueryList(windowDimension).matches,
  );

  useEffect(() => {
    const mql = getMediaQueryList(windowDimension);
    const handleChange = (e) => setMatches(e.matches);

    mql.addEventListener("change", handleChange);

    return () => mql.removeEventListener("change", handleChange);
  }, [windowDimension]);

  return matches;
}

/**
 * Returns MediaQueryList object for the provided window dimension.
 * @param {WindowDimension} windowDimension - The breakpoint key.
 * @returns {MediaQueryList} The MediaQueryList object.
 */
function getMediaQueryList(windowDimension) {
  return window.matchMedia(`(min-width: ${Breakpoint[windowDimension]}px)`);
}
