import React from "react";
import { Translate } from "react-localize-redux";
import InfoIcon from "../../../images/svg/icon/info.svg";
import warningIcon from "../../../images/svg/icon/warning-info-alert.svg";

const AlertBox = ({ titleId, textId, isWarningBox }) => {
  return (
    <div className={`alert_box ${isWarningBox ? "warning_alert_box" : ""}`}>
      <div className="info-icon-wrapper">
        <img
          className="info-icon"
          src={isWarningBox ? warningIcon : InfoIcon}
          alt="info"
        />
      </div>
      <div className="content">
        {titleId && (
          <p className={`title ${isWarningBox ? "title-warning" : ""}`}>
            <Translate id={titleId} />
          </p>
        )}
        {textId && (
          <p className={`text ${isWarningBox ? "text-warning-alert" : ""}`}>
            <Translate id={textId} />
          </p>
        )}
      </div>
    </div>
  );
};

export default AlertBox;
