import { useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { ReactComponent as Next } from "../../../images/svg/icon/next.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import {
  documentContainsError,
  domicileContainsErrors,
  residenceContainsErrors,
} from "../../../utility/checkingForm/checkingForm.container";
import { useFiscalCodeNextStep } from "../../fiscalCode/fiscalCode.hooks";
import OcrSummaryLeaveConfirmPopup from "../ocrSummaryLeaveConfirmPopup/ocrSummaryLeaveConfirmPopup.component";

export default function WizardOcrSummaryDataFooter() {
  const [showLeaveConfirmPopup, setShowLeaveConfirmPopup] = useState(false);

  return (
    <div className="wizard-ocr-summary-footer">
      <JumpToFirstStepButton onClick={() => setShowLeaveConfirmPopup(true)} />

      <SubmitButton />

      {showLeaveConfirmPopup && (
        <OcrSummaryLeaveConfirmPopup
          closePopup={() => setShowLeaveConfirmPopup(false)}
        />
      )}
    </div>
  );
}

function JumpToFirstStepButton({ onClick }) {
  return (
    <button
      className="wizard-ocr-summary-footer__button wizard-ocr-summary-footer__button--back"
      onClick={onClick}
    >
      <Translate id="text.registrationOCR.returnToTheBeginning" />
    </button>
  );
}

function SubmitButton() {
  const { handleFiscalCodeNextStep } = useFiscalCodeNextStep();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const personalDataInvalid =
    !formData.name ||
    !!formStatus.name ||
    !formData.surname ||
    !!formStatus.surname ||
    !formData.fiscalCode ||
    !!formStatus.fiscalCode;

  const addressInvalid =
    residenceContainsErrors(formData, formStatus) ||
    domicileContainsErrors(formData, formStatus);

  const documentInvalid = documentContainsError(formData, formStatus);

  const disabled = personalDataInvalid || addressInvalid || documentInvalid;

  return (
    <button
      className="wizard-ocr-summary-footer__button wizard-ocr-summary-footer__button--continue"
      onClick={handleFiscalCodeNextStep}
      disabled={disabled}
    >
      <span>
        <Translate id="lbl.continuous" />
      </span>

      <span className="wizard-ocr-summary-footer__icon-next">
        <Next />
      </span>
    </button>
  );
}
