import { useSelector } from "react-redux";
import { useFlow } from "../../../hooks/useFlow";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import SignButtonUI from "../../signButton/signButtonUI.component";
import { useSignUpPdv } from "../../signButton/signButton.hooks";

export default function WelcomeBonusSignUp() {
  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);

  const { goToPreviousStep } = useFlow();

  const { signUpPdv, isLoading } = useSignUpPdv();

  const disabled =
    isLoading ||
    (formData.bonusCode === "" && formData.promoCode === "") ||
    formStatus.bonusCode !== "";

  const handleClickSubmit = async () => {
    await signUpPdv();
  };

  return (
    <div className="welcome-bonus-sign-up-container">
      <SignButtonUI
        disabled={disabled}
        onClickBack={goToPreviousStep}
        onClickSubmit={handleClickSubmit}
        signUpTranslateId="text.registrationOCR.completeSignup"
      />
    </div>
  );
}
