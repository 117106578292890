import store from "../../store.config";
export const VIEW = "VIEW";
export const LINK = "LINK";

export const utagView = (state, options = {}) => {
  const reg_step = store.getState().utagReducer.view;

  if (
    options.force ||
    reg_step.reg_step == null ||
    reg_step.reg_step !== state.reg_step
  ) {
    setTimeout(() => {
      try {
        window.utag?.view?.(state);
      } catch (e) {
        console.error(e);
      }
    }, options.delay || 0);
  }

  return {
    type: VIEW,
    view: state,
  };
};

export const utagLink = (state, options = {}) => {
  setTimeout(() => {
    try {
      window.utag?.link?.(state);
    } catch (e) {
      console.error(e);
    }
  }, options.delay || 0);

  return {
    type: LINK,
    link: state,
  };
};
