import { useSelector } from "react-redux";
import { wizardSelectorsComponent } from "../../../store/wizard/wizard.selectors";

export default function ValidationCheck({ children }) {
  const { name: componentName } = useSelector(
    wizardSelectorsComponent.getComponent,
  );

  return (
    <div className="validation-check-container">
      <div
        className={`checklist ${
          componentName === "summary" ? "ml-0 mr-0" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}
