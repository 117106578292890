import { FORM_DATA, RESET_FORM_DATA } from "./formData.action";

const initialState = {
  formData: {
    phoneNumberCertified: false,
    activeFloatingBar: true,
    name: "",
    surname: "",
    fiscalCode: "",
    gender: "",
    rechargeLimit: "",
    promoCode: "",
    promoCodeUrl: "",
    sameResidenceAndDomicile: true,
    hasDocFile: false,
    capEstero: "",
    cityEstero: "",
    addressEstero: "",
    cityEsteroBirth: "",
    addressEsteroDomi: "",
    cityEsteroDomi: "",
    capEsteroDomi: "",
    findBonus: false,
    jwt: "",
    loyalty: {
      lotteryReceipt: {
        playCode: "",
        flagPdv: "0",
      },
      sisalBonusPoints: "",
      gameCodeExist: false,
    },

    channel: "62", // Portale Web Full Responsive - www.sisal.it
    mpPartner: "", //cookie
    requestId: "", //uuid
    siteId: "", //cookie
    uploadDocsId: "", //uuidGeneratoBackEnd Dalla chiamata ocr

    //accessInfo:
    email: "",
    phonePrefix: "",
    username: "",
    password: "",
    phoneNumber: "",

    //termsAndConditions:
    gameContract: false,
    loyaltyLightConsent: false,
    personalDataProfilingTreatmentConsent: null,
    personalDataTreatmentConsent: null,
    treatmentConsents: {
      email: false,
      sms: false,
      phone: false,
    },
    privacyPolicy: false,
    termsAndConditions: false,

    //birthInfo:
    birthId: "",
    birthDate: "",
    birthProvince: "", //provinceCode
    birthState: {}, // countryFiscalCode solo z000
    birthCityFiscalCode: "",

    //document:
    documentId: "",
    number: "",
    releaseDate: "",
    expirationDate: "",
    documentCityFiscalCode: "", //cityFiscalCode
    documentProvinceCode: "", //provinceCode
    releasedByEntity: null, //releasedByEntity
    documentType: null, //type

    //ocr
    isLoadingOcr: false,
    ocrData: false,

    // residence:
    address: "",
    cap: "",
    cityFiscalCode: "",
    state: {}, //countryFiscalCode solo z000
    province: "", //provinceCode
    city: "",

    // domicile:
    domicileState: {},
    domicileCap: "",
    domicileProvince: "",
    domicileAddress: "",
    domicileCity: "",

    documentTown: "",
    birthTown: "",
    frontFileName: "",
    backFileName: "",
    documentTypeObj: null,
    releasedByEntityObj: null,
    bonusSelection: 2,

    usernamePdv: "",
    accountPin: "",
    chargingPin: "",
    fiscalCodePdv: "",
    pdvCode: { accountCode: "" },

    bonusList: [],
    bonusListAll: [],
    bonusCode: "",

    generatedFiscalCode: "",
    errorcallbackurl: "",
    endcallbackurl: "",
    cancelcallbackurl: "",
    errorPageText: "",
    nameError: "",
    securityQuestion: "",
    answer: "",

    sessionId: "",
    userAgent: "",
    ipAddress: "",
  },
};

export const formDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_DATA:
      const newObj = Object.assign(state.formData, action.formData);
      return { ...state, formData: { ...newObj } };
    case RESET_FORM_DATA:
      return { ...state, formData: { ...initialState.formData } };
    default:
      return state;
  }
};
