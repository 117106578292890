import { useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BonusStar } from "../../../images/svg/summary/bonus-star.svg";
import { addFormData } from "../../../store/formData/formData.action";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { setBonus } from "../../../store/wizard/wizard.action";
import {
  useCurrentData,
  useSummaryAnalyticsTrackLinks,
} from "../summary.hooks";
import SummaryInfoBlock from "../summaryInfoBlock/summaryInfoBlock.component";
import { bonusLinkTranslateId } from "./summaryWelcomeBonus.config";
import SummaryWelcomeBonusPopup from "./summaryWelcomeBonusPopup/summaryWelcomeBonusPopup.component";

export default function SummaryWelcomeBonus() {
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);

  const { bonusCode, promoCode } = useSelector(formDataSelectors.getFormData);

  const { trackOpenPopup } = useSummaryAnalyticsTrackLinks();

  const { getCurrentBonusFormData, getCurrentBonusWizard } = useCurrentData();

  const bonusFormDataBeforeEditRef = useRef(getCurrentBonusFormData());
  const bonusWizardBeforeEditRef = useRef(getCurrentBonusWizard());

  const bonus = !!promoCode ? promoCode : bonusCode;
  const bonusBeforeEdit = !!bonusFormDataBeforeEditRef.current?.promoCode
    ? bonusFormDataBeforeEditRef.current?.promoCode
    : bonusFormDataBeforeEditRef.current.bonusCode;

  const description = showPopup ? bonusBeforeEdit : bonus;

  const handleOpenPopup = () => {
    trackOpenPopup(bonusLinkTranslateId);

    bonusFormDataBeforeEditRef.current = getCurrentBonusFormData();
    bonusWizardBeforeEditRef.current = getCurrentBonusWizard();

    setShowPopup(true);
  };

  const restoreState = () => {
    if (!!bonusFormDataBeforeEditRef.current) {
      dispatch(addFormData(bonusFormDataBeforeEditRef.current));
      dispatch(setBonus(bonusWizardBeforeEditRef.current));
    }
  };

  return (
    <>
      <SummaryInfoBlock
        icon={<BonusStar />}
        title={<Translate id="text.typ.spid.welcomeBonus" />}
        description={description}
        onClick={handleOpenPopup}
      />

      <SummaryWelcomeBonusPopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
        onRestoreState={restoreState}
      />
    </>
  );
}
