import React from "react";
import { useState } from "react";
import { Translate } from "react-localize-redux";
import BaseErrorMessage from "../../commons/baseErrorMessage/baseErrorMessage.component";

export default function SpidFiscalCodeForm({
  inputValue,
  error,
  handleChange,
  handleSubmit,
}) {
  const [focus, setFocus] = useState(false);

  const showInvalidInput =
    !!error && ((inputValue.length > 0 && !focus) || inputValue.length === 16);

  return (
    <form className="spid-fc-card-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          className={`form-control ${showInvalidInput ? "is-invalid" : ""}`}
          type="text"
          required
          value={inputValue}
          onChange={(e) => handleChange(e.target.value.toUpperCase())}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          id="fiscal-code-input"
        />

        <label className="form-control-label" htmlFor="fiscal-code-input">
          <Translate id="lbl.fiscalcode" />
        </label>

        {inputValue.length > 0 &&
          (!focus || inputValue.length === 16) &&
          !!error && (
            <div className={"feedback-container"}>
              <BaseErrorMessage message={<Translate id={error} />} />
            </div>
          )}
      </div>

      <button
        className="spid-fc-card-button"
        disabled={!inputValue.length || !!error}
      >
        <Translate id="lbl.continuous" />
      </button>
    </form>
  );
}
