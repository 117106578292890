import { getDocumentType } from "../../services/documentUtils";

export const FETCH_DOCUMENT_TYPE_START = "FETCH_DOCUMENT_TYPE_START";
export const RECEIVE_DOCTYPES = "RECEIVE_DOCTYPES";
export const FETCH_DOCUMENT_TYPE_ERROR = "FETCH_DOCUMENT_TYPE_ERROR";

export const getDocumentTypeAPI = (dispatch, channel, history) => {
  return () => {
    dispatch(fetchDocumentTypeStart());
    getDocumentType(channel)
      .then((response) => {
        dispatch(receiveDoctypes(response.docTypes));
      })
      .catch((err) => {
        if (err.response && err.response.error === 422)
          dispatch(fetchDocumentTypeError(err));
        else {
          history.push("/errore-registrazione");
        }
      });
  };
};

export const fetchDocumentTypeStart = () => {
  return {
    type: FETCH_DOCUMENT_TYPE_START,
  };
};

export const receiveDoctypes = (state) => {
  return {
    type: RECEIVE_DOCTYPES,
    payload: state,
  };
};

export const fetchDocumentTypeError = (state) => {
  return {
    type: FETCH_DOCUMENT_TYPE_ERROR,
    formData: state,
  };
};
