import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import { getActiveBonusList } from "../../../welcomeBonus/welcomeBonus.helpers";
import { useSelectedBonusDetection } from "../../../welcomeBonus/welcomeBonus.hooks";
import WelcomeBonusCard from "../../../welcomeBonus/welcomeBonusCard/welcomeBonusCard.component";
import WelcomeBonusCardList from "../../../welcomeBonus/welcomeBonusCardList/welcomeBonusCardList.component";
import WelcomeBonusOthers from "../../../welcomeBonus/welcomeBonusOthers/welcomeBonusOthers.component";

export default function SummaryWelcomeBonusListNoPromo({
  onClickChangeButton,
  onClickInfoLink,
}) {
  const sortedBonusList = useSortedBonusesOnFirstRender();

  if (sortedBonusList.length === 0) {
    return (
      <WelcomeBonusCardList
        bonusList={[]}
        onClickChangeButton={onClickChangeButton}
        onClickInfoLink={onClickInfoLink}
      />
    );
  }

  return (
    <>
      <WelcomeBonusCard
        bonusItem={sortedBonusList[0]}
        onClickChangeButton={onClickChangeButton}
        onClickInfoLink={onClickInfoLink}
      />
      <WelcomeBonusOthers
        otherBonusList={sortedBonusList.slice(1)}
        onClickChangeButton={onClickChangeButton}
        onClickInfoLink={onClickInfoLink}
      />
    </>
  );
}

function useSortedBonusesOnFirstRender() {
  const [renderedBonusList, setRenderedBonusList] = useState([]);

  const { bonusListAll, channel } = useSelector(formDataSelectors.getFormData);

  const detectSelectedBonus = useSelectedBonusDetection();

  // this is used to sort the bonus list on first render but not afterwards.
  // this is the reason why detectSelectedBonus is not a dependency of this hook
  useEffect(() => {
    const activeBonusList = getActiveBonusList(bonusListAll, channel) ?? [];

    // selected bonus first
    const sortedBonusList = activeBonusList.sort(
      (a, b) =>
        (detectSelectedBonus(a) ? -1 : 1) - (detectSelectedBonus(b) ? -1 : 1),
    );

    setRenderedBonusList(sortedBonusList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonusListAll, channel]);

  return renderedBonusList;
}
