import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../../store/formStatus/formStatus.selectors";
import { getActiveBonusList } from "../welcomeBonus.helpers";
import WelcomeBonusCard from "../welcomeBonusCard/welcomeBonusCard.component";
import WelcomeBonusManualInsertBtn from "../welcomeBonusManualInsertBtn/welcomeBonusManualInsertBtn.component";

export default function WelcomeBonusCardList({
  bonusList,
  onClickInfoLink,
  onClickChangeButton,
}) {
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const { channel } = useSelector(formDataSelectors.getFormData);

  return (
    <>
      {(formStatus.bonusCode !== "" || bonusList.length === 0) && (
        <Translate id="err.bonusNotFound" />
      )}

      <div className="bonus-card" id="it-ar-welcomeBonus-row">
        {getActiveBonusList(bonusList, channel).map((item, index) => (
          <WelcomeBonusCard
            key={index}
            bonusItem={item}
            onClickInfoLink={onClickInfoLink}
            onClickChangeButton={onClickChangeButton}
          />
        ))}
      </div>

      <WelcomeBonusManualInsertBtn onClick={onClickChangeButton} />
    </>
  );
}
