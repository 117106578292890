import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loghiAttivazione from "../../images/svg/loghi-attivazione.svg";
import logoSisal from "../../images/svg/logo-sisal-new.svg";
import { getAllCitiesAPI } from "../../store/allCities/allCities.action";
import { allCitiesSelectors } from "../../store/allCities/allCities.selectors";
import { getCountriesAPI } from "../../store/countries/countries.action";
import { countriesSelectors } from "../../store/countries/countries.selectors";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { addFormStatus } from "../../store/formStatus/formStatus.action";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { disabledBtn } from "../../store/wizard/wizard.action";
import { checkingForm } from "../../utility/checkingForm/checkingForm.container";
import MessageError from "../elementForm/messageError/messageError.component";
import PrivacyDisclaimer from "../privacyDisclaimer/privacyDisclaimer.component";
import {
  getAnalyticErrorMessage,
  useActiveAccountHelper,
  useDebouncedCheckValidityApi,
} from "./activeAccount.hooks";
import ActiveAccountBtnBack from "./activeAccountBtnBack/activeAccountBtnBack.component";
import ActiveAccountBtnSubmit from "./activeAccountBtnSubmit/activeAccountBtnSubmit.component";

export default function ActiveAccount({ hideUtag }) {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const allCities = useSelector(allCitiesSelectors.getAllCities);
  const countries = useSelector(countriesSelectors.getCountries);

  const { isFormCorrectlyFilledIn, validateAndGoToNextStep } =
    useActiveAccountHelper();

  const debouncedCheckValidityApi = useDebouncedCheckValidityApi();

  const [valueForMessageError, setValueForMessageError] = useState({
    usernamePdv: "",
    accountPin: "",
    chargingPin: "",
  });

  const [flag, setFlag] = useState({
    usernamePdv: false,
    accountPin: false,
  });

  const history = useHistory();

  useEffect(() => {
    dispatch(disabledBtn(checkingForm("activeAccount")));
  }, [dispatch]);

  useEffect(() => {
    if (allCities.fetched === false) {
      dispatch(getAllCitiesAPI(formData.channel, history));
    }
  }, [allCities.fetched, dispatch, formData.channel, history]);

  useEffect(() => {
    if (countries.fetchedFalse === false) {
      dispatch(getCountriesAPI(false, formData.channel, history));
    }
  }, [countries.fetchedFalse, dispatch, formData.channel, history]);

  useEffect(() => {
    if (!hideUtag) {
      dispatch(
        utagView(
          {
            reg_step: "step1:pdv:attiva il tuo conto",
            reg_flusso: "pdv",
            conversion_pagename: "step1:pdv:attiva il tuo conto",
          },
          { delay: 700 },
        ),
      );
    }
  }, [dispatch, hideUtag]);

  useEffect(() => {
    dispatch(
      addFormStatus({
        usernamePdv: "",
        accountPin: "",
        chargingPin: "",
      }),
    );
    dispatch(
      addFormData({
        usernamePdv: "",
        accountPin: "",
        chargingPin: "",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      addFormData({
        loyalty: {
          lotteryReceipt: {
            flagPdv: "0",
            playCode: "",
          },
          sisalBonusPoints: "",
          gameCodeExist: false,
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    // cleanup possibly pending debounced api call
    return () => debouncedCheckValidityApi.cancel();
  }, [debouncedCheckValidityApi]);

  useEffect(() => {
    if (formStatus.chargingPin) {
      const errorMessageRecharge = getAnalyticErrorMessage(
        formStatus.chargingPin,
      );
      dispatch(
        utagLink({
          error_message: errorMessageRecharge,
          reg_event: "form-error",
          conversion_pagename: "step1:pdv:attiva il tuo conto",
        }),
      );
    }
  }, [formStatus.chargingPin, dispatch]);

  const handleBlurUsername = (event) => {
    setFlag((flag) => ({ ...flag, usernamePdv: true }));
    setValueForMessageError((value) => ({
      ...value,
      usernamePdv: event.target.value,
    }));
    if (event.target.value.length === 0) {
      dispatch(addFormStatus({ usernamePdv: "err.required" }));
    }
  };

  const handleBlurAccountPin = (event) => {
    setFlag((flag) => ({ ...flag, accountPin: true }));
    setValueForMessageError((value) => ({
      ...value,
      accountPin: event.target.value,
    }));
    if (event.target.value.length === 0) {
      dispatch(addFormStatus({ accountPin: "err.required" }));
    }
  };

  const handleBlurCharginPin = (event) => {
    setValueForMessageError((value) => ({
      ...value,
      chargingPin: event.target.value,
    }));
  };

  const handleChangeUsername = async (event) => {
    const value = event.target.value;

    setFlag({ ...flag, usernamePdv: true });
    dispatch(addFormStatus({ usernamePdv: "" }));

    if (value.length <= 16) {
      dispatch(addFormData({ usernamePdv: value }));

      if (isFormCorrectlyFilledIn()) {
        debouncedCheckValidityApi();
      }
    }
  };

  const handleChangeAccountPin = async (event) => {
    const value = event.target.value;

    setFlag({ ...flag, accountPin: true });
    dispatch(addFormStatus({ accountPin: "" }));

    if (/^[0-9]{1,4}$/.test(value) || value === "") {
      dispatch(addFormData({ accountPin: value }));

      if (isFormCorrectlyFilledIn()) {
        debouncedCheckValidityApi();
      }
    }
  };

  const handleChangeChargingPin = (event) => {
    const value = event.target.value;

    dispatch(addFormStatus({ chargingPin: "" }));

    if (/^[0-9]{1,6}$/.test(value) || value === "") {
      dispatch(addFormData({ chargingPin: value }));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && isFormCorrectlyFilledIn()) {
      validateAndGoToNextStep();
    }
  };

  const showGenericPdvError = () => {
    return (
      formStatus.usernamePdv === "err.generic.pdv" &&
      formStatus.accountPin === "err.generic.pdv" &&
      formStatus.chargingPin === "err.generic.pdv"
    );
  };

  return (
    <div className="activate-account-page">
      <div
        className="activeAccount container"
        id="it-ar-activeAccount-container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className={"row justify-content-center"}
          style={{ maxWidth: "570px" }}
        >
          <div className={"col-12 offset-0 px-sm-0 is-not-center"}>
            <form>
              <div className="header-form" id="it-ar-activeAccount-header-form">
                <h2
                  className="sub-title-form"
                  id="it-ar-activeAccount-sub-title"
                >
                  <Translate id="text.activeSisalAccount" />
                </h2>

                <p
                  className="label-form margin-bot-standard"
                  id="it-ar-activeAccount-label"
                >
                  <Translate id="text.insertPin" />
                </p>
              </div>
              <div className="row mx-0" id="it-ar-activeAccount-header-form">
                <div
                  className="col-12 col-sm-8 widthCard px-0"
                  id="it-ar-activeAccount-widthCard"
                >
                  <div
                    className="card-active mr-md-0"
                    id="it-ar-activeAccount-card-active"
                  >
                    <div
                      className="row mb-0 mb-md-3"
                      id="it-ar-activeAccount-row1"
                    >
                      <div
                        className="col-6 text-left"
                        id="it-ar-activeAccount-coltextleft"
                      >
                        <img
                          src={logoSisal}
                          className="img-card-sisal"
                          alt=""
                        />
                      </div>

                      <div
                        className="col-6 text-right"
                        id="it-ar-activeAccount-coltextright"
                        style={{ paddingRight: "0px" }}
                      >
                        <img
                          id="it-ar-activeAccount-loghiAttivazione"
                          src={loghiAttivazione}
                          className="img-card-attivazione mr-2 mr-md-0"
                          alt=""
                        />
                      </div>
                    </div>

                    <div
                      className={
                        "form-group form-group-card mt-3 mt-md-3 " +
                        (formStatus.usernamePdv.length > 0
                          ? formStatus.usernamePdv === "err.generic.pdv"
                            ? " "
                            : formStatus.usernamePdv === "err.required"
                              ? flag.usernamePdv
                                ? " "
                                : ""
                              : " "
                          : "")
                      }
                      id="it-ar-activeAccount-formgroup1"
                    >
                      <input
                        className={
                          "form-control" +
                          (formStatus.usernamePdv.length > 0
                            ? formStatus.usernamePdv === "err.generic.pdv"
                              ? " is-invalid-yellow"
                              : formStatus.usernamePdv === "err.required"
                                ? flag.usernamePdv
                                  ? " is-present"
                                  : " "
                                : " is-invalid"
                            : "")
                        }
                        required
                        type="text"
                        id="it-ar-activeAccount-usernamePdv"
                        value={formData.usernamePdv}
                        onChange={handleChangeUsername}
                        onBlur={handleBlurUsername}
                        name="usernamePdv"
                        onKeyDown={handleKeyDown}
                      />
                      <label
                        className="form-control-label"
                        htmlFor="it-ar-activeAccount-usernamePdv"
                      >
                        <Translate id="lbl.username" />
                      </label>

                      {formStatus.usernamePdv !==
                        "err.generic.pdv.mismatch" && (
                        <MessageError
                          currentValue={valueForMessageError.usernamePdv}
                          currentName="usernamePdv"
                        />
                      )}
                    </div>

                    <div
                      className={
                        "form-group form-group-card " +
                        (formStatus.accountPin.length > 0
                          ? formStatus.accountPin === "err.generic.pdv"
                            ? " "
                            : formStatus.accountPin === "err.required"
                              ? flag.accountPin
                                ? " "
                                : ""
                              : " "
                          : "")
                      }
                      id="it-ar-activeAccount-formgroup2"
                    >
                      <input
                        className={
                          "form-control" +
                          (formStatus.accountPin.length > 0
                            ? formStatus.accountPin === "err.generic.pdv"
                              ? " is-invalid-yellow"
                              : formStatus.accountPin === "err.required"
                                ? flag.accountPin
                                  ? " is-present"
                                  : " "
                                : " is-invalid"
                            : "")
                        }
                        required
                        type="number"
                        id="it-ar-activeAccount-accountPin"
                        value={formData.accountPin}
                        onChange={handleChangeAccountPin}
                        onBlur={handleBlurAccountPin}
                        name="accountPin"
                        onKeyDown={handleKeyDown}
                      />
                      <label
                        className="form-control-label"
                        htmlFor="it-ar-activeAccount-accountPin"
                      >
                        <Translate id="lbl.pinprov" />
                      </label>

                      <MessageError
                        id="it-ar-activeAccount-msgAccountPin"
                        currentValue={valueForMessageError.accountPin}
                        currentName="accountPin"
                      />
                    </div>

                    <div
                      className="form-group form-group-card mb-0"
                      id="it-ar-activeAccount-formgroup3"
                    >
                      <input
                        className={
                          "form-control" +
                          (formStatus.chargingPin.length > 0
                            ? formStatus.chargingPin === "err.generic.pdv"
                              ? " is-invalid-yellow"
                              : " is-invalid"
                            : "")
                        }
                        required
                        id="it-ar-activeAccount-chargingPin"
                        value={formData.chargingPin}
                        type="number"
                        onChange={handleChangeChargingPin}
                        onBlur={handleBlurCharginPin}
                        name="chargingPin"
                        onKeyDown={handleKeyDown}
                      />
                      <label
                        htmlFor="it-ar-activeAccount-chargingPin"
                        className="form-control-label"
                        style={{ fontWeight: "400" }}
                      >
                        <Translate id="lbl.pinric" />
                      </label>

                      <MessageError
                        id="it-ar-activeAccount-msgChargingPin"
                        currentValue={valueForMessageError.chargingPin}
                        currentName="chargingPin"
                      />
                    </div>
                  </div>

                  {showGenericPdvError() && (
                    <div className="text-error-pdv text-error-pdv-generic">
                      <Translate id={formStatus.usernamePdv} />
                    </div>
                  )}
                </div>

                <div
                  className="col-12 col-sm infoCardActiveAccount pl-0 pr-0 mr-0"
                  id="it-ar-activeAccount-col1"
                >
                  <h5
                    className="text-pdv-right"
                    id="it-ar-activeAccount-pdvRightTop"
                  >
                    <Translate id="text.pdvRightTop" />
                  </h5>

                  <h5
                    className="text-pdv-right down"
                    id="it-ar-activeAccount-pdvRightSoft"
                  >
                    <span className="text-pdv-right soft">
                      {" "}
                      <Translate id="text.pdvRightSoft" />
                    </span>
                  </h5>
                </div>
              </div>
            </form>

            <div
              className="d-flex flex-row justify-content-space-between align-items-center"
              style={{ marginTop: "87px" }}
            >
              <div className="col pl-0">
                <ActiveAccountBtnBack />
              </div>

              <div className="d-flex">
                <ActiveAccountBtnSubmit />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ActivateAccountPrivacyDisclaimer />
    </div>
  );
}

function ActivateAccountPrivacyDisclaimer() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      utagLink({
        reg_event: "informativa-privacy",
        conversion_pagename: "step1:pdv:attiva il tuo conto",
        reg_cta: "informativa sulla privacy",
      }),
    );
  };

  return <PrivacyDisclaimer onClick={handleClick} className="" />;
}
