import { useMemo } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";
import ProgressBarBonusStep from "./progressBarBonusStep/progressBarBonusStep.component";

export function useProgressBarData() {
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  const progressBarData = useMemo(() => {
    switch (flusso) {
      case "C1":
        return stepGroupsFlowC1;
      case "OCR":
        return stepGroupsFlowOCR;
      case "OCRPDV":
        return stepsGroupsFlowOCRPDV;
      case "PDV":
        return stepsGroupsFlowPDV;
      default:
        return [];
    }
  }, [flusso]);

  return progressBarData;
}

const stepGroupsFlowC1 = [
  {
    name: "personal data",
    steps: [0, 1, 2, 3, 4, 5],
    stepContent: <Translate id="lbl.personalData" />,
  },
  {
    name: "access data",
    steps: [6],
    stepContent: <Translate id="lbl.accessData" />,
  },
  {
    name: "bonus",
    steps: [8],
    stepContent: <ProgressBarBonusStep />,
  },
  {
    name: "game account",
    steps: [9, 10, 11],
    stepContent: <Translate id="lbl.gameAccount" />,
  },
];

const stepGroupsFlowOCR = [
  {
    name: "personal data",
    steps: [1],
    stepContent: <Translate id="lbl.personalData" />,
  },
  {
    name: "access data",
    steps: [2, 3],
    stepContent: <Translate id="lbl.accessData" />,
  },
  {
    name: "bonus",
    steps: [4],
    stepContent: <ProgressBarBonusStep />,
  },
  {
    name: "game account",
    steps: [5, 6],
    stepContent: <Translate id="lbl.gameAccount" />,
  },
];

const stepsGroupsFlowOCRPDV = [
  {
    name: "personal data",
    steps: [1],
    stepContent: <Translate id="lbl.personalData" />,
  },
  {
    name: "access data",
    steps: [2],
    stepContent: <Translate id="lbl.accessData" />,
  },
  {
    name: "bonus",
    steps: [3],
    stepContent: <ProgressBarBonusStep />,
  },
];

const stepsGroupsFlowPDV = [
  {
    name: "personal data",
    steps: [1, 2, 3, 4],
    stepContent: <Translate id="lbl.personalData" />,
  },
  {
    name: "identity document",
    steps: [5],
    stepContent: <Translate id="text.typ.spid.document" />,
  },
  {
    name: "bonus",
    steps: [6],
    stepContent: <ProgressBarBonusStep />,
  },
];
