import React from "react";
import { wizardSelectorsComponent } from "../../store/wizard/wizard.selectors";
import ContactsHeaderForm from "./contactsHeaderForm/contactsHeaderForm.component";
import { useSelector } from "react-redux";
import EmailContact from "./email/emailContact.component";
import PhoneContact from "./phone/phoneContact.component";

export default function Contacts() {
  const component = useSelector(wizardSelectorsComponent.getComponent);

  return (
    <div
      className={
        "contacts container step-container " +
        (component.name === "summary" ? " pb-0" : "pb-3")
      }
      id="it-ar-contacts-container"
    >
      <div className="row">
        <div
          className={
            "col-12" +
            (component.name === "contacts"
              ? " col-lg-6 offset-0 offset-lg-3 px-sm-0"
              : "")
          }
        >
          <ContactsHeaderForm />

          <EmailContact />

          <PhoneContact />
        </div>
      </div>
    </div>
  );
}
