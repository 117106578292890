import { useSelector } from "react-redux";
import useQueryParams from "../../hooks/useQueryParams";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { formStatusSelectors } from "../../store/formStatus/formStatus.selectors";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";
import { errorCardsPageText, genericError } from "./errorCardsPage.helpers";

export function useCardErrorKey() {
  const { searchParams } = useQueryParams();

  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const formStatus = useSelector(formStatusSelectors.getFormStatus);
  const spidStatus = useSelector(spidStatusSelectors.getSpidStatus);
  const spidFiscalCodeFlowStatus = useSelector(
    spidStatusSelectors.getSpidFiscalCodeFlowStatus,
  );
  const formData = useSelector(formDataSelectors.getFormData);

  const cardErrorType = searchParams.get("type");

  let cardError;
  if (cardErrorType === "fiscal-code") {
    const isPdvFlow = flusso === "PDV" || flusso === "OCRPDV";
    if (isPdvFlow) {
      switch (formStatus.fiscalCode) {
        case "err.fiscalcode.alreadypresent":
          cardError = "err.fiscalcode.alreadypresentPdv";
          break;
        case "err.fiscalcode.activationalreadystarted":
          cardError = "err.fiscalcode.activationalreadystartedPdv";
          break;
        default:
          cardError = formStatus.fiscalCode;
          break;
      }
    } else {
      cardError = formStatus.fiscalCode;
    }
  } else if (spidStatus.spidCallbackError) {
    cardError = spidStatus.spidCallbackError;
  } else if (spidFiscalCodeFlowStatus.error) {
    cardError = spidFiscalCodeFlowStatus.error;
  } else {
    cardError = formData.nameError;
  }

  return errorCardsPageText[cardError] ? cardError : genericError;
}
