import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../header/header.component";
import { LOGIN_REDIRECT } from "../../config";
import { postJumioInfo, postJumioDocumentStatus } from "../../services/jumio";
import { customRedirectRecaptchaToken } from "../../utility/thankYouPage/thankYouPage";

const Jumio = ({ addFormData, formData, utagLink }) => {
  const [iframeUrl, setIframeUrl] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const getIframeUrl = async () => {
      try {
        const jumioResponse = await postJumioInfo(
          formData.billCodeId,
          formData.channel,
          formData.ipAddress,
        );
        setIframeUrl(jumioResponse.url);
      } catch (error) {
        return history.replace("/errore-registrazione");
      }
    };

    const setJumioDocumentStatus = () =>
      postJumioDocumentStatus(
        formData.channel,
        formData.ipAddress,
        formData.token,
      ).finally(() => {
        customRedirectRecaptchaToken(
          formData,
          addFormData,
          `${LOGIN_REDIRECT}/area-riservata/profilo/?info=uploaded&from=reg#info-document`,
          history,
        );
      });

    const iframeEventListener = (event) => {
      if (event.data && formData.token) {
        try {
          const eventData = JSON.parse(event.data);
          if (eventData.payload && eventData.payload.value === "success")
            setJumioDocumentStatus();
        } catch (error) {
          console.error("ERROR: ", error);
        }
      }
    };

    getIframeUrl();
    window.addEventListener("message", iframeEventListener, false);

    return () => {
      window.removeEventListener("message", iframeEventListener);
    };
  }, [addFormData, formData, history, utagLink]);

  return (
    <>
      <Header />
      {iframeUrl && (
        <div className="jumio-container">
          <iframe
            title="jumio"
            src={iframeUrl}
            className="jumio-iframe"
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
            allowFullScreen
          />
        </div>
      )}
    </>
  );
};

export default Jumio;
