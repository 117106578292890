import React from "react";
import { Translate } from "react-localize-redux";

const MessageYellow = () => {
  return (
    <>
      <label className="messageYellow form-control-label text-label-ocr mb-0">
        <Translate id="text.cantread" />
      </label>
    </>
  );
};
export default MessageYellow;
