import React from "react";
import { Document, Page } from "react-pdf";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";

function Doc({ value }) {
  const [numPages, setNumPages] = React.useState(0);

  const onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    setNumPages(numPages);
  };

  return (
    <Document
      file={value}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={<FullPageLoader />}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} loading={null} />
      ))}
    </Document>
  );
}

export default function DocumentViewer({
  pdfActive,
  setPdfActive,
  pdfHeaderTitle,
}) {
  const handleClickCloseBtn = () => {
    setPdfActive({ status: false, value: "", title: "" });
  };

  return (
    <div
      id="it-ar-termsandconditions-documentViewer"
      className={
        "documentViewer" + (pdfActive.status === true ? "" : " d-none")
      }
    >
      <div id="it-ar-termsandconditions-closePdf" className="closePdf">
        <div
          className={pdfHeaderTitle ? "pdf-header-title" : "pdf-title-d-none"}
        >
          {pdfHeaderTitle}
        </div>
        <button
          type="button"
          className="btn btn-discard"
          tabIndex={0}
          onClick={handleClickCloseBtn}
        />
      </div>
      {pdfActive.status === true && <Doc value={pdfActive.value} />}
    </div>
  );
}
