import { useDispatch, useSelector } from "react-redux";
import { getSelfLimitationRegistration } from "../../services/selfLimitationController";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import {
  fetchRechargeLimitError,
  fetchRechargeLimitStart,
  fetchRechargeLimitSuccess,
} from "../../store/rechargeLimit/rechargeLimit.action";
import { useEffect } from "react";

export function useRechargeLimitsFetch() {
  const dispatch = useDispatch();

  const { birthDate } = useSelector(formDataSelectors.getFormData);

  const { infoChannel, channel, ipAddress } = useSelector(
    formDataSelectors.getFormData,
  );

  useEffect(() => {
    const userAge = new Date(birthDate);

    const fetchData = async () => {
      try {
        dispatch(fetchRechargeLimitStart());

        const selfLimitationRegistrationResponse =
          await getSelfLimitationRegistration(
            userAge,
            ipAddress,
            channel,
            infoChannel,
          );

        const { approvalAmount, maxAmount } =
          selfLimitationRegistrationResponse.responseObject.typeList.find(
            (type) => type.code === "DW",
          ).clusterList[0];

        dispatch(fetchRechargeLimitSuccess({ approvalAmount, maxAmount }));
      } catch (e) {
        dispatch(fetchRechargeLimitError());
        console.error(e);
      }
    };

    fetchData();
  }, [birthDate, channel, dispatch, infoChannel, ipAddress]);
}
