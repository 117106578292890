import { Translate } from "react-localize-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as IdDocument } from "../../../images/svg/thankyouPage/spid/idDocument.svg";

export default function UploadDocumentCard() {
  const history = useHistory();

  const handleClick = async () => {
    history.push("/carica-documento");
  };

  return (
    <div className="upload-document-card">
      <div className="upload-document-card__desc">
        <IdDocument />
        <Translate id="text.typ.spid.document" />
      </div>

      <button className="upload-document-card__button" onClick={handleClick}>
        <Translate id="text.typ.spid.upload" />
      </button>
    </div>
  );
}
